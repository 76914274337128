import React from 'react'

const Comp12 = () => {
  return (
    <div className='fs-5'>
      <h2>12. Harakatlanish tezligi</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          12.1
        </span>
        Haydovchi harakatning serqatnovligini, transport vositasi va yukning
        hususiyati hamda holatini, yo’l va ob-havo sharoitini, shuningdek,
        harakatlanish yo’nalishidagi ko’rinishni hisobga olgan holda transport
        vositasini belgilangan cheklangan tezlikdan oshirmasdan boshqarishi
        kerak. <br />
        Transport vositasining tezligi haydovchiga Qoidalar talablarini bajarish
        uchun uning harakatini doimo nazorat qilib borish imkoniyatini berishi
        kerak. <br />
        Harakatlanishda, haydovchi aniqlay olishi imkoniyatida bo’lgan havf yoki
        to’siq paydo bo’lsa, u transport vositasining tezligini to’la
        to’xtashini ta’minlaydigan darajada kamaytirish yoki to’siqni boshqa
        harakat qatnashchilari uchun havfsiz aylanib o’tish choralarini ko’rishi
        kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          12.2
        </span>
        Aholi yashaydigan joylarda transport vositalari tezligini soatiga 70
        kilometrdan oshirmasdan harakatlanishga ruxsat etiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          12.3
        </span>
        Aholi yashaydigan joylardan tashqarida: <br />
        engil avtomobillarga va ruxsat etilgan to’la vazni 3,5 tonnadan
        oshmaydigan yuk avtomobillariga tezlikni soatiga 100 kilometrdan
        oshirmasdan; <br />
        shaharlararo qatnaydigan avtobuslarga va mikro-avtobuslarga tezlikni
        soatiga 90 kilometrdan oshirmasdan; <br />
        boshqa avtobuslar, tirkamali engil avtomobillar, mototsikllar, ruxsat
        etilgan to’la vazni 3,5 tonnadan ortiq bo’lgan yuk avtomobillariga
        tezlikni soatiga 80 kilometrdan oshirmasdan; <br />
        tirkamali yuk avtomobillariga tezlikni soatiga 70 kilometrdan
        oshirmasdan harakatlanishga ruxsat etiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          12.4
        </span>
        Har qanday yo’llarda: <br />
        odam tashiyotgan yuk avtomobillariga tezlikni soatiga 60 kilometrdan
        oshirmasdan; <br />
        mexanik transport vositalarini shatakka olgan transport vositalariga
        tezlikni soatiga 50 kilometrdan oshirmasdan; <br />
        havfli, og’ir vaznli va katta o’lchamli yuklarni tashiyotgan, tashkiliy
        jamlanmada harakatlanayotgan transport vositalariga DAN bilan
        kelishilgan tezlikdan oshirmasdan harakatlanishga ruxsat etiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          12.5
        </span>
        Haydovchilarga quyidagilar taqiqlanadi: <br />
        tezlikni mazkur transport vositasining texnik tavsifnomasida
        ko’rsatilgan eng yuqori tezlikdan oshirish; <br />
        tezlikni transport vositasiga o’rnatilgan taniqlik belgi (Tezlik
        cheklangan)da ko’rsatilgan qiymatidan oshirish; <br />
        zarurat bo’lmaganda juda past tezlikda harakatlanib, boshqa transport
        vositalariga halaqit berish; <br />
        yo’l-transport hodisasining oldini olish zarurati bo’lmaganda, keskin
        tormoz berish.
      </p>
    </div>
  )
}

export default Comp12
