import axios from 'axios'
import React, { useEffect, useState } from 'react'

const Test = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    const getTests = async () => {
      const result = await axios.get(
        `${process.env.PUBLIC_URL}/data/uzlotin.json`,
      )
      setData(result.data)
    }
    getTests()
  }, [])
  return (
    <div className='w-50 mx-auto'>
      {data.length &&
        data.map((d: any) => (
            <div key={d.id}>
                <h2>{d.id}-Savol</h2>
            {d.media.exist ? (
              <img className='d-block mx-auto'
                src={`${process.env.PUBLIC_URL}/images/${d.media.name}.png`}
                alt=""
              />
            ) : (
              ''
            )}
                <h4>{d.question}</h4>
                <div>
                    {
                        d.choises.map((choise: any) =>
                        {
                            return <p className={ choise.answer?"bg-success p-1 text-white":"bg-secondary p-1 text-white"}>{ choise.text}</p>
                        })
                    }
                </div>
          </div>
        ))}
    </div>
  )
}

export default Test
