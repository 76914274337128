import React from 'react'

const Comp10 = () => {
  return (
    <div className="fs-5">
      <h2>10. Ҳаракатланишни бошлаш, йўналишларни ўзгартириш</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.1
        </span>
        Ҳайдовчи ҳаракатланишни бошлаш, қайта тизилиш, бурилиш (қайрилиб олиш),
        йўналишини ўзгартириш ва тўхташдан олдин ўз ҳаракати билан бошқа
        транспорт воситаларига ҳавф солмаётганлигига ва тўсқинлик
        қилмаётганлигига ишонч ҳосил қилиши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.2
        </span>
        Ёндош ҳудудлардан йўлга чиқаётган ҳайдовчи унда ҳаракатланаётган
        транспорт воситаларига ва пиёдаларга, шунингдек, ундан чиқишда ўзининг
        ҳаракат йўналишини кесиб ўтаётган пиёдалар ва велосипедчиларга йўл
        бериши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.3
        </span>
        Ҳайдовчи қайта тизилишда ён бўлакда йўлакай ҳаракат йўналишини
        ўзгартирмасдан кетаётган транспорт воситаларига йўл бериши керак.
        <br />
        Йўлакай йўналишда ҳаракатланаётган транспорт воситалари бир вақтда қайта
        тизилаётганда, ҳайдовчи ўнг томондаги транспорт воситасига йўл бериши
        керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.4
        </span>
        Aйланма ҳаракатланиш ташкил қилинган чорраҳаларга кириш учун бурилишдан
        бошқа барча ҳолларда ҳайдовчи ўнгга, чапга бурилиш ёки қайрилиб олишдан
        олдин шу йўналишда ҳаракатланиш учун мўлжалланган қатнов қисмининг енг
        четки ҳолатини егаллаши шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.5
        </span>
        Бурилишни шундай амалга ошириш керакки, бунда қатнов қисмларининг
        кесишмасидан чиқаётган транспорт воситаси қарама-қарши йўналишдаги
        ҳаракат томонига ўтиб кетмасин. <br />
        Ўнгга бурилаётган транспорт воситаси иложи борича қатнов қисмининг ўнг
        томонига яқинроқда ҳаракатланиши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.6
        </span>
        Транспорт воситаси ўзининг габарит ўлчамларига ёки бошқа техник
        сабабларига кўра Қоидаларнинг 10.4 банди талабларига мувофик бурилишни
        бажара олмаса, йўл ҳаракати ҳавфсизлигини таъминлаб ва бошқа транспорт
        воситаларига ҳалақит бермасдан ундан четга чиқишига йўл кўйилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.7
        </span>
        Релъссиз транспорт воситасининг ҳайдовчиси йўлнинг чорраҳадан ташқари
        жойида чапга бурилиш ёки қайрилиб олишда қарама-қарши йўналишдан
        келаётган транспорт воситаларига ва йўлакай йўналишдаги трамвайга йўл
        бериши шарт. <br />
        Чорраҳадан ташқаридаги қатнов қисмининг кенглиги четки чап вазиятдан
        қайрилиб олиш учун етарли бўлмаса, уни қатнов қисмининг ўнг четидан
        (йўлнинг ўнг ёқасидан) амалга ошириш мумкин. Бу ҳолда ҳайдовчи йўлакай
        ва қарама-қарши йўналишдаги транспорт воситаларига йўл бериши шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.8
        </span>
        Транспорт воситаларининг ҳаракат йўналишлари кесишадиган ва ўтиш навбати
        ушбу Қоидаларда кўзда тутилмаган ҳолларда ҳайдовчи ўнг томондан
        яқинлашиб келаётган транспорт воситасига йўл бериши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.9
        </span>
        Секинлашиш бўлаги бўлган йўлларда бурилмоқчи бўлаётган ҳайдовчи ўз
        вақтида шу бўлакка ўтиши ва тезликни фақат унда камайтириши керак.{' '}
        <br />
        Йўлга чиқиш жойида тезланиш бўлаги бўлса, ҳайдовчи унда ҳаракатланиши ва
        қўшни бўлакда ҳаракатланаётган транспорт воситаларига йўл бериши, сўнгра
        оқимга кўшилиши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.10
        </span>
        Қуйидаги жойларда қайрилиб олиш тақиқланади: <br />
        пиёдаларнинг ўтиш жойларида; <br />
        тоннелларда; <br />
        кўприклар, осма йўл (естакада)лар, осма кўприклар ва уларнинг остида;
        <br />
        темир йўл кесишмаларида; <br />
        йўлнинг кўриниши бирон-бир йўналишда 100 метрдан кам бўлган жойларда.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.11
        </span>
        Транспорт воситасини орқага ҳаракатлантиришда ҳайдовчи йўл ҳаракати
        ҳавфсизлигини таъминлаши ва бошқа йўл ҳаракати қатнашчиларига ҳалақит
        бермаслиги шарт. Зарурат туғилганда, ҳайдовчи бошқа шахсларнинг
        ёрдамидан фойдаланиши мумкин.
      </p>
    </div>
  )
}

export default Comp10
