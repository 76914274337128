import React from 'react'

const Comp17 = () => {
  return (
    <div className='fs-5'>
      <h2>17. Temir yo'l kesishmalari orqali harakatlanish</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          17.1
        </span>
        Transport vositalarining haydovchilari temir yo’llarni faqat temir yo’l
        kesishmalari orqali poezd (lokomotiv, drezina)larga yo’l berib kesib
        o’tishlari mumkin.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          17.2
        </span>
        Temir yo’l kesishmasiga yaqinlashib kelayotgan haydovchi yo’l belgilari
        va chiziqlariga, svetofor ishoralariga, shlagbaum holatiga, kesishma
        navbatchisining ko’rsatmalariga amal qilishi, yaqinlashib kelayotgan
        poezd (lokomotiv, drezina) yo’qligiga ishonch hosil qilishi shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          17.3
        </span>
        Temir yo’l kesishmasiga kirish quyidagi hollarda taqiqlanadi: <br />
        svetofor ishorasidan qat’i nazar, shlagbaum yopiq turgan yoki yopila
        boshlagan holatda; <br />
        shlagbaumning bor-yo’qligidan, uning holatidan qat’i nazar, svetoforning
        taqiqlovchi ishorasida; <br />
        kesishma navbatchisining taqiqlovchi ishorasida (navbatchi
        haydovchilarga oldi yoki orqasi bilan turib tayoqchani, qizil chiroq
        yoki bayroqchani boshidan ko’tarib tursa yoki qo’llarini yonga uzatsa);{' '}
        <br />
        kesishmadan keyin hosil bo’lgan tirbandlik tufayli haydovchi kesishmada
        to’xtashga majbur bo’ladigan hollarda; <br />
        agar ko’rinish chegarasidagi masofada kesishmaga poezd (lokomotiv,
        drezina) yaqinlashib kelayotgan bulsa. <br />
        Bundan tashqari: <br />
        kesishma oldida turgan transport vositalarining qarama-qarshi
        harakatlanish bo’lagiga chiqib aylanib o’tish; <br />
        shlagbaumni o’zboshimchalik bilan ochish; <br />
        kesishma orqali zanjirli qishlok ho’jaligi, yo’l qurilishi va boshqa
        mashina va mexanizmlarning yurib o’tishi (mexanik transport vositalariga
        yuklab olib utish ruxsat etiladi); <br />
        temir yo’l distantsiyasi boshlig’ining ruxsatisiz tezligi soatiga 8
        kilometrdan kam bo’lgan transport vositalarini olib o’tish taqiqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          17.4
        </span>
        Kesishma orqali harakatlanish taqiqlangan hollarda haydovchi to’xtash
        chizig’i, 2.5 yo’l belgisi yoki svetofor oldida, ular bo’lmaganda
        shlagbaumgacha kamida 5 metr, shlagbaum bo’lmaganda esa birinchi temir
        yo’l iziga kamida 10 metr qolganda to’xtashi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          17.5
        </span>
        Temir yo’l kesishmasida majburan to’xtab qolgan transport vositasining
        haydovchisi darhol odamlarni tushirish va kesishmani bo’shatish
        choralarini ko’rishi kerak. <br />
        Agarda kesishmani bo’shatishni iloji bo’lmasa haydovchi: <br />
        imkoniyati bo’lganda yaqinlashib kelayotgan poezd mashinistiga to’xtash
        ishorasini berish qoidasini tushuntirib, ikki kishini temir yo’l yoqasi
        bo’ylab ikkala tomonga 1000 metr masofaga (agar bir kishi bo’lsa uni
        yo’l yaxshi ko’rinmaydigan tomonga) yuborishi; <br />
        transport vositasining yonida qolishi va umumiy xatar borligi ishorasini
        berishi; <br />
        poezd ko’ringanda to’xhtash ishorasini berib, u kelayotgan tomonga
        yugurishi kerak. <br />
        Eslatma: To’xtash ishorasi qo’lni (kunduzi yorqin mato parchasi yoki
        aniq ko’rinadigan biror narsa bilan, tunda esa mash’ala yoki chiroq
        bilan) gir aylantirish orqali beriladi. <br />
        Umumiy xatar ishorasi bitta uzun va uchta qisqa tovush ishorasi orqali
        beriladi.
      </p>
    </div>
  )
}

export default Comp17
