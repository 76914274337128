import React from 'react'

const Comp5 = () => {
  return (
    <div className='fs-5'>
 <h2>5. Пиёдаларнинг вазифаси</h2>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 5.1
 </span>
 Пиёдалар тротуардан ёки пиёдалар йўлкасидан, улар бўлмаганда еса
 йўл ёқасидан юришлари керак. Қўпол юкларни олиб кетаётган,
 ногиронларнинг юргизгичсиз аравасида бораётган шахсларнинг тротуар
 ёки йўл ёқасидан юришлари бошқа пиёдаларнинг ҳаракатланишига
 ҳалақит бераётган бўлса, улар қатнов қисмининг четидан юришлари
 керак. <br />
 Тротуарлар, пиёдалар йўлкаси, йўл ёқаси бўлмаса ёки улардан
 юришнинг имконияти бўлмаган ҳолларда пиёдалар велосипед юлкасидан
 ёки қатнов қисмининг четидан (ажратувчи бўлаги бор йўлларда қатнов
 қисмининг ўнг четидан) бир қатор бўлиб юришлари мумкин. <br />
 Aҳоли яшайдиган жойлардан ташқарида йўлнинг қатнов қисмида
 ҳаракатланаётган пиёдалар транспорт воситаларининг ҳаракатига қарши
 йўналишда юришлари керак. <br />
 Ногиронларнинг юргизгичсиз аравасида кетаётган, мотоцикл, мопед,
 велосипед етаклаб кетаётган шахслар еса бундай ҳолларда транспорт
 воситаларининг ҳаракат юналиши буйлаб юришлари керак. <br />
 Еслатма: пиёдалар юлкасида, тротуарларда, йўлнинг ёқасида
 пиёдаларнинг ҳаракатланишига ҳалақит берадиган қурилма ва жиҳозлар
 ўрнатиш ман етилади.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 5.2
 </span>
 Пиёдаларнинг ташкилий жамланмасига бир қаторда тўрт кишидан ортиқ
 бўлмасдан йўлнинг қатнов қисмида фақат транспорт воситаларининг
 ҳаракат юналиши бўйлаб ўнг томондан юришга рухсат етилади. <br />
 Жамланманинг олди ва орқасида чап томондан қизил байроқча, қоронри
 вақтда ёки етарлича кўринмаслик шароитида еса олдинда оқ, орқада
 қизил рангли чироқ кўтарган кузатувчилар бўлиши керак. <br />
 Болалар гуруҳини тротуарлар ва пиёдалар йўлкаларидангина, улар
 бўлмаганда еса йўл ёқасидан фақат кундузи ва катта ёшдагилар
 кузатувида олиб юришга руҳсат етилади.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 5.3
 </span>
 Пиёдалар йўлнинг қатнов қисмини пиёдалар ўтиш жойларидан,
 шунингдек, ер ости ва ер усти ўтиш жойларидан, улар бўлмаганда еса
 чорраҳаларда тротуар чизиқлари ёки йўл ёқаси бўйлаб кесиб
 ўтишлари керак. <br />
 Кўринадиган оралиқда ўтиш жойи ёки чорраҳа бўлмаса, ажратувчи
 бўлаксиз ва тўсиқсиз йўлларда пиёдаларга йўлнинг икки томони яхши
 кўринадиган жойидан қатнов қисмининг четига нисбатан тўғри бурчак
 остида кесиб ўтишларига руҳсат етилади.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 5.4
 </span>
 Пиёдалар йўл ҳаракати тартибга солинган жойларда тартибга солувчининг
 ёки светофорларнинг, улар бўлмаганда еса транспорт светофорларининг
 ишораларига амал қилишлари керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 5.5
 </span>
 Пиёдалар ҳаракат тартибга солинмайдиган пиёдалар ўтиш жойларида,
 яқинлашиб келаётган транспорт воситасигача бўлган масофани ва унинг
 тезлигини чамалаб кўриб, ўтиш ўзлари учун ҳавфсиз еканлигига
 ишонч ҳосил қилганларидан сўнг йўлнинг қатнов қисмига чиқишлари
 мумкин. <br />
 Шунингдек, улар йўлнинг қатнов қисмини пиёдалар ўтиш жойларидан
 ташқарида кесиб ўтишда транспорт воситаларининг ҳаракатланишига
 ҳалақит бермасликлари, яқинлашиб келаётган транспорт воситаларининг
 йўқлигига ишонч ҳосил килмасдан туриб куринишни чекловчи тўхтаб
 турган транспорт воситаси ёки бошқа бирор тўсик панасидан
 чиқмасликлари керак. Пиёдалар тўхтаб турган автобус ва троллейбуснинг
 орқа томонидан, трамвайнинг еса олди томонидан йўлни кесиб ўтишлари
 шарт.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 5.6
 </span>
 Aгар йўл ҳаракати ҳавфсизлигини таъминлаш билан боғлиқ бўлмаса,
 қатнов қисмига чиққан пиёдалар ушланиб қолмасликлари ва
 тўхтамасликлари керак. <br />
 Ўтишга улгурмаган пиёдалар қарама-қарши йўналишдаги транспорт
 оқимларини ажратувчи чизиқда тўхташлари лозим. Кейинги ҳаракатланиш
 ҳавфсиз еканлигига ишонч ҳосил қилгандан сўнг ва светофор ёки
 тартибга солувчининг ишораларини ҳисобга олган ҳолда ўтишни давом
 еттиришлари мумкин.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 5.7
 </span>
 Ялт-ялт етувчи кўк рангли ёки кўк ва қизил рангли
 чироқ-маёқчаси ва (ёки) махсус товуш ишораси ёқилган транспорт
 воситалари яқинлашиб келаётган бўлса, пиёдалар қатнов қисмидан
 ўтмасликлари, унда ҳаракатланаётганлар еса бу транспорт воситаларига
 йўл беришлари ва зудлик билан қатнов қисмини бўшатишлари керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 5.8
 </span>
 Белгиланган йўналишдаги транспорт воситалари ва таксиларни фақат
 бекатларда, улар бўлмаган тақдирда еса тротуарлар ёки йўл ёқасида
 кутиш керак. <br />
 Махсус жиҳозланган бекатлари бўлмаган тўхташ жойларида транспорт
 воситаси тўла тўхтагандан сўнг унга чиқиш учун йўлнинг қатнов
 қисмига чиқишга рухсат етилади. Ундан тушгандан кейин ушланиб
 қолмасдан йўлнинг қатнов қисмини бўшатишлари шарт. <br />
 Бекатларга бораётган ёки ундан кайтаётган пиёдалар Қоидаларнинг
 5.4-5.7 бандлари талабларига амал қилишлари керак.
 </p>
 </div>
  )
}

export default Comp5
