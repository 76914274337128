import React from 'react'

const Comp16 = () => {
  return (
    <div className="fs-5">
      <h2>
        16. Пиёдаларнинг ўтиш жойлари ва белгиланган йўналишдаги транспорт
        воситаларининг бекатлари
      </h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.1
        </span>
        Транспорт воситасининг ҳайдовчиси тартибга солинмаган пиёдалар ўтиш
        жойида қатнов қисмидан ўтаётган пиёдаларга йўл бериши шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.2
        </span>
        Aгар тартибга солинмаган пиёдалар ўтиш жойи олдида транспорт воситаси
        ҳаракатини секинлаштирса ёки тўхтаца, қўшни бўлакларда ҳаракатланаётган
        бошқа ҳайдовчилар бу транспорт воситаси олдида пиёдалар йўқлигига ишонч
        ҳосил қилганларидан сўнггина ҳаракатланишни давом еттиришлари мумкин.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.3
        </span>
        Тартибга солинган пиёдалар ўтиш жойларида ҳайдовчилар светофорнинг
        рухсат етувчи ишораси ёнган тақдирда ҳам пиёдаларга қатнов қисмини шу
        йўналишда кесиб ўтишни тугаллашга имкон беришлари керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.4
        </span>
        Aгарда, пиёдалар ўтиш жойларидан кейин пайдо бўлган тирбандлик туфайли
        ҳайдовчи пиёдалар ўтиш жойида тўхташга мажбур бўладиган бўлса, бундай
        пиёдалар ўтиш жойларига кириш тақиқланади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.5
        </span>
        Барча ҳолларда, шу жумладан, пиёдалар ўтиш жойларидан ташқарида ҳам
        ҳайдовчи оқ ҳасса билан ишора бераётган кўзи ожиз пиёдаларни ўтказиб
        юбориши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.6
        </span>
        Aгар йўловчиларни тушириш ёки чиқариш қатнов қисмида ёки унда жойлашган
        майдончада амалга оширилаётган бўлса, ҳайдовчи бекатда тўхтаган
        белгиланган йўналишдаги транспорт воситаси ешиклари томон бораётган ёки
        ундан келаётган пиёдаларга йўл бериши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.7
        </span>
        “Болалар гуруҳини ташиш” таниқлиқ белгиси ўрнатилган транспорт воситаси
        тўхтаб турганда, унга яқинлашаётган ҳайдовчи ҳаракат тезлигини
        камайтириши, зарур бўлса тўхташи ва болалар гуруҳини ўтказиб юбориши
        керак.
      </p>
    </div>
  )
}

export default Comp16
