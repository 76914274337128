import React from 'react'

const Comp18 = () => {
  return (
    <div className='fs-5'>
      <h2>18. Avtomagistrallarda harakatlanish</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          18.1
        </span>
        Avtomagistrallarda quyidagilar taqiqlanadi: <br />
        piyodalar, uy hayvonlari, ot-aravalar, velosipedlar, mopedlar,
        traktorlar va o’zi yurar mashinalar, texnik tavsifnomasiga yoki holatiga
        ko’ra tezligi soatiga 40 kilometrdan kam bo’lgan transport
        vositalarining harakatlanishi; <br />
        ruxsat etilgan to’la vazni 3,5 tonnadan ortiq bo’lgan yuk
        avtomobillariga birinchi va ikkinchi bo’laklardan boshqa bo’laklarda
        harakatlanishi; <br />
        5.15 yoki 6.11 belgilar bilan belgilangan maxsus to’xtab turish
        maydonchalaridan boshqa joylarda to’xtash, ajratuvchi bo’lakning
        texnologik uzilish joylariga kirish va qayrilib olish; <br />
        orqaga harakatlanish; <br />
        transport vositasini o’rganish uchun boshqarish.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          18.2
        </span>
        Qatnov qismida majburiy to’xtaganda haydovchi transport vositasini
        Qoidalarning 9-bobi talablariga muvofik belgilashi va bunday holatlar
        uchun mo’ljallangan bo’lakka (qatnov qismining chetini bildiruvchi
        chiziqdan o’ng tomonga) olib chiqishning barcha choralarini ko’rishi
        kerak.
      </p>
    </div>
  )
}

export default Comp18
