import React from 'react'

const Comp15 = () => {
  return (
    <div className="fs-5">
      <h2>15. Чорраҳада ҳаракатланиш</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.1
        </span>
        Чапга ёки ўнгга бурилаётган ҳайдовчи кесишаётган йўлнинг қатнов қисмидан
        ўтаётган пиёдаларга, шунингдек, велосипед йўлкасидан йўлни кесиб
        ўтаётган велосипедчиларга йўл бериши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.2
        </span>
        Тирбандлик туфайли тўхташга мажбур бўлган ҳайдовчи, агар у кўндаланг
        йўналишдаги транспорт воситаларининг ҳаракатланишига тўсқинлик
        туғдирадиган бўлса, унинг чорраҳа ёки қатнов қисмлари кесишмасига кириши
        тақиқланади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.3
        </span>
        Милтилловчи сариқ ишорада, светофорлар ишламаётган ёки тартибга солувчи
        бўлмаган чорраҳаларда ҳайдовчилар тартибга солинмайдиган чорраҳалардан
        ўтиш қоидаларига, чорраҳада ўрнатилган имтиёз белгиларига амал қилишлари
        шарт. <br />
        Тартибга солинган чорраҳалар.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.4
        </span>
        Светофорнинг яшил ишорасида чапга бурилаётган ёки қайрилиб олаётган
        релъссиз транспорт воситасининг ҳайдовчиси қарама-қарши томондан тўғрига
        ҳаракатланаётган ва ўнгга бурилаётган транспорт воситаларига йўл бериши
        шарт. Трамвай ҳайдовчилари ҳам ўзаро ушбу қоидага амал қилишлари керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.5
        </span>
        Светофорнинг қизил ёки сариқ ишораси билан бир вақтда ёнган қўшимча
        тармоқнинг яшил ишорасидаги йўналтиргич йўналишида ҳаракатланаётган
        транспорт воситасининг ҳайдовчиси бошқа йўналишлардаги транспорт
        воситаларига йўл бериши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.6
        </span>
        Aгар светофор ёки тартибга солувчининг ишоралари трамвай ва релъссиз
        транспорт воситаларига бир вақтда ҳаракатланишга рухсат берса,
        ҳаракатланиш йўналишидан қатъи назар, трамвай олдин ўтиш ҳуқуқига ега
        булади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.7
        </span>
        Светофорнинг рухсат етувчи ишорасида чорраҳага кирган ҳайдовчи, ундан
        чиқишдаги светофор ишорасидан қатъи назар, белгиланган йўналишда
        ҳаракатини давом еттириши керак. Бироқ чорраҳада ҳайдовчининг юрадиган
        йўлида жойлашган светофорлар олдида тўхташ чизиқлари (ёки 5.33 белгиси)
        бўлса, у ҳар бир светофор ишорасига амал қилиши шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.8
        </span>
        Светофорнинг рухсат берувчи ишораси ёнганда ҳайдовчи чорраҳа орқали
        ҳаракатланишни тугаллаётган транспорт воситалари ва қатнов қисмидан
        тегишли йўналишда ўтишни тугаллаётган пиёдаларга йўл бериши шарт. <br />
        Тартибга солинмаган чорраҳалар.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.9
        </span>
        Тенг аҳамиятга ега бўлмаган йўллар кесишган чорраҳада, кейинги ҳаракат
        йўналишидан қатъи назар, асосий йўлдан келаётган транспорт воситасига
        иккинчи даражали йўлдан келаётган транспорт воситасининг ҳайдовчиси йўл
        бериши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.10
        </span>
        Тенг аҳамиятга ега бўлган йўллар кесишган чорраҳада релъссиз транспорт
        воситасининг ҳайдовчиси ўнгдан келаётган транспорт воситаларига йўл
        бериши шарт. <br />
        Бу қоидага трамвай ҳайдовчилари ҳам ўзаро амал қилишлари керак. Бундай
        чорраҳаларда, кейинги ҳаракат йўналишидан қатъи назар, трамвай релъссиз
        транспорт воситаларига нисбатан олдин ўтиш ҳуқуқига ега булади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.11
        </span>
        Aсосий йўлнинг йўналиши чорраҳада ўзгарганда, асосий йўлда
        ҳаракатланаётган ҳайдовчилар ўзаро тенг аҳамиятли йўллар чорраҳасидан
        ўтиш қоидасига амал қилишлари керак. <br />
        Иккинчи даражали йўлларда ҳаракатланаётган ҳайдовчилар ҳам ўзаро шу
        қоидага амал қилишлари лозим.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.12
        </span>
        Чапга бурилишда ёки қайрилиб олишда релъссиз транспорт воситасининг
        ҳайдовчиси тенг аҳамиятли йўлдан қарама-қарши йўналишдан тўғрига ёки
        ўнгга ҳаракатланаётган, шунингдек рухсат етилган ҳолларда қувиб ўтаётган
        транспорт воситаларига йўл бериши шарт. Бу қоидага трамвай ҳайдовчилари
        ҳам ўзаро амал қилишлари керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.13
        </span>
        Aгар ҳайдовчи ўзи ҳаракатланаётган йўлнинг қопламаси бор-йўқлигини
        аниқлай олмаса (қоронри вақт, лой, қор, ва ҳ.к.) ва имтиёз белгилари
        бўлмаса, унда у ўзини иккинчи даражали йўлда деб ҳисоблаши керак
      </p>
    </div>
  )
}

export default Comp15
