import React from 'react'

const Comp28 = () => {
  return (
    <div className='fs-5'>
      <h2>
        28. Mansabdor shaxslar va fuqarolarning yo'l harakati xavfsizligini
        ta'minlash, transport vositalarini yo'lga chiqarish, ularga raqam va
        taniqlik belgilarinin o'rnatish bo'yicha vazifalari
      </h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          28.1
        </span>
        Mexanik transport vositalari va tirkamalari, ularning texnik holatidan
        qat’i nazar, xarid qilingan yoki bojxonada rasmiylashtirilgan vaqtdan
        boshlab 10 kun mobaynida DANda ro’yxatdan o’tkazilishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          28.2
        </span>
        Mexanik transport vositalari (tramvay, trolleybuslardan tashqari) va
        tirkamalarning ko’zda tutilgan joylarida tegishli namunadagi raqam
        belgilari o’rnatilgan bo’lishi shart. Avtomobilda esa old peshoynaning
        o’ng tomonidagi yuqori burchagiga davlat texnik ko’rigidan o’tganligi
        haqidagi talon ham o’rnatilishi kerak. <br />
        Yuk avtomobillari, tirkamalar (engil avtomobillar va mototsikllar
        tirkamalaridan tashqari) va avtobuslar (mikroavtobuslardan tashqari)
        kuzovining orqa devoriga uning raqam belgisi va harfi yozilishi kerak.
        Raqamning balandligi 300 mm, raqamlar kengligi 120 mm, chiziqning
        yo’g’onligi 30 mm, harflar balandligi esa raqam o’lchamlarining 2/3
        qismiga teng bo’lishi lozim. <br />
        Tramvay va trolleybuslarga tegishli tashkilot tomonidan ro’yxatga
        olingan raqamlar yoziladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          28.3
        </span>
        Transport vositalariga quyidagi ko’rinishda bo’lgan taniqlik belgilari
        o’rnatilishi kerak: <br />
        Avtopoezd – yuk avtomobillari va g’ildirakli traktor (1,4 tonna va undan
        yuqori)larda tirkama bo’lganda, shuningdek, tutashtirilgan avtobus va
        trolleybus kabinalari tomining old qismi o’rtasiga bir qator qilib 150
        dan 300 millimetrgacha oraliqda ko’ndalang joylashgan uchta zarg’aldok
        rangli chiroq yoki ichidan yoritiladigan, tomonlarining har biri 250
        millimetr bo’lgan sariq rangli uchburchak shaklidagi belgi; <br />
        Turumlangan (tishli, shipli) – turumlangan shinali mexanik transport
        vositasining orqasiga tomonlari teng, ichiga qora rangda “T” harfi
        tushirilgan, qizil hoshiyali oq rangdagi uchburchak shaklidagi belgi;{' '}
        <br />
        Uchburchakning tomonlari transport vositasining turiga qarab 200-300
        millimetrgacha, hoshiyaning kengligi uchburchak tomonining 1/10 qismiga
        teng bo’lishi kerak. <br />
        Bolalar guruhini tashish – avtobus yoki maxsus jihozlangan yuk
        avtomobillarida bolalar tashilganda, transport vositasining old va orqa
        tomonlariga qizil hoshiyali, ichiga 1.21 yo’l belgisidagi bolalar
        timsolining tasviri tushirilgan, sariq rangdagi to’rtburchak
        ko’rinishidagi belgi; <br />
        to’rtburchakning tomonlari 250-300 millimetrgacha, hoshiyaning kengligi
        to’rtburchak tomonining 1/10 qismiga teng bo’lishi kerak. <br />
        Kar haydovchi – kar-soqov yoki kar haydovchi boshqarayotgan transport
        vositasining old va orqa tomonlariga ichiga diametri 40 millimetr
        bo’lgan uchta qora doira tushirilgan, diametri 160 millimetrni tashkil
        etgan sariq rangli doira shaklidagi belgi; <br />
        Boshqarishni o’rgatish transport vositasi – boshqarishni o’rgatishda
        foydalanadigan transport vositalarining old va orqa tomoniga ichiga qora
        rangda “O” harfi tushirilgan, transport vositasining turiga qarab
        tomonlari 200 millimetrdan 300 millimetrgacha bo’lgan, qizil hoshiyali
        oq rangli uchburchak shaklidagi belgi; <br />
        Tezlik cheklangan – juda og’ir, havfli va katta o’lchamli yuklarni
        tashiyotgan, shuningdek, transport vositasining texnik tavsifnomasida
        ko’rsatilgan eng yuqori tezligi Qoidalarning 12.2, 12.3 va 12.4
        bandlarida belgilangan tezlikdan past bo’lgan hollarda transport
        vositasi kuzovi orqa devorining chap tomoniga 3.24 – “Yuqori tezlik
        cheklangan” yo’l belgisining kichraytirilgan rangli tasviri tushirilgan
        shaklidagi belgi; <br />
        Belgining diametri 160 millimetrdan kam bo’lmasligi, hoshiyasining
        kengligi belgi diametrining 1/10 qismiga teng bo’lishi kerak. <br />
        Xavfli yuk – xavfli yukni tashiyotgan transport vositasining old va orqa
        tomoniga o’rnatilgan ulchami 690×300 millimetr, zarg’aldok rangli o’ng
        qismi 400×300 millimetr bo’lgan, chap qismi esa yukning xavfli
        hususiyatini bildirib turgan belgilar tushirilgan qora hoshiyali
        (kengligi 15 millimetr) oq rangdagi to’rtburchak shaklidagi belgi
        (4-ilova); <br />
        Katta o’lchamli yuk – ichiga kengligi 50 millimetrli, diagonal bo’yicha
        galma-gal joylashgan qizil va oq rangli chiziqlar tushirilgan, nur
        qaytaradigan yuzali, o’lchami 400×400 millimetrli to’rtburchak
        shaklidagi belgi; <br />
        Uzun o’lchamli transport vositasi – yukli yoki yuksiz uzunligi 20
        metrdan oshadigan transport vositasining, ikki yoki undan ko’proq
        tirkamali avtopoezdlarning orqasiga kengligi 40 millimetr bo’lgan qizil
        hoshiyali, o’lchami 1200×200 millimetr bo’lgan, tarkibning tasviri
        tushirilgan sariq rangli to’rtburchak shaklidagi belgi; <br />
        ko’rsatilgan o’lchamli belgini joylashtirishning imkoniyati bo’lmasa,
        bir hil ikkita 600×200 millimetrli o’lchamdagi belgini transport
        vositasining o’qiga simmetrik ravishda o’rnatishga yo’l qo’yiladi.{' '}
        <br />
        Falokat sababli to’xtash – miltillovchi qizil chiroq va davlat standarti
        talablari asosidagi uchburchak shaklidagi belgi. <br />
        Haydovchilarning xohishiga ko’ra quyidagi taniqliq belgilari
        o’rnatilishi mumkin: <br />
        Shifokor – shifokor-haydovchi boshqarayotgan transport vositasining old
        va orqa tomoniga o’rnatiladigan, tomonlari 140 millimetrdan bo’lgan ko’k
        rangli to’rtburchak ichida, diametri 90 millimetrli oq doiraga qalinligi
        25 millimetrli qizil xoch tasviri tushirilgan belgi; <br />
        Nogiron – birinchi yoki ikkinchi guruh nogironi boshqarayotgan transport
        vositasining old va orqa tomoniga o’rnatiladigan barcha tomonlari 150
        millimetrdan bo’lgan va qora rangdagi 7.17 yo’l belgisining tasviri
        tushirilgan sariq rangli to’rtburchak shaklidagi belgi; <br />
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          28.4
        </span>
        Mexanik transport vositalarini shatakka olishda egiluvchan ulagichni
        ko’rsatadigan ogohlantiruvchi qurilma o’lchami 200×200 millimetr
        bo’lgan, diagonali bo’yicha ketma-ket joylashgan 50 millimetr
        qalinlikdagi qizil va oq rangli chiziqlar tushirilgan, yorug’lik
        qaytaradigan yuzali bayrokchalar yoki taxtachalar ko’rinishida
        tayyorlanishi kerak. <br />
        Egiluvchan ulagichning har bir metriga bittadan ogohlantiruvchi qurilma
        o’rnatiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          28.5
        </span>
        Qattik ulagichli qurilmaning tuzilishi davlat standarti talablariga mos
        bo’lishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          28.6
        </span>
        Quyidagi hollarda transport vositalaridan foydalanish taqiqlanadi:{' '}
        <br />
        agar avtomobillar, avtobuslar, avtopoezdlar, tirkamalar, mototsikllar,
        mopedlar, traktorlar va boshqa o’ziyurar mashinalarning texnik holati va
        jihozlanishi 3-ilovadagi transport vositalaridan foydalanishni
        taqiqlaydigan shartlar ro’yxatining biron-bir talabiga javob bersa;{' '}
        <br />
        trolleybus va tramvaylarda tegishli texnik foydalanish qoidalarida
        ko’zda tutilgan nosozliklardan birortasi bo’lsa; <br />
        transport vositalari davlat texnika ko’rigidan o’tmagan, shuningdek,
        tegishli ruxsatsiz qayta jihozlangan bo’lsa; <br />
        transport vositalari davlat avtomobil’ nazorati organlarining ruxsatisiz
        miltillovchi chiroq — mayoqcha va maxsus tovushli ishora bilan
        jihozlangan, kuzovining yon tomoniga DAN bilan kelishmasdan qiya oq
        chiziq tortilgan, ko’zda tutilmagan joylarga tegishli raqam belgilari
        o’rnatilgan, agregat va qismlarining raqamlari yoki raqam belgilari
        ko’rinmaydigan, qalbakilashtirilgan, o’zgartirilgan bo’lsa.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          28.7
        </span>
        Transport vositalarining texnik holatiga va ulardan foydalanishga
        javobgar bo’lgan mansabdor va boshqa shaxlarga quyidagilar taqiqlanadi:{' '}
        <br />
        texnik nosozligi tufayli foydalanish taqiqlangan, tegishli ruxsatsiz
        qayta jihozlangan, belgilangan tartibda ro’yxatdan o’tkazilmagan yoki
        davlat texnika ko’rigidan o’tkazilmagan transport vositalarini yo’lga
        chiqarish; <br />
        yo’l harakati havfsizligiga taxdid soladigan har qanday mastlik
        (alkogol’, giyohvand va boshqalar) holatida yoki sezgirlik va e’tiborni
        susaytiradigan dori-darmonlar ta’sirida, charchoqlik yoki betoblik
        holatida, shuningdek, tegishli toifadagi transport vositalarini
        boshqarish huquqi bo’lmagan, belgilangan muddatda tibbiy ko’rikdan
        o’tmagan, giyohvand yoki spirtli ichimliklarni surunkali istemol
        qiluvchi shaxs sifatida hisobda turgan shaxslarni transport vositasini
        boshqarishga qo’yish; <br />
        asfal’t yoki tsement-beton qoplamali yo’llarga zanjirli traktor va
        boshqa o’ziyurar mexanizmlarni chiqarish.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          28.8
        </span>
        Yo’llar, temir yo’l kesishmalari va boshqa yo’l inshootlarining holatiga
        javobgar bo’lgan mansabdor va boshqa shaxslar: <br />
        yo’llar, temir yo’l kesishmalari va boshqa yo’l inshootlarini yo’l
        harakati xavfsizligini ta’minlaydigan holatda saqlashga; <br />
        yo’l harakatiga halaqit beradigan har qanday tusqinlik vujudga kelsa
        ularni o’z vaqtida bartaraf etish, yo’lning ayrim qismlarida ulardan
        foydalanish yo’l harakati xavfsizligiga taxdid soladigan hollarda
        harakatni cheklash yoki taqiqlash choralarini ko’rishga majburdirlar.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          28.9
        </span>
        Yo’llarda ta’mirlash ishlarini bajarishga javobgar mansabdor va boshqa
        shaxslar ishlar olib borilayotgan joyda yo’l harakati xavfsizligini
        ta’minlashga majburdirlar. Bunday joylar, shuningdek, yo’ldan tashqariga
        olib chiqish imkoniyati bo’lmagan ish bajarmayotgan yo’l mashinalari,
        qurilish materiallari, qurilma va shunga o’xshashlar tegishli yo’l
        belgilari, yo’naltiruvchi va to’suvchi moslamalar bilan, sutkaning
        qoronri vaqtida va etarlicha ko’rinmaslik sharoitida esa qo’shimcha
        qizil yoki sariq chiroqli ishoralar bilan belgilanishi kerak. <br />
        Ta’mirlash ishlari tugallangandan so’ng yo’llarda transport vositalari
        va piyodalarning xavfsiz harakatlanishi ta’minlangan bo’lishi kerak
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          28.10
        </span>
        Amaldagi qonun hujjatlarida ko’zda tutilgan hollarda tegishli mansabdor
        va boshqa shaxslar tomonidan DAN organlari bilan belgilangan tartibda
        quyidagilar kelishiladi: <br />
        shaharlarda va avtomobil’ yo’llarida harakatni tashkil etishni
        loyihalash, yo’llarni harakatni tashkil etuvchi texnik vositalar bilan
        jihozlash; <br />
        yo’llarni, yo’l inshootlarini qurish, qayta rekonstruktsiya qilish va
        ta’mirlash ishlarini loyihalash; <br />
        yo’llarga bevosita yaqinroqqa ko’rinishni cheklovchi yoki piyodalar
        harakatini qiyinlashtiruvchi kioskalar, transparantlar, plakatlar,
        reklama taxtalari va shunga o’xshashlarni o’rnatish; <br />
        yo’nalishli transport vositalari yo’nalishlari va bekatlarining
        joylanishi; <br />
        yo’llarda ommaviy, sport va boshqa tadbirlarni o’tkazish; <br />
        transport vositalarini qayta jihozlash, ularga maxsus yorug’lik va
        tovush ishoralarini o’rnatish, transport vositalari kuzovining yon
        tomoniga qiya oq chiziqlar tortish; <br />
        mazkur Qoidalarning 26.5 bandida keltirilgan transport vositalarining
        harakatlanishi; <br />
        boshqarishni o’rgatish ruxsat etilgan yo’llar ro’yxati va chizmasi;{' '}
        <br />
        yo’llarda transport vositalari va piyodalarning harakatiga xavf
        tug’diruvchi har qanday ishlarni olib borish. <br />
      </p>
    </div>
  )
}

export default Comp28
