import React from 'react'

const Comp11 = () => {
  return (
    <div className='fs-5'>
      <h2>11. Yo'lning qatnov qismida transport vositalarining joylashishi</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.1
        </span>
        Relssiz transport vositalari harakatlanadigan bo’laklar soni chiziqlar
        yoki 5.8.1, 5.8.2, 5.8.7, 5.8.8 yo’l belgilari bilan belgilanadi. Bunday
        chiziqlar yoki yo’l belgilari bo’lmasa, haydovchilar yo’l bo’laklarini
        o’zlari, qatnov qismining kengligini, transport vositalari orasidagi
        zarur yonlama oraliq masofani va ularning gabarit o’lchamlarini hisobga
        olgan holda aniqlaydilar. Bunda harakat ikki tomonlama bo’lgan yo’l
        qatnov qismining chap tomondagi yarmi qarama-qarshi yo’nalishda
        harakatlanish uchun muljallangan, deb hisoblanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.2
        </span>
        To’rt va undan ortiq bo’lakli, harakat ikki tomonlama bo’lgan yollarda
        qarama-qarshi yo’nalishda harakatlanish uchun mo’ljallangan bo’laklarga
        chiqish taqiqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.3
        </span>
        Harakat ikki tomonlama bo’lgan uchta bo’lakli (1.9 chizig’i bilan
        belgilangandan tashqari) yo’llarda o’rta bo’lakka faqat quvib o’tish,
        chapga burilish, qayrilib olish va aylanib o’tish uchungina chiqishga
        ruxsat etiladi. Qarama-qarshi yo’nalishda harakatlanish uchun
        mo’ljallangan chetki chap bo’lakka chiqish taqiqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.4
        </span>
        Aholi yashaydigan joylardan tashqarida haydovchilar transport
        vositalarini mumkin qadar qatnov qismining o’ng chetiga yaqinroqqa olib
        harakatlanishlari kerak. <br />
        Aholi yashaydigan joylarda Qoidalarning 11.6, 19.1, 26.2 bandlaridagi
        talablarni hisobga olgan holda haydovchilar o’zlariga qulay bo’lgan
        harakatlanish bo’lagidan foydalanishlari mumkin. Faqat o’ngga yoki
        chapga burilish, qayrilib olish, quvib o’tish, to’xtash oldidan
        harakatlanish bo’lagini o’zgartirishga ruxsat etiladi. <br />
        Biroq, bir yo’nalishdagi uch va undan ortiq bo’lakli har qandaj yo’lda
        harakat serqatnovligi sababli boshqa bo’laklar band bo’lgan hollarda,
        shuningdek, quvib o’tish, chapga burilish yoki qayrilib olish, ruxsat
        etilgan to’la vazni 3,5 tonnadan ortiq bo’lgan yuk avtomobillariga esa
        faqat chapga burilish yoki qayrilib olish uchun chetki chap bo’lakni
        egallashga ruxsat etiladi. <br />
        Harakat bir tomonlama bo’lgan yo’lning chap bo’lagida to’xtash va
        to’xtab turish Qoidalarning 14.1 bandiga muvofik amalga oshiriladi.{' '}
        <br />
        Biror bo’lakda qo’shni bo’lakdagi transport vositalariga nisbatan katta
        tezlikda harakatlanish quvib o’tish deb hisoblanmaydi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.5
        </span>
        Tezligini soatiga 40 kilometrdan oshirishi mumkin bo’lmagan yoki texnik
        sabablarga ko’ra tezligini bundan oshira olmaydigan transport vositalari
        quvib o’tish, chapga burilish yoki qayrilib olish, aylanib o’tish uchun
        qayta tizilishdan boshqa hollarda faqat chetki o’ng bo’lakda
        harakatlanishlari kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.6
        </span>
        Yo’lakay yo’nalishdagi qatnov qismining chap tomonida u bilan bir sathda
        joylashgan tramvay izidan shu yo’nalishdagi boshqa bo’laklar band
        bo’lganda harakatlanishga, aylanib va quvib o’tishga, shuningdek, 5.8.1,
        5.8.2 belgilari bilan belgilanmagan hollarda esa chapga burilish va
        qayrilib olishga ruxsat etiladi. Biroq bu tramvay harakatiga halaqit
        bermasligi kerak. <br />
        Qarama-qarshi yo’nalishdagi tramvay izidan harakatlanish taqiqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.7
        </span>
        Agar qatnov qismi yo’l chiziqlari bilan bo’laklarga ajratilgan bo’lsa,
        transport vositalarining harakatlanishi qat’iy ravishda belgilangan
        bulaklarda amalga oshirilishi kerak. Faqat qayta tizilishda uzuq-uzuq
        chiziqlarni bosib o’tishga ruxsat etiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.8
        </span>
        Reversiv harakatlanishli yo’lga burilishda transport vositalari qatnov
        qismlari kesishmasidan chiqishda chetki o’ng bo’lakni egallashi kerak.
        Haydovchiga shu yo’nalishdagi boshqa bo’laklarda ham harakatlanishga
        ruxsat berilganligiga ishonch hosil qilgandan keyingina qayta tizilishga
        ruxsat etiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.9
        </span>
        Qoidalarning 14.1, 27.2 bandlarida ko’zda tutilgandan boshqa hollarda
        transport vositalarining yo’l yoqasi, trotuar va piyodalar yo’lkalaridan
        harakatlanishi taqiqlanadi. Bu joylarda yo’ldan foydalanish va kommunal
        xizmat mashinalarining harakatlanishiga, shuningdek, boshqa imkoniyat
        bo’lmagan hollarda bevosita yo’l yoqasida, trotuar yoki piyodalar
        yo’lkalari oldida joylashgan savdo shoxobchalari, korxonalar va boshqa
        inshootlarga yuk olib kirish uchun transport vositalariga yaqin yo’ldan
        kirishga ruxsat etiladi. Bunda yo’l harakati havfsizligi to’la
        ta’minlangan bo’lishi shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.10
        </span>
        Haydovchi o’zidan oldinda ketayotgan transport vositasi keskin
        to’xtaganda u bilan to’qnashib ketmaslik kafolatini beradigan oraliq
        masofani, shuningdek, yo’l harakati havfsizligini ta’minlaydigan yonlama
        oraliq masofani saqlashi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.11
        </span>
        Aholi yashaydigan joylardan tashqaridagi yo’llarda tezligini soatiga 50
        kilometrdan oshirishi mumkin bo’lmagan, shuningdek, ruxsat etilgan to’la
        vazni 12 tonnadan ortiq bo’lgan transport vositalarining haydovchilari
        o’zi va oldida ketayotgan transport vositasi oralig’ida, ularni quvib
        o’tishi mumkin bo’lgan transport vositalari bemalol yo’lning o’ng
        tomoniga qayta tizilishi uchun imkon beradigan masofani saqlashlari
        kerak. <br />
        Quvib o’tishga tayorlanayotgan, shuningdek, transport vositalarining
        tashkiliy jamlanmasi tarkibida, serqatnov bo’lgan yo’llarda
        harakatlanayotgan transport vositalarining haydovchilariga bu talab
        taalluqli emas.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.12
        </span>
        Agar biron-bir to’siq sababli qarama-qarshi yo’nalishlarda harakatlanish
        qiyin bo’lsa, to’siq o’z tomonida bo’lgan haydovchi yo’l berishi kerak.
      </p>
    </div>
  )
}

export default Comp11
