import React from 'react'

const Comp21 = () => {
  return (
    <div className="fs-5">
      <h2>21. Белгиланган йўналишдаги транспорт воситаларининг имтиёзлари</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          21.1
        </span>
        Трамвай йўли чорраҳадан бошқа жойларда йўл қатнов қисмини кесиб ўтадиган
        бўлса, трамвйж релъссиз транспорт воситаларига нисбатан имтиёзга ега
        бўлади (деподан чиқиш жойларидан ташқари).
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          21.2
        </span>
        Белгиланган йўналишдаги транспорт воситаларининг ҳаракатланиши учун 5.9,
        5.10.1–5.10.3 белгилари билан ажратилган бўлакда бошқа транспорт
        воситаларининг ҳаракатланиши ва тўхташи тақиқланади. <br />
        Aгар бу бўлак йўл қатнов қисмининг бошқа жойидан узуқ-узуқ чизиқ билан
        ажратилган бўлса, бурилмоқчи бўлаётган транспорт воситалари бу бўлакка
        қайта тизилишлари керак. <br />
        Шунингдек, бундай жойларда белгиланган йўналишдаги транспорт
        воситаларининг ҳаракатига ҳалақит бермаслик шарти билан йўлга чиқиш,
        қатнов қисмининг четки ўнг томонида йўловчиларни чиқариш ва тушириш учун
        бу бўлакка ўтишга рухсат етилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          21.3
        </span>
        Aҳоли яшайдиган жойларда бекатдан ҳаракатланишни бошлаётган автобус ва
        троллейбусларга ҳайдовчилар йўл беришлари керак. Ўз навбатида автобус ва
        троллейбус ҳайдовчилари уларга йўл берилганлигига ишонч ҳосил
        қилганликларидан кейингина ҳаракатланишни бошлашлари мумкин.
      </p>
    </div>
  )
}

export default Comp21
