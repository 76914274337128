import React from 'react'

const Comp19 = () => {
  return (
    <div className='fs-5'>
      <h2>19. Turar-joy dahalarida harakatlanish</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          19.1
        </span>
        Turar-joy dahalari (kirish va chiqish 5.38 va 5.39 belgilari bilan
        belgilangan hududlar)da piyodalarga trotuarlar hamda qatnov qismida
        harakatlanishiga ruxsat etiladi. Bunda piyodalar imtiyozga ega
        bo’ladilar, biroq ular transport vositalarining harakatlanishiga asossiz
        halaqit bermasliklari kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          19.2
        </span>
        Turar-joy dahalarida: <br />
        mexanik transport vositasini boshqarishni o’rgatish; <br />
        yurgizgichi ishlab turgan holda to’xtab turish; <br />
        ruxsat etilgan to’la vazni 3.5 tonnadan ortiq bo’lgan yuk
        avtomobillarining maxsus ajratilgan, belgi yoki chiziqlar bilan
        belgilangan joylardan tashqarida to’xtab turishi taqiqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          19.3
        </span>
        Turar-joy dahalarida haydovchilar piyodalar havfsizligini, shuningdek,
        ushbu hududdagi inshoot, qurilma va o’simliklarning shikastlanmasligini
        ta’minlashlari zarur.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          19.4
        </span>
        Turar-joy dahalaridan chiqishda haydovchilar boshqa harakat
        qatnashchilariga yo’l berishlari kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          19.5
        </span>
        Ushbu bo’limdagi talablar hovlili hududlarga ham tegishlidir.
      </p>
    </div>
  )
}

export default Comp19
