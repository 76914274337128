import { InterstitialAd } from '@admob-plus/capacitor'
import { IonIcon, useIonModal } from '@ionic/react'
import { arrowBackOutline, arrowForwardOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import Comp1 from './Components/comp1'
import Comp2 from './Components/comp2'

const YolChiziqlar = (props:any) => {
  const [id, setId] = useState(1)
  const [isCard,setCard]=useState(false)
  const handleDismiss = () => {
    dismiss()
  }
  const [present, dismiss] = useIonModal(Body, {
    id,
    onDismiss: handleDismiss,
  })
     useEffect(() =>
  {
    const startAds = async () =>
    { 
    const interstitial = new InterstitialAd({
      adUnitId: 'ca-app-pub-2110563711012419/1927421731',
    })
    await interstitial.load()
    await interstitial.show()
    }
    const interval=setInterval(startAds, 420000);
     return () =>
      {
        (async () =>
        {          
          clearInterval(interval);
        })();
      }
     }, [])
    const renderComp = () => {
    switch (id) {
      case 1:
        return <Comp1 />
      case 2:
        return <Comp2 />  
    }
    return 10
  }
  return (
    <div style={{ marginTop: '50px' }}>
       {isCard ? (
        <div className='card card-custom2 m-auto'>
          <div className="card-header">
            <div className="d-flex justify-content-between btn-back rounded">
              <button
                onClick={() => {
                  setCard(false)
                }}
                className="btn "
              >
                <IonIcon
                  style={{ fontSize: '30px', color: 'white' }}
                  icon={arrowBackOutline}
                ></IonIcon>
              </button>
              <div>
                <p className="p-2 fs-3 text-white">{id}/2</p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between my-3">
            <button
              className="btn"
              onClick={() => {
                setId((prev) => {
                  if (id === 1) {
                    return 1
                  } else {
                    return prev - 1
                  }
                })
              }}
            >
              <IonIcon
                style={{ fontSize: '30px' }}
                icon={arrowBackOutline}
              ></IonIcon>
            </button>
            <button
              className="btn"
              onClick={() => {
                setId((prev) => {
                  if (id === 2) {
                    return 2
                  } else {
                    return prev + 1
                  }
                })
              }}
            >
              <IonIcon
                style={{ fontSize: '30px' }}
                icon={arrowForwardOutline}
              ></IonIcon>
            </button>
          </div>
          <div
            className="p-2 rounded"
            style={{
              height: '60vh',
              overflow: 'auto',
              width: '96%',
              marginLeft: '2%',
              border: '2px solid #00004d',
            }}
          >
            {renderComp()}
            
          </div>
        </div>
      ) : (
        ''
      )}
      <div
        onClick={() => {
          setId(1)
          setCard(true)
        }}
        className="btn-back rounded m-1 p-2 text-white d-flex flex-column"
      >
        <h4 className="mx-auto">{props?.data?.yotiqch}</h4>
      </div>
      <div
        onClick={() => {
          setId(2)
          setCard(true)
        }}
        className="btn-back rounded m-1 p-2 text-white d-flex flex-column"
      >
        <h4 className="mx-auto">{props.data.tikch }</h4>
      </div>
    </div>
  )
}

export default YolChiziqlar

const Body: React.FC<{
  id: number
  onDismiss: () => void
}> = ({ id, onDismiss }) => {
  const [index, setIndex] = useState(id)
  const renderComp = () => {
    switch (index) {
      case 1:
        return <Comp1 />
      case 2:
        return <Comp2 />  
    }
    return 10
  }
  return (
    <div>
      <div className="d-flex justify-content-between mb-2 btn-back">
        <button className="btn " onClick={() => onDismiss()}>
          <IonIcon
            style={{ fontSize: '30px', color: 'white' }}
            icon={arrowBackOutline}
          ></IonIcon>
        </button>
        <div>
          <p className="p-2 fs-3 text-white">{index}/2</p>
        </div>
      </div>
  <div className="d-flex justify-content-between mt-2">
        <button
          className="btn"
          onClick={() => {
            setIndex((prev) => {
              if (index === 1) {
                return 1
              } else {
                return prev - 1
              }
            })
          }}
        >
          <IonIcon
            style={{ fontSize: '30px' }}
            icon={arrowBackOutline}
          ></IonIcon>
        </button>
        <button
          className="btn"
          onClick={() => {
            setIndex((prev) => {
              if (index === 2) {
                return 2
              } else {
                return prev + 1
              }
            })
          }}
        >
          <IonIcon
            style={{ fontSize: '30px' }}
            icon={arrowForwardOutline}
          ></IonIcon>
        </button>
      </div>
      <div
        className="p-2 rounded"
        style={{
          height: '650px',
          overflow: 'scroll',
          width: '96%',
          marginLeft: '2%',
          border: '2px solid #00004d',
        }}
      >
        {renderComp()}
          <div className="d-flex justify-content-between mt-2">
        <button
          className="btn"
          onClick={() => {
            setIndex((prev) => {
              if (index === 1) {
                return 1
              } else {
                return prev - 1
              }
            })
          }}
        >
          <IonIcon
            style={{ fontSize: '30px' }}
            icon={arrowBackOutline}
          ></IonIcon>
        </button>
        <button
          className="btn"
          onClick={() => {
            setIndex((prev) => {
              if (index === 2) {
                return 2
              } else {
                return prev + 1
              }
            })
          }}
        >
          <IonIcon
            style={{ fontSize: '30px' }}
            icon={arrowForwardOutline}
          ></IonIcon>
        </button>
      </div>
      </div>
    </div>
  )
}
