import React from 'react'

const Comp25 = () => {
  return (
    <div className='fs-5'>
 <h2>25. Одам ташиш</h2>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 25.1
 </span>
 Юк автомобилининг юкхонасида одам ташиш "C" тоифасидаги транспорт
 воситаларини бошқариш ҳуқуқига ега бўлган, одамлар сони
 кабинадагиларни ҳам қўшиб ҳисоблаганда 8 нафардан ортиқ бўлганда еса
 "C" ва "Д" тоифасидаги транспорт воситаларини бошқариш ҳуқуқига ега
 бўлган ва шу тоифадаги транспорт воситаларидан бирини 3 йилдан ортиқ
 бошқарган ҳайдовчилар томонидан амалга оширилиши керак. <br />
 Еслатма: Ҳарбий ҳаидовчиларнинг юк автомобилларда одам ташишига йўл
 қўиш Мудофаа вазирлиги томонидан белгиланган тартибда амалга
 оширилади.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 25.2
 </span>
 Одам ташиш учун мўлжалланган юк автомобили юкхонаси бортининг
 баландлигидан 30 сантиметр пастда жойлашган ўриндиқлар билан
 жиҳозланиши, икки ён ва орқа бортдаги ўриндиқлар мустаҳкам
 суянчиққа ега бўлиши керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 25.3
 </span>
 Ташиладиган одамлар сони жиҳозланган ўриндиқлар сонидан ошмаслиги
 керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 25.4
 </span>
 Ҳаракатни бошлашдан олдин ҳайдовчи йўловчиларга автомобилга
 чиқиш, ундан тушиш ва унга жойлашиш тартиби ҳакида тушунтириш
 бериши керак. <br />
 Ҳайдовчи ҳаракатни бошлашдан олдин йўловчиларни ҳавфсиз ташиш
 шароити таъминланганлигига ишонч ҳосил қилиши керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 25.5
 </span>
 Одам ташишга мослаштирилмаган юк автомобилларининг юкхонасида фақат
 юкни олиш учун бораётган ёки уни кузатиб бораётган шахсларнинг
 бўлишига йўл қўйилади, бунда улар бортлардан пастда жойлашган
 ўриндиқлар билан таъминланишлари керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 25.6
 </span>
 Aвтобусларда ёки фургон-юкхонали юк автомобилларида болаларни
 ташишда катта ёшли кузатиб борувчи шахс, бортли юк автомобилининг
 кузовида ташишда еса камида икки нафар катта ёшли кузатиб борувчи
 шахс бўлиши шарт. Бундай транспорт воситаларининг олди ва орқа
 томонига Болалар гуруҳини ташиш таниқлиқ белгиси ўрнатилиши керак.
 <br />
 Болаларни юк автомобилларида ташишда еса ушбу талабларга қўшимча
 равишда борт баландлиги 1 (бир) метрдан кам бўлмаслиги керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 25.7
 </span>
 Ҳайдовчи транспорт воситаси тўла тўхтагандан кейин одамларни
 тушириши ва чиқариши, ешиклар ёпилгандан сўнггина ҳаракатланишни
 бошлаши ва тўла тўхтамагунча уларни очмаслиги шарт.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 25.8
 </span>
 Одамларни ташиш қуйидаги ҳолларда тақиқланади: <br />
 автомобилнинг кабинасидан ташқарида (бортли юк ёки фургон-юкхонали
 автомобилларда одам ташишдан ташқари); <br />
 тракторлар ва бошқа ўзи юрар машиналар, юк ташиладиган тиркамалар,
 тиркама-уйча, юк мотоцикллари юкхонасида; <br />
 мотоциклларнинг конструкциясида кўзда тутилган жойларидан
 ташқарида; <br />
 12 ёшга тўлмаган болаларни мотоциклнинг орқа уриндиғида, шунингдек
 енгил автомобилга болаларни ушлаб турувчи махсус қурилма ўрнатилмаган
 бўлса, унинг олдинги ўриндиғида; <br />
 12 ёшга тўлмаган болаларни ҳисобга олмаганда транспорт воситасининг
 техник тавсифномасида кўзда тутилган миқдордан ортик. <br />
 Бунда транспорт воситасининг ҳақиқий вазни уни ишлаб чиқарган корхона
 белгилаган рухсат етилган тўла вазндан ортмаслиги керак.
 </p>
 </div>
  )
}

export default Comp25
