import React from 'react'

const Comp2 = () => {
  const roadsigns = [
    {
      title: '2.1. Asosiy yo’l',
      description:
        'Harakat tartibga solinmagan chorrahalarda ikkinchi darajali yo’lda harakatlanayotgan transport vositasidan oldin o’tish huquqi beriladigan yo’lning boshlanishi.',
      img: '1',
    },
    {
      title: '2.2. Asosiy yo’lning oxiri',
      description: '',
      img: '2',
    },
    {
      title: '2.3. Ikkinchi darajali yo’l bilan kesishuv',
      description: '2.3.2 – tutashuv o’ngdan, 2.3.3 – tutashuv chapdan.',
      img: '3',
    },
    {
      title: '2.4. Yo’l bering',
      description:
        'Haydovchi kesib o’tilayotgan yo’ldan, 7.13 qo’shimcha belgisi bo’lganda esa asosiy yo’ldan kelayotgan transport vositasiga yo’l berishi kerak.',
      img: '4',
    },
    {
      title: '2.5. To’xtamasdan harakatlanish taqiqlangan',
      description:
        'To’xtash chizig’i oldida, agar u bo’lmasa, kesib o’tiladigan qatnov qismining chetida to’xtamasdan harakatlanish taqiqlanadi. Haydovchi kesib o’tilayotgan yo’lda 7.13 qo’shimcha belgisi bo’lganda esa asosiy yo’ldan chiqayotgan transport vositalariga yo’l berish kerak. Bu belgi temir yo’l kesishmasi yoki karantin masqanidan oldin o’rnatilishi mumkin. Bunday hollarda haydovchi to’xtash chizig’i oldida, u bo’lmasa, belgi oldida to’xtashi kerak.',
      img: '5',
    },
    {
      title: '2.6. Ro’para harakatlanishning ustunligi',
      description:
        'Qarama-qarshi harakatnalishni qiyinlashtiradigan hollarda yo’lning tor qismiga kirish taqiqlanadi. Haydovchi yo’lning tor qismida bo’lgan yoki ro’paradan harakatlanayotgan transport vositasiga yo’l berishi kerak.',
      img: '6',
    },
    {
      title: '2.7. Ro’paradagi harakatlanishga nisbatan imtiyoz',
      description:
        'Yo’lning tor qismida harakatlanmoqchi bo’lgan haydovchi ro’paradan kelayotgan transport vositasidan oldin o’tish huquqiga ega.',
      img: '7',
    },
  ]
   const imgSize = (sign: any) => {
    if (
      +sign.img === 3
    ) {
      return (
        <img
          src={`${process.env.PUBLIC_URL}/images/belgilar/imtiyoz/${sign.img}.png`}
          className="big-img mx-auto d-block"
          alt={sign.description}
        />
      )
    } else {
      return (
        <img
          src={`${process.env.PUBLIC_URL}/images/belgilar/imtiyoz/${sign.img}.png`}
          className="small-img d-block mx-auto"
          alt={sign.description}
        />
      )
    }
  }
  return (
    <div>
      <h2>Imtiyoz belgilari</h2>
      <p className='fs-4'>
        Imtiyoz belgilari – chorrahalarda, alohida joylashgan qatnov qismlari
        kesishgan joylarda, shuningdek, yo'lning tor qismida yo'l harakati
        qatnshchilarining harakatlanish navbatini belgilash uchun qo'llaniladi.
      </p>
      <div>
        {roadsigns.map((sign: any) => (
          <div key={sign.img}>
            <h3 className="text-center">{sign.title}</h3>
            <p className='fs-4'>{sign.description}</p>
            {
              imgSize(sign)
            }
            <hr />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Comp2
