import React from 'react'

const Comp24 = () => {
  return (
    <div className='fs-5'>
      <h2>24. Transport vositalarini boshqarishni o'rgatish</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          24.1
        </span>
        Transport vositalarini boshqarishni dastlabki o’rgatish yopiq
        maydonchalarda yoki avtodromlarda o’tkazilishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          24.2
        </span>
        Yo’llarda boshqarishni o’rgatishga faqat yo’riqchi ishtiroqida,
        o’rganuvchi dastlabki boshqaruv malakasini etarlicha egallagandan
        so’nggina ruxsat etiladi. O’rganuvchi Qoidalar talablarini bilishi va
        ularga amal qilishi shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          24.3
        </span>
        Boshqarishni o’rgatayotgan yo’rikchi o’rgatish huquqini beruvchi
        hujjatga, shu toifadagi transport vositasini boshqarish huquqini
        beruvchi guvohnomaga va u bevosita 3 yillik haydovchilik faoliyatiga ega
        bo’lishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          24.4
        </span>
        Haydovchilarni tayyorlash mashg’ulotlari o’quv yakunida avtomobil’
        boshqarishni o’rganuvchi 18 yoshga, avtobus, tramvay va trolleybus
        boshqarishni o’rganuvchi 21 yoshga, mototsikl boshqarishni o’rganuvchi
        esa 16 yoshga to’ladigan shaxslar bilan olib boriladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          24.5
        </span>
        Boshqarishni o’rgatish uchun mo’ljallangan mexanik transport vositalari
        Boshqarishni o’rgatish transport vositasi taniqliq belgisi va yo’riqchi
        uchun orqani ko’rsatuvchi ko’zgu bilan jihozlanishi kerak. Bundan
        tashqari boshqarishni o’rgatish transport vositalarida yo’riqchi uchun
        qo’shimcha ulovchi (stsepleniye) va to’xtatish tepkilari ham bo’lishi
        kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          24.6
        </span>
        Boshqarishni o’rgatish yo’nalishlari Davlat avtomobil’ nazorati
        organlari bilan kelishilishi shart. Boshqa Yo’llarda boshqarishni
        o’rgatish taqiqlanadi.
      </p>
    </div>
  )
}

export default Comp24
