import React from 'react'

const Comp10 = () => {
  return (
    <div className='fs-5'>
      <h2>10. Harakatlanishni boshlash, yo'nalishlarni o'zgartirish</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.1
        </span>
        Haydovchi harakatlanishni boshlash, qayta tizilish, burilish (qayrilib
        olish), yo’nalishini o’zgartirish va to’xtashdan oldin o’z harakati
        bilan boshqa transport vositalariga havf solmayotganligiga va to’sqinlik
        qilmayotganligiga ishonch hosil qilishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.2
        </span>
        Yondosh hududlardan yo’lga chiqayotgan haydovchi unda harakatlanayotgan
        transport vositalariga va piyodalarga, shuningdek, undan chiqishda
        o’zining harakat yo’nalishini kesib o’tayotgan piyodalar va
        velosipedchilarga yo’l berishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.3
        </span>
        Haydovchi qayta tizilishda yon bo’lakda yo’lakay harakat yo’nalishini
        o’zgartirmasdan ketayotgan transport vositalariga yo’l berishi kerak.{' '}
        <br />
        Yo’lakay yo’nalishda harakatlanayotgan transport vositalari bir vaqtda
        qayta tizilayotganda, haydovchi o’ng tomondagi transport vositasiga yo’l
        berishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.4
        </span>
        Aylanma harakatlanish tashkil qilingan chorrahalarga kirish uchun
        burilishdan boshqa barcha hollarda haydovchi o’ngga, chapga burilish
        yoki qayrilib olishdan oldin shu yo’nalishda harakatlanish uchun
        mo’ljallangan qatnov qismining eng chetki holatini egallashi shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.5
        </span>
        Burilishni shunday amalga oshirish kerakki, bunda qatnov qismlarining
        kesishmasidan chiqayotgan transport vositasi qarama-qarshi yo’nalishdagi
        harakat tomoniga o’tib ketmasin. <br />
        O’ngga burilayotgan transport vositasi iloji boricha qatnov qismining
        o’ng tomoniga yaqinroqda harakatlanishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.6
        </span>
        Transport vositasi o’zining gabarit o’lchamlariga yoki boshqa texnik
        sabablariga ko’ra Qoidalarning 10.4 bandi talablariga muvofik burilishni
        bajara olmasa, yo’l harakati havfsizligini ta’minlab va boshqa transport
        vositalariga halaqit bermasdan undan chetga chiqishiga yo’l ko’yiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.7
        </span>
        Rel’ssiz transport vositasining haydovchisi yo’lning chorrahadan
        tashqari joyida chapga burilish yoki qayrilib olishda qarama-qarshi
        yo’nalishdan kelayotgan transport vositalariga va yo’lakay yo’nalishdagi
        tramvayga yo’l berishi shart. <br />
        Chorrahadan tashqaridagi qatnov qismining kengligi chetki chap
        vaziyatdan qayrilib olish uchun etarli bo’lmasa, uni qatnov qismining
        o’ng chetidan (yo’lning o’ng yoqasidan) amalga oshirish mumkin. Bu holda
        haydovchi yo’lakay va qarama-qarshi yo’nalishdagi transport vositalariga
        yo’l berishi shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.8
        </span>
        Transport vositalarining harakat yo’nalishlari kesishadigan va o’tish
        navbati ushbu Qoidalarda ko’zda tutilmagan hollarda haydovchi o’ng
        tomondan yaqinlashib kelayotgan transport vositasiga yo’l berishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.9
        </span>
        Sekinlashish bo’lagi bo’lgan yo’llarda burilmoqchi bo’layotgan haydovchi
        o’z vaqtida shu bo’lakka o’tishi va tezlikni faqat unda kamaytirishi
        kerak. <br />
        Yo’lga chiqish joyida tezlanish bo’lagi bo’lsa, haydovchi unda
        harakatlanishi va qo’shni bo’lakda harakatlanayotgan transport
        vositalariga yo’l berishi, so’ngra oqimga ko’shilishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.10
        </span>
        Quyidagi joylarda qayrilib olish taqiqlanadi: <br />
        piyodalarning o’tish joylarida; <br />
        tonnellarda; <br />
        ko’priklar, osma yo’l (estakada)lar, osma ko’priklar va ularning ostida;{' '}
        <br />
        temir yo’l kesishmalarida; <br />
        yo’lning ko’rinishi biron-bir yo’nalishda 100 metrdan kam bo’lgan
        joylarda.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          10.11
        </span>
        Transport vositasini orqaga harakatlantirishda haydovchi yo’l harakati havfsizligini ta’minlashi va boshqa yo’l harakati qatnashchilariga halaqit bermasligi shart. Zarurat tug’ilganda, haydovchi boshqa shaxslarning yordamidan foydalanishi mumkin.
      </p>
    </div>
  )
}

export default Comp10
