import React from 'react'

const Comp24 = () => {
  return (
    <div className="fs-5">
      <h2>24. Транспорт воситаларини бошқаришни ўргатиш</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          24.1
        </span>
        Транспорт воситаларини бошқаришни дастлабки ўргатиш ёпиқ майдончаларда
        ёки автодромларда ўтказилиши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          24.2
        </span>
        Йўлларда бошқаришни ўргатишга фақат йўриқчи иштироқида, ўрганувчи
        дастлабки бошқарув малакасини етарлича егаллагандан сўнггина рухсат
        етилади. Ўрганувчи Қоидалар талабларини билиши ва уларга амал қилиши
        шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          24.3
        </span>
        Бошқаришни ўргатаётган йўрикчи ўргатиш ҳуқуқини берувчи ҳужжатга, шу
        тоифадаги транспорт воситасини бошқариш ҳуқуқини берувчи гувоҳномага ва
        у бевосита 3 йиллик ҳайдовчилик фаолиятига ега бўлиши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          24.4
        </span>
        Ҳайдовчиларни тайёрлаш машғулотлари ўқув якунида автомобилъ бошқаришни
        ўрганувчи 18 ёшга, автобус, трамвай ва троллейбус бошқаришни ўрганувчи
        21 ёшга, мотоцикл бошқаришни ўрганувчи еса 16 ёшга тўладиган шахслар
        билан олиб борилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          24.5
        </span>
        Бошқаришни ўргатиш учун мўлжалланган механик транспорт воситалари
        Бошқаришни ўргатиш транспорт воситаси таниқлиқ белгиси ва йўриқчи учун
        орқани кўрсатувчи кўзгу билан жиҳозланиши керак. Бундан ташқари
        бошқаришни ўргатиш транспорт воситаларида йўриқчи учун қўшимча уловчи
        (сцепленийе) ва тўхтатиш тепкилари ҳам бўлиши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          24.6
        </span>
        Бошқаришни ўргатиш йўналишлари Давлат автомобилъ назорати органлари
        билан келишилиши шарт. Бошқа Йўлларда бошқаришни ўргатиш тақиқланади.
      </p>
    </div>
  )
}

export default Comp24
