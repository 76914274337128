import React from 'react'

const Comp13 = () => {
  return (
    <div className='fs-5'>
      <h2>13. Quvib o'tish</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          13.1
        </span>
        Haydovchi quvib o’tishni boshlashdan oldin: o’zi o’tmoqchi bo’lgan
        harakatlanish bo’lagi quvib o’tish uchun etarlicha masofada bo’sh
        ekanligiga, bu qarama-qarshi yo’nalishdagi va shu bo’lakdagi transport
        vositalarining harakatiga halaqit bermasligiga; <br />
        shu bo’lakda orqada kelayotgan transport vositasi quvib o’tishni
        boshlamaganligiga; <br />
        oldinda harakatlanayotgan transport vositasi esa quvib o’tish, chapga
        burilish (qayta tizilish) haqida ishora bermaganligiga; <br />
        quvib o’tishni tugallayotganda quvib o’tilayotgan transport vositasiga
        halaqit bermasdan ilgari egallagan bo’lakka qaytib o’ta olishiga ishonch
        hosil qilishi shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          13.2
        </span>
        Rel’ssiz transport vositalarini faqat chap tomondan quvib o’tishga
        ruxsat beriladi. Biroq chapga burilish ishorasini berib, burilishga
        kirishgan transport vositasini quvib o’tish o’ng tomondan amalga
        oshiriladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          13.3
        </span>
        Quvib o’tilayotgan transport vositasining haydovchisiga harakat
        tezligini oshirish yoki boshqa hatti-harakatlar bilan quvib o’tishga
        to’sqinlik qilish taqiqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          13.4
        </span>
        Quvib o’tishni tugallagandan so’ng haydovchi ilgari egallagan
        harakatlanish bo’lagiga qaytishi shart (o’ng tomondan ruxsat etilgan
        quvib o’tishdan tashqari). Biroq ushbu yo’nalishda harakatlanish uchun
        ikki yoki undan ortiq bo’lak bo’lsa, quvib o’tayotgan haydovchi o’zi
        egallagan bo’lakka qaytgani zahoti keyingi quvib o’tishni boshlashga
        to’g’ri keladigan hollarda orqada o’zidan katta tezlikda
        harakatlanayotgan transport vositalariga halaqit bermasa, chap bo’lakda
        harakatlanishni davom ettirishi mumkin.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          13.5
        </span>
        Quvib o’tish quyidagi hollarda taqiqlanadi: <br />
        tartibga solingan chorrahalarda qarama-qarshi yo’nalishda harakatlanish
        bo’lagiga chiqib; <br />
        tartibga solinmaydigan chorrahalarda asosiy bo’lib hisoblanmaydigan
        yo’llarda harakatlanishda (aylanma harakatlanishli chorrahalarda quvib
        o’tish, kajavasiz ikki g’ildirakli transport vositalarini quvib o’tish
        va ruxsat etilgan hollarda o’ng tomondan quvib o’tish bundam mustasno);{' '}
        <br />
        piyodalar o’tish joylarida piyodalar bo’lganda; <br />
        temir y’ol kesishmalarida va ulargacha 100 metrdan kam masofa qolganda;{' '}
        <br />
        quvib yoki aylanib o’tayotgan transport vositalarini; <br />
        tepalikka ko’tarilishning oxirida va yo’lning ko’rinishi cheklangan
        boshqa joylarida qarama-qarshi harakatlanish yo’nalishiga chiqib.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          13.6
        </span>
        Aholi yashaydigan joylardan tashqaridagi yo’llarda sekin
        harakatlanadigan yoki katta hajmli transport vositasini quvib o’tish
        qiyin bo’lgan hollarda uning haydovchisi ushbu transport vositasini
        iloji boricha yo’lning o’ng tomoniga olishi, zarur bo’lganda esa
        orqasida to’planib qolgan transport vositalarini o’tkazib yuborish uchun
        to’xtashi kerak.
      </p>
    </div>
  )
}

export default Comp13
