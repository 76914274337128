import React from 'react'

const Comp26 = () => {
  return (
    <div className='fs-5'>
      <h2>26. Yuk tashish</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          26.1
        </span>
        Tashilayotgan yukning vazni va o’qlarga tushadigan og’irlik miqdorining
        taqsimlanishi transport vositasini ishlab chiqargan korxona tomonidan
        belgilangan miqdordan oshmasligi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          26.2
        </span>
        Haydovchi harakatni boshlashdan oldin yukning to’g’ri joylashganligi va
        mahkamlanganligiga ishonch hosil qilishi, harakatlanish vaqtida esa
        yukning tushib ketmasligini va harakatlanishga halaqit bermasligini
        ta’minlash maqsadida uning holatini kuzatib borishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          26.3
        </span>
        Quyidagi shartlarga amal qilinganda yuk tashishga yo’l qo’yiladi: <br />
        haydovchiga tevarak-atrof ko’rinishini cheklamasa; <br />
        transport vositasini boshqarishni qiyinlashtirmasa va muvozanatini
        buzmasa; <br />
        tashqi yoritish asboblari va yorug’lik qaitargichlarini, davlat raqami
        belgilarini va taniqlik belgilarni, shuningdek, ishoralarni va qo’l
        bilan beriladigan ishoralarni ko’rishni to’smasa; <br />
        shovqin solmasa, chang ko’tarmasa yo’lni va atrof muhitni iflos qilmasa.{' '}
        <br />
        Agar yukning joylashuvi va holati qaid etilgan talablarga javob bermasa,
        haydovchi yuqorida sanab o’tilgan qoidalar buzilishini bartaraf etish
        choralarini ko’rishi, uning iloji bo’lmasa bundan keyingi
        harakatlanishni to’xtatishi shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          26.4
        </span>
        Transport vositasining gabaritlaridan oldi yoki orqa tomonga 1 metrdan,
        gabarit chiroqlarining chetki qismidan 0,4 metrdan ortiq chiqib turgan
        yuk ushbu Qoidalarning 28.3 bandiga muvofik Katta o’lchamli yuk belgisi
        bilan belgilanishi, qorong’i vaqtda va etarlicha ko’rinmaydigan
        sharoitda esa bunga qo’shimcha qilib oldi oq chiroq yoki yorug’lik
        qaitargich, orqasi esa qizil chiroq yoki yorug’lik qaitargich bilan
        belgilanishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          26.5
        </span>
        O’ta og’ir, katta o’lchamli va xavfli yuklarni tashish, gabarit
        o’lchamlari yukli yoki yuksiz holda eni 2,5 metrdan va balandligi yo’l
        sathidan 4 metrdan, uzunligi bitta tirkama bilan 20 metrdan ortiq
        bo’lgan yoxud yuki transport vositasining orqa nuqtasidan 2 metrdan
        ortiq chiqib turgan transport vositalarining, ikki yoki undan ortiq
        tirkamali avtopoezdlarning harakatlanishi uchun DAN organlarining
        ruxsatnomasi bo’lishi kerak.
      </p>
    </div>
  )
}

export default Comp26
