import React from 'react'

const Comp1 = () => {
  return (
    <div className="fs-5">
      <h2>1. Умумий қоидалар.</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          1.1
        </span>
        Ушбу Йўл ҳаракати қоидалари (кейинги ўринларда – “Қоидалар” деб аталади)
        республика ҳудудидаги йўлларда ҳаракатланишнинг ягона тартибини
        белгилайди.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          1.2
        </span>
        Ўзбекистон Республикаси йўлларида транспорт воситаларининг ўнг тарафлама
        ҳаракатланиш тартиби белгиланган.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          1.3
        </span>
        Йўл ҳаракати қатнашчилари “Йўл ҳаракати хавфсизлиги тўғрисида”ги
        Ўзбекистон Республикаси Қонуни, ушбу Қоидаларни ва унда келтирилган
        светофор ишоралари, йўл белгилари (1-илова), қатнов қисмидаги чизиқлар
        (2-илова)нинг ўзларига тегишли бўлган талабларини билишлари, уларга амал
        қилишлари, шунингдек, ўзларига берилган ҳуқуқ доирасида йўлларда
        ҳаракатни бошқараётган тартибга солувчиларнинг кўрсатмаларини сўзсиз
        бажаришлари шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          1.4
        </span>
        Йўл ҳаракати қатнашчилари ҳаракатланиш вақтида бошқа қатнашчиларнинг
        ҳаракатланишига хавф туғдирмаслиги керак. Ваколати бўлмаган жисмоний ва
        юридик шахслар томонидан йўл қопламасини бузиш ёки ифлослантириш, йўл
        белгиларини, светофорларни ва ҳаракатланишни ташкил етишнинг бошқа
        техник воситаларини ўзбошимчалик билан олиб ташлаш, ўрнатиш, тўсиб
        қўйиш, шикастлантириш, йўлларда ҳаракатланишга тўсқинлик қилувчи
        нарсаларни қолдириш тақиқланади.
        <br />
        Ҳаракатга тўсқинлиқ туғдирган шахс уни тезда бартараф қилиш учун
        имконияти даражасида барча чораларни кўриши, агарда бунинг иложи
        бўлмаса, ўзида бўлган барча воситалар билан хавф-хатар ҳақида ҳаракат
        қатнашчиларини огоҳлантириши ва милицияга хабар бериши шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          1.5
        </span>
        Мазкур Қоидаларни бузган шахслар Ўзбекистон Республикасининг амалдаги
        қонун ҳужжатларига биноан жавобгарликка тортиладилар.
      </p>
    </div>
  )
}

export default Comp1
