import React from 'react'

const Comp1 = () => {
  return (
    <div className='fs-5'>
      <h2>1. Umumiy qoidalar.</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          1.1
        </span>
        Ushbu Yo’l harakati qoidalari (keyingi o’rinlarda – “Qoidalar” deb
        ataladi) respublika hududidagi yo’llarda harakatlanishning yagona
        tartibini belgilaydi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          1.2
        </span>
        O’zbekiston Respublikasi yo’llarida transport vositalarining o’ng
        taraflama harakatlanish tartibi belgilangan.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          1.3
        </span>
        Yo’l harakati qatnashchilari “Yo’l harakati xavfsizligi to’g’risida”gi
        O’zbekiston Respublikasi Qonuni, ushbu Qoidalarni va unda keltirilgan
        svetofor ishoralari, yo’l belgilari (1-ilova), qatnov qismidagi
        chiziqlar (2-ilova)ning o’zlariga tegishli bo’lgan talablarini
        bilishlari, ularga amal qilishlari, shuningdek, o’zlariga berilgan huquq
        doirasida yo’llarda harakatni boshqarayotgan tartibga soluvchilarning
        ko’rsatmalarini so’zsiz bajarishlari shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          1.4
        </span>
        Yo’l harakati qatnashchilari harakatlanish vaqtida boshqa
        qatnashchilarning harakatlanishiga xavf tug’dirmasligi kerak. Vakolati
        bo’lmagan jismoniy va yuridik shaxslar tomonidan yo’l qoplamasini buzish
        yoki ifloslantirish, yo’l belgilarini, svetoforlarni va harakatlanishni
        tashkil etishning boshqa texnik vositalarini o’zboshimchalik bilan olib
        tashlash, o’rnatish, to’sib qo’yish, shikastlantirish, yo’llarda
        harakatlanishga to’sqinlik qiluvchi narsalarni qoldirish taqiqlanadi.
        <br />
        Harakatga to’sqinliq tug’dirgan shaxs uni tezda bartaraf qilish uchun
        imkoniyati darajasida barcha choralarni ko’rishi, agarda buning iloji
        bo’lmasa, o’zida bo’lgan barcha vositalar bilan xavf-xatar haqida
        harakat qatnashchilarini ogohlantirishi va militsiyaga xabar berishi
        shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          1.5
        </span>
        Mazkur Qoidalarni buzgan shaxslar O’zbekiston Respublikasining amaldagi qonun hujjatlariga binoan javobgarlikka tortiladilar.
      </p>
    </div>
  )
}

export default Comp1
