import React from 'react'

const Comp19 = () => {
  return (
    <div className="fs-5">
      <h2>19. Турар-жой даҳаларида ҳаракатланиш</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          19.1
        </span>
        Турар-жой даҳалари (кириш ва чиқиш 5.38 ва 5.39 белгилари билан
        белгиланган ҳудудлар)да пиёдаларга тротуарлар ҳамда қатнов қисмида
        ҳаракатланишига рухсат етилади. Бунда пиёдалар имтиёзга ега бўладилар,
        бироқ улар транспорт воситаларининг ҳаракатланишига асоссиз ҳалақит
        бермасликлари керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          19.2
        </span>
        Турар-жой даҳаларида: <br />
        механик транспорт воситасини бошқаришни ўргатиш; <br />
        юргизгичи ишлаб турган ҳолда тўхтаб туриш; <br />
        рухсат етилган тўла вазни 3.5 тоннадан ортиқ бўлган юк автомобилларининг
        махсус ажратилган, белги ёки чизиқлар билан белгиланган жойлардан
        ташқарида тўхтаб туриши тақиқланади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          19.3
        </span>
        Турар-жой даҳаларида ҳайдовчилар пиёдалар ҳавфсизлигини, шунингдек, ушбу
        ҳудуддаги иншоот, қурилма ва ўсимликларнинг шикастланмаслигини
        таъминлашлари зарур.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          19.4
        </span>
        Турар-жой даҳаларидан чиқишда ҳайдовчилар бошқа ҳаракат қатнашчиларига
        йўл беришлари керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          19.5
        </span>
        Ушбу бўлимдаги талаблар ҳовлили ҳудудларга ҳам тегишлидир.
      </p>
    </div>
  )
}

export default Comp19
