import React from 'react'

const Comp22 = () => {
  return (
    <div className='fs-5'>
 <h2>22. Ташқи ёритиш асбобларидан фойдаланиш</h2>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 22.1
 </span>
 Қоронғи вақтда ва етарлича кўринмайдиган шароитда, шунингдек,
 тоннелларда ҳаракатланаётган барча транспорт воситалари: <br />
 йўлнинг ёритилмаган қисмида узоқни ёки яқинни ёритувчи фараларини;
 <br />
 йўлнинг ёритилган қисмида яқинни ёритувчи фараларини; <br />
 йўлнинг ёритилганлигидан қатъи назар, тиркамаларнинг габарит
 чироқларини ёкиши керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 22.2
 </span>
 Қуйидаги ҳолларда узоқни ёритувчи фара яқинни ёритувчи фара билан
 алмаштирилиши керак: <br />
 аҳоли яшайдиган жойларда йўл ёритилган бўлса; <br />
 қарама-қарши йўналишдаги транспорт воситасига 150 метрдан камроқ
 масофа қолганда, шунингдек, ҳайдовчи транспорт воситасининг
 чироқларини вақти-вақти билан ўчириб бунга зарурият борлигини
 билдирган ҳолларда ундан ҳам кўпрок масофада; <br />
 қарама-қарши ва бир йўналишдаги ҳайдовчиларнинг кўзини
 қамаштириши мумкин бўлган бошқа барча ҳолларда. <br />
 Кўзи қамашган ҳайдовчи фалокат ёруғлик ишораларини ёқиши,
 ҳаракатланиш бўлагини ўзгартирмасдан тезликни камайтириши ва
 тўхташи керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 22.3
 </span>
 Қоронғи вақтда йўлнинг ёритилмаган қисмида, шунингдек, етарлича
 кўринмайдиган шароитда тўхтаган ёки тўхтаб турган транспорт
 воситасида габарит чироқлари ёқилган бўлиши керак. <br />
 Етарлича кўринмайдиган шароитда габарит чироқларига қўшимча қилиб
 яқинни ёритувчи фаралар, туманга қарши фаралар ва туманга қарши
 орқа чироқлар ёқилиши мумкин.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 22.4
 </span>
 Қуйидаги ҳолларда туманга қарши фараларни қўллаш мумкин: <br />
 етарлича кўринмайдиган шароитда алоҳида, шунингдек узоқни ёритувчи
 ёки яқинни ёритувчи фаралар билан; қоидаларнинг 22.5 бандида кўзда
 тутилган ҳолларда яқинни ёритувчи фара ўрнида.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 22.5
 </span>
 Куннинг ёруғ вақтида қуйидаги ҳолларда яқинни ёритувчи фаралар
 ёқилиши керак: <br />
 транспорт воситаларининг ташкилий жамланмаси сафида
 ҳаракатланаётганда; <br />
 умумий ҳаракат оқимига қарши йўналишда махсус ажратилган бўлакда
 ҳаракатланаётган белгиланган йўналишдаги транспорт воситаларида;
 <br />
 автобус ва юк автомобилларида болалар гуруҳини ташкилий ташишда;
 <br />
 ҳавфли, катта ўлчамли ва оғир вазнли юкларни ташишда; <br />
 механик транспорт воситаларини шатакка олишда (шатакка олиб
 кетаётган транспорт воситасида).
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 22.6
 </span>
 Фара-прозҳектор ва изловчи фаралардан фақат аҳоли яшаидиган жойлардан
 ташқарида қарама-қарши йўналишдан келаётган транспорт воситалари
 бўлмаганда фойдаланиш мумкин. <br />
 Aҳоли яшаидиган жойларда еса бундай фаралардан фақат хизмат
 вазифаларини бажараётган тезкор ва махсус хизмат транспорт
 воситаларининг ҳайдовчиларига фойдаланишга рухсат етилади.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 22.7
 </span>
 Туманга қарши орқа чироқларни фақат етарлича кўринмайдиган шароитда
 қўллаш мумкин. Орқадаги тўхташ чироқлари (стоп-сигнал)га туманга
 қарши орқа чироқларни улаш тақиқланади.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 22.8
 </span>
 “Aвтопоезд” таниқлиқ белгиси автопоезд ҳаракатланаётганда, куннинг
 қоронғи вақтида еса йўлда тўхташ ва тўхтаб туриш пайтида ҳам ёқиб
 қўйилиши керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 22.9
 </span>
 Зарғалдок ёки сариқ рангли милтилловчи чироқ-маёқча йўлда
 қурилиш, таъмирлаш ёки тозалаш ишлари олиб бораётган, шунингдек,
 оғир вазнли, катта ўлчамли ёки ҳавфли юкларни ташиш қоидаларида
 кўзда тутилган ҳолларда уларни ташиётган транспорт воситаларида
 ёкилиши керак.
 </p>
 </div>
  )
}

export default Comp22
