/** @format */
// import { AdMobPlus, InterstitialAd } from '@admob-plus/capacitor'
import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router";
import exit from "./../../images/svg/exit.svg";
import testbg from "./../../images/homebg2.png";
import nextimg from "./../../images/svg/next.svg";
import "./starttest.css";
import { BannerAd } from "@admob-plus/capacitor";
const Starttest = () => {
  const history = useHistory();
  const [alltests, setAll] = useState([]) as any;
  const [tests, setTests] = useState([]) as any;
  const [datah, setDataH] = useState({}) as any;
  const [index, setIndex] = useState(0);
  const [count, setCount] = useState(10);
  const [lost, setLost] = useState(0);
  const [minute, setMinutes] = useState(14);
  const [second, setSeconds] = useState(-1);
  const [bgtime, setBgtime] = useState("bg-secondary");
  const [timeover, setTimeOver] = useState(false);
  const lang = localStorage.getItem("lang");
  const [testresult, setTestresult] = useState([
    { ind: -1, bg: "bg-primary" },
    { ind: -1, bg: "bg-primary" },
    { ind: -1, bg: "bg-primary" },
    { ind: -1, bg: "bg-primary" },
    { ind: -1, bg: "bg-primary" },
    { ind: -1, bg: "bg-primary" },
    { ind: -1, bg: "bg-primary" },
    { ind: -1, bg: "bg-primary" },
    { ind: -1, bg: "bg-primary" },
    { ind: -1, bg: "bg-primary" },
  ]) as any;
  var interval: NodeJS.Timeout;
  //  const startAds =async() => {
  // const interstitial = new InterstitialAd({
  //   adUnitId: 'ca-app-pub-3940256099942544/1033173712',
  // })
  // await interstitial.load()
  // await interstitial.show()
  // }
  // setTimeout(() =>
  // {
  //   startAds();
  // },5000)
  useEffect(() =>
  {
    let banner:any;
    (async () =>
    {
      banner = new BannerAd({
        adUnitId: 'ca-app-pub-2110563711012419/7321534432',
      })
      await banner.show()
      //  ca-app-pub-3940256099942544/6300978111
  
    })();
    return () =>
    {
      (async () =>
    {
        await banner.hide();
      //  ca-app-pub-3940256099942544/6300978111
    })();
    }
  },[])
  useEffect(() => {
    if (second !== -1) {
      interval = setTimeout(() => {
        if (minute <= 4) {
          setBgtime("bg-danger");
        }
        if (second > 0) {
          setSeconds(second - 1);
        } else {
          if (minute > 0) {
            setMinutes(minute - 1);
            setSeconds(59);
          } else {
            setMinutes(minute);
            setSeconds(second);
            setTimeOver(true);
            clearTimeout(interval);
          }
        }
      }, 1000);
    }
  }, [second]);
  useEffect(() => {
    const getTests = async () => {
      let temp = "";
      let temp2 = "";
      if (lang === "uzlotin" || lang === "uzkiril" || lang === "rus") {
        temp = lang;
        if (lang === "uzkiril") {
          temp2 = "kiril";
        }
        if (lang === "uzlotin") {
          temp2 = "lotin";
        }
         if (lang === "rus") {
          temp2 = "ru";
        }
      } else {
        temp = "uzkiril";
        temp2 = "lotin";
        localStorage.setItem("lang", temp);
      }
      const result = await axios.get(
        `${process.env.PUBLIC_URL}/data/${temp}.json`
      );
      const result2 = await axios.get(
        `${process.env.PUBLIC_URL}/data/${temp2}.json`
      );
      setDataH(result2.data);
      setAll(result.data);
      generateRandArr(result.data);
    };
    getTests();
  }, []);
  const decideLang = () =>
  {
    if (lang === "uzlotin")
    {
      return "Kuting..."
    }
    if (lang === "uzkiril")
    {
      return "Кутинг...";
    }
    if (lang === "rus")
    {
      return "Подождите ...";
    }
  }
  const generateRandArr = (all: any) => {
    const temp = Math.floor(all.length / 10);
    const rand = Math.floor(Math.random() * temp);
    let newarr = [];
    for (let i = 0; i < 10; i++) {
      newarr[i] = all[i + rand * 10];
    }
    setTests(newarr);
    setSeconds(59);
  };
  const nextQuestion = () => {
    if (index < 9) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
    const audio = new Audio(`${process.env.PUBLIC_URL}/mp3/start.mp3`);
    audio.play();
  };
  const submitChoise = (i: any) => {
    const temp = tests[index].choises[i].answer;
    const newarr = testresult.map((el: any) => el);
    if (temp) {
      newarr[index].bg = "bg-success";
      newarr[index].ind = +i;
      setCount(count - 1);
      const audio = new Audio(`${process.env.PUBLIC_URL}/mp3/won.mp3`);
      audio.play();
      if (count === 1) {
        clearTimeout(interval);
      }
    } else {
      newarr[index].bg = "bg-danger";
      newarr[index].ind = +i;
      setCount(count - 1);
      const audio = new Audio(`${process.env.PUBLIC_URL}/mp3/lost.mp3`);
      audio.play();
      if (count === 1) {
        clearTimeout(interval);
      }
      setLost(lost + 1);
    }
    setTestresult(newarr);
  };
  const exitGame = () => {
    history.push("/");
  };
  const nextMatch = () => {
    clearTimeout(interval);
    setCount(10);
    setIndex(0);
    setLost(0);
    setTestresult([
      { ind: -1, bg: "bg-primary" },
      { ind: -1, bg: "bg-primary" },
      { ind: -1, bg: "bg-primary" },
      { ind: -1, bg: "bg-primary" },
      { ind: -1, bg: "bg-primary" },
      { ind: -1, bg: "bg-primary" },
      { ind: -1, bg: "bg-primary" },
      { ind: -1, bg: "bg-primary" },
      { ind: -1, bg: "bg-primary" },
      { ind: -1, bg: "bg-primary" },
    ]);
    setBgtime("bg-secondary");
    setMinutes(14);
    setSeconds(59);
    generateRandArr(alltests);
    setCount(10);
    setTimeOver(false);
  };

  return (
    <div className='container bg-light'>
      <div className='d-flex justify-content-between'>
        <div className='my-3'>
          <button onClick={exitGame} className='btn btn-primary'>
            <img src={exit} alt='exit game' />
          </button>
        </div>
        <div className='my-3 px-3 fs-4'>
          {lost >= 2 ? (
            <p className='text-danger border border-danger p-1 rounded'>
             {datah?.fail}
            </p>
          ) : count <= 0 && lost <= 1 ? (
            <p className='text-success border border-success p-1 rounded'>
             {datah?.success}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className='my-3' style={{ right: "100px", top: "20px" }}>
          <p className={bgtime + " p-2  rounded text-white fs-4"}>
            {minute > 10 ? minute : "0" + minute}:
            {second > 9 ? second : "0" + second}
          </p>
        </div>
      </div>
      {timeover ? (
        <div className='position-fixed start-0 w-100 h-100  finish-match'>
          <div
            className='d-flex justify-content-center'
            style={{ marginTop: "200px" }}>
            <div className='card' style={{ width: "18rem" }}>
              <div className='card-body'>
                <p className='card-text fs-4'>{ datah?.timeover}</p>
                <div className='d-flex justify-content-between'>
                  <button onClick={nextMatch} className='btn btn-primary'>
                    {datah?.cont}
                  </button>
                  <button
                    onClick={() => {
                      exitGame();
                    }}
                    className='btn btn-primary'>
                    {datah?.exit}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {count === 0 ? (
        <div className='position-fixed start-0 w-100 h-100  finish-match'>
          <div
            className='d-flex justify-content-center'
            style={{ marginTop: "200px" }}>
            <div className='card' style={{ width: "18rem" }}>
              <div className='card-body'>
                <h5 className='card-title'>{ datah.results}</h5>
                <p className='card-text fs-4'>{`${datah?.trueres}: ${10 - lost}/10`}</p>
                <p className='card-text fs-4'>{`${datah?.falseres}: ${lost}/10`}</p>
                <div className='d-flex justify-content-between'>
                  <button onClick={nextMatch} className='btn btn-primary'>
                    {datah?.cont}
                  </button>
                  <button
                    onClick={() => {
                      exitGame();
                    }}
                    className='btn btn-primary'>
                    {datah?.exit}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {tests.length ? (
        <div>
          <div className='d-grid justify-content-center '>
            <div className='d-flex overflow-auto my-2  '>
              {tests.map((_: any, i: any) => (
                <p
                  role='button'
                  key={i + "_id"}
                  onClick={() => {
                    setIndex(i);
                    const audio = new Audio(
                      `${process.env.PUBLIC_URL}/mp3/start.mp3`
                    );
                    audio.play();
                  }}
                  style={{ width: "50px", height: "50px" }}
                  className={`d-flex justify-content-center flex-shrink-0 align-items-center mx-2 fs-4 text-white rounded cursor position-relative ${testresult[i].bg}`}>
                  {i + 1}{" "}
                  {i === index ? (
                    <span
                      style={{
                        transform: "translateY(6px)",
                        clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
                        width: "30px",
                        height: "18px",
                      }}
                      className='position-absolute bottom-0 bg-light'></span>
                  ) : (
                    ""
                  )}
                </p>
              ))}
            </div>
          </div>

          <div className='row'>
            <div className='col-md-7 order-md-1'>
              <div>
                {tests[index].media.exist ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/${tests[index].media.name}.png`}
                    className='w-100 mx-auto'
                    alt={`${tests[index].question}`}
                  />
                ) : (
                  <img
                    src={testbg}
                    className='w-100 mx-auto'
                    alt={`${tests[index].question}`}
                  />
                )}
              </div>
            </div>

            <div className='col-md-5 order-md-0'>
              <div className='p-4'>
                <h2>{index + 1}-{datah?.question}:</h2>
                <p className='fs-4'>{tests[index].question}</p>
                <hr />
                <div className='position-relative'>
                  {testresult[index].ind !== -1 ? (
                    <div className='position-absolute w-100 h-100 choise-bg'></div>
                  ) : (
                    ""
                  )}

                  {tests[index]?.choises?.map((el: any, i: any) => (
                    <p
                      role='button'
                      onClick={() => {
                        submitChoise(i);
                      }}
                      key={i + "-id"}
                      className={
                        testresult[index].ind === i
                          ? `${testresult[index].bg} text-white p-2 rounded`
                          : `${
                              testresult[index].bg === "bg-danger"
                                ? `${
                                    tests[index]?.choises[i].answer
                                      ? "bg-success"
                                      : "bg-dark"
                                  }`
                                : "bg-dark"
                            } text-white p-2 rounded`
                      }>
                      {el.text}
                    </p>
                  ))}
                </div>
                <div>
                  <button
                    onClick={nextQuestion}
                    className='btn btn-primary px-2 py-1'>
                    <img src={nextimg} alt="keyingi" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        `${decideLang()}`
      )}
    </div>
  );
};

export default Starttest;
