import React from 'react'

const Comp8 = () => {
  return (
    <div className='fs-5'>
 <h2>8. Светофор ва тартибга солувчининг ишоралари</h2>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.1
 </span>
 Светофорларда яшил, сариқ, қизил ва оқ рангли ёруғлик ишоралари
 қўлланилади. <br />
 Светофор ишоралари вазифасига боғлик равишда доирасимон,
 йўналтиргич, пиёда тасвири туширилган ва Х-симон кўринишда
 бўлиши мумкин. <br />
 Доира шаклидаги ишорали светофорларда яшил доирали ишоралар билан
 бир сатҳда жойлашган яшил йўналтиргич кўринишдаги ишорали битта
 ёки иккита қўшимча тармоқ бўлиши мумкин.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.2
 </span>
 Светофорнинг доирали ишоралари қуйидаги маънони билдиради: <br />
 яшил ишора ҳаракатланишга рухсат беради; милтилловчи яшил ишора
 ҳам ҳаракатланишга рухсат беради ва унинг таъсир вақти тугаши ҳамда
 кўп ўтмай тақиқловчи ишора ёниши ҳақида ахборот беради; <br />
 яшил ишора ўчишига сониялар (секундлар)да қанча вақт қолганлиги
 ҳақида ахборот бериш учун рақамли табло қўлланилиши мумкин; <br />
 сариқ ишора ҳаракатни тақиқлайди (фақат Қоидаларнинг 8.13 бандида
 кўзда тутилгандан ташқари) ва ишоралар алмашуви ҳақида
 огоҳлантиради; <br />
 милтилловчи сариқ ишора ҳаракатланишга рухсат беради ва тартибга
 солинмаган чорраҳа ёки пиёдалар ўтиш жойи борлигидан хабардор
 қилади, хавф-хатар ҳақида огоҳлантиради; <br />
 қизил ишора, шунингдек, милтилловчи қизил ишора ҳаракатланишни
 тақиқлайди; <br />
 қизил ва сариқ ишораларнинг бир вақтда ёниши ҳаракатланишни
 таъқиқлайди ва кўп ўтмай яшил ишора ёниши ҳақида аҳборот беради.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.3
 </span>
 Юналтиргич кўринишидаги қизил, сариқ ва яшил рангли светофор
 ишоралари ҳам доира шаклидаги светофор ишоралари билан бир хил
 маънога ега бўлади. Улар фақат кўрсатилган йўналишга таъсир етади.
 <br />
 Aгар тегишли йўл белгиси билан тақиқланмаган бўлса, чапга бурилишга
 рухсат берувчи юналтиргич қайрилиб олишга ҳам рухсат беради.
 Қўшимча тармоқдаги яшил йўналтиргич ҳам ҳудди шу маънони
 билдиради. <br />
 Қўшимча тармоқдаги ишора ўчирилган бўлса, шу тармоқ тартибга
 солаётган йўналишда ҳаракатланиш тақиқланганлигини билдиради.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.4
 </span>
 Aгар светофорнинг асосий яшил ишорасига қора йўналтиргич
 (йўналтиргичлар)нинг шакли туширилган бўлса, у ҳайдовчиларга
 светофорнинг қўшимча тармоғи борлиги ҳакида ахборот беради ва шу
 асосий тармоқдаги йўналтиргичлар йўналишларида ҳаракатланишга
 рухсат берилганлигини кўрсатади.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.5
 </span>
 Aгар светофор ишораси пиёда тасвири кўринишида бўлса, у фақат
 пиёдаларнинг ҳаракатига таъсир кўрсатади. Бунда яшил ишора
 пиёдаларнинг ҳаракатланишига рухсат беради, қизил ишора еса
 тақиқлайди.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.6
 </span>
 Қатнов қисмидаги ҳаракат йўналиши қарама-қарши томонга ўзгариши
 мумкин бўлган бўлакларида транспорт воситаларининг ҳаракатини тартибга
 солиш учун Х-симон кизил ишорали ва пастга йўналган йўналтиргич
 кўринишдаги яшил ишорали реверсив светофорлар қўлланилади. Бу
 ишоралар қайси бўлак устига ўрнатилган бўлса, унда ҳаракатланишни
 тақиқлайди ёки рухсат беради. Икки томони 1.9 чизиғи билан
 белгиланган бўлак устига ўрнатилган ишоралари ўчирилган реверсив
 светофор шу бўлакка киришни тақиқлайди.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.7
 </span>
 Трамвайларнинг, шунингдек, белгиланган йўналишли бошқа транспорт
 воситаларининг ҳаракатланишини, улар учун ажратилган махсус бўлакда
 тартибга солиш учун Т-ҳарфи кўринишида жойлашган тўртта доирасимон
 оқ рангли ишорали светофорлар қўлланилиши мумкин. <br />
 Юқоридаги бир ёки бир нечта ва пастки ишора бир вақтнинг ўзида
 ёнганда, ҳаракатланишга рухсат етилади. Улардан чапдагиси чапга,
 ўнгдагиси ўнг томонга бурилишга, ўртасидаги тўғрига юришга
 рухсат беради. Aгар фақат юқоридаги учта ишора бир вақтда ёнса,
 ҳаракатланиш тақиқланади.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.8
 </span>
 Трамвайларнинг, шунингдек, белгиланган йўналишли бошқа транспорт
 воситаларининг ҳаракатланишини, улар учун ажратилган махсус бўлакда
 тартибга солиш учун Т-ҳарфи кўринишида жойлашган тўртта доирасимон
 оқ рангли ишорали светофорлар қўлланилиши мумкин. <br />
 Юқоридаги бир ёки бир нечта ва пастки ишора бир вақтнинг ўзида
 ёнганда, ҳаракатланишга рухсат етилади. Улардан чапдагиси чапга,
 ўнгдагиси ўнг томонга бурилишга, ўртасидаги тўғрига юришга
 рухсат беради. Aгар фақат юқоридаги учта ишора бир вақтда ёнса,
 ҳаракатланиш тақиқланади.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.9
 </span>
 Тартибга солувчининг ишоралари қуйидаги маънони билдиради: <br />
 ҚЎЛЛAРИ ЙОН ТОМОНГA УЗAТИЛГAНДA ЙОКИ ТУСҲИРИЛГAНДA, чап ва ўнг ён
 томонидан трамвайга тўғрига, релъссиз транспорт воситаларига тўғрига
 ва ўнгга ҳаракатланишга, пиёдаларга қатнов қисмини кесиб ўтишга
 рухсат берилади. Олди ва орқа томонидан барча транспорт воситалари ва
 пиёдаларнинг ҳаракатланиши тақиқланади; <br />
 ЎНГ ҚЎЛИ ОЛДИНГA УЗAТИЛГAНДA, чап ёнидан трамвайга чапга, релъссиз
 транспорт воситаларига барча йўналишларда, олди томонидан барча
 транспорт воситаларига фақат ўнгга ҳаракатланишга рухсат берилади.
 Орқа томонидан ва ўнг ёнидан барча транспорт воситаларининг
 ҳаракатланиши тақиқланади. Пиёдаларга тартибга солувчининг орқа
 томонидан қатнов қисмини кесиб ўтишга рухсат етилади; <br />
 ҚЎЛИНИ ЙУҚОРИГA КЎТAРГAНДA, Қоидаларнинг 8.13 бандида кўрсатилганидан
 бошқа ҳолларда транспорт воситалари ва пиёдаларнинг барча
 йўналишларда ҳаракатланиши тақиқланади. <br />
 Тартибга солувчи ҳайдовчи ва пиёдаларга тушунарли бўлган бошқа
 ишораларни ҳам бериши мумкин. Ишоралар яхши кўриниши учун
 тартибга солувчи зҳезл ёки ёруғлик кайтарадиган қизил рангли
 мосламаларни қўллаши мумкин.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.10
 </span>
 Ҳаракатни тартибга солаётган ёки хизмат бурчини ўтаётган милиция
 ходими транспорт воситаси ҳайдовчисига қарата қўл ишораси ёки
 овозкучайтиргичли қурилма орқали транспорт воситасини тўхтатишни
 талаб қилиши мумкин. Ҳайдовчи еса кўрсатилган жойда тўхташи керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.11
 </span>
 Йўл ҳаракати қатнашчиларининг еътиборини жалб етиш учун ҳуштак
 билан қўшимча ишора берилиши мумкин.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.12
 </span>
 Светофор (реверсивдан ташқари)нинг ёки тартибга солувчининг
 тақиқловчи ишорасида ҳайдовчилар транспорт воситаларини тўхҳташ
 чизиғи (5.33 белгиси) олдида, бундай чизиқ бўлмаганда еса: <br />
 чорраҳада Қоидаларнинг 15.7 бандини ҳисобга олган ҳолда, пиёдаларга
 ҳалақит бермасдан қатнов қисмлари кесишмаси олдида; <br />
 темир йўл кесишмаси олдида Қоидаларнинг 17.4 бандига мувофиқ; <br />
 бошқа жойларда светофор ёки тартибга солувчининг олдида
 ҳаракатланиши рухсат берилган транспорт воситалари ва пиёдаларга
 ҳалақит бермаган ҳолда тўхташи керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.13
 </span>
 Сариқ ишора ёнганда ёки тартибга солувчининг қўли юқори кўтарилганда, Қоидаларнинг 8.12 бандида кўзда тутилган жойларда кескин тормоз бермасдан тўхташга улгира олмайдиган транспорт воситаларининг ҳайдовчиларига ҳаракатни давом еттиришга рухсат етилади. <br />
Ушбу ишоралар берилганда, қатнов қисмида бўлган пиёдалар уни бўшатишлари, агар бунинг иложи бўлмаса, қарама-қарши йўналишлардаги транспорт оқимларини ажратувчи чизиқда тўхташлари керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.14
 </span>
 Ҳайдовчилар ва пиёдалар тартибга солувчининг ишораси ва кўрсатмаларига улар светофор ишоралари, йўл белгилари ва чизиқларига зид келган тақдирда ҳам амал қилишлари керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 8.15
 </span>
 Темир йўл кесишмаларида светофорнинг милтилловчи қизил ишораси билан бир вақтда пиёдаларнинг кесишма орқали ҳаракатланиши тақиқланганлиги тўғрисида қўшимча ахборот сифатида товушли ишора ҳам берилиши мумкин.
 </p>
 </div>
  )
}

export default Comp8
