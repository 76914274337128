import React from 'react'

const Comp6 = () => {
  const roadsigns = [
    {
      title: '6.1. Tibbiy yordam ko’rsatish joyi',
      description: '',
      img: '1',
    },
    {
      title: '6.2. Shifoxona',
      description: '',
      img: '2',
    },
    {
      title: '6.3. Yonilg’i shoxobchasi',
      description: '',
      img: '3',
    },
    {
      title: '6.4. Texnik xizmat ko’rsatish joyi.',
      description: '',
      img: '4',
    },
    {
      title: '6.5. Transport vositalarini yuvish joyi',
      description: '',
      img: '5',
    },
    {
      title: '6.6. Telefon',
      description: '',
      img: '6',
    },
    {
      title: '6.7. Oshxona',
      description: '',
      img: '7',
    },
    {
      title: '6.8. Ichimlik suv',
      description: '',
      img: '8',
    },
    {
      title: '6.9. Mehmonxona',
      description: '',
      img: '9',
    },
    {
      title: '6.10. Kemping',
      description: '',
      img: '10',
    },
    {
      title: '6.11. Dam olish joyi',
      description: '',
      img: '11',
    },
    {
      title: '6.12. Yo’l patrul xizmati maskani',
      description: '',
      img: '12',
    },
  ]
  return (
    <div>
      <h2>Servis belgilari</h2>
      <p className='fs-4'>
        Servis belgilari transport vositalari, yo’lovchilar va haydovchilarga
        xizmat ko’rsatish joylari haqida ma’lumot berish uchun qo’llaniladi.
      </p>
      <div>
        {roadsigns.map((sign: any) => (
          <div key={sign.img}>
            <h3 className="text-center">{sign.title}</h3>
            <p className='fs-4'>{sign.description}</p>
            <img
              src={`${process.env.PUBLIC_URL}/images/belgilar/servis/${sign.img}.png`}
              alt={sign.description}
              className="small-img d-block mx-auto"
            />
            <hr />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Comp6
