import React from 'react'

const Comp15 = () => {
  return (
    <div className='fs-5'>
      <h2>15. Chorrahada harakatlanish</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.1
        </span>
        Chapga yoki o’ngga burilayotgan haydovchi kesishayotgan yo’lning qatnov
        qismidan o’tayotgan piyodalarga, shuningdek, velosiped yo’lkasidan
        yo’lni kesib o’tayotgan velosipedchilarga yo’l berishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.2
        </span>
        Tirbandlik tufayli to’xtashga majbur bo’lgan haydovchi, agar u
        ko’ndalang yo’nalishdagi transport vositalarining harakatlanishiga
        to’sqinlik tug’diradigan bo’lsa, uning chorraha yoki qatnov qismlari
        kesishmasiga kirishi taqiqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.3
        </span>
        Miltillovchi sariq ishorada, svetoforlar ishlamayotgan yoki tartibga
        soluvchi bo’lmagan chorrahalarda haydovchilar tartibga solinmaydigan
        chorrahalardan o’tish qoidalariga, chorrahada o’rnatilgan imtiyoz
        belgilariga amal qilishlari shart. <br />
        Tartibga solingan chorrahalar.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.4
        </span>
        Svetoforning yashil ishorasida chapga burilayotgan yoki qayrilib
        olayotgan rel’ssiz transport vositasining haydovchisi qarama-qarshi
        tomondan to’g’riga harakatlanayotgan va o’ngga burilayotgan transport
        vositalariga yo’l berishi shart. Tramvay haydovchilari ham o’zaro ushbu
        qoidaga amal qilishlari kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.5
        </span>
        Svetoforning qizil yoki sariq ishorasi bilan bir vaqtda yongan
        qo’shimcha tarmoqning yashil ishorasidagi yo’naltirgich yo’nalishida
        harakatlanayotgan transport vositasining haydovchisi boshqa
        yo’nalishlardagi transport vositalariga yo’l berishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.6
        </span>
        Agar svetofor yoki tartibga soluvchining ishoralari tramvay va rel’ssiz
        transport vositalariga bir vaqtda harakatlanishga ruxsat bersa,
        harakatlanish yo’nalishidan qat’i nazar, tramvay oldin o’tish huquqiga
        ega buladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.7
        </span>
        Svetoforning ruxsat etuvchi ishorasida chorrahaga kirgan haydovchi,
        undan chiqishdagi svetofor ishorasidan qat’i nazar, belgilangan
        yo’nalishda harakatini davom ettirishi kerak. Biroq chorrahada
        haydovchining yuradigan yo’lida joylashgan svetoforlar oldida to’xtash
        chiziqlari (yoki 5.33 belgisi) bo’lsa, u har bir svetofor ishorasiga
        amal qilishi shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.8
        </span>
        Svetoforning ruxsat beruvchi ishorasi yonganda haydovchi chorraha orqali
        harakatlanishni tugallayotgan transport vositalari va qatnov qismidan
        tegishli yo’nalishda o’tishni tugallayotgan piyodalarga yo’l berishi
        shart. <br />
        Tartibga solinmagan chorrahalar.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.9
        </span>
        Teng ahamiyatga ega bo’lmagan yo’llar kesishgan chorrahada, keyingi
        harakat yo’nalishidan qat’i nazar, asosiy yo’ldan kelayotgan transport
        vositasiga ikkinchi darajali yo’ldan kelayotgan transport vositasining
        haydovchisi yo’l berishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.10
        </span>
        Teng ahamiyatga ega bo’lgan yo’llar kesishgan chorrahada rel’ssiz
        transport vositasining haydovchisi o’ngdan kelayotgan transport
        vositalariga yo’l berishi shart. <br />
        Bu qoidaga tramvay haydovchilari ham o’zaro amal qilishlari kerak.
        Bunday chorrahalarda, keyingi harakat yo’nalishidan qat’i nazar, tramvay
        rel’ssiz transport vositalariga nisbatan oldin o’tish huquqiga ega
        buladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.11
        </span>
        Asosiy yo’lning yo’nalishi chorrahada o’zgarganda, asosiy yo’lda harakatlanayotgan haydovchilar o’zaro teng ahamiyatli yo’llar chorrahasidan o’tish qoidasiga amal qilishlari kerak. <br />
Ikkinchi darajali yo’llarda harakatlanayotgan haydovchilar ham o’zaro shu qoidaga amal qilishlari lozim.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.12
        </span>
        Chapga burilishda yoki qayrilib olishda rel’ssiz transport vositasining haydovchisi teng ahamiyatli yo’ldan qarama-qarshi yo’nalishdan to’g’riga yoki o’ngga harakatlanayotgan, shuningdek ruxsat etilgan hollarda quvib o’tayotgan transport vositalariga yo’l berishi shart. Bu qoidaga tramvay haydovchilari ham o’zaro amal qilishlari kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          15.13
        </span>
        Agar haydovchi o’zi harakatlanayotgan yo’lning qoplamasi bor-yo’qligini aniqlay olmasa (qoronri vaqt, loy, qor, va h.k.) va imtiyoz belgilari bo’lmasa, unda u o’zini ikkinchi darajali yo’lda deb hisoblashi kerak
      </p>
    </div>
  )
}

export default Comp15
