import React from 'react'

const Comp7 = () => {
  return (
    <div className="fs-5">
      <h2>7. Махсус транспорт воситаларининг имтиёзлари</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          7.1
        </span>
        Кўк рангли ялт-ялт етувчи чироқ-маёқчаси ва (ёки) махсус товушли ишораси
        ёқилган тезкор ва махсус хизмат транспорт воситаларининг ҳайдовчилари
        кечиктириб бўлмайдиган хизмат вазифаларини бажараётиб, йўл ҳаракати
        ҳавфсизлигини таъминлаш шарти билан ушбу Қоидаларнинг 8-боб (8.9
        бандидан ташқари), 10–15, 18–19 боблари, Қоидаларнинг 1 ва 2-иловалари
        талабларидан четга чиқишлари мумкин. Кўк рангли чироқ маёқчага қўшимча
        қизил рангли чироқ-маёқча ҳам ёқилиши мумкин. <br />
        Бошқа йўул ҳаракати қатнашчиларига нисбатан имтиёзга ега бўлишлари учун
        бундай транспорт воситаларида ялт-ялт етувчи чироқ маёқча ва (ёки)
        махсус товушли ишораси ёқилган бўлиши шарт. Улар ўзларига йўул
        берилаётганлигига ишонч ҳосил қилганларидан сунггина имтиёздан
        фойдаланишлари мумкин. <br />
        Тезкор хизмат ва махсус хизмат транспорт воситалари кузатиб келаётган
        транспорт воситалари ҳам шу имтиёзлардан фойдаланадилар.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          7.2
        </span>
        Ҳайдовчилар кўк рангли чироқ-маёқча ва (ёки) махсус товушли ишораларни
        ёққан ҳолда яқинлашиб келаётган транспорт воситаларига, шунингдек,
        уларнинг кузатувидаги яққинни ёритувчи чироқдари ёқилган транспорт
        воситаларига йўл беришлари, зарур бўлган ҳолларда уларнинг тўсиқсиз ўтиб
        кетишлари учун ўзлари бошқариб бораётган транспорт воситаларини йўлнинг
        ўнг томонига олиб ўхтатишлари шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          7.3
        </span>
        Кўк рангли чироқ-маёқчасини ёқиб турган транспорт воситасига
        яқинлашаётган ҳайдовчилар зарур бўлиб қолганда дарҳол тўхташ имкониятини
        берадиган даражада ҳаракатланиш тезлигини камайтиришлари шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          7.4
        </span>
        Зарралдоқ ёки сариқ рангли чироқ-маёқчаси ёқилган, йўлдан фойдаланиш
        хизматига тегишли мослама ва транспорт воситаларининг ҳайдовчилари иш
        бажариш жараёнида йўл ҳаракати ҳавфсизлигини таъминлаш шарти билан ушбу
        Қоидаларнинг йўл белгилари, чизиқлари ҳамда 11.4, 11.5, 11.8 ва 18.1
        бандлари талабларидан четга чиқишлари мумкин. Бошқа ҳайдовчилар уларнинг
        иш бажаришларига тўсқинлик туғдирмасликлари шарт. Зарралдок ва сариқ
        рангли чироқ-маёқча ҳаракатланишда имтиёз бермайди ва у фақат бошқа
        ҳаракатланиш қатнашчиларининг еътиборини жалб етиш учун хизмат қилади.
      </p>
    </div>
  )
}

export default Comp7
