import React from 'react'

const Comp14 = () => {
  return (
    <div className="fs-5">
      <h2>14. Тўхташ ва тўхтаб туриш</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.1
        </span>
        Транспорт воситаларига йўлнинг ўнг томонидаги ёқасида, у бўлмаганда еса
        қатнов қисмининг четида тўхташ ва тўхтаб туришга рухсат берилади. <br />
        Aҳоли яшайдиган жойларда йўлларнинг чап томонида тўхташ ва тўхтаб
        туришга ҳар йўналишда камида биттадан ҳаракатланиш бўлаги бўлган ва
        ўртада трамвай изи бўлмаган ҳамда ҳаракат фақат бир томонлама бўлган
        йўлларда рухсат етилади. <br />
        Рухсат етилган тўла вазни 3,5 тоннадан кўп бўлган юк автомобиллари
        ҳаракат бир томонлама бўлган йўлларнинг чап томонида фақат юк ортиш ва
        тушириш учун тўхташлари мумкин.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.2
        </span>
        Транспорт воситаларини қатнов қисмининг чети бўйлаб бир қатор қилиб,
        кажаваси бўлмаган икки ғилдиракли транспорт воситаларини еса икки қатор
        қилиб қўйишга рухсат етилади. <br />
        Йўлнинг қатнов қисми кенгайган айрим жойларида бошқа йўл ҳаракати
        қатнашчиларига ҳалақит бермаслик шарти билан транспорт воситаларини
        бошқача тартибда ҳам қўйишга рухсат етилади. <br />
        Пиёдалар ҳаракатига ҳалақит бермаслик шарти билан қатнов қисмига
        чегарадош тротуар четида фақат енгил автомобиллар, мотоцикллар, мопедлар
        ва велосипедларга тўхтаб туришга рухсат етилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.3
        </span>
        Aҳоли яшайдиган жойлардан ташқарида тунаб қолиш, дам олиш ёки бошқа
        мақсадларда тўхтаб туриш фақат бунинг учун кўзда тутилган майдончаларда
        ёки йўлдан ташкарида рухсат етилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.4
        </span>
        Қуйидаги жойларда тўхташ тақиқланади: <br />
        трамвай йўлларида, шунингдек, бевосита уларга яқинроқда трамвайлар
        ҳаракатланишига ҳалақит берадиган бўлса; <br />
        тоннелларда, темир йўл кесишмаларида; <br />
        бир йўналишда ҳаракатланиш учун учтадан кам бўлаги бўлган кўприклар,
        осма йўл (естакада)лар, осма кўприклар ва уларнинг остида;
        <br />
        тўхтаган транспорт воситаси билан сидирға чизиқ (қатнов қисмининг четини
        белгилаган чизиқдан ташқари) орасидаги масофа 3 метрдан кам бўлган
        жойларда; <br />
        пиёдалар ўтиш жойларида ва уларга 5 метрдан камроқ масофа қолганда;
        <br />
        қатнов қисмининг ҳавфли бурилишларида; <br />
        лоақал бир йўналишдаги кўриниш 100 метрдан кам бўлган йўл дўнгликлари
        яқинида; <br />
        қатнов қисмлари кесишмаларида ва кесилаётган қатнов қисми четига 5
        метрдан камроқ масофа қолганда (қарама-қарши ҳаракат йўналишлари сидирға
        чизиқ ёки ажратувчи бўлак билан ажратилган йўлда, унга ёндан туташган
        йўлнинг қарама-қарши томони бундан мустасно); <br />
        бекат майдончаларига, улар бўлмаганда еса ҳаракатга ҳалақит берадиган
        бўлса, белгиланган йўналишдаги транспорт воситалари ёки таксининг тўхташ
        жойи кўрсаткичларига 15 метр қолганда; <br />
        транспорт воситаси светофор ишоралари, йўл белгиларини бошқа
        ҳайдовчилардан тўсиб қўядиган ёки бошқа транспорт воситаларининг
        ҳаракатланишига (кириш ва чиқишга) имкон қолдирмайдиган ва пиёдаларнинг
        ҳаракатига ҳалақит берадиган жойларда; <br />
        “Тўхташ тақиқланган” йўл белгиси (3.27) ҳамда чизиғи (1.4)нинг
        таъсирлари доирасида.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.5
        </span>
        Тўхтаб туриш қуйидаги жойларда тақиқланади: <br />
        тўхташ тақиқланган жойларда; <br />
        аҳоли яшайдиган жойлардан ташқарида 2.1 белгиси билан белгиланган
        йўлларнинг қатнов қисмида; <br />
        темир йўл кесишмаларига 50 метр қолганда; <br />
        тўхташ ва тўхтаб туришни тақиқловчи йўл белгилари (3.27–3.30) ва
        чизиқлари (1.4, 1.10) таъсирлари доирасида.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.6
        </span>
        Тўхташ тақиқланган жойда мажбурий тўхтаган ҳайдовчи транспорт воситасини
        бу жойдан олиб кетишнинг барча чораларини кўриши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.7
        </span>
        Aгар бошқа йўл ҳаракати қатнашчиларнинг ҳаракатига ҳалақит берадиган
        бўлса ёки ҳавф туғдирса, транспорт воситасининг ешикларини очиш
        тақиқланади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.8
        </span>
        Ҳайдовчи транспорт воситасини ўз-ўзидан ҳаракатланишини ёки ўзи
        йўқлигида ундан фойдаланишни истисно қиладиган зарур еҳтиёт чораларини
        кўргандан кейингина ўз жойини ташлаб кетиши ёки транспорт воситасини
        қолдириши мумкин.
      </p>
    </div>
  )
}

export default Comp14
