import React from 'react'

const Comp1 = () => {
  const roadsigns = [
    {
      title: '',
      description:
        "1.1 – qarama-qarshi yo'nalishlarda harakatlanayotgan transport oqimlarini ajratadi, yo'lning havfli joylaridagi harakatlanish bo'lagi chegarasini bildiradi; yo'lning kirish taqiqlangan qismini anglatadi; transport vositalarining to'xtab turish joyi chegarasini hamda avtomagistrallar qatoriga kiritilmagan yo'lning harakatlanish qismi chegarasini bildiradi.",
      img: '1',
    },
    {
      title: '',
      description:
        "1.2 (enli sidirg'a chiziq)-avtomagistrallarda harakatlanish qismi chegarasini bildiradi.",
      img: '2',
    },
    {
      title: '',
      description:
        "1.3 – to'rt va undan ortiq harakatlanish bo'lagi bo'lgan yo'llarda qarama-qarshi yo'nalishdagi transport vositalari oqimini ajratadi.",
      img: '3',
    },
    {
      title: '',
      description:
        "1.4 – to'xtash taqiqlangan joyni bildiradi. Yakka holda yoki 3.27 “To'xhtash taqiqlangan” yo'l belgisi bilan qo'llaniladi hamda yo'lning harakatlanish qismi chetiga yoki yo'l chetidagi to'siq (bordyur) ustidan chiziladi.",
      img: '4',
    },
    {
      title: '',
      description:
        "1.5 – ikki yoki uch bo'lakli yo'llarda qarama-qarshi yo'nalishlarda harakatlanayotgan transport vositalari oqimlarini ajratadi; bir yo'nalishda harakatlanish uchun mo'ljallangan ikki yoki ko'proq bo'lakli yo'llarda bo'laklarning chegaralarini bildiradi.",
      img: '5',
    },
    {
      title: '',
      description:
        "1.6 (yaqinlashish chizig'i – har bir chiziq uzunligi ular orasidagi masofadan uch barobar katta bo'lgan uzuq-uzuq chiziq) – qarama-qarshi yoki bir yo'nalishda harakatlanayotgan transport vositalari oqimlarini ajratuvchi 1.1 yoki 1.11 chizig'iga yaqinlashayotganlik hakida ogohlantiradi.",
      img: '6',
    },
    {
      title: '',
      description:
        "1.7 – (har bir chizig'i qisqa va oralari shu chiziqlar bo'yiga teng bo'lgan uzuq-uzuq chiziq) – chorrahadagi harakatlanish bo'laklarini bildiradi.",
      img: '7',
    },
    {
      title: '',
      description:
        "1.8 (enli uzuq-uzuq chiziq) – tezlanish yoki sekinlanish bo'lagi bilan yo'lning harakatlanish qismidagi asosiy bo'lagi o'rtasidagi chegarani bildiradi (chorrahalarda, yo'lning turli sathda kesishgan qismlarida, avtobuslar to'xtaydigan joylarda va h.k.).",
      img: '8',
    },
    {
      title: '',
      description:
        "1.9 – reversiv harakatlanishda qatnov qismining chegarasini belgilaydi; reversiv harakatlanish amalga oshirilgan yo'llarda (reversiv svetofori o'chirilgan holatda) qarama-qarshi yo'nalishdagi transport oqimini ajratadi.",
      img: '9',
    },
    {
      title: '',
      description:
        "1.10 – to'xtab turish taqiqlangan joyni bildiradi. Yakka holda yoki 3.28 yo'l belgisi bilan qo'llaniladi va yo'lning harakatlanish qismi chetiga yoki yo'l chetidagi to'siq (bordyur) ustidan chiziladi.",
      img: '10',
    },
    {
      title: '',
      description:
        "1.11 – faqat biror bo'lakka qayta tizilishga ruxsat etadi, bir yo'nalishdagi yoki qarama-qarshi kelayotgan transport oqimlarini ajratadi; qayrilib olish, to'xtash maydonchalari va shunga o'xshashlarga kirish uchun mo'ljallangan joylarda manyovr faqat bir yo'nalishda ruxsat etilganligini ko'rsatadi.",
      img: '11',
    },
    {
      title: '',
      description:
        "1.12 (to'xtash) – 2.5 belgisi yoki svetoforning (tartibga soluvchining) taqiqlovchi ishorasida haydovchi to'xtash kerak bo'lgan joyni ko'rsatadi.",
      img: '12',
    },
    {
      title: '',
      description:
        "1.13 – kesishgan yo'lda harakatlanayotgan transport vositasiga yo'l berish zarur bo'lganda haydovchi transport vositasini to'xtatishi lozim bo'lgan joyni ko'rsatadi.",
      img: '13',
    },
    {
      title: '',
      description:
        "1.14.1, 1.14.2 (zebra) – yo'lning harakat tartibga solinmagan qismida piyodalarning o'tish joyini belgilaydi.",
      img: '14',
    },
    {
      title: '',
      description:
        "1.14.2 belgining ishorali yo'naltirgichlari piyodalarning harakatlanish yo'nalishini ko'rsatadi.",
      img: '15',
    },
    {
      title: '',
      description:
        '1.14.3 – piyodalarning harakati svetofor yordamida tartibga solinganini bildiradi.',
      img: '16',
    },
    {
      title: '',
      description:
        "1.16.1–1.16.3 – transport vositalari oqimi ajraladigan yoki qo'shiladigan joylardagi yo'naltiruvchi orolchalarni bildiradi.",
      img: '17',
    },
    {
      title: '',
      description:
        "1.17 – belgilangan yo'nalishli transport vositalari to'xtash joylari hamda taksilarning to'xtab turish joylarini bildiradi.",
      img: '18',
    },
    {
      title: '',
      description:
        "1.18 – chorrahada bo'laklar bo'yicha ruxsat etilgan harakatlanish yo'nalishlarini ko'rsatadi. Yakka holda yoki 5.8.1, 5.8.2 belgilari bilan qo'llaniladi. Oxiri berk yo'l tasvirlangan chiziq, shu yo'nalishdagi yon tomonda joylashgan qatnov qismiga burilish taqiqlanganligini ko'rsatadi. Chetki chap bo'lakdan chapga burilishga ruxsat beruvchi yo'naltirgich shu bo'lakdan qayrilishga ham ruxsat beradi.",
      img: '19',
    },
    {
      title: '',
      description:
        "1.19 – harakatlanish qismining toraygan (shu yo'nalishdagi harakatlanish bo'laklarining soni kamaygan) joyiga yoki qarama-qarshi yo'nalishdan kelayotgan transport vositalari oqimlarini ajratuvchi 1.1 yoki 1.11 chizig'iga yaqinlashganlik haqida ogohlantiruvchi chiziq. Birinchi holda 1.18.1-1.18.3 yo'l belgilari bilan qo'llanilishi mumkin.",
      img: '20',
    },
    {
      title: '',
      description:
        "1.20 – haydovchini 1.13 chizig'iga yaqinlashayotgani haqida ogohlantiradi.",
      img: '21',
    },
    {
      title: '',
      description:
        "1.21 (“STOP” – to'xtang yozuvi) – 2.5 belgisi bilan birga qo'llanilganda haydovchini 1.12 chizig'iga yaqinlashayotganligi haqida ogohlantiradi.",
      img: '22',
    },
    {
      title: '',
      description:
        "1.22 – yo'l (harakatlanish yo'nalishi) raqamini ko'rsatadi.",
      img: '23',
    },
    {
      title: '',
      description:
        "1.23 – yo'lning faqat belgilangan yo'nalishli transport vositalari uchun mo'ljallangan bo'lagini bildiradi.",
      img: '24',
    },
  ]
  return (
    <div className='fs-5'>
      <h2 className="text-center">Yotiq chiziqlar</h2>
      <h4 className="text-center">
        Yo'lning qatnov qismi yuzasidagi chiziqlar, ishoralar, yozuvlar va
        boshqa belgilar harakatlanish tartibini belgilaydi.
      </h4>
      <p className="text-center">
        Yo'l yuzasidagi yotiq chiziqlar oq rangda bo'ladi (1.4, 1.10 va 1.17
        chiziqlar sariq rangda bo'ladi.)
      </p>
      <div>
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/images/chiziqlar/yotiq/main/1.png`}
            className="w-100"
            alt="yoqit chiziqlar"
          />
        </div>
        <div className="mt-4">
          <img
            src={`${process.env.PUBLIC_URL}/images/chiziqlar/yotiq/main/2.png`}
            className="w-100"
            alt="yoqit chiziqlar"
          />
        </div>
        <div className="mt-4">
          <img
            src={`${process.env.PUBLIC_URL}/images/chiziqlar/yotiq/main/3.png`}
            className="w-100"
            alt="yoqit chiziqlar"
          />
        </div>
      </div>
      <div className="mt-4">
        {roadsigns.map((sign: any) => (
          <div key={sign.img}>
            <p>{sign.description}</p>
            <img
              src={`${process.env.PUBLIC_URL}/images/chiziqlar/yotiq/${sign.img}.png`}
              alt="yoqit chiziqlar"
              className="small-img d-block mx-auto"
            />
            <hr />
          </div>
        ))}
      </div>
      <p>
        1.1 va 1.3 chiziqlarini bosib o'tish taqiqlanadi, harakatlanish qismining
        chetini bildirish uchun qo'llanilgan 1.1 chizig'i bundan mustasno.{' '}
        <br />
        1.2, 1.5-1.8 chiziqlarini istalgan tomondan bosib o'tish mumkin. <br />
        Reversiv svetoforlar bo'lmaganda yoki ular o'chirib qo'yilganda, 1.9
        chizig'i faqat u haydovchining o'ng tomonida bo'lsa, bosib o'tishga
        ruxsat etiladi. <br />
        Reversiv svetofor yoqilgan paytda bir yo'nalishli bo'laklarni
        ajratayotgan 1.9 chizig'ini istalgan tomonidan bosib o'tishga ruxsat
        etiladi. Reversiv svetofor o'chirilganda, haydovchilar darhol 1.9
        chizig'idan o'ngga qayta tizilishlari kerak. <br />
        Reversiv svetofor o'chirilgan bo'lsa, qarama-qarshi yo'nalishdagi
        transport oqimlarini ajratuvchi 1.9 chizig'ini bosib o'tish taqiqlanadi.
        1.11 chizig'ini uzuq-uzuq tomondan, shuningdek, sidirg'a chiziq
        tomonidan esa faqat quvib yoki chetlab o'tishni tugatayotganda bosib
        o'tishga ruxsat etiladi. <br />
        Ko'chma tirgakka o'rnatilgan vaqtinchalik yo'l belgilari va chiziqlar
        ma'no jihatidan bir-biriga zid kelsa, haydovchilar vaqtinchalik yo'l
        belgilariga amal qilishlari kerak.
      </p>
    </div>
  )
}

export default Comp1
