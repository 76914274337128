import React from 'react'

const Comp12 = () => {
  return (
    <div className="fs-5">
      <h2>12. Ҳаракатланиш тезлиги</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          12.1
        </span>
        Ҳайдовчи ҳаракатнинг серқатновлигини, транспорт воситаси ва юкнинг
        ҳусусияти ҳамда ҳолатини, йўл ва об-ҳаво шароитини, шунингдек,
        ҳаракатланиш йўналишидаги кўринишни ҳисобга олган ҳолда транспорт
        воситасини белгиланган чекланган тезликдан оширмасдан бошқариши керак.{' '}
        <br />
        Транспорт воситасининг тезлиги ҳайдовчига Қоидалар талабларини бажариш
        учун унинг ҳаракатини доимо назорат қилиб бориш имкониятини бериши
        керак. <br />
        Ҳаракатланишда, ҳайдовчи аниқлай олиши имкониятида бўлган ҳавф ёки тўсиқ
        пайдо бўлса, у транспорт воситасининг тезлигини тўла тўхташини
        таъминлайдиган даражада камайтириш ёки тўсиқни бошқа ҳаракат
        қатнашчилари учун ҳавфсиз айланиб ўтиш чораларини кўриши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          12.2
        </span>
        Aҳоли яшайдиган жойларда транспорт воситалари тезлигини соатига 70
        километрдан оширмасдан ҳаракатланишга рухсат етилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          12.3
        </span>
        Aҳоли яшайдиган жойлардан ташқарида: <br />
        енгил автомобилларга ва рухсат етилган тўла вазни 3,5 тоннадан
        ошмайдиган юк автомобилларига тезликни соатига 100 километрдан
        оширмасдан; <br />
        шаҳарлараро қатнайдиган автобусларга ва микро-автобусларга тезликни
        соатига 90 километрдан оширмасдан; <br />
        бошқа автобуслар, тиркамали енгил автомобиллар, мотоцикллар, рухсат
        етилган тўла вазни 3,5 тоннадан ортиқ бўлган юк автомобилларига тезликни
        соатига 80 километрдан оширмасдан; <br />
        тиркамали юк автомобилларига тезликни соатига 70 километрдан оширмасдан
        ҳаракатланишга рухсат етилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          12.4
        </span>
        Ҳар қандай йўлларда: <br />
        одам ташиётган юк автомобилларига тезликни соатига 60 километрдан
        оширмасдан; <br />
        механик транспорт воситаларини шатакка олган транспорт воситаларига
        тезликни соатига 50 километрдан оширмасдан; <br />
        ҳавфли, оғир вазнли ва катта ўлчамли юкларни ташиётган, ташкилий
        жамланмада ҳаракатланаётган транспорт воситаларига ДAН билан келишилган
        тезликдан оширмасдан ҳаракатланишга рухсат етилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          12.5
        </span>
        Ҳайдовчиларга қуйидагилар тақиқланади: <br />
        тезликни мазкур транспорт воситасининг техник тавсифномасида кўрсатилган
        енг юқори тезликдан ошириш; <br />
        тезликни транспорт воситасига ўрнатилган таниқлик белги (Тезлик
        чекланган)да кўрсатилган қийматидан ошириш; <br />
        зарурат бўлмаганда жуда паст тезликда ҳаракатланиб, бошқа транспорт
        воситаларига ҳалақит бериш; <br />
        йўл-транспорт ҳодисасининг олдини олиш зарурати бўлмаганда, кескин
        тормоз бериш.
      </p>
    </div>
  )
}

export default Comp12
