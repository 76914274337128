import React from 'react'

const Comp7 = () => {
  return (
    <div className="fs-5">
      <h2>7. Maxsus transport vositalarining imtiyozlari</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          7.1
        </span>
        Ko’k rangli yalt-yalt etuvchi chiroq-mayoqchasi va (yoki) maxsus
        tovushli ishorasi yoqilgan tezkor va maxsus xizmat transport
        vositalarining haydovchilari kechiktirib bo’lmaydigan xizmat
        vazifalarini bajarayotib, yo’l harakati havfsizligini ta’minlash sharti
        bilan ushbu Qoidalarning 8-bob (8.9 bandidan tashqari), 10–15, 18–19
        boblari, Qoidalarning 1 va 2-ilovalari talablaridan chetga chiqishlari
        mumkin. Ko’k rangli chiroq mayoqchaga qo’shimcha qizil rangli
        chiroq-mayoqcha ham yoqilishi mumkin. <br />
        Boshqa yo’ul harakati qatnashchilariga nisbatan imtiyozga ega
        bo’lishlari uchun bunday transport vositalarida yalt-yalt etuvchi chiroq
        mayoqcha va (yoki) maxsus tovushli ishorasi yoqilgan bo’lishi shart.
        Ular o’zlariga yo’ul berilayotganligiga ishonch hosil qilganlaridan
        sunggina imtiyozdan foydalanishlari mumkin. <br />
        Tezkor xizmat va maxsus xizmat transport vositalari kuzatib kelayotgan
        transport vositalari ham shu imtiyozlardan foydalanadilar.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          7.2
        </span>
        Haydovchilar ko’k rangli chiroq-mayoqcha va (yoki) maxsus tovushli
        ishoralarni yoqqan holda yaqinlashib kelayotgan transport vositalariga,
        shuningdek, ularning kuzatuvidagi yaqqinni yorituvchi chiroqdari
        yoqilgan transport vositalariga yo’l berishlari, zarur bo’lgan hollarda
        ularning to’siqsiz o’tib ketishlari uchun o’zlari boshqarib borayotgan
        transport vositalarini yo’lning o’ng tomoniga olib o’xtatishlari shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          7.3
        </span>
        Ko’k rangli chiroq-mayoqchasini yoqib turgan transport vositasiga
        yaqinlashayotgan haydovchilar zarur bo’lib qolganda darhol to’xtash
        imkoniyatini beradigan darajada harakatlanish tezligini kamaytirishlari
        shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          7.4
        </span>
        Zarraldoq yoki sariq rangli chiroq-mayoqchasi yoqilgan, yo’ldan
        foydalanish xizmatiga tegishli moslama va transport vositalarining
        haydovchilari ish bajarish jarayonida yo’l harakati havfsizligini
        ta’minlash sharti bilan ushbu Qoidalarning yo’l belgilari, chiziqlari
        hamda 11.4, 11.5, 11.8 va 18.1 bandlari talablaridan chetga chiqishlari
        mumkin. Boshqa haydovchilar ularning ish bajarishlariga to’sqinlik
        tug’dirmasliklari shart. Zarraldok va sariq rangli chiroq-mayoqcha
        harakatlanishda imtiyoz bermaydi va u faqat boshqa harakatlanish
        qatnashchilarining e’tiborini jalb etish uchun xizmat qiladi.
      </p>
    </div>
  )
}

export default Comp7
