import React from 'react'

const Comp13 = () => {
  return (
    <div className="fs-5">
      <h2>13. Қувиб ўтиш</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          13.1
        </span>
        Ҳайдовчи қувиб ўтишни бошлашдан олдин: ўзи ўтмоқчи бўлган ҳаракатланиш
        бўлаги қувиб ўтиш учун етарлича масофада бўш еканлигига, бу қарама-қарши
        йўналишдаги ва шу бўлакдаги транспорт воситаларининг ҳаракатига ҳалақит
        бермаслигига; <br />
        шу бўлакда орқада келаётган транспорт воситаси қувиб ўтишни
        бошламаганлигига; <br />
        олдинда ҳаракатланаётган транспорт воситаси еса қувиб ўтиш, чапга
        бурилиш (қайта тизилиш) ҳақида ишора бермаганлигига; <br />
        қувиб ўтишни тугаллаётганда қувиб ўтилаётган транспорт воситасига
        ҳалақит бермасдан илгари егаллаган бўлакка қайтиб ўта олишига ишонч
        ҳосил қилиши шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          13.2
        </span>
        Релъссиз транспорт воситаларини фақат чап томондан қувиб ўтишга рухсат
        берилади. Бироқ чапга бурилиш ишорасини бериб, бурилишга киришган
        транспорт воситасини қувиб ўтиш ўнг томондан амалга оширилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          13.3
        </span>
        Қувиб ўтилаётган транспорт воситасининг ҳайдовчисига ҳаракат тезлигини
        ошириш ёки бошқа ҳатти-ҳаракатлар билан қувиб ўтишга тўсқинлик қилиш
        тақиқланади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          13.4
        </span>
        Қувиб ўтишни тугаллагандан сўнг ҳайдовчи илгари егаллаган ҳаракатланиш
        бўлагига қайтиши шарт (ўнг томондан рухсат етилган қувиб ўтишдан
        ташқари). Бироқ ушбу йўналишда ҳаракатланиш учун икки ёки ундан ортиқ
        бўлак бўлса, қувиб ўтаётган ҳайдовчи ўзи егаллаган бўлакка қайтгани
        заҳоти кейинги қувиб ўтишни бошлашга тўғри келадиган ҳолларда орқада
        ўзидан катта тезликда ҳаракатланаётган транспорт воситаларига ҳалақит
        бермаса, чап бўлакда ҳаракатланишни давом еттириши мумкин.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          13.5
        </span>
        Қувиб ўтиш қуйидаги ҳолларда тақиқланади: <br />
        тартибга солинган чорраҳаларда қарама-қарши йўналишда ҳаракатланиш
        бўлагига чиқиб; <br />
        тартибга солинмайдиган чорраҳаларда асосий бўлиб ҳисобланмайдиган
        йўлларда ҳаракатланишда (айланма ҳаракатланишли чорраҳаларда қувиб ўтиш,
        кажавасиз икки ғилдиракли транспорт воситаларини қувиб ўтиш ва рухсат
        етилган ҳолларда ўнг томондан қувиб ўтиш бундам мустасно);
        <br />
        пиёдалар ўтиш жойларида пиёдалар бўлганда; <br />
        темир йъол кесишмаларида ва уларгача 100 метрдан кам масофа қолганда;
        <br />
        қувиб ёки айланиб ўтаётган транспорт воситаларини; <br />
        тепаликка кўтарилишнинг охирида ва йўлнинг кўриниши чекланган бошқа
        жойларида қарама-қарши ҳаракатланиш йўналишига чиқиб.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          13.6
        </span>
        Aҳоли яшайдиган жойлардан ташқаридаги йўлларда секин ҳаракатланадиган
        ёки катта ҳажмли транспорт воситасини қувиб ўтиш қийин бўлган ҳолларда
        унинг ҳайдовчиси ушбу транспорт воситасини иложи борича йўлнинг ўнг
        томонига олиши, зарур бўлганда еса орқасида тўпланиб қолган транспорт
        воситаларини ўтказиб юбориш учун тўхташи керак.
      </p>
    </div>
  )
}

export default Comp13
