/** @format */
import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router";
import testbg from "./../../images/homebg2.png";
import left from "./../../images/svg/left.svg";
import right from "./../../images/svg/right.svg";
import exit from "./../../images/svg/exit.svg";
import doubleleft from "./../../images/svg/doubleleft.svg";
import doubleright from "./../../images/svg/doubleright.svg";
import descript from "./../../images/svg/description.svg";
import nextimg from "./../../images/svg/next.svg";
import "./marafon.css";
import { BannerAd, InterstitialAd } from "@admob-plus/capacitor";
import { IonAlert } from "@ionic/react";
const Marafon = () => {
  const history = useHistory();
  const [alltests, setAll] = useState([]) as any;
  const [tests, setTests] = useState([]) as any;
  const [index, setIndex] = useState(0);
  const [count, setCount] = useState(10);
  const [lost, setLost] = useState(0);
  const [datah, setDataH] = useState({}) as any;
  const [testresult, setTestresult] = useState([]) as any;
    const [showAlert1, setShowAlert1] = useState(false);
  const mar = localStorage.getItem("marafon");

  const lang = localStorage.getItem("lang");
  useEffect(() =>
  {
    if (mar)
    {
      setShowAlert1(true);
    }
    
    let banner: any;
    (async () =>
    {
      banner = new BannerAd({
        adUnitId: 'ca-app-pub-2110563711012419/7321534432',
      })
      await banner.show()
      //  ca-app-pub-3940256099942544/6300978111
  
    })();
    return () =>
    {
      (async () =>
      {
        await banner.hide();
        //  ca-app-pub-3940256099942544/6300978111
      })();
    }
  }, []);

  useEffect(() =>
  {
    const startAds = async () =>
    { 
    const interstitial = new InterstitialAd({
      adUnitId: 'ca-app-pub-2110563711012419/1927421731',
    })
    await interstitial.load()
    await interstitial.show()
    }
    const interval=setInterval(startAds, 800000);
     return () =>
      {
        (async () =>
        {          
          clearInterval(interval);
        })();
      }
  },[])
  
  const decideLang = () =>
  {
    if (lang === "uzlotin")
    {
      return "Kuting..."
    }
    if (lang === "uzkiril")
    {
      return "Кутинг...";
    }
    if (lang === "rus")
    {
      return "Подождите ...";
    }
  }
  useEffect(() => {
    const getTests = async () => {
      let temp = "";
      let temp2 = "";
      if (lang === "uzlotin" || lang === "uzkiril" || lang === "rus") {
        temp = lang;
        if (lang === "uzkiril") {
          temp2 = "kiril";
        }
        if (lang === "uzlotin") {
          temp2 = "lotin";
        }
         if (lang === "rus") {
          temp2 = "ru";
        }
      } else {
        temp = "uzkiril";
        temp2 = "lotin";
        localStorage.setItem("lang", temp);
      }
      const result = await axios.get(
        `${process.env.PUBLIC_URL}/data/${temp}.json`
      );
      const result2 = await axios.get(
        `${process.env.PUBLIC_URL}/data/${temp2}.json`
      );
      setDataH(result2.data);
      const t = result.data.map(() => {
        return { ind: -1, bg: "bg-primary", help: false };
      });
      setCount(result.data.length);
      setTestresult(t);
      setAll(result.data);
      setTests(result.data);
    };
    getTests();
  }, []);
  const nextQuestion = () => {
    if (index < alltests.length - 1) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
    const audio = new Audio(`${process.env.PUBLIC_URL}/mp3/start.mp3`);
    audio.play();
  };
  const submitChoise = (i: any) => {
    const temp = tests[index].choises[i].answer;
    const newarr = testresult.map((el: any) => el);
    if (temp) {
      newarr[index].bg = "bg-success";
      newarr[index].ind = +i;
      setCount(count - 1);
      const audio = new Audio(`${process.env.PUBLIC_URL}/mp3/won.mp3`);
      audio.play();
    } else {
      newarr[index].bg = "bg-danger";
      newarr[index].ind = +i;
      setCount(count - 1);
      setLost(lost + 1);
      const audio = new Audio(`${process.env.PUBLIC_URL}/mp3/lost.mp3`);
      audio.play();
    }
    setTestresult(newarr);
    localStorage.setItem("marafon", JSON.stringify(newarr));    
  };
  const exitGame = () => {
    history.push("/");
  };

  return (
    <div className='container bg-container p-1'>
            <IonAlert
        isOpen={showAlert1}
        onDidDismiss={() => setShowAlert1(false)}
        cssClass="my-custom-class"
        header={'Davom ettirishni xohlaysizmi?'}
        // message={'Message <strong>text</strong>!!!'}
        buttons={[
          {
            text: 'Qaytadan boshlash',
            role: 'cancel',
            cssClass: 'primary',
            handler: (blah) => {
              console.log("cancel");
            },
          },
          {
            text: 'Davom ettirish',
            handler: () =>
            {
              const marafon:any = localStorage.getItem("marafon");
              setTestresult(JSON.parse(marafon))
            },
          },
        ]}
      />
      <div className='position-absolute start-0 top-0'>
        <button onClick={exitGame} className='btn btn-primary mt-3 mx-3'>
          <img src={exit} alt='exit game' />
        </button>
      </div>
      <div className='d-flex justify-content-center my-3'>
        <button
          onClick={() => {
            setIndex(0);
          }}
          className='btn btn-primary  rounded-circle'>
          <img src={doubleleft} alt='double left' />
        </button>
        <button
          onClick={() => {
            if (index > 0) {
              setIndex(index - 1);
            }
          }}
          className='btn btn-primary  rounded-circle'>
          <img src={left} alt=' left' />
        </button>
        <div className='mx-2'></div>
        <button
          onClick={() => {
            if (alltests.length - 1 > index) {
              setIndex(index + 1);
            }
          }}
          className='btn btn-primary  rounded-circle'>
          <img src={right} alt='right' />
        </button>
        <button
          onClick={() => {
            setIndex(alltests.length - 1);
          }}
          className='btn btn-primary  rounded-circle'>
          <img src={doubleright} alt='double right' />
        </button>
      </div>

      {count === 0 ? (
        <div className='position-fixed start-0 w-100 h-100  finish-match'>
          <div
            className='d-flex justify-content-center'
            style={{ marginTop: "200px" }}>
            <div className='card' style={{ width: "18rem" }}>
              <div className='card-body'>
                <h5 className='card-title'>{datah?.results}</h5>
                <p className='card-text fs-4'>{`${datah?.trueres}: ${
                  alltests.length - lost
                }/${alltests.length}`}</p>
                <p className='card-text fs-4'>{`${datah?.falseres}: ${lost}/${alltests.length}`}</p>
                <div className='d-flex justify-content-between'>
                  <button className='btn btn-primary'>{datah?.cont}</button>
                  <button
                    onClick={() => {
                      exitGame();
                    }}
                    className='btn btn-primary'>
                    {datah?.exit}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {tests.length ? (
        <div>
          <div className='overflowh mx-auto position-relative my-4'>
            {testresult?.length&&tests.map((_: any, i: any) => (
              <div
                key={i + "_id"}
                style={{
                  width: "70px",
                  transform: `translateX(${100 * (i-index)}%)`,
                  transition: ".5s",
                }}
                className='position-absolute'>
                <p
                  role='button'
                  key={i + "_id"}
                  onClick={() => {
                    setIndex(i);
                    const audio = new Audio(
                      `${process.env.PUBLIC_URL}/mp3/start.mp3`
                    );
                    audio.play();
                  }}
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                  className={` d-flex justify-content-center align-items-center mx-2 fs-4 text-white rounded cursor position-relative ${testresult[i].bg}`}>
                  {i + 1}
                  {i === index ? (
                    <span
                      style={{
                        clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
                        width: "20px",
                        height: "10px",
                      }}
                      className='position-absolute bottom-0 bg-light'></span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            ))}
          </div>

          <div className='row'>
            <div className='col-md-7 order-md-1'>
              <div>
                {tests[index].media.exist ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/${tests[index].media.name}.png`}
                    className='w-100 mx-auto'
                    alt={`${tests[index].question}`}
                  />
                ) : (
                  <img
                    src={testbg}
                    className='w-100 mx-auto'
                    alt={`${tests[index].question}`}
                  />
                )}
              </div>
            </div>

            <div className='col-md-5 order-md-0'>
              <div className='p-4'>
                <h2>
                  {index + 1}-{datah?.question}:
                </h2>
                <p className='fs-5'>{tests[index].question}</p>
                <hr />
                <div className='position-relative'>
                  { testresult[index].ind !== -1 ? (
                    <div className='position-absolute w-100 h-100 choise-bg'></div>
                  ) : (
                    ""
                  )}

                  {tests[index]?.choises?.map((el: any, i: any) => (
                    <p
                      role='button'
                      onClick={() => {
                        submitChoise(i);
                      }}
                      key={i + "-id"}
                      className={
                        testresult[index].ind === i
                          ? `${testresult[index].bg} text-white p-2 rounded`
                          : `${
                              testresult[index].bg === "bg-danger"
                                ? `${
                                    tests[index]?.choises[i].answer
                                      ? "bg-success"
                                      : "bg-dark"
                                  }`
                                : "bg-dark"
                            } text-white p-2 rounded`
                      }>
                      {el.text}
                    </p>
                  ))}
                </div>
                <div>
                  <button
                    onClick={nextQuestion}
                    className='btn btn-primary px-2 py-1'>
                    <img src={nextimg} alt='keyingi' />
                  </button>
                </div>
              </div>
              <div>
                <button
                  onClick={() => {
                    const newarr = testresult.map((el: any) => el);
                    newarr[index].help = !testresult[index].help;
                    setTestresult(newarr);
                  }}
                  className='btn btn-primary rounded-circle mx-4'
                  style={{ width: "50px", height: "50px" }}>
                  <img src={descript} alt='descip' />
                </button>
                {testresult[index].help ? (
                  <div className='p-3 fs-5'>
                    <h4>{datah?.desc}:</h4>
                    <p>{tests[index].description}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        `${decideLang()}`
      )}
    </div>
  );
};

export default Marafon;
