import React from 'react'

const Comp17 = () => {
  return (
    <div className="fs-5">
      <h2>17. Темир йўл кесишмалари орқали ҳаракатланиш</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          17.1
        </span>
        Транспорт воситаларининг ҳайдовчилари темир йўлларни фақат темир йўл
        кесишмалари орқали поезд (локомотив, дрезина)ларга йўл бериб кесиб
        ўтишлари мумкин.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          17.2
        </span>
        Темир йўл кесишмасига яқинлашиб келаётган ҳайдовчи йўл белгилари ва
        чизиқларига, светофор ишораларига, шлагбаум ҳолатига, кесишма
        навбатчисининг кўрсатмаларига амал қилиши, яқинлашиб келаётган поезд
        (локомотив, дрезина) йўқлигига ишонч ҳосил қилиши шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          17.3
        </span>
        Темир йўл кесишмасига кириш қуйидаги ҳолларда тақиқланади: <br />
        светофор ишорасидан қатъи назар, шлагбаум ёпиқ турган ёки ёпила бошлаган
        ҳолатда; <br />
        шлагбаумнинг бор-йўқлигидан, унинг ҳолатидан қатъи назар, светофорнинг
        тақиқловчи ишорасида; <br />
        кесишма навбатчисининг тақиқловчи ишорасида (навбатчи ҳайдовчиларга олди
        ёки орқаси билан туриб таёқчани, қизил чироқ ёки байроқчани бошидан
        кўтариб турса ёки қўлларини ёнга узаца);
        <br />
        кесишмадан кейин ҳосил бўлган тирбандлик туфайли ҳайдовчи кесишмада
        тўхташга мажбур бўладиган ҳолларда; <br />
        агар кўриниш чегарасидаги масофада кесишмага поезд (локомотив, дрезина)
        яқинлашиб келаётган булса. <br />
        Бундан ташқари: <br />
        кесишма олдида турган транспорт воситаларининг қарама-қарши ҳаракатланиш
        бўлагига чиқиб айланиб ўтиш; <br />
        шлагбаумни ўзбошимчалик билан очиш; <br />
        кесишма орқали занжирли қишлок ҳўжалиги, йўл қурилиши ва бошқа машина ва
        механизмларнинг юриб ўтиши (механик транспорт воситаларига юклаб олиб
        утиш рухсат етилади); <br />
        темир йўл дистанцияси бошлиғининг рухсатисиз тезлиги соатига 8
        километрдан кам бўлган транспорт воситаларини олиб ўтиш тақиқланади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          17.4
        </span>
        Кесишма орқали ҳаракатланиш тақиқланган ҳолларда ҳайдовчи тўхташ чизиғи,
        2.5 йўл белгиси ёки светофор олдида, улар бўлмаганда шлагбаумгача камида
        5 метр, шлагбаум бўлмаганда еса биринчи темир йўл изига камида 10 метр
        қолганда тўхташи керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          17.5
        </span>
        Темир йўл кесишмасида мажбуран тўхтаб қолган транспорт воситасининг
        ҳайдовчиси дарҳол одамларни тушириш ва кесишмани бўшатиш чораларини
        кўриши керак. <br />
        Aгарда кесишмани бўшатишни иложи бўлмаса ҳайдовчи: <br />
        имконияти бўлганда яқинлашиб келаётган поезд машинистига тўхташ
        ишорасини бериш қоидасини тушунтириб, икки кишини темир йўл ёқаси бўйлаб
        иккала томонга 1000 метр масофага (агар бир киши бўлса уни йўл яхши
        кўринмайдиган томонга) юбориши; <br />
        транспорт воситасининг ёнида қолиши ва умумий хатар борлиги ишорасини
        бериши; <br />
        поезд кўринганда тўхҳташ ишорасини бериб, у келаётган томонга югуриши
        керак. <br />
        Еслатма: Тўхташ ишораси қўлни (кундузи ёрқин мато парчаси ёки аниқ
        кўринадиган бирор нарса билан, тунда еса машъала ёки чироқ билан) гир
        айлантириш орқали берилади. <br />
        Умумий хатар ишораси битта узун ва учта қисқа товуш ишораси орқали
        берилади.
      </p>
    </div>
  )
}

export default Comp17
