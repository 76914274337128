import React from 'react'

const Comp3 = () => {
  return (
    <div className='fs-5'>
      <h2>3. Haydovchilarning umumiy majburiyatlari.</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          3.1
        </span>
        Mexanik transport vositasining haydovchisi: haydovchilik guvohnomasi va
        uning talonini yoki transport vositasini vaqtinchalik boshqarish
        huquqini beruvchi talonni, haydovchilik guvohnomasi belgilangan tartibda
        olib qo’yilgan bo’lsa, uning talonini, haydovchilik guvohnomasi va uning
        taloni olib qo’yilgan hollarda vaqtinchalik ruxsatnomani; vaqtinchalik
        boshqarish huquqini beruvchi talon yoki vaqtinchalik ruhsatnoma bilan
        transport vositasini boshqarishda esa haydovchining shaxsini
        tasdiqlovchi hujjatni (fuqarolik pasporti va h.k.); <br />
        transport vositasi ro’yxatdan o’tkazilgani haqidagi hujjatni, transport
        vositasining egasi bo’lmagan hollarda esa umumiy egalik huquqini
        beruvchi, tasarrufga yoki foydalanishga berilganligini tasdiqlovchi
        hujjatni, belgilanga hollarda yo’l varaqasi va tashilayotgan yukning
        hujjatlarini yonida olib yurishi, militsiya xodimlari talab qilganda
        tekshirish uchun berishi shartdir.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          3.2
        </span>
        Tegishli toifadagi haydovchilik guvohnomasiga ega bo’lgan shaxs o’ziga
        qarashli bo’lmagan transport vositasini, uning egasi, yoxud tasarrufga
        yoki foydalanishga berilganligini tasdiqlovchi hujjatlari bo’lgan shaxs
        ishtiroqidagina (ularning roziligi bilan) boshqarishi mumkin.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          3.3
        </span>
        Mototsiklda harakatlanayotganda haydovchi maxsus bosh kiyimini kiyib
        olishi, tugmalashi, yo’lovchilar ham maxsus bosh kiyimini kiygan va u
        tugmalangan holda bo’lishi shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          3.4
        </span>
        Xalqaro yo’llardagi harakatlanishda ishtiroq etayotgan mexanik transport
        vositasi haydovchisining yonida “Yo’l harakati haqidagi” Konventsiya
        talarlariga mos keladigan haydovchilik guvohnomasi va transport
        vositasining ro’yxatdan o’tganligi haqidagi hujjat, transport vositasida
        esa ro’yxatdan o’tgan davlatning raqamli va taniqlik belgisi bo’lishi
        shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          3.5
        </span>
        Transport vositasining haydovchisi quyidagilarga amal qilishga
        majburdir: <br />
        harakatlanishdan oldin transport vositasining texnik sozligini,
        tozaligini va to’liq jihozlanganligini tekshirishi; <br />
        harakatlanish vaqtida transport vositasining texnik holatini soz holda
        bo’lishini ta’minlashi; <br />
        harakatlanishni boshlashdan oldin yo’lovchilarning transport vositasiga
        to’liq chiqib joylarini egallaganligiga, transport vositasining
        eshiklari yopilganligiga, yo’lovchilar tashish uchun maxsus jihozlangan
        yuk avtomobillarida yo’lovchilar tashiyotganda ushbu Qoidalarning 6.2
        bandi talablari bajarilganligiga ishonch hosil qilishi; <br />
        harakatlanish jarayonida piyodalar, ayniqsa bolalar, nogironlar,
        qariyalarga va velosipedchilarga nisbatan ehtiyotkorlik choralarini
        ko’rishi; <br />
        militsiya xodimlarining talabiga ko’ra mastlik holatini aniqlovchi
        maxsus tekshiruvdan o’tishi; yo’l-transport hodisasi tufayli
        shikastlangan transport vositasini shatakka olish (faqat yuk
        avtomobilida), tabiiy ofatlar sodir bo’lgan joylarga etib borish,
        shuningdek, kechiktirib bo’lmaydigan xizmat fazifalarini bajarish
        zarurati tug’ilgan hollarda militsiya xodimlariga, yo’lakay yo’nalishda
        tibbiy yordam ko’rsatishga ketayotgan tibbiyot xodimlariga, zudlik bilan
        tibbiy yordamga muxtoj bo’lgan fuqarolarni davolash muassasasiga olib
        borish uchun tibbiyot va militsiya xodimlariga, militsiyaning shtatdan
        tashqari xodimlariga va jamoatchi avtonazoratchilarga transport
        vositasidan foydalanishga imkon berishi. <br />
        Eslatma: tibbiy hizmatlar uchun transport vositasidan foydalanishga
        imkon berishdan boshqa hollar fuqarolarga tegishli transport
        vositalariga taalluqli emas. <br />
        Transport vositasidan foydalangan shaxs haydovchi talab qilganda yo’l
        varaqasiga yurilgan vaqtni, bosib o’tilgan masofani, o’z ismi sharifi,
        mansabi, ish joyi nomini, yo’l varaqasi bo’lmagan taqdirda tegishli
        namunadagi ma’lumotnoma yozib berishi lozim. <br />
        Transport vositalarini tekshirish yoki undan foydalanish huquqiga ega
        bo’lgan shaxs haydovchining talabiga muvofik o’z xizmat guvohnomasini
        ko’rsatishi shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          3.6
        </span>
        Haydovchilarga quyidagilar taqiqlanadi: <br />
        transport vositasini har qanday mastlik (alkogol’, giyohvand modda va
        boshqalar) holatida, sezgirlik va e’tiborni susaytiradigan
        dori-darmonlar ta’sirida, yo’l harakati havfsizligiga tahdid soladigan
        darajadagi charchoqlik va betoblikda boshqarish; <br />
        har qanday mastlik holatida sezgirlik va e’tiborni susaytiradigan
        dori-darmonlar ta’sirida, yo’l harakati havfsizligiga tahdid soladigan
        darajada charchoqlik va betoblikda bo’lgan, shuningdek, transport
        vositasini boshqarish huquqini beradigan tegishli toifadagi guvohnomasi
        bo’lmagan yoki yo’l varaqasida ismi-sharifi ko’rsatilmagan shaxslarga
        transport vositasini boshqarish uchun topshirish; <br />
        texnik nosoz transport vositasini, moped, motoroller va mototsikllarni
        tovush pasaytirgichsiz boshqarish; <br />
        transport vositasining tormoz tizimi yoki boshqaruvi ishdan chiqsa,
        ulagich moslamasi (avtopoezd tarkibida) nosoz bo’lsa, korong’i vaqtda
        yoki etarlicha ko’rinmaslik sharoitida – faralar va orqa gabarit
        chiroklari yonmasa, yomg’ir, qor yog’ayotgan vaqtda esa haydovchi
        tomonidagi oyna tozalagich ishlamay qolsa, bunday transport vositalarida
        harakatlanish; <br />
        Eslatma: ishchi tormoz tizimi yoki boshqaruvi sekin harakatlanayotgan
        transport vositasini to’htashi yoki manyovr qilishiga imkon bermasa,
        ular ishlamayotgan hisoblanadi. <br />
        transport vositalariga tegishli ruhsatsiz mahsus texnik jihozlar
        (ratsiya, sirena va h.k.)ni o’rnatish; <br />
        transport vositalarining tashkiliy jamlanmasi (shuningdek, piyodalar
        jamlanmalari) harakatiga xalaqit berish va ular orasiga kirish.
      </p>
    </div>
  )
}

export default Comp3
