import React from 'react'

const Comp27 = () => {
  return (
    <div className='fs-5'>
      <h2>
        27. Velosiped va aravalar harakatlanishi, shuningdek, hayvonlarni haydab
        o'tishga doir qo'shimcha talablar
      </h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.1
        </span>
        Yo’lda velosiped, aravani boshqarish, hayvonlarni haydab o’tish yoki
        ularni minib o’tish 14 yoshga to’lgan, mopedni boshqarish esa 16 yoshga
        to’lgan shaxslarga ruxsat etiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.2
        </span>
        Velosipedlar, mopedlar, aravalarni boshqarish, hayvonlarni minib borish
        yoki haydab ketish paytida yo’lning chetki o’ng bo’lagida imkoni boricha
        o’ng tomonidan bir qator bo’lib harakatlanishga yo’l qo’yiladi. Agar
        piyodalarga halaqit bermasa, yo’l yoqasida ham harakatlanishga ruxsat
        etiladi. <br />
        Yo’lning qatnov qismida harakatlanishda velosipedchilar, aravalar
        jamlanmasi, minib olingan hayvonlar guruhlari va haydab ketilayotgan
        hayvonlar to’dasi, yo’l harakati xavfsizligini ta’minlaydigan hamda
        ularni quvib o’tishni osonlashtiradigan guruhlarga bo’linishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.3
        </span>
        Velosiped tovush bergich moslamasi bilan jihozlangan va uning tormozi
        soz bo’lishi zarur. Qoronri vaqtda va etarli ko’rinmaslik sharoitida
        velosipedning old tomoniga oq rangli, orqa tomoniga qizil rangli
        chiroqcha yoki qizil rangli nur qaitargich, yon tomonlariga esa
        zarg’aldok yoki qizil rangli nur qaitargich o’rnatilishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.4
        </span>
        Velosiped va moped haydovchilariga quyidagilar taqiqlanadi: <br />
        rulni bir qo’lda ushlab harakatlanish (manyovrdan oldin ishoralar berish
        bundan mustasno); <br />
        yo’lovchi tashish (ishonchli oyoq qo’gich bilan jihozlangan qo’shimcha
        o’rindiqda 7 yoshgacha bo’lgan yulovchini tashish bundan mustasno);{' '}
        <br />
        gabaritdan bo’yiga va eniga 0,5 metrdan ortiq chiqib turgan yoki
        boshkarishga halaqit beradigan yuklarni tashish; <br />
        velosiped yo’lkasi bo’lgani holda yo’lning qatnov qismida harakatlanish;{' '}
        <br />
        tramvaylar harakatlanadigan yo’llarda va tegishli yo’nalishda bo’lagi
        bittadan ko’p bo’lgan yo’llarda chapga burilish yoki qayrilib olish;{' '}
        <br />
        velosipedlarni va mopedlarni shatakka olish, shuningdek, ulardan
        shatakka olishda foydalanish, (velosiped yoki mopedlarga mo’ljallangan
        tirkamalar bundan mustasno).
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.5
        </span>
        Chorrahadan tashqaridagi harakat tartibga solinmaydigan joyda velosiped
        yo’lkasida borayotgan velosiped va moped haydovchilari bu yo’lka bilan
        kesishgan yo’llarda harakatlanayotgan transport vositalariga yo’l
        berishlari kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.6
        </span>
        Ot-aravalarda orqaga tisarilib ketishga yo’l qo’ymaydigan tirgaklar va
        uni to’xtatib turadigan moslama bo’lishi kerak. <br />
        Qorong’i vaqtda va etarli ko’rinmaslik sharoitida yo’lda
        harakatlanayotgan ot-aravaning old tomoniga oq rangli chiroqcha yoki
        ikkita nur qaytargich, orqa tomoniga esa qizil rangli chiroqcha yoki
        ikkita nur qaitargich o’rnatilishi kerak. <br />
        Yondosh hududdan yoki ikkinchi darajali yo’ldan ko’rinish cheklangan
        joylarda yo’lga chiqayotgan arava haydovchisi hayvonlarning jilovini
        qo’yib yubormasligi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.7
        </span>
        Hayvonlar yo’llarda asosan kunning yorug’ vaqtida haydab boriladi.
        Podachilar hayvonlarni mumkin qadar yo’lning o’ng chetidan olib
        yurishlari kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.8
        </span>
        Temir yo’l kesishmalari orqali o’tishda podachilar sonini hisobga olgan
        holda hayvonlar podasi xavf-hatarsiz haydab o’tilishi ta’minlanadigan
        to’dalarga bo’linishi lozim.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.9
        </span>
        Aravani boshqarayotgan, hayvonlarni minib yoki haqdab ketayotgan
        shaxslarga quyidagilar taqiqlanadi: <br />
        yo’llarda hayvonlarni nazoratsiz qoldirish; <br />
        podani temir yo’ldan va yo’lda maxsus ajratilmagan joylardan,
        shuningdek, ruxsat etilgan joylarda esa korong’i vaqtda va etarli
        ko’rinmaslik sharoitida haydab utish; <br />
        hayvonlarni mukammal qoplamali yo’llardan haydab borish; <br />
        kukalamzorlashtirilgan joylarda (yo’l, yo’l yoqasi, ajratuvchi bo’lak va
        h.k.) hayvonlarni boqish va bog’lab qo’yish.
      </p>
    </div>
  )
}

export default Comp27
