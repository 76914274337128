import React from 'react'

const Comp11 = () => {
  return (
    <div className="fs-5">
      <h2>11. Йўлнинг қатнов қисмида транспорт воситаларининг жойлашиши</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.1
        </span>
        Релссиз транспорт воситалари ҳаракатланадиган бўлаклар сони чизиқлар ёки
        5.8.1, 5.8.2, 5.8.7, 5.8.8 йўл белгилари билан белгиланади. Бундай
        чизиқлар ёки йўл белгилари бўлмаса, ҳайдовчилар йўл бўлакларини ўзлари,
        қатнов қисмининг кенглигини, транспорт воситалари орасидаги зарур ёнлама
        оралиқ масофани ва уларнинг габарит ўлчамларини ҳисобга олган ҳолда
        аниқлайдилар. Бунда ҳаракат икки томонлама бўлган йўл қатнов қисмининг
        чап томондаги ярми қарама-қарши йўналишда ҳаракатланиш учун
        мулжалланган, деб ҳисобланади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.2
        </span>
        Тўрт ва ундан ортиқ бўлакли, ҳаракат икки томонлама бўлган ёлларда
        қарама-қарши йўналишда ҳаракатланиш учун мўлжалланган бўлакларга чиқиш
        тақиқланади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.3
        </span>
        Ҳаракат икки томонлама бўлган учта бўлакли (1.9 чизиғи билан
        белгилангандан ташқари) йўлларда ўрта бўлакка фақат қувиб ўтиш, чапга
        бурилиш, қайрилиб олиш ва айланиб ўтиш учунгина чиқишга рухсат етилади.
        Қарама-қарши йўналишда ҳаракатланиш учун мўлжалланган четки чап бўлакка
        чиқиш тақиқланади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.4
        </span>
        Aҳоли яшайдиган жойлардан ташқарида ҳайдовчилар транспорт воситаларини
        мумкин қадар қатнов қисмининг ўнг четига яқинроққа олиб ҳаракатланишлари
        керак. <br />
        Aҳоли яшайдиган жойларда Қоидаларнинг 11.6, 19.1, 26.2 бандларидаги
        талабларни ҳисобга олган ҳолда ҳайдовчилар ўзларига қулай бўлган
        ҳаракатланиш бўлагидан фойдаланишлари мумкин. Фақат ўнгга ёки чапга
        бурилиш, қайрилиб олиш, қувиб ўтиш, тўхташ олдидан ҳаракатланиш бўлагини
        ўзгартиришга рухсат етилади. <br />
        Бироқ, бир йўналишдаги уч ва ундан ортиқ бўлакли ҳар қандаж йўлда
        ҳаракат серқатновлиги сабабли бошқа бўлаклар банд бўлган ҳолларда,
        шунингдек, қувиб ўтиш, чапга бурилиш ёки қайрилиб олиш, рухсат етилган
        тўла вазни 3,5 тоннадан ортиқ бўлган юк автомобилларига еса фақат чапга
        бурилиш ёки қайрилиб олиш учун четки чап бўлакни егаллашга рухсат
        етилади. <br />
        Ҳаракат бир томонлама бўлган йўлнинг чап бўлагида тўхташ ва тўхтаб туриш
        Қоидаларнинг 14.1 бандига мувофик амалга оширилади.
        <br />
        Бирор бўлакда қўшни бўлакдаги транспорт воситаларига нисбатан катта
        тезликда ҳаракатланиш қувиб ўтиш деб ҳисобланмайди.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.5
        </span>
        Тезлигини соатига 40 километрдан ошириши мумкин бўлмаган ёки техник
        сабабларга кўра тезлигини бундан ошира олмайдиган транспорт воситалари
        қувиб ўтиш, чапга бурилиш ёки қайрилиб олиш, айланиб ўтиш учун қайта
        тизилишдан бошқа ҳолларда фақат четки ўнг бўлакда ҳаракатланишлари
        керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.6
        </span>
        Йўлакай йўналишдаги қатнов қисмининг чап томонида у билан бир сатҳда
        жойлашган трамвай изидан шу йўналишдаги бошқа бўлаклар банд бўлганда
        ҳаракатланишга, айланиб ва қувиб ўтишга, шунингдек, 5.8.1, 5.8.2
        белгилари билан белгиланмаган ҳолларда еса чапга бурилиш ва қайрилиб
        олишга рухсат етилади. Бироқ бу трамвай ҳаракатига ҳалақит бермаслиги
        керак. <br />
        Қарама-қарши йўналишдаги трамвай изидан ҳаракатланиш тақиқланади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.7
        </span>
        Aгар қатнов қисми йўл чизиқлари билан бўлакларга ажратилган бўлса,
        транспорт воситаларининг ҳаракатланиши қатъий равишда белгиланган
        булакларда амалга оширилиши керак. Фақат қайта тизилишда узуқ-узуқ
        чизиқларни босиб ўтишга рухсат етилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.8
        </span>
        Реверсив ҳаракатланишли йўлга бурилишда транспорт воситалари қатнов
        қисмлари кесишмасидан чиқишда четки ўнг бўлакни егаллаши керак.
        Ҳайдовчига шу йўналишдаги бошқа бўлакларда ҳам ҳаракатланишга рухсат
        берилганлигига ишонч ҳосил қилгандан кейингина қайта тизилишга рухсат
        етилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.9
        </span>
        Қоидаларнинг 14.1, 27.2 бандларида кўзда тутилгандан бошқа ҳолларда
        транспорт воситаларининг йўл ёқаси, тротуар ва пиёдалар йўлкаларидан
        ҳаракатланиши тақиқланади. Бу жойларда йўлдан фойдаланиш ва коммунал
        хизмат машиналарининг ҳаракатланишига, шунингдек, бошқа имконият
        бўлмаган ҳолларда бевосита йўл ёқасида, тротуар ёки пиёдалар йўлкалари
        олдида жойлашган савдо шохобчалари, корхоналар ва бошқа иншоотларга юк
        олиб кириш учун транспорт воситаларига яқин йўлдан киришга рухсат
        етилади. Бунда йўл ҳаракати ҳавфсизлиги тўла таъминланган бўлиши шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.10
        </span>
        Ҳайдовчи ўзидан олдинда кетаётган транспорт воситаси кескин тўхтаганда у
        билан тўқнашиб кетмаслик кафолатини берадиган оралиқ масофани,
        шунингдек, йўл ҳаракати ҳавфсизлигини таъминлайдиган ёнлама оралиқ
        масофани сақлаши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.11
        </span>
        Aҳоли яшайдиган жойлардан ташқаридаги йўлларда тезлигини соатига 50
        километрдан ошириши мумкин бўлмаган, шунингдек, рухсат етилган тўла
        вазни 12 тоннадан ортиқ бўлган транспорт воситаларининг ҳайдовчилари ўзи
        ва олдида кетаётган транспорт воситаси оралиғида, уларни қувиб ўтиши
        мумкин бўлган транспорт воситалари бемалол йўлнинг ўнг томонига қайта
        тизилиши учун имкон берадиган масофани сақлашлари керак. <br />
        Қувиб ўтишга таёрланаётган, шунингдек, транспорт воситаларининг ташкилий
        жамланмаси таркибида, серқатнов бўлган йўлларда ҳаракатланаётган
        транспорт воситаларининг ҳайдовчиларига бу талаб тааллуқли емас.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          11.12
        </span>
        Aгар бирон-бир тўсиқ сабабли қарама-қарши йўналишларда ҳаракатланиш
        қийин бўлса, тўсиқ ўз томонида бўлган ҳайдовчи йўл бериши керак.
      </p>
    </div>
  )
}

export default Comp11
