/** @format */
import React, { useEffect, useState } from 'react'
import sharesvg from './../images/svg/share.svg'
import axios from 'axios'
import './home.css'
import { BannerAd } from '@admob-plus/capacitor'
import { Share } from '@capacitor/share'
import {
  IonButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonModal,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import { arrowBackOutline, settingsOutline } from 'ionicons/icons'
import { useHistory } from 'react-router'
import UzYhq from '../YHQ/UzYHQ/uzyhq'
import YolBelgilar from '../YHQ/YolBelgilari/yolBelgilar'
import YolChiziqlar from '../YHQ/YolChiziqlari/yolChiziqlar'
import Statistic from '../Autotest/Statistic/statistic'
import add from './../images/profile.png'
import add2 from './../images/svg/add.svg'
import { setInterval } from 'timers'
import ImageUploading from 'react-images-uploading'
import profileImg from './../images/profile.png'

const Home = () => {
  const checkData = () => {
    let temp = localStorage.getItem('imgProfile')
    let temp2 = localStorage.getItem('name')
    if (temp && temp2) {
      return false
    }
    return true
  }
  const [data, setData] = useState({}) as any
  const [d, setD] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [showModal3, setShowModal3] = useState(false)
  const [option, setOption] = useState(1)
  const [showModal4, setShowModal4] = useState(checkData())
  const [text, setText] = useState<string>('')
  const [images, setImages] = React.useState([]) as any
  const [alert, setAlert] = useState(false)
  const maxNumber = 69
  const history = useHistory()
  const lang = localStorage.getItem('lang')

  const onChange = (imageList: any, addUpdateIndex: any) => {
    setImages(imageList)
  }
  const saveData = () => {
    if (images[0]?.data_url && text) {
      localStorage.setItem('imgProfile', images[0].data_url)
      localStorage.setItem('name', text)
      setShowModal4(false)
    } else {
      setAlert(true)
      setInterval(() => {
        setAlert(false)
      }, 3000)
    }
  }

  useEffect(() => {
    let banner: any
    ;(async () => {
      banner = new BannerAd({
        adUnitId: 'ca-app-pub-2110563711012419/7321534432',
      })
      await banner.show()
      //  ca-app-pub-3940256099942544/6300978111
    })()
    return () => {
      ;(async () => {
        await banner.hide()
      })()
    }
  }, [])
  const changeValue = (value: any) => {
    localStorage.setItem('lang', value)
    setD(value)
  }
  useEffect(() => {
    const getTests = async () => {
      const lang = localStorage.getItem('lang')
      let temp = ''
      if (lang === 'uzlotin' || lang === 'uzkiril' || lang === 'rus') {
        if (lang === 'uzkiril') {
          temp = 'kiril'
        }
        if (lang === 'uzlotin') {
          temp = 'lotin'
        }
        if (lang === 'rus') {
          temp = 'ru'
        }
      } else {
        temp = 'lotin'
        localStorage.setItem('lang', temp)
      }
      const result = await axios.get(
        `${process.env.PUBLIC_URL}/data/${temp}.json`,
      )
      setData(result.data)
    }
    getTests()
  }, [d])
  const socialShare = async () => {
    await Share.share({
      text: 'Avto test',
      dialogTitle: 'Share with friends',
      url: 'https://play.google.com/store/apps/details?id=io.ionic.avtotest',
    })
  }
  const calcPerSavol = () => {
    const mar = localStorage.getItem('marafon')
    if (mar) {
      const temp = JSON.parse(mar)
      let count = 0
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].bg === 'bg-success') {
          count++
        }
      }
      return count
    } else {
      return 0
    }
  }
  const calcShablon = () => {
    const shab = localStorage.getItem('shablon')
    let mainIndex = 0
    if (shab) {
      const temp = JSON.parse(shab)
      let innerIndex = 0
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].value) {
          for (let j = 0; j < temp[i].arr.length; j++) {
            if (temp[i].arr[j].bg === 'bg-success') {
              innerIndex++
            }
          }
          if (innerIndex === 10) {
            mainIndex++
          }
          innerIndex = 0
        }
      }
    }
    return mainIndex
  }
  useEffect(() => {
    let canvas: any = document.getElementById('canvas')
    if (canvas) {
      let ctx = canvas.getContext('2d')
      let cX = Math.floor(canvas.width / 2)
      let cY = Math.floor(canvas.height / 2)
      let radius = Math.min(cX, cY) * 0.9
      let data2: number[] = []
      const s = calcPerSavol();
      data2.push(100 - s / 7)
      data2.push(s / 7)
      let colors: any[] = []

      colors.push('rgba(13, 3, 64, 0.78)')
      colors.push('rgba(230, 115, 13, 0.78)')
      let totalArc = 0
      const drawWedge2 = (percent: number, color: any) => {
        let arcRadians = ((percent / 100) * 360 * Math.PI) / 180
        ctx.save()
        ctx.beginPath()
        ctx.moveTo(cX, cY)
        ctx.arc(cX, cY, radius, totalArc, totalArc + arcRadians, false)
        ctx.closePath()
        ctx.fillStyle = color
        ctx.fill()
        ctx.restore()
        totalArc += arcRadians
      }
      const drawDonut = () => {
        for (var i = 0; i < data2.length; i++) {
          drawWedge2(data2[i], colors[i])
        }
        ctx.beginPath()
        ctx.moveTo(cX, cY)
        ctx.fillStyle = gradient
        ctx.arc(cX, cY, radius * 0.8, 0, 2 * Math.PI, false)
        ctx.fill()
      }
      let gradient = ctx.createLinearGradient(0, 0, canvas.width, 0)
      // gradient.addColorStop(0, "#008B8B");
      gradient.addColorStop(0.75, 'rgba(121, 117, 121, 0.0)')
      ctx.fillStyle = gradient
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      drawDonut()
    }
  }, [])
  useEffect(() => {
    let canvas: any = document.getElementById('canvas2')
    if (canvas) {
      let ctx = canvas.getContext('2d')
      // define the donut
      let cX = Math.floor(canvas.width / 2)
      let cY = Math.floor(canvas.height / 2)
      let radius = Math.min(cX, cY) * 0.9
      // the datapoints
      let data2: number[] = []
      const shab = calcShablon()
      data2.push(100 - (shab * 10) / 7)
      data2.push((shab * 10) / 7)
      // colors to use for each datapoint
      let colors: any[] = []
      // colors.push("rgba(13, 3, 64, 0.78)");
      colors.push('rgba(13, 3, 64, 0.78)')
      colors.push('rgba(230, 115, 13, 0.78)')
      // track the accumulated arcs drawn so far
      let totalArc = 0
      // draw a wedge
      const drawWedge2 = (percent: number, color: any) => {
        let arcRadians = ((percent / 100) * 360 * Math.PI) / 180
        ctx.save()
        ctx.beginPath()
        ctx.moveTo(cX, cY)
        ctx.arc(cX, cY, radius, totalArc, totalArc + arcRadians, false)
        ctx.closePath()
        ctx.fillStyle = color
        ctx.fill()
        ctx.restore()
        totalArc += arcRadians
      }
      // draw the donut one wedge at a time
      const drawDonut = () => {
        for (var i = 0; i < data2.length; i++) {
          drawWedge2(data2[i], colors[i])
        }
        // cut out an inner-circle == donut
        ctx.beginPath()
        ctx.moveTo(cX, cY)
        ctx.fillStyle = gradient
        ctx.arc(cX, cY, radius * 0.8, 0, 2 * Math.PI, false)
        ctx.fill()
      }
      // draw the background gradient
      let gradient = ctx.createLinearGradient(0, 0, canvas.width, 0)
      gradient.addColorStop(0, 'rgba(121, 117, 121, 0.0)')
      // gradient.addColorStop(0.85, "#008B8B");
      ctx.fillStyle = gradient
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      // draw the donut
      drawDonut()
    }
  }, [])
  const setImg = () => {
    let temp = localStorage.getItem('imgProfile')
    if (temp) {
      return temp
    }
    return add
  }
  const setUserName = () => {
    let temp = localStorage.getItem('name')
    if (temp) {
      return temp
    }
    return data?.user
  }
  return (
    <div className="home-bg">
      <div className="position-absolute" style={{ top: '10px', left: '10px' }}>
        <img
          src={setImg()}
          alt="set img"
          width={60}
          height={60}
          className="rounded-circle"
        />
        <p>{setUserName()}</p>
      </div>
      <div>
        {alert ? (
          <div className="alert alert-danger w-50 custom-alert" role="alert">
            {data?.warning}
          </div>
        ) : (
          ''
        )}
        {showModal4 ? (
          <div>
            <div
              onClick={() => {
                setShowModal4(false)
              }}
              className="overlay-custom"
            ></div>
            <div className="custom-modal">
              <h3 className="text-white text-center mt-5">
                {checkData() ? data?.register :data?.profile}
              </h3>
              <input
                onChange={(e: any) => {
                  setText(e.target.value)
                }}
                className="form-control form-control-lg w-75 mx-auto mt-5"
                type="text"
                placeholder={data?.entername}
                aria-label=".form-control-lg example"
                maxLength={25}
              />

              <h4 className="text-white text-center mt-4">{ data?.uploadphoto}</h4>
              <ImageUploading
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                  onImageRemove,
                }) => (
                  <div className="upload__image-wrapper position-relative">
                    <img
                      onClick={onImageUpload}
                      src={add2}
                      alt="add image"
                      className="d-block position-btn p-2"
                    />
                    <img
                      src={profileImg}
                      alt="profile images"
                      width={130}
                      height={130}
                      className="rounded-circle d-block mx-auto"
                    />
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img
                          className="rounded-circle profile-img"
                          src={image.data_url}
                          alt="profile image"
                          width="130"
                          height="130"
                        />
                        {/* <div className="image-item__btn-wrapper">
                        <button onClick={() => onImageUpdate(index)}>
                          Update
                        </button>
                        <button onClick={() => onImageRemove(index)}>
                          Remove
                        </button>
                      </div> */}
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
              <div className="mt-5 w-50 d-flex justify-content-around mx-auto">
                <button
                  onClick={() => {
                    saveData()
                  }}
                  className="btn btn-light"
                >
                  {data?.save}
                </button>
                <button
                  onClick={() => {
                    setShowModal4(false)
                  }}
                  className="btn btn-light"
                >
                  {data?.later}
                </button>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div
        className="modal fade"
        id="statisticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex">
                <img
                  src={setImg()}
                  alt=""
                  className="d-block rounded-circle"
                  width="60"
                  height={60}
                />
                <p className="fs-4 px-2">
                  {data?.regardless} {setUserName()}, {data?.testresult}
                </p>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Statistic data={data} />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {data?.close}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="position-absolute end-0 my-2 mx-2">
        <button
          className="btn-set btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <IonIcon size="large" icon={settingsOutline}></IonIcon>
        </button>
      </div>
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: '220px' }}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">{ data?.settings}</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div>
            <button
              style={{ backgroundColor: 'rgba(13, 3, 64, 0.78)' }}
              data-bs-dismiss="offcanvas"
              onClick={() => {
                setShowModal4(true)
              }}
              className="btn text-white w-100"
            >
              {data?.updatepro}
            </button>
          </div>
          {/* <div className="mt-5">
            <button
              style={{ backgroundColor: 'rgba(13, 3, 64, 0.78)' }}
              onClick={socialShare}
              className="btn text-white w-100"
            >
              Bo'lishish
              <img className="mx-2" src={sharesvg} alt="sharing" />
            </button>
          </div> */}
          <div>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#statisticBackdrop"
              style={{ backgroundColor: 'rgba(13, 3, 64, 0.78)' }}
              className="btn d-block text-white mt-2 w-100"
            >
              {data?.result}
            </button>
          </div>
          <a
            style={{ backgroundColor: 'rgba(13, 3, 64, 0.78)' }}
            className="btn d-block text-white mt-2"
            href="https://uzinfobiz.ru/"
            target="_blank"
            rel="noreferrer"
          >
            {data?.tosite}
          </a>
          <div className="mt-3">
            <IonItem>
              <IonSelect
                style={{ backgroundColor: 'rgba(13, 3, 64, 0.78)' }}
                className=" p-2 text-white w-100 rounded"
                placeholder={
                  lang === 'uzlotin'
                    ? 'Uz (lotin)'
                    : lang === 'uzkiril'
                    ? 'Uz (kiril)'
                    : lang === 'rus'
                    ? 'Rus'
                    : 'Uz (lotin)'
                }
                onIonChange={(e: any) => {
                  changeValue(e.target.value)
                }}
              >
                <IonSelectOption value="uzlotin">Uz (lotin)</IonSelectOption>
                <IonSelectOption value="uzkiril">Uz (kiril)</IonSelectOption>
                <IonSelectOption value="rus">Rus</IonSelectOption>
              </IonSelect>
            </IonItem>
          </div>
        </div>
      </div>
      <div
        style={{ marginTop: '60px', display: 'flex', flexDirection: 'column' }}
      >
        <div className="position-relative">
          <canvas
            style={{ marginLeft: '50%', transform: 'translateX(-50%)' }}
            id="canvas"
            width="100"
            height="100"
          />
          <p
            className="position-absolute text-white fs-5"
            style={{
              top: '29%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            {calcPerSavol()}/700
          </p>
          <p
            style={{ color: '#00004d' }}
            className=" fs-3 p-1 text-center rounded"
          >
            {data !== {} ? data?.quests : ''}
          </p>
        </div>
        <div className="position-relative">
          <canvas
            style={{ marginLeft: '50%', transform: 'translateX(-50%)' }}
            id="canvas2"
            width="100"
            height="100"
          />
          <p
            className="position-absolute text-white fs-5"
            style={{
              top: '29%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            {calcShablon()}/70
          </p>
          <p
            style={{ color: '#00004d' }}
            className=" fs-3 text-center p-1  rounded"
          >
            {data !== {} ? data?.shablon : ''}
          </p>
        </div>
      </div>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                YHQ
              </h5>
              <button
                onClick={() => {
                  setShowModal2(true)
                }}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {option === 1 ? (
                <UzYhq lang={lang }/>
              ) : option === 2 ? (
                <YolBelgilar  lang={lang }/>
              ) : (
                    <YolChiziqlar data={data}/>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {data?.close}
              </button>
            </div>
          </div>
        </div>
      </div>
      <IonModal
        onDidDismiss={() => setShowModal2(false)}
        isOpen={showModal2}
        cssClass="my-custom-class"
      >
        <IonHeader style={{ backgroundColor: '#00004d' }}>
          <button className="btn btn-back" onClick={() => setShowModal2(false)}>
            <IonIcon
              style={{ fontSize: '30px', color: 'white' }}
              icon={arrowBackOutline}
            ></IonIcon>
          </button>{' '}
        </IonHeader>
        <div className="d-flex flex-column p-2" style={{ marginTop: '50px' }}>
          <button
            onClick={() => {
              setOption(1)
              setShowModal2(false)
            }}
            style={{ backgroundColor: 'rgba(13, 3, 64, 0.78)' }}
            type="button"
            className="btn text-white fs-4 d-block"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            {data?.yhq}
          </button>
          <button
            onClick={() => {
              setOption(2)
              setShowModal2(false)
            }}
            style={{ backgroundColor: 'rgba(13, 3, 64, 0.78)' }}
            type="button"
            className="btn text-white fs-4 d-block my-2"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            {data?.yb}
          </button>
          <button
            onClick={() => {
              setOption(3)
              setShowModal2(false)
            }}
            style={{ backgroundColor: 'rgba(13, 3, 64, 0.78)' }}
            type="button"
            className="btn text-white fs-4 d-block"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            {data?.ych}
          </button>
        </div>
      </IonModal>
      <IonModal isOpen={showModal3} cssClass="my-custom-class">
        <IonHeader className="btn-back">
          <button className="btn" onClick={() => setShowModal3(false)}>
            <IonIcon
              style={{ fontSize: '30px', color: 'white' }}
              icon={arrowBackOutline}
            ></IonIcon>
          </button>
        </IonHeader>
        {option === 1 ? (
          <UzYhq />
        ) : option === 2 ? (
          <YolBelgilar />
        ) : (
          <YolChiziqlar />
        )}
      </IonModal>
      <IonModal
        onDidDismiss={() => setShowModal(false)}
        isOpen={showModal}
        cssClass="my-custom-class"
      >
        <IonHeader>
          <IonButton onClick={() => setShowModal(false)}>
            <IonIcon icon={arrowBackOutline}></IonIcon>
          </IonButton>{' '}
        </IonHeader>
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          <h2 className="mt-4">{data !== {} ? data.allquest : ''}</h2>
          <IonButton
            onClick={() => {
              setShowModal(false)
              history.push('/marafon')
            }}
            className="my-4"
          >
            {data !== {} ? data.startmarafon : ''}
          </IonButton>
          <p>{data !== {} ? data.gamedesc : ''}</p>
        </div>
      </IonModal>
      {data !== {} ? (
        <div className="d-sm-flex justify-content-center mt-2">
          <div
            style={{ backgroundColor: 'rgba(13, 3, 64, 0.78)' }}
            onClick={() => {
              setShowModal2(true)
            }}
            className=" rounded m-1 p-2 text-white d-flex flex-column"
          >
            <h4 className="mx-auto">{data.yhq}</h4>
          </div>
          <div
            style={{ backgroundColor: 'rgba(13, 3, 64, 0.78)' }}
            onClick={() => {
              history.push('/learntests')
            }}
            className=" rounded m-1 p-2 text-white d-flex flex-column"
          >
            <h4 className="mx-auto">{data.themes}</h4>
          </div>
          <div
            style={{ backgroundColor: 'rgba(13, 3, 64, 0.78)' }}
            onClick={() => setShowModal(true)}
            className=" rounded m-1 p-2 text-white d-flex flex-column"
          >
            <h4 className="mx-auto">{data.marafon}</h4>
          </div>
          <div
            style={{ backgroundColor: 'rgba(13, 3, 64, 0.78)' }}
            onClick={() => {
              history.push('/starttest')
            }}
            className=" rounded m-1 p-2 text-white d-flex flex-column"
          >
            <h4 className="mx-auto">{data.examstart}</h4>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Home
