import React from 'react'

const Comp16 = () => {
  return (
    <div className='fs-5'>
      <h2>
        16. Piyodalarning o'tish joylari va belgilangan yo'nalishdagi transport
        vositalarining bekatlari
      </h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.1
        </span>
        Transport vositasining haydovchisi tartibga solinmagan piyodalar o’tish
        joyida qatnov qismidan o’tayotgan piyodalarga yo’l berishi shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.2
        </span>
        Agar tartibga solinmagan piyodalar o’tish joyi oldida transport vositasi
        harakatini sekinlashtirsa yoki to’xtatsa, qo’shni bo’laklarda
        harakatlanayotgan boshqa haydovchilar bu transport vositasi oldida
        piyodalar yo’qligiga ishonch hosil qilganlaridan so’nggina
        harakatlanishni davom ettirishlari mumkin.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.3
        </span>
        Tartibga solingan piyodalar o’tish joylarida haydovchilar svetoforning
        ruxsat etuvchi ishorasi yongan taqdirda ham piyodalarga qatnov qismini
        shu yo’nalishda kesib o’tishni tugallashga imkon berishlari kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.4
        </span>
        Agarda, piyodalar o’tish joylaridan keyin paydo bo’lgan tirbandlik
        tufayli haydovchi piyodalar o’tish joyida to’xtashga majbur bo’ladigan
        bo’lsa, bunday piyodalar o’tish joylariga kirish taqiqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.5
        </span>
        Barcha hollarda, shu jumladan, piyodalar o’tish joylaridan tashqarida
        ham haydovchi oq hassa bilan ishora berayotgan ko’zi ojiz piyodalarni
        o’tkazib yuborishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.6
        </span>
        Agar yo’lovchilarni tushirish yoki chiqarish qatnov qismida yoki unda
        joylashgan maydonchada amalga oshirilayotgan bo’lsa, haydovchi bekatda
        to’xtagan belgilangan yo’nalishdagi transport vositasi eshiklari tomon
        borayotgan yoki undan kelayotgan piyodalarga yo’l berishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          16.7
        </span>
        “Bolalar guruhini tashish” taniqliq belgisi o’rnatilgan transport
        vositasi to’xtab turganda, unga yaqinlashayotgan haydovchi harakat
        tezligini kamaytirishi, zarur bo’lsa to’xtashi va bolalar guruhini
        o’tkazib yuborishi kerak.
      </p>
    </div>
  )
}

export default Comp16
