import { IonIcon } from '@ionic/react'
import { arrowBackOutline, arrowForwardOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import './uzyhq.css'

import { InterstitialAd } from '@admob-plus/capacitor'
import RenderComp from './renderComp';
import RenderComp2 from './renderComp2'

const UzYhq = (props: any) => {
  const titles = [
    'Umumiy qoidalar.',
    "Qoidalarda qo'llanilgan asosiy tushuncha va atamalar.",
    'Haydovchilarning umumiy majburiyatlari.',
    "Yo'l-transport hodisasi sodir etgan haydovchilarning vazifasi",
    'Piyodalarning vazifasi',
    "Yo'lovchilarning vazifasi",
    'Maxsus transport vositalarining imtiyozlari',
    'Svetofor va tartibga soluvchining ishoralari',
    "Ogohlanturuvchi va falokat ishoralari, falokat sababli to'xtash belgisining qo'llanilishi",
    "Harakatlanishni boshlash, yo'nalishlarni o'zgartirish",
    "Yo'lning qatnov qismida transport vositalarining joylashishi",
    'Harakatlanish tezligi',
    "Quvib o'tish",
    "To'xtash va to'xtab turish",
    'Chorrahada harakatlanish',
    "Piyodalarning o'tish joylari va belgilangan yo'nalishdagi transport vositalarining bekatlari",
    "Temir yo'l kesishmalari orqali harakatlanish",
    'Avtomagistrallarda harakatlanish',
    'Turar-joy dahalarida harakatlanish',
    'Tik nishablik va balandliklarda harakatlanish',
    "Belgilangan yo'nalishdagi transport vositalarining imtiyozlari",
    'Tashqi yoritish asboblaridan foydalanish',
    'Mexanik transport vositalarini shatakka olish',
    "Transport vositalarini boshqarishni o'rgatish",
    'Odam tashish',
    'Yuk tashish',
    "Velosiped va aravalar harakatlanishi, shuningdek, hayvonlarni haydab o'tishga doir qo'shimcha talablar",
    "Mansabdor shaxslar va fuqarolarning yo'l harakati xavfsizligini ta'minlash, transport vositalarini yo'lga chiqarish, ularga raqam va taniqlik belgilarinin o'rnatish bo'yicha vazifalari",
  ]
  const titles2 = [
    'Умумий қоидалар.',
    'Қоидаларда қўлланилган асосий тушунча ва атамалар.',
    'Ҳайдовчиларнинг умумий мажбуриятлари.',
    'Йўл-транспорт ҳодисаси содир етган ҳайдовчиларнинг вазифаси',
    'Пиёдаларнинг вазифасиъ',
    'Йўловчиларнинг вазифаси',
    'Махсус транспорт воситаларининг имтиёзлариъ',
    'Светофор ва тартибга солувчининг ишоралариъ',
    'Огоҳлантурувчи ва фалокат ишоралари, фалокат сабабли тўхташ белгисининг қўлланилиши',
    'Ҳаракатланишни бошлаш, йўналишларни ўзгартириш',
    'Йўлнинг қатнов қисмида транспорт воситаларининг жойлашиши',
    'Ҳаракатланиш тезлигиъ',
    'Қувиб ўтиш',
    'Тўхташ ва тўхтаб туриш',
    'Чорраҳада ҳаракатланишъ',
    'Пиёдаларнинг ўтиш жойлари ва белгиланган йўналишдаги транспорт воситаларининг бекатлари',
    'Темир йўл кесишмалари орқали ҳаракатланиш',
    'Aвтомагистралларда ҳаракатланишъ',
    'Турар-жой даҳаларида ҳаракатланишъ',
    'Тик нишаблик ва баландликларда ҳаракатланишъ',
    'Белгиланган йўналишдаги транспорт воситаларининг имтиёзлари',
    'Ташқи ёритиш асбобларидан фойдаланишъ',
    'Механик транспорт воситаларини шатакка олишъ',
    'Транспорт воситаларини бошқаришни ўргатиш',
    'Одам ташишъ',
    'Юк ташишъ',
    'Велосипед ва аравалар ҳаракатланиши, шунингдек, ҳайвонларни ҳайдаб ўтишга доир қўшимча талаблар',
    'Мансабдор шахслар ва фуқароларнинг йўл ҳаракати хавфсизлигини таъминлаш, транспорт воситаларини йўлга чиқариш, уларга рақам ва таниқлик белгиларинин ўрнатиш бўйича вазифалари',
  ]
  const [id, setId] = useState(1)
  const [isCard, setCard] = useState(false)

  useEffect(() => {
    const startAds = async () => {
      const interstitial = new InterstitialAd({
        adUnitId: 'ca-app-pub-2110563711012419/1927421731',
      })
      await interstitial.load()
      await interstitial.show()
    }
    const interval = setInterval(startAds, 420000)
    return () => {
      ;(async () => {
        clearInterval(interval)
      })()
    }
  }, [])

  return (
    <div>
      {isCard ? (
        <div className="card  card-custom">
          <div className="card-header">
            <div className="d-flex justify-content-between btn-back rounded">
              <button
                onClick={() => {
                  setCard(false)
                }}
                className="btn "
              >
                <IonIcon
                  style={{ fontSize: '30px', color: 'white' }}
                  icon={arrowBackOutline}
                ></IonIcon>
              </button>
              <div>
                <p className="p-2 fs-3 text-white">{id}/28</p>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-between my-3">
              <button
                className="btn"
                onClick={() => {
                  setId((prev) => {
                    if (id === 1) {
                      return 1
                    } else {
                      return prev - 1
                    }
                  })
                }}
              >
                <IonIcon
                  style={{ fontSize: '30px' }}
                  icon={arrowBackOutline}
                ></IonIcon>
              </button>
              <button
                className="btn"
                onClick={() => {
                  setId((prev) => {
                    if (id === 28) {
                      return 28
                    } else {
                      return prev + 1
                    }
                  })
                }}
              >
                <IonIcon
                  style={{ fontSize: '30px' }}
                  icon={arrowForwardOutline}
                ></IonIcon>
              </button>
            </div>
            <div
              className="p-2 rounded"
              style={{
                maxHeight: '60vh',
                overflow: 'auto',
                width: '96%',
                marginLeft: '2%',
                border: '2px solid #00004d',
              }}
            >
              {props.lang === 'uzlotin'
        ? <RenderComp id={id} />
        : props.lang === 'uzkiril'
        ? <RenderComp2 id={id} />
        : props.lang === 'rus'
        ? <RenderComp id={id} />
        : <RenderComp id={id} />}
              
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {(props.lang === 'uzlotin'
        ? titles
        : props.lang === 'uzkiril'
        ? titles2
        : props.lang === 'rus'
        ? titles
        : titles
      ).map((title: any, i: any) => (
        <div
          onClick={() => {
            setId(i + 1)
            setCard(true)
          }}
          className="rounded bg-light p-1 m-1 text-center text-dark border border-secondary"
          key={title}
        >
          <p className="fs-4">
            {i + 1}. {title}
          </p>
        </div>
      ))}
      <div className="my-5 w-100"></div>
    </div>
  )
}

export default UzYhq

