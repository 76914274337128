import React from 'react'

const Comp7 = () => {
  const roadsigns = [
    {
      title: "7.1.1. Manzilgacha bo'lgan masofa",
      description:
        "Belgilangan yo'lning xavfli joyigacha bo'lgan masofani, yo'l harakatiga tegishli cheklovlar kiritilgan joyni yoki harakat yo'nalishi bo'yicha oldindagi muayan joyni ko'rsatadi.",
      img: '1',
    },
    {
      title: "7.1.2. Manzilgacha bo'lgan masofa",
      description:
        "Chorraha oldida 2.5 belgisi o'rnatilgan bo'lsa, 2.4 belgisidan chorrahagacha bo'lgan masofani ko'rsatadi.",
      img: '2',
    },
    {
      title: "7.1.3, 7.1.4. Manzilgacha bo'lgan masofa",
      description: "Yo'ldan manzilgacha bo'lgan masofa.",
      img: '3',
    },
    {
      title: "7.2.1 Ta'sir oralig'i",
      description:
        "Ogohlantiruvchi, buyuruvchi, axborot-ishora belgilarining ta'sir oralig'ini ko'rsatadi.",
      img: '4',
    },
    {
      title: "7.2.2.-7.2.6 Ta'sir oralig'i",
      description:
        "Bu belgilar faqat taqiqlovchi 3.27–3.30 belgilari bilan qo'llaniladi.",
      img: '5',
    },
    {
      title: "7.3.1. -7.3.3. Ta'sir yo'nalishlari",
      description:
        "Chorraha oldida o'rnatilgan belgilarning ta'sir yo'nalishini ko'rsatadi.",
      img: '6',
    },
    {
      title: '7.4.1 – 7.4.7. Transport vositasining turi',
      description:
        "Belgining ta'siri joriy etilgan transport vositalari turini ko'rsatadi. 7.4.1 qo'shimcha belgisining ta'siri yuk tashuvchi, shu jumladan, tirkamali, to'la vazni 3.5 tonnadan ortiq bo'lgan transport vositalariga, 7.4.3 qoshimcha belgisi esa engil avtomobillarga, shuningdek, to'la vazni 3.5 tonnagacha bo'lgan yuk tashuvchi transport vositalariga tatbiq etiladi.",
      img: '7',
    },
    {
      title: '7.5.1. Shanba, yakshanba va bayram kunlari',
      description: '',
      img: '8',
    },
    {
      title: '7.5.2. Ish kunlari',
      description: '',
      img: '9',
    },
    {
      title: '7.5.3. Hafta kunlari',
      description: "Belgiga haftaning qaysi kunlari amal qilishi ko'rsatilgan.",
      img: '10',
    },
    {
      title: '7.5.4. Amal qilish vaqti',
      description: "Belgiga kunning qaysi vaqtida amal qilishni ko'rsatadi.",
      img: '11',
    },
    {
      title: '7.5.5 – 7.5.7. Amal qilish vaqti',
      description:
        "Belgiga haftaning qaysi kuni va qaysi soatlarida amal qilishni ko'rsatadi.",
      img: '12',
    },
    {
      title:
        "7.6.1 – 7.6.9. Transport vositasini to'xtab turish joyiga qo'yish usuli",
      description:
        "Barcha turdagi transport vositalarini to'xtab turish uchun yo'lning qatnov qismida, piyodalar yo'lkasi yoniga qo'yish usuli. Piyodalar yo'lkasi yonidagi to'xtab turish joyiga engil avtomobillar va mototsikllarni qanday qo'yish usuli ko'rsatilgan.",
      img: '13',
    },
    {
      title: "7.7. Yurgizgichni ishlatmasdan to'xtab turish joyi",
      description:
        "5.15 belgisi bilan birgalikda qo'llanganda, to'xtab turish joyida faqat yurgizgichi ishlamayotgan transport vositalariga to'xtab turishiga ruxsat etilganini bildiradi.",
      img: '14',
    },
    {
      title: "7.8. Pullik xizmat ko'rsatish joyi",
      description: "Naqd pulga xizmat ko'rsatilishini bildiradi.",
      img: '15',
    },
    {
      title: "7.9. To'xtab turish muddati cheklangan",
      description:
        "5.15 belgisi o'rnatilgan to'xtab turish joyida transport vositalari to'xtab turishining eng ko'p muddatini ko'rsatadi.",
      img: '16',
    },
    {
      title: "7.10. Avtomobillarni ko'rikdan o'tkazish joyi",
      description:
        "5.15 yoki 6.11 belgisi bilan ko'rsatilgan maydonlarda estakada yoki ko'zdan kechirish chuqurchalari borligini bildiradi.",
      img: '17',
    },
    {
      title: "7.11. To'la vazni cheklangan",
      description:
        "To'la vazni qo'shimcha belgida ko'rsatilgan miqdordan ortiq bo'lgan transport vositalariga taaluqli.",
      img: '18',
    },
    {
      title: "7.12. Xavfli yo'l yoqasi",
      description:
        "Yo'l ishlari bajarilayotgani sababli yo'l yoqasiga chiqish xavfli ekanligi haqida ogoxlantiradi, 1.23 belgisi bilan qo'llaniladi",
      img: '19',
    },
    {
      title: "7.13. Asosiy yo'lning yo'nalishi",
      description: "Chorrahada asosiy yo'lning yo'nalishini ko'rsatadi.",
      img: '20',
    },
    {
      title: "7.14. Harakatlanish bo'lagi",
      description:
        "Belgi yoki svetofor ta'siridagi harakatlanish bo'lagini ko'rsatadi.",
      img: '21',
    },
    {
      title: "7.15. Ko'zi ojiz piyodalar",
      description:
        "1.20, 5.16.1, 5.16.2< belgilari hamda svetofor bilan qo'llaniladi, piyodalar o'tish joyidan ko'zi ojiz kishilar foydalanishi haqida ogohlantiradi.",
      img: '22',
    },
    {
      title: '7.16. Nam qoplam',
      description:
        "Belgi qatnov qismining qoplamasi nam bo'lgan vaqtda ta'sir etishini ko'rsatadi.",
      img: '23',
    },
    {
      title: '7.17. Nogironlar',
      description:
        "5.15 belgisi bilan qo'llaniladi. To'xtab turish joyi (yoki uning bir qismi) ushbu Qoidalarning 28.3 bandiga muvofiq Nogiron taniqliq belgisi o'rnatilgan transport vositalari uchun ajratilganligini ko'rsatadi.",
      img: '24',
    },
    {
      title: '7.18. Nogironlar mustasno',
      description:
        "Belgilarning ta'siri ushbu Qoidalarning 28.3 bandiga muvofiq Nogiron taniqliq belgisi o'rnatilgan transport vositalariga va motokajavalarga joriy etilmaydi.",
      img: '25',
    },
    {
      title: '7.19. Chorrahadagi kamera',
      description: '',
      img: '26',
    },
  ]
  const imgSize = (sign: any) => {
    if (
      +sign.img === 3 ||
      +sign.img === 5 ||
      +sign.img === 6 ||
      +sign.img === 7 ||
      +sign.img === 12 ||
      +sign.img === 13
    ) {
      return (
        <img
          src={`${process.env.PUBLIC_URL}/images/belgilar/qoshimcha/${sign.img}.png`}
          alt={sign.description}
          className="big-img mx-auto d-block"
        />
      )
    } else {
      return (
        <img
          src={`${process.env.PUBLIC_URL}/images/belgilar/qoshimcha/${sign.img}.png`}
          alt={sign.description}
          className="small-img d-block mx-auto"
        />
      )
    }
  }
  return (
    <div>
      <h2>Qo'shimcha axborot belgilari</h2>
      <p className='fs-4'>
        Qo'shimcha axborot belgilari ular bilan birgalikda qo'llangan belgilarga
        aniqlik kiritish yoki ularning ta'sirini cheklash uchun qo'llaniladi.
      </p>
      <div>
        {roadsigns.map((sign: any) => (
          <div key={sign.img}>
            <h3 className="text-center">{sign.title}</h3>
            <p className='fs-4'>{sign.description}</p>
            {imgSize(sign)}
            <hr />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Comp7
