import React from 'react'

const Comp21 = () => {
  return (
    <div className='fs-5'>
      <h2>
        21. Belgilangan yo'nalishdagi transport vositalarining imtiyozlari
      </h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          21.1
        </span>
        Tramvay yo’li chorrahadan boshqa joylarda yo’l qatnov qismini kesib
        o’tadigan bo’lsa, tramvyj rel’ssiz transport vositalariga nisbatan
        imtiyozga ega bo’ladi (depodan chiqish joylaridan tashqari).
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          21.2
        </span>
        Belgilangan yo’nalishdagi transport vositalarining harakatlanishi uchun
        5.9, 5.10.1–5.10.3 belgilari bilan ajratilgan bo’lakda boshqa transport
        vositalarining harakatlanishi va to’xtashi taqiqlanadi. <br />
        Agar bu bo’lak yo’l qatnov qismining boshqa joyidan uzuq-uzuq chiziq
        bilan ajratilgan bo’lsa, burilmoqchi bo’layotgan transport vositalari bu
        bo’lakka qayta tizilishlari kerak. <br />
        Shuningdek, bunday joylarda belgilangan yo’nalishdagi transport
        vositalarining harakatiga halaqit bermaslik sharti bilan yo’lga chiqish,
        qatnov qismining chetki o’ng tomonida yo’lovchilarni chiqarish va
        tushirish uchun bu bo’lakka o’tishga ruxsat etiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          21.3
        </span>
        Aholi yashaydigan joylarda bekatdan harakatlanishni boshlayotgan avtobus
        va trolleybuslarga haydovchilar yo’l berishlari kerak. O’z navbatida
        avtobus va trolleybus haydovchilari ularga yo’l berilganligiga ishonch
        hosil qilganliklaridan keyingina harakatlanishni boshlashlari mumkin.
      </p>
    </div>
  )
}

export default Comp21
