import React from 'react'

const Comp4 = () => {
  return (
    <div className='fs-5'>
      <h2>4. Yo'l-transport hodisasi sodir etgan haydovchilarning vazifasi</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          4.1
        </span>
        Yo’l-transport hodisasi sodir bo’lganda unga dahldor haydovchilar
        kuyidagilarni bajarishlari shart: <br />
        transport vositasini darhol to’xtatishi, falokat yorug’lik ishoralarini
        yoki falokat sababli to’xtash belgisi (miltillovchi qizil chiroq)ni
        o’rnatishi, transport vositasini va hodisaga dahldor buyumlarni joyidan
        qo’zg’atmasligi; <br />
        shikastlanganlarga birinchi tibbiy yordam berish uchun zarur bo’lgan
        choralarni ko’rishi, Tez tibbiy yordamni chaqirishi, shoshilinch
        hollarda esa shikast-langanlarni yaqin oradagi davolash muassasasiga
        yo’lakay transportda jo’natishi, buning iloji bo’lmagan hollarda o’z
        transport vositasida olib borishi, u erda o’z ismi-sharifini, transport
        vositasining raqamli belgisini bildirishi (haydovchilik guvohnomasini
        yoki o’z shaxsini tasdiqlovchi, transport vositasining ro’yhatdan
        o’tganlik hujjatini ko’rsatishi) va hodisa ro’y bergan joyga qaytib
        kelishi; <br />
        agar boshqa transport vositalarining harakatiga to’sqinlik kilsa, avvalo
        transport vositasi, hodisaga aloqador buyumlar va izlarning holatini
        kayd etib (imkoniyati bo’lgan hollarda guvohlar ishtiroqida), ularning
        yo’qolmasligini ta’minlashi, so’ngra qatnov qismini bo’shatishi yoki
        hodisa ro’y bergan joyni aylanib o’tishni tashkil qilishning o’ziga
        bog’lik bo’lgan barcha choralarini ko’rishi; <br />
        hodisa hakida militsiyaga habar berishi, guvohlarning ismi sharifini va
        manzilgohini yozib olib, militsiya xodimlarining kelishini kutishi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          4.2
        </span>
        Agar yo’l-transport hodisasida shikastlanganlar bo’lmasa va haydovchilar
        hodisa vaziyatiga baho berishda o’zaro kelisha olsalar, oldindan hodisa
        chizmasini tuzib va uni imzolab, yaqin oradagi DAN maskani yoki
        militsiya organiga hodisani rasmiylashtirish uchun borishlari mumkin.
      </p>
    </div>
  )
}

export default Comp4
