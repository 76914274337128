import React from 'react'

const Comp2 = () => {
  return (
    <div className="fs-5">
      <h2>2. Qoidalarda qo'llanilgan asosiy tushuncha va atamalar.</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Aholi yashaydigan joy
        </span>{' '}
        <br />
        Kirish va chiqish yo’llari 5.22 – 5.25 belgilari bilan belgilangan
        hudud.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Ajratuvchi bo’lak
        </span>{' '}
        <br />
        Yo’lning yonma-yon joylashgan qatnov qismlarini ajratuvchi, relssiz
        transport vositalari va piyodalarning harakatlanishi yoki to’xtashi
        uchun mo’ljallanmagan alohida qismi. Ajratuvchi bo’lak yotiq chiziq,
        yo’l sathidan baland qurilma, ko’kalamzorlashtirilgan joylar
        ko’rinishida bo’lishi mumkin.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Arava
        </span>
        <br />
        Ot (yoki boshqa hayvonlar)ga qo’shib tortiladigan, yoki odam mushak
        kuchi bilan tortib (itarib) harakatga keltiriladigani yurgizgichsiz
        (dvigatelsiz), yuk tashishga mu’ljallangan qurilma.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Asosiy yo’l
        </span>
        <br />
        Tuproqli yo’lga nisbatan qattiq qoplamali (asfalt va tsement betonli,
        tosh yotqizilgan va shunga o’xshashlar), kesishayotgan yoki tutashgan
        yo’lga nisbatan 2.1, 2.3.1–2.3.3 yoki 5.1 belgilari bilan belgilangan
        yo’l yoxud yondosh hududdan chiqadigan yo’lga nisbatan har qanday yo’l.
        Ikkinchi darajali yo’lning bevosita chorrahaga tutash qismining
        qoplamali bo’lishi uni asosiy yo’l bilan teng huquqli qilmaydi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Avtomagistral
        </span>
        <br />
        5.1 belgi bilan belgilangan yo’l.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Belgilangan yo’nalishli transport vositasi
        </span>
        <br />
        Belgilangan yo’nalishi va bekatlari bo’lgan, yo’lovchi tashish uchun
        mo’ljallangan umumiy foydalanishdagi transport vositalari (trolleybus,
        avtobus, tramvay, yo’nalishli taksi).
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Chorraha
        </span>
        <br />
        Yo’lning o’zaro bir sathda kesishadigan, tutashadigan va ayriladigan
        joyi. <br />
        Chorraha chegarasi uning markazidan qatnov qismlari ohiridagi
        qarama-qarshi tomonlarning eng uzoqroqdagi burila boshlagan joylarini
        tutashuvchi hayoliy chiziqlan bilan aniqlanadi. <br />
        Yondosh hududlardan chiqish joylari chorraha hisoblanmaydi. Yo’l
        harakati svetofor yoki tartibga soluvchining ishoralari orqali
        boshqarilayotgan chorraha tartibga solingan chorraha deb hisoblanadi.
        <br />
        Miltillovchi sariq ishorada, svetoforlar ishlamayotgan yoki tartibga
        soluvchi bo’lmagan chorraha tartibga solimanagan chorraha deb
        hisoblanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Yetarli ko’rinmaslik
        </span>
        <br />
        Yomg’ir, qor yog’ishi, tuman tushishi va shunga o’xshash sharoitlarda,
        shuningdek, kunning g’ira-shira vaqtida yo’lning ko’rinishi 300 metrdan
        kam bo’lishi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Harakatlanish bo’lagi
        </span>
        <br />
        Avtomobillarning bir qator bo’lib harakatlanishi uchun kengligi
        etarlicha bo’lgan, yo’l chiziqlari bilan belgilangan yoki belgilanmagan
        yo’l qatnov qismining har qanda bo’ylama bo’lagi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Haydovchi
        </span>
        <br />
        Yo’llarda biron-bir transport vositasini boshqarib borayotgan shaxs.{' '}
        <br />
        Boshqarishni o’rgatuvchi, miniladigan hayvonlarda ketayotgan yoki ularni
        etaklab borayotgan, chorva mollari (poda)ni haydam borayotgan shaxslar
        ham haydovchiga tenglashtiriladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Imtiyoz
        </span>
        <br />
        Mo’ljallangan yo’nalishda boshqa yo’l harakati qatnashchilariga nisbatan
        oldin harakatlanish huquqi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Majburiy to’xtash
        </span>
        <br />
        Texnik nuqson, tashilayotgan yuk, haydovchi, yo’lovchining holati,
        yo’ldagi biror to’siq tufayli xavf tug’ilganda yoki ob-havo sharoitiga
        bog’liq holda transport vositasi harakatlanishining to’xtatilishi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Mexanik transport vositasi
        </span>
        <br />
        Yurgizgich (dvigatel) bilan harakatga keltiriladigan transport vositasi
        (mopeddan tashqari). Bu atama barcha traktor va o’ziyurar moslamalarga
        ham taalluqlidir.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Moped
        </span>
        <br />
        Ish hajmi 50 santimetr kubgacha bo’lgan yurgizgich (dvigatel) bilan
        harakatga keltiriladigan, eng yuqori tezligi soatiga 50 kilometrdan
        oshmaydigan ikki yoki uch gildirakli transport vositasi. <br />
        Osma yurgizgichli velosipedlar va yuqoridagi ta’rifga ega bo’lgan boshqa
        transport vositalari ham mopedlarga tenglashtiriladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Motosikl
        </span>
        <br />
        Kajavali yoki kajavasiz ikki g’ildirakli mexanik transport vositasi.{' '}
        <br />
        Yuklanmangan holatdagi vazni 400 kilogrammdan oshmaydigan uch
        g’ildirakli mexanik transport vositalari ham motisikllarga
        tenglashtiriladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Ogohlantiruvchi ishoralar
        </span>
        <br />
        Falokatli holatlarning oldini olish maqsadida, harakatlanish
        yo’nalishini o’zgartirishda qo’llaniladigan ishoralar.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Piyoda
        </span>
        <br />
        Yo’lda transport vositasidan tashqarida bo’lgan va ish bilan band
        bo’lmagan shaxs. <br />
        Velosiped, moped, motosikl va chana etaklagan, aravacha, bolalar hamda
        nogironlar aravachasini tortib ketayotgan, nogironlarning yugizgichsiz
        aravasida harakatlanayotgan shaxslar ham piyoda hisoblanadilar.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Piyodalar o’tish joyi
        </span>
        <br />
        Yo’lning qatnov qismini piyodalar kesib o’tishi uchun mo’ljallangan,
        5.16.1, 5.16.2 belgilari va 1.14.1–1.14.3 chiziqlari bilan ajratilgan
        qismi. <br />
        Chiziqlar bo’lmasa piyodalar o’tish joyining kengligi 5.16.1, 5.16.2
        belgilari orasidagi masofa bilan aniqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Piyodalar yo’lkasi
        </span>
        <br />
        Yo’lning piyodalar harakatlanishi uchun mo’ljallangan va transport
        vositalari harakati taqiqlangan qismi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Piyodalarning tashkiliy jamlanmasi
        </span>
        <br />
        Qoidalarning 5.2 band talablariga muvofiq yo’lda bir yo’nalishda
        birgalikda harakatlanayotgan odamlar guruhi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Qatnov qismi
        </span>
        <br />
        Yo’lning relssiz transport vositalari harakati uchun mo’ljallangan
        qismi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Qorong’i vaqt
        </span>
        <br />
        Kechki g’ira-shiraning ohiridan tonggi g’ira-shiraning boshlanishi
        orasidagi vaqt.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Quvib o’tish
        </span>
        <br />
        Egallagan harakatlanish bo’lagidan chiqib oldinda ketayotgan transport
        vositasidan o’zib ketish.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Reversiv harakat
        </span>
        <br />
        Yo’l qatnov qismining maxsus ajratilgan, 5.35–5.37yo’l belgilari, 1.19
        chiziqlar bilan belgilangan yoki ustida reversiv svetofor o’rnatilgan
        bo’lagida harakat yo’nalishining qarama-qarshi tomonga o’zgarishi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Ruxsat etilgan to’la vazn
        </span>
        <br />
        Aslahalangan transport vositasining yuki, haydovchi va yo’lovchilari
        bilan birga, ishlab chiqaruvchi korxona tomonidan belgilangan eng yuqori
        vazni (miqdori). <br />
        Bir tarkibda harakatlanayotgan (tirkama va h.k.) transport
        vositalarining ruxsat etilgan to’la vazniga shu tarkibga kiruvchi
        transport vositalarining ruxsat etilgan to’la vaznlari yig’indisi
        kiradi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Tartibga soluvchi
        </span>
        <br />
        Tegishli guvohnomasi va belgisi (maxsus kiyimi yoki taniqlik belgilari:
        qo’l bog’ichi, zhezl, qizil ishorali yorug’lik qaytargich yoki yorug’lik
        qaytargichsiz gardish, qizil chiroq yoki bayroqcha) bo’lgan militsiya,
        harbiy avtonazorat xodimi, yo’ldan foydalanish idoralarining
        xizmatchisi, temir yo’l kesishmasi, sol kechuvlari (parom) navbatchisi,
        janoatchi avtonazoratchi va militsiyaning shtatdan tashqari xodimi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Temir yo’l kesishmasi
        </span>
        <br />
        Yo’lning temir yo’l bilan bir sathda kesishgan joyi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Tirkama
        </span>
        <br />
        Mexanik transport vositasi tarkibida harakatlanishga mo’ljallangan,
        yurgizgich bilan jihozlanmagan transport vositasi. <br />
        Bu atama yarim tirkama va uzaytirilgan tirkamalarga ham taalluqlidir.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          To’xtab turish
        </span>
        <br />
        Transport vositasining, unga yo’lovchilarni chiqarish yoki tushirish,
        yuk ortish yoki tushirish bilan bog’liq bo’lmagan hollarda harakatini 10
        daqiqadan ko’proq vaqtga atayin to’xtatish.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          To’xtash
        </span>
        <br />
        Transport vositasining harakati 10 daqiqadan kam vaqtga to’xtatish
        (harakatsiz holatga keltirish).
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Transport vositalarining tashkiliy jamlanmasi
        </span>
        <br />
        Bevosita bir-birining ketidan bitta harakatlanish bo’lagida faralarini
        doimiy yoqib kelayotgan, oldida yalt-yalt etuvchi ko’k rangli yoki ko’k
        va qizil rangli chiroq mayoqchasi yoqilgan transport vositasi kuzatib
        borayotgan uch va undan ortiq mexanik transport vositalari guruhi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Transport vositasi
        </span>
        <br />
        Odamlarni, yuklarni tashishga yoki maxsus ishlarni bajarishga
        mo’ljallangan qurilma.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Transport vositasining egasi
        </span>
        <br />
        Odamlarni, yuklarni tashishga yoki maxsus ishlarni bajarishga
        mo’ljallangan qurilma.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Trotuar
        </span>
        <br />
        Yo’lning qatnov qismiga tutashgan yoki undan maysazor, ariq, maxsus
        to’siqlar bilan ajratilgan va piyodalarning harakatlanishi uchun
        mo’ljallangan qismi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Velosiped
        </span>
        <br />
        Odam kuchi bilan harakatlantiriladigan ikki yoki undan ortiq g’ildirakli
        transport vositasi (nogironlar aravachasidan tashqari).
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Yo’l bering
        </span>
        <br />
        Yo’l harakati qatnashchisiga nisbatan imtiyozi bo’lgan boshqa yo’l
        harakati qatnashchilarining harakat yo’lnalishini yoki tezligini
        o’zgartirishga majbur etishi mumkin bo’lgan hollarda harakatni davom
        ettirmasligini yoki boshlamasligini, biron-bir manyovr mumkin emasligini
        bildiruvchi talab.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Yo’l harakati qatnashchisi
        </span>
        <br />
        Yo’l harakati jarayonida transport vositasi haydovchisi, yo’lovchisi
        yoki piyoda tariqasida bevosida ishtiroq etayotgan shaxs.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Yo’l harakati xavfsizligi
        </span>
        <br />
        Yo’l harakati qatnashchilarining yo’l-transport hodisalari va ularning
        oqibatlaridan himoyalanganlik darajasini aks ettiruvchi yo’l harakati
        holati.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Yo’l harakati xavfsizligini ta’minlash
        </span>
        <br />
        Yo’l-transport hodisalarining yuzaga kelish sabablari oldini olishga,
        bunday hodisalar oqibatlarini engillashtirishga qaratilgan faoliyat.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Yo’l harakati
        </span>
        <br />
        Odamlar va yuklarning transport vositalari yordamida yoki bunday
        vositalarsiz yo’llar doirasida harakatlanishi jarayonida yuzaga keluvchi
        munosabatlar majmui.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Yo’l harakatini tashkil etish
        </span>
        <br />
        Yo’llarda harakatni boshqarish bo’yicha huquqiy, tashkiliy-texnikaviy
        tadbirlar va boshqaruv harakatlari majmui.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Yo’l
        </span>
        <br />
        Transport vositalari qatnovi uchun qurilgan yoki moslashtirilgan va
        xuddi shu maqsadda foydalaniladigan er mintaqasi yoxud sun’iy inshoot
        yuzasi. Yo’l o’z ichiga avtomobil yo’llarini (umumiy foydalanishdagi va
        idoraviy avtomobil yo’llarini), shaharchalar, shaharchalar va qishloq
        aholi yashash joylarining ko’chalari va yo’lkalarini, shuningdek, shahar
        elektr transporti yo’llarini oladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Yo’lovchi
        </span>
        <br />
        Transport vositasidagi (haydovchidan tashqari) shaxs.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Yo’l-transport hodisasi
        </span>
        <br />
        Transport vositasining yo’ldagi qatnovi jarayonida sodir bo’lib,
        odamlarning o’limi yoki ularning sog’ligiga zarar etishiga, transport
        vositalari, inshootlar, yuklar shikastlanishiga yoki boshqa tarzda
        moddiy zarar etkazilishiga sabab bo’lgan hodisa.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Yondosh hudud
        </span>
        <br />
        Bevosita yo’lga tutashgan va transport vositalari o’tib ketishi uchun
        mo’ljallangan hudud (hovlilar, turar joy dahalari, avtomobil to’xtab
        turish joylari, yonilg’i quyish tarmoqlari, korxona va shunga
        o’xshashlar).
      </p>
    </div>
  )
}

export default Comp2
