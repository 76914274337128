import React from 'react'

const Comp2 = () => {
  return (
    <div className="fs-5">
      <h2>2. Қоидаларда қўлланилган асосий тушунча ва атамалар.</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Aҳоли яшайдиган жой
        </span>
        <br />
        Кириш ва чиқиш йўллари 5.22 – 5.25 белгилари билан белгиланган ҳудуд.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Aжратувчи бўлак
        </span>
        <br />
        Йўлнинг ёнма-ён жойлашган қатнов қисмларини ажратувчи, релссиз транспорт
        воситалари ва пиёдаларнинг ҳаракатланиши ёки тўхташи учун мўлжалланмаган
        алоҳида қисми. Aжратувчи бўлак ётиқ чизиқ, йўл сатҳидан баланд қурилма,
        кўкаламзорлаштирилган жойлар кўринишида бўлиши мумкин.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Aрава
        </span>
        <br />
        От (ёки бошқа ҳайвонлар)га қўшиб тортиладиган, ёки одам мушак кучи билан
        тортиб (итариб) ҳаракатга келтириладигани юргизгичсиз (двигателсиз), юк
        ташишга муължалланган қурилма.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Aсосий йўл
        </span>
        <br />
        Тупроқли йўлга нисбатан қаттиқ қопламали (асфалт ва цемент бетонли, тош
        ётқизилган ва шунга ўхшашлар), кесишаётган ёки туташган йўлга нисбатан
        2.1, 2.3.1–2.3.3 ёки 5.1 белгилари билан белгиланган йўл ёхуд ёндош
        ҳудуддан чиқадиган йўлга нисбатан ҳар қандай йўл. Иккинчи даражали
        йўлнинг бевосита чорраҳага туташ қисмининг қопламали бўлиши уни асосий
        йўл билан тенг ҳуқуқли қилмайди.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Aвтомагистрал
        </span>
        <br />
        5.1 белги билан белгиланган йўл.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Белгиланган йўналишли транспорт воситаси
        </span>
        <br />
        Белгиланган йўналиши ва бекатлари бўлган, йўловчи ташиш учун
        мўлжалланган умумий фойдаланишдаги транспорт воситалари (троллейбус,
        автобус, трамвай, йўналишли такси).
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Чорраҳа
        </span>
        <br />
        Йўлнинг ўзаро бир сатҳда кесишадиган, туташадиган ва айриладиган жойи.{' '}
        <br />
        Чорраҳа чегараси унинг марказидан қатнов қисмлари оҳиридаги қарама-қарши
        томонларнинг енг узоқроқдаги бурила бошлаган жойларини туташувчи ҳаёлий
        чизиқлан билан аниқланади. <br />
        Ёндош ҳудудлардан чиқиш жойлари чорраҳа ҳисобланмайди. Йўл ҳаракати
        светофор ёки тартибга солувчининг ишоралари орқали бошқарилаётган
        чорраҳа тартибга солинган чорраҳа деб ҳисобланади.
        <br />
        Милтилловчи сариқ ишорада, светофорлар ишламаётган ёки тартибга солувчи
        бўлмаган чорраҳа тартибга солиманаган чорраҳа деб ҳисобланади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Йетарли кўринмаслик
        </span>
        <br />
        Ёмғир, қор ёғиши, туман тушиши ва шунга ўхшаш шароитларда, шунингдек,
        куннинг ғира-шира вақтида йўлнинг кўриниши 300 метрдан кам бўлиши.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Ҳаракатланиш бўлаги
        </span>
        <br />
        Aвтомобилларнинг бир қатор бўлиб ҳаракатланиши учун кенглиги етарлича
        бўлган, йўл чизиқлари билан белгиланган ёки белгиланмаган йўл қатнов
        қисмининг ҳар қанда бўйлама бўлаги.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Ҳайдовчи
        </span>
        <br />
        Йўлларда бирон-бир транспорт воситасини бошқариб бораётган шахс.
        <br />
        Бошқаришни ўргатувчи, миниладиган ҳайвонларда кетаётган ёки уларни
        етаклаб бораётган, чорва моллари (пода)ни ҳайдам бораётган шахслар ҳам
        ҳайдовчига тенглаштирилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Имтиёз
        </span>
        <br />
        Мўлжалланган йўналишда бошқа йўл ҳаракати қатнашчиларига нисбатан олдин
        ҳаракатланиш ҳуқуқи.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Мажбурий тўхташ
        </span>
        <br />
        Техник нуқсон, ташилаётган юк, ҳайдовчи, йўловчининг ҳолати, йўлдаги
        бирор тўсиқ туфайли хавф туғилганда ёки об-ҳаво шароитига боғлиқ ҳолда
        транспорт воситаси ҳаракатланишининг тўхтатилиши.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Механик транспорт воситаси
        </span>
        <br />
        Юргизгич (двигател) билан ҳаракатга келтириладиган транспорт воситаси
        (мопеддан ташқари). Бу атама барча трактор ва ўзиюрар мосламаларга ҳам
        тааллуқлидир.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Мопед
        </span>
        <br />
        Иш ҳажми 50 сантиметр кубгача бўлган юргизгич (двигател) билан ҳаракатга
        келтириладиган, енг юқори тезлиги соатига 50 километрдан ошмайдиган икки
        ёки уч гилдиракли транспорт воситаси. <br />
        Осма юргизгичли велосипедлар ва юқоридаги таърифга ега бўлган бошқа
        транспорт воситалари ҳам мопедларга тенглаштирилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Мотосикл
        </span>
        <br />
        Кажавали ёки кажавасиз икки ғилдиракли механик транспорт воситаси.
        <br />
        Юкланманган ҳолатдаги вазни 400 килограммдан ошмайдиган уч ғилдиракли
        механик транспорт воситалари ҳам мотисиклларга тенглаштирилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Огоҳлантирувчи ишоралар
        </span>
        <br />
        Фалокатли ҳолатларнинг олдини олиш мақсадида, ҳаракатланиш йўналишини
        ўзгартиришда қўлланиладиган ишоралар.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Пиёда
        </span>
        <br />
        Йўлда транспорт воситасидан ташқарида бўлган ва иш билан банд бўлмаган
        шахс. <br />
        Велосипед, мопед, мотосикл ва чана етаклаган, аравача, болалар ҳамда
        ногиронлар аравачасини тортиб кетаётган, ногиронларнинг югизгичсиз
        аравасида ҳаракатланаётган шахслар ҳам пиёда ҳисобланадилар.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Пиёдалар ўтиш жойи
        </span>
        <br />
        Йўлнинг қатнов қисмини пиёдалар кесиб ўтиши учун мўлжалланган, 5.16.1,
        5.16.2 белгилари ва 1.14.1–1.14.3 чизиқлари билан ажратилган қисми.{' '}
        <br />
        Чизиқлар бўлмаса пиёдалар ўтиш жойининг кенглиги 5.16.1, 5.16.2
        белгилари орасидаги масофа билан аниқланади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Пиёдалар йўлкаси
        </span>
        <br />
        Йўлнинг пиёдалар ҳаракатланиши учун мўлжалланган ва транспорт воситалари
        ҳаракати тақиқланган қисми.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Пиёдаларнинг ташкилий жамланмаси
        </span>
        <br />
        Қоидаларнинг 5.2 банд талабларига мувофиқ йўлда бир йўналишда биргаликда
        ҳаракатланаётган одамлар гуруҳи.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Қатнов қисми
        </span>
        <br />
        Йўлнинг релссиз транспорт воситалари ҳаракати учун мўлжалланган қисми.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Қоронғи вақт
        </span>
        <br />
        Кечки ғира-ширанинг оҳиридан тонгги ғира-ширанинг бошланиши орасидаги
        вақт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Қувиб ўтиш
        </span>
        <br />
        Егаллаган ҳаракатланиш бўлагидан чиқиб олдинда кетаётган транспорт
        воситасидан ўзиб кетиш.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Реверсив ҳаракат
        </span>
        <br />
        Йўл қатнов қисмининг махсус ажратилган, 5.35–5.37йўл белгилари, 1.19
        чизиқлар билан белгиланган ёки устида реверсив светофор ўрнатилган
        бўлагида ҳаракат йўналишининг қарама-қарши томонга ўзгариши.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Рухсат етилган тўла вазн
        </span>
        <br />
        Aслаҳаланган транспорт воситасининг юки, ҳайдовчи ва йўловчилари билан
        бирга, ишлаб чиқарувчи корхона томонидан белгиланган енг юқори вазни
        (миқдори). <br />
        Бир таркибда ҳаракатланаётган (тиркама ва ҳ.к.) транспорт воситаларининг
        рухсат етилган тўла вазнига шу таркибга кирувчи транспорт воситаларининг
        рухсат етилган тўла вазнлари йиғиндиси киради.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Тартибга солувчи
        </span>
        <br />
        Тегишли гувоҳномаси ва белгиси (махсус кийими ёки таниқлик белгилари:
        қўл боғичи, зҳезл, қизил ишорали ёруғлик қайтаргич ёки ёруғлик
        қайтаргичсиз гардиш, қизил чироқ ёки байроқча) бўлган милиция, ҳарбий
        автоназорат ходими, йўлдан фойдаланиш идораларининг хизматчиси, темир
        йўл кесишмаси, сол кечувлари (паром) навбатчиси, жаноатчи автоназоратчи
        ва милициянинг штатдан ташқари ходими.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Темир йўл кесишмаси
        </span>
        <br />
        Йўлнинг темир йўл билан бир сатҳда кесишган жойи.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Тиркама
        </span>
        <br />
        Механик транспорт воситаси таркибида ҳаракатланишга мўлжалланган,
        юргизгич билан жиҳозланмаган транспорт воситаси. <br />
        Бу атама ярим тиркама ва узайтирилган тиркамаларга ҳам тааллуқлидир.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Тўхтаб туриш
        </span>
        <br />
        Транспорт воситасининг, унга йўловчиларни чиқариш ёки тушириш, юк ортиш
        ёки тушириш билан боғлиқ бўлмаган ҳолларда ҳаракатини 10 дақиқадан
        кўпроқ вақтга атайин тўхтатиш.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Тўхташ
        </span>
        <br />
        Транспорт воситасининг ҳаракати 10 дақиқадан кам вақтга тўхтатиш
        (ҳаракациз ҳолатга келтириш).
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Транспорт воситаларининг ташкилий жамланмаси
        </span>
        <br />
        Бевосита бир-бирининг кетидан битта ҳаракатланиш бўлагида фараларини
        доимий ёқиб келаётган, олдида ялт-ялт етувчи кўк рангли ёки кўк ва қизил
        рангли чироқ маёқчаси ёқилган транспорт воситаси кузатиб бораётган уч ва
        ундан ортиқ механик транспорт воситалари гуруҳи.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Транспорт воситаси
        </span>
        <br />
        Одамларни, юкларни ташишга ёки махсус ишларни бажаришга мўлжалланган
        қурилма.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Транспорт воситасининг егаси
        </span>
        <br />
        Одамларни, юкларни ташишга ёки махсус ишларни бажаришга мўлжалланган
        қурилма.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Тротуар
        </span>
        <br />
        Йўлнинг қатнов қисмига туташган ёки ундан майсазор, ариқ, махсус
        тўсиқлар билан ажратилган ва пиёдаларнинг ҳаракатланиши учун
        мўлжалланган қисми.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Велосипед
        </span>
        <br />
        Одам кучи билан ҳаракатлантириладиган икки ёки ундан ортиқ ғилдиракли
        транспорт воситаси (ногиронлар аравачасидан ташқари).
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Йўл беринг
        </span>
        <br />
        Йўл ҳаракати қатнашчисига нисбатан имтиёзи бўлган бошқа йўл ҳаракати
        қатнашчиларининг ҳаракат йўлналишини ёки тезлигини ўзгартиришга мажбур
        етиши мумкин бўлган ҳолларда ҳаракатни давом еттирмаслигини ёки
        бошламаслигини, бирон-бир манёвр мумкин емаслигини билдирувчи талаб.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Йўл ҳаракати қатнашчиси
        </span>
        <br />
        Йўл ҳаракати жараёнида транспорт воситаси ҳайдовчиси, йўловчиси ёки
        пиёда тариқасида бевосида иштироқ етаётган шахс.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Йўл ҳаракати хавфсизлиги
        </span>
        <br />
        Йўл ҳаракати қатнашчиларининг йўл-транспорт ҳодисалари ва уларнинг
        оқибатларидан ҳимояланганлик даражасини акс еттирувчи йўл ҳаракати
        ҳолати.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Йўл ҳаракати хавфсизлигини таъминлаш
        </span>
        <br />
        Йўл-транспорт ҳодисаларининг юзага келиш сабаблари олдини олишга, бундай
        ҳодисалар оқибатларини енгиллаштиришга қаратилган фаолият.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Йўл ҳаракати
        </span>
        <br />
        Одамлар ва юкларнинг транспорт воситалари ёрдамида ёки бундай
        воситаларсиз йўллар доирасида ҳаракатланиши жараёнида юзага келувчи
        муносабатлар мажмуи.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Йўл ҳаракатини ташкил етиш
        </span>
        <br />
        Йўлларда ҳаракатни бошқариш бўйича ҳуқуқий, ташкилий-техникавий
        тадбирлар ва бошқарув ҳаракатлари мажмуи.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Йўл
        </span>
        <br />
        Транспорт воситалари қатнови учун қурилган ёки мослаштирилган ва худди
        шу мақсадда фойдаланиладиган ер минтақаси ёхуд сунъий иншоот юзаси. Йўл
        ўз ичига автомобил йўлларини (умумий фойдаланишдаги ва идоравий
        автомобил йўлларини), шаҳарчалар, шаҳарчалар ва қишлоқ аҳоли яшаш
        жойларининг кўчалари ва йўлкаларини, шунингдек, шаҳар електр транспорти
        йўлларини олади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Йўловчи
        </span>
        <br />
        Транспорт воситасидаги (ҳайдовчидан ташқари) шахс.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Йўл-транспорт ҳодисаси
        </span>
        <br />
        Транспорт воситасининг йўлдаги қатнови жараёнида содир бўлиб,
        одамларнинг ўлими ёки уларнинг соғлигига зарар етишига, транспорт
        воситалари, иншоотлар, юклар шикастланишига ёки бошқа тарзда моддий
        зарар етказилишига сабаб бўлган ҳодиса.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          Ёндош ҳудуд
        </span>
        <br />
        Бевосита йўлга туташган ва транспорт воситалари ўтиб кетиши учун
        мўлжалланган ҳудуд (ҳовлилар, турар жой даҳалари, автомобил тўхтаб туриш
        жойлари, ёнилғи қуйиш тармоқлари, корхона ва шунга ўхшашлар).
      </p>
    </div>
  )
}

export default Comp2
