import React from 'react'

const Comp4 = () => {
  return (
    <div className="fs-5">
      <h2>4. Йўл-транспорт ҳодисаси содир етган ҳайдовчиларнинг вазифаси</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          4.1
        </span>
        Йўл-транспорт ҳодисаси содир бўлганда унга даҳлдор ҳайдовчилар
        куйидагиларни бажаришлари шарт: <br />
        транспорт воситасини дарҳол тўхтатиши, фалокат ёруғлик ишораларини ёки
        фалокат сабабли тўхташ белгиси (милтилловчи қизил чироқ)ни ўрнатиши,
        транспорт воситасини ва ҳодисага даҳлдор буюмларни жойидан
        қўзғатмаслиги; <br />
        шикастланганларга биринчи тиббий ёрдам бериш учун зарур бўлган чораларни
        кўриши, Тез тиббий ёрдамни чақириши, шошилинч ҳолларда еса
        шикаст-ланганларни яқин орадаги даволаш муассасасига йўлакай транспортда
        жўнатиши, бунинг иложи бўлмаган ҳолларда ўз транспорт воситасида олиб
        бориши, у ерда ўз исми-шарифини, транспорт воситасининг рақамли
        белгисини билдириши (ҳайдовчилик гувоҳномасини ёки ўз шахсини
        тасдиқловчи, транспорт воситасининг рўйҳатдан ўтганлик ҳужжатини
        кўрсатиши) ва ҳодиса рўй берган жойга қайтиб келиши; <br />
        агар бошқа транспорт воситаларининг ҳаракатига тўсқинлик килса, аввало
        транспорт воситаси, ҳодисага алоқадор буюмлар ва изларнинг ҳолатини кайд
        етиб (имконияти бўлган ҳолларда гувоҳлар иштироқида), уларнинг
        йўқолмаслигини таъминлаши, сўнгра қатнов қисмини бўшатиши ёки ҳодиса рўй
        берган жойни айланиб ўтишни ташкил қилишнинг ўзига боғлик бўлган барча
        чораларини кўриши; <br />
        ҳодиса ҳакида милицияга ҳабар бериши, гувоҳларнинг исми шарифини ва
        манзилгоҳини ёзиб олиб, милиция ходимларининг келишини кутиши.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          4.2
        </span>
        Aгар йўл-транспорт ҳодисасида шикастланганлар бўлмаса ва ҳайдовчилар
        ҳодиса вазиятига баҳо беришда ўзаро келиша олсалар, олдиндан ҳодиса
        чизмасини тузиб ва уни имзолаб, яқин орадаги ДAН маскани ёки милиция
        органига ҳодисани расмийлаштириш учун боришлари мумкин.
      </p>
    </div>
  )
}

export default Comp4
