import React from 'react'

const Comp23 = () => {
  return (
    <div className='fs-5'>
      <h2>23. Mexanik transport vositalarini shatakka olish</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          23.1
        </span>
        Qattik yoki egiluvchan ulagich yordamida shatakda olib yurish shatakka
        olingan transport vositasining rul boshqaruvida haydovchi bo’lgandagina
        amalga oshirilishi kerak. Qattik ulagichning tuzilishi (konstruktsiyasi)
        shatakka olingan transport vositasini shatakka olgan transport
        vositasining izidan borishini ta’minlagan hollar bundan mustasno.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          23.2
        </span>
        Qattik yoki egiluvchan ulagich yordamida shatakka olingan avtobusda,
        trolleybusda va yuk avtomobili yukhonasida odam tashish, qisman ortish
        yo’li bilan shatakka olishda esa shatakka olingan transport vositasining
        kabinasida va yukhonasida, shuningdek, shatakka olgan transport
        vositasining yukhonasida odamlar bo’lishi taqiqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          23.3
        </span>
        Egiluvchan ulagich bilan shatakka olishda shatakka olgan va shatakka
        olingan transport vositalari o’rtasidagi masofa 4-6 metr oralig’ida
        bo’lishi, qattiq ulagich yordamida esa 4 metrdan ko’p bo’lmasligi kerak.
        Egiluvchan ulagichga Qoidalarning 28.4 bandiga muvofik taxtacha yoki
        bayroqcha qo’yilishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          23.4
        </span>
        Shatakka olish quyidagi hollarda taqiqlanadi: <br />
        rul boshqaruvi ishlamayotgan transport vositalarini (qisman ortish usuli
        bilan shatakka olish bundan mustasno); <br />
        ikki va undan ko’prok transport vositalarini; <br />
        shatakka olingan va shatakka olgan transport vositalarining ulagich
        bilan birga umumiy uzunligi 24 metrdan ortiq bo’lsa; <br />
        to’xtatish tizimi ishlamayotgan transport vositasining haqiqiy vazni
        shatakka olgan transport vositasining haqiqiy vaznining yarmidan ortiq
        bo’lsa (haqiqiy vazni kam bo’lgan bunday transport vositasini faqat
        qattiq ulagich yordamida yoki qisman ortish usuli bilan shatakka olish
        bundan mustasno); <br />
        kajavasiz mototsikllar bilan, shuningdek, bunday mototsikllarni; <br />
        yo’l yaxmalak, sirpanchiq bo’lgan hollarda egiluvchan ulagichda.
      </p>
    </div>
  )
}

export default Comp23
