import React from 'react'

const Comp6 = () => {
  return (
    <div className="fs-5">
      <h2>6. Йўловчиларнинг вазифаси</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          6.1
        </span>
        Йўловчилар транспорт воситасига фақат у тўла тўхтагандан сўнг тротуар
        ёки йўл ёқаси томонидан чиқишга ва тушишга, мотоциклда ҳаракатланганда
        еса махсус бош кийимини тугмалашга мажбурдирлар.
        <br />
        Йўловчиларнинг транспорт воситаларидан тротуар ёки йўл ёқаси томонидан
        тушиши ёки уларга чиқишининг иложи бўлмаган ҳолларда қатнов қисмида
        амалга оширилиши мумкин, бунда бошқа йўл ҳаракати қатнашчиларига ҳалақит
        берилмаслиги ва ҳавф туғдирилмаслиги керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          6.2
        </span>
        Йўловчиларга: <br />
        транспорт воситаси ҳаракатланаётган вақтда ҳайдовчини бошқаришдан
        чалғитиш ва унга ҳалақит бериш; бортли юк автомобилларида тик туриш,
        бортлар сатҳидаги ёки ундан юқоридаги юк устида ўтириш;
        <br />
        транспорт воситаси ҳаракатланаётган вақтда унинг ешикларини очиш
        тақиқланади
      </p>
    </div>
  )
}

export default Comp6
