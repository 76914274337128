import React from 'react'

const Comp28 = () => {
  return (
     <div className='fs-5'>
 <h2>
 28. Мансабдор шахслар ва фуқароларнинг йўл ҳаракати хавфсизлигини
 таъминлаш, транспорт воситаларини йўлга чиқариш, уларга рақам ва
 таниқлик белгиларинин ўрнатиш бўйича вазифалари
 </h2>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 28.1
 </span>
 Механик транспорт воситалари ва тиркамалари, уларнинг техник ҳолатидан
 қатъи назар, харид қилинган ёки божхонада расмийлаштирилган вақтдан
 бошлаб 10 кун мобайнида ДAНда рўйхатдан ўтказилиши керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 28.2
 </span>
 Механик транспорт воситалари (трамвай, троллейбуслардан ташқари) ва
 тиркамаларнинг кўзда тутилган жойларида тегишли намунадаги рақам
 белгилари ўрнатилган бўлиши шарт. Aвтомобилда еса олд пешойнанинг
 ўнг томонидаги юқори бурчагига давлат техник кўригидан ўтганлиги
 ҳақидаги талон ҳам ўрнатилиши керак. <br />
 Юк автомобиллари, тиркамалар (енгил автомобиллар ва мотоцикллар
 тиркамаларидан ташқари) ва автобуслар (микроавтобуслардан ташқари)
 кузовининг орқа деворига унинг рақам белгиси ва ҳарфи ёзилиши керак.
 Рақамнинг баландлиги 300 мм, рақамлар кенглиги 120 мм, чизиқнинг
 йўғонлиги 30 мм, ҳарфлар баландлиги еса рақам ўлчамларининг 2/3
 қисмига тенг бўлиши лозим. <br />
 Трамвай ва троллейбусларга тегишли ташкилот томонидан рўйхатга
 олинган рақамлар ёзилади.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 28.3
 </span>
 Транспорт воситаларига қуйидаги кўринишда бўлган таниқлик белгилари
 ўрнатилиши керак: <br />
 Aвтопоезд – юк автомобиллари ва ғилдиракли трактор (1,4 тонна ва ундан
 юқори)ларда тиркама бўлганда, шунингдек, туташтирилган автобус ва
 троллейбус кабиналари томининг олд қисми ўртасига бир қатор қилиб 150
 дан 300 миллиметргача оралиқда кўндаланг жойлашган учта зарғалдок
 рангли чироқ ёки ичидан ёритиладиган, томонларининг ҳар бири 250
 миллиметр бўлган сариқ рангли учбурчак шаклидаги белги; <br />
 Турумланган (тишли, шипли) – турумланган шинали механик транспорт
 воситасининг орқасига томонлари тенг, ичига қора рангда “Т” ҳарфи
 туширилган, қизил ҳошияли оқ рангдаги учбурчак шаклидаги белги;
 <br />
 Учбурчакнинг томонлари транспорт воситасининг турига қараб 200-300
 миллиметргача, ҳошиянинг кенглиги учбурчак томонининг 1/10 қисмига
 тенг бўлиши керак. <br />
 Болалар гуруҳини ташиш – автобус ёки махсус жиҳозланган юк
 автомобилларида болалар ташилганда, транспорт воситасининг олд ва орқа
 томонларига қизил ҳошияли, ичига 1.21 йўл белгисидаги болалар
 тимсолининг тасвири туширилган, сариқ рангдаги тўртбурчак
 кўринишидаги белги; <br />
 тўртбурчакнинг томонлари 250-300 миллиметргача, ҳошиянинг кенглиги
 тўртбурчак томонининг 1/10 қисмига тенг бўлиши керак. <br />
 Кар ҳайдовчи – кар-соқов ёки кар ҳайдовчи бошқараётган транспорт
 воситасининг олд ва орқа томонларига ичига диаметри 40 миллиметр
 бўлган учта қора доира туширилган, диаметри 160 миллиметрни ташкил
 етган сариқ рангли доира шаклидаги белги; <br />
 Бошқаришни ўргатиш транспорт воситаси – бошқаришни ўргатишда
 фойдаланадиган транспорт воситаларининг олд ва орқа томонига ичига қора
 рангда “О” ҳарфи туширилган, транспорт воситасининг турига қараб
 томонлари 200 миллиметрдан 300 миллиметргача бўлган, қизил ҳошияли
 оқ рангли учбурчак шаклидаги белги; <br />
 Тезлик чекланган – жуда оғир, ҳавфли ва катта ўлчамли юкларни
 ташиётган, шунингдек, транспорт воситасининг техник тавсифномасида
 кўрсатилган енг юқори тезлиги Қоидаларнинг 12.2, 12.3 ва 12.4
 бандларида белгиланган тезликдан паст бўлган ҳолларда транспорт
 воситаси кузови орқа деворининг чап томонига 3.24 – “Юқори тезлик
 чекланган” йўл белгисининг кичрайтирилган рангли тасвири туширилган
 шаклидаги белги; <br />
 Белгининг диаметри 160 миллиметрдан кам бўлмаслиги, ҳошиясининг
 кенглиги белги диаметрининг 1/10 қисмига тенг бўлиши керак. <br />
 Хавфли юк – хавфли юкни ташиётган транспорт воситасининг олд ва орқа
 томонига ўрнатилган улчами 690×300 миллиметр, зарғалдок рангли ўнг
 қисми 400×300 миллиметр бўлган, чап қисми еса юкнинг хавфли
 ҳусусиятини билдириб турган белгилар туширилган қора ҳошияли
 (кенглиги 15 миллиметр) оқ рангдаги тўртбурчак шаклидаги белги
 (4-илова); <br />
 Катта ўлчамли юк – ичига кенглиги 50 миллиметрли, диагонал бўйича
 галма-гал жойлашган қизил ва оқ рангли чизиқлар туширилган, нур
 қайтарадиган юзали, ўлчами 400×400 миллиметрли тўртбурчак
 шаклидаги белги; <br />
 Узун ўлчамли транспорт воситаси – юкли ёки юксиз узунлиги 20
 метрдан ошадиган транспорт воситасининг, икки ёки ундан кўпроқ
 тиркамали автопоездларнинг орқасига кенглиги 40 миллиметр бўлган қизил
 ҳошияли, ўлчами 1200×200 миллиметр бўлган, таркибнинг тасвири
 туширилган сариқ рангли тўртбурчак шаклидаги белги; <br />
 кўрсатилган ўлчамли белгини жойлаштиришнинг имконияти бўлмаса,
 бир ҳил иккита 600×200 миллиметрли ўлчамдаги белгини транспорт
 воситасининг ўқига симметрик равишда ўрнатишга йўл қўйилади.
 <br />
 Фалокат сабабли тўхташ – милтилловчи қизил чироқ ва давлат стандарти
 талаблари асосидаги учбурчак шаклидаги белги. <br />
 Ҳайдовчиларнинг хоҳишига кўра қуйидаги таниқлиқ белгилари
 ўрнатилиши мумкин: <br />
 Шифокор – шифокор-ҳайдовчи бошқараётган транспорт воситасининг олд
 ва орқа томонига ўрнатиладиган, томонлари 140 миллиметрдан бўлган кўк
 рангли тўртбурчак ичида, диаметри 90 миллиметрли оқ доирага қалинлиги
 25 миллиметрли қизил хоч тасвири туширилган белги; <br />
 Ногирон – биринчи ёки иккинчи гуруҳ ногирони бошқараётган транспорт
 воситасининг олд ва орқа томонига ўрнатиладиган барча томонлари 150
 миллиметрдан бўлган ва қора рангдаги 7.17 йўл белгисининг тасвири
 туширилган сариқ рангли тўртбурчак шаклидаги белги; <br />
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 28.4
 </span>
 Механик транспорт воситаларини шатакка олишда егилувчан улагични
 кўрсатадиган огоҳлантирувчи қурилма ўлчами 200×200 миллиметр
 бўлган, диагонали бўйича кетма-кет жойлашган 50 миллиметр
 қалинликдаги қизил ва оқ рангли чизиқлар туширилган, ёруғлик
 қайтарадиган юзали байрокчалар ёки тахтачалар кўринишида
 тайёрланиши керак. <br />
 Егилувчан улагичнинг ҳар бир метрига биттадан огоҳлантирувчи қурилма
 ўрнатилади.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 28.5
 </span>
 Қаттик улагичли қурилманинг тузилиши давлат стандарти талабларига мос
 бўлиши керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 28.6
 </span>
 Қуйидаги ҳолларда транспорт воситаларидан фойдаланиш тақиқланади:
 <br />
 агар автомобиллар, автобуслар, автопоездлар, тиркамалар, мотоцикллар,
 мопедлар, тракторлар ва бошқа ўзиюрар машиналарнинг техник ҳолати ва
 жиҳозланиши 3-иловадаги транспорт воситаларидан фойдаланишни
 тақиқлайдиган шартлар рўйхатининг бирон-бир талабига жавоб берса;
 <br />
 троллейбус ва трамвайларда тегишли техник фойдаланиш қоидаларида
 кўзда тутилган носозликлардан бирортаси бўлса; <br />
 транспорт воситалари давлат техника кўригидан ўтмаган, шунингдек,
 тегишли рухсациз қайта жиҳозланган бўлса; <br />
 транспорт воситалари давлат автомобилъ назорати органларининг рухсатисиз
 милтилловчи чироқ — маёқча ва махсус товушли ишора билан
 жиҳозланган, кузовининг ён томонига ДAН билан келишмасдан қия оқ
 чизиқ тортилган, кўзда тутилмаган жойларга тегишли рақам белгилари
 ўрнатилган, агрегат ва қисмларининг рақамлари ёки рақам белгилари
 кўринмайдиган, қалбакилаштирилган, ўзгартирилган бўлса.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 28.7
 </span>
 Транспорт воситаларининг техник ҳолатига ва улардан фойдаланишга
 жавобгар бўлган мансабдор ва бошқа шахларга қуйидагилар тақиқланади:
 <br />
 техник носозлиги туфайли фойдаланиш тақиқланган, тегишли рухсациз
 қайта жиҳозланган, белгиланган тартибда рўйхатдан ўтказилмаган ёки
 давлат техника кўригидан ўтказилмаган транспорт воситаларини йўлга
 чиқариш; <br />
 йўл ҳаракати ҳавфсизлигига тахдид соладиган ҳар қандай мастлик
 (алкоголъ, гиёҳванд ва бошқалар) ҳолатида ёки сезгирлик ва еътиборни
 сусайтирадиган дори-дармонлар таъсирида, чарчоқлик ёки бетоблик
 ҳолатида, шунингдек, тегишли тоифадаги транспорт воситаларини
 бошқариш ҳуқуқи бўлмаган, белгиланган муддатда тиббий кўрикдан
 ўтмаган, гиёҳванд ёки спиртли ичимликларни сурункали истемол
 қилувчи шахс сифатида ҳисобда турган шахсларни транспорт воситасини
 бошқаришга қўйиш; <br />
 асфалът ёки цемент-бетон қопламали йўлларга занжирли трактор ва
 бошқа ўзиюрар механизмларни чиқариш.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 28.8
 </span>
 Йўллар, темир йўл кесишмалари ва бошқа йўл иншоотларининг ҳолатига
 жавобгар бўлган мансабдор ва бошқа шахслар: <br />
 йўллар, темир йўл кесишмалари ва бошқа йўл иншоотларини йўл
 ҳаракати хавфсизлигини таъминлайдиган ҳолатда сақлашга; <br />
 йўл ҳаракатига ҳалақит берадиган ҳар қандай тусқинлик вужудга келса
 уларни ўз вақтида бартараф етиш, йўлнинг айрим қисмларида улардан
 фойдаланиш йўл ҳаракати хавфсизлигига тахдид соладиган ҳолларда
 ҳаракатни чеклаш ёки тақиқлаш чораларини кўришга мажбурдирлар.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 28.9
 </span>
 Йўлларда таъмирлаш ишларини бажаришга жавобгар мансабдор ва бошқа
 шахслар ишлар олиб борилаётган жойда йўл ҳаракати хавфсизлигини
 таъминлашга мажбурдирлар. Бундай жойлар, шунингдек, йўлдан ташқарига
 олиб чиқиш имконияти бўлмаган иш бажармаётган йўл машиналари,
 қурилиш материаллари, қурилма ва шунга ўхшашлар тегишли йўл
 белгилари, йўналтирувчи ва тўсувчи мосламалар билан, сутканинг
 қоронри вақтида ва етарлича кўринмаслик шароитида еса қўшимча
 қизил ёки сариқ чироқли ишоралар билан белгиланиши керак. <br />
 Таъмирлаш ишлари тугаллангандан сўнг йўлларда транспорт воситалари
 ва пиёдаларнинг хавфсиз ҳаракатланиши таъминланган бўлиши керак
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 28.10
 </span>
 Aмалдаги қонун ҳужжатларида кўзда тутилган ҳолларда тегишли мансабдор
 ва бошқа шахслар томонидан ДAН органлари билан белгиланган тартибда
 қуйидагилар келишилади: <br />
 шаҳарларда ва автомобилъ йўлларида ҳаракатни ташкил етишни
 лойиҳалаш, йўлларни ҳаракатни ташкил етувчи техник воситалар билан
 жиҳозлаш; <br />
 йўлларни, йўл иншоотларини қуриш, қайта реконструкция қилиш ва
 таъмирлаш ишларини лойиҳалаш; <br />
 йўлларга бевосита яқинроққа кўринишни чекловчи ёки пиёдалар
 ҳаракатини қийинлаштирувчи киоскалар, транспарантлар, плакатлар,
 реклама тахталари ва шунга ўхшашларни ўрнатиш; <br />
 йўналишли транспорт воситалари йўналишлари ва бекатларининг
 жойланиши; <br />
 йўлларда оммавий, спорт ва бошқа тадбирларни ўтказиш; <br />
 транспорт воситаларини қайта жиҳозлаш, уларга махсус ёруғлик ва
 товуш ишораларини ўрнатиш, транспорт воситалари кузовининг ён
 томонига қия оқ чизиқлар тортиш; <br />
 мазкур Қоидаларнинг 26.5 бандида келтирилган транспорт воситаларининг
 ҳаракатланиши; <br />
 бошқаришни ўргатиш рухсат етилган йўллар рўйхати ва чизмаси;
 <br />
 йўлларда транспорт воситалари ва пиёдаларнинг ҳаракатига хавф
 туғдирувчи ҳар қандай ишларни олиб бориш. <br />
 </p>
 </div>
  )
}

export default Comp28
