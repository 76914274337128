import React from 'react'

const Comp4 = () => {
  const roadsigns = [
    {
      title: '4.1.1. Harakatlanish to’g’riga',
      description: '',
      ing: '1',
    },
    {
      title: '4.1.2. Harakatlanish o’ngga',
      description: '',
      ing: '2',
    },
    {
      title: '4.1.3. Harakatlanish chapga',
      description: '',
      ing: '3',
    },
    {
      title: '4.1.4. Harakatlanish to’g’riga yoki o’ngga',
      description: '',
      ing: '4',
    },
    {
      title: '4.1.5. Harakatlanish to’g’riga yoki chapga',
      description: '',
      ing: '5',
    },
    {
      title: '4.1.6. Harakatlanish o’ngga yoki chapga',
      description: 'Faqat yo’naltirgichlar buyurgan tomonga harakatlanishga ruxsat etiladi. Chapga burilishga ruxsat etuvchi belgi qayrilishga ham ruxsat beradi. (Muayan kesishmada talab etilgan harakat yo’nalishiga tegishli yo’naltirgichning shakli tushirilgan 4.1.1-4.1.6 belgisini qo’llash mumkin. 4.1.1-4.1.6 belgilarining belgilangan yo’nalishli transport vositalariga daxli yo’q (avtobus, trolleybus, marshrut taksi va hokazo). 4.1.1-4.1.6 belgilarining ta’siri qatnov qismlarining qaysi kesishmasi oldida o’rnatilsa, shu kesishmasiga tatbiq etiladi. 4.1.1 belgisi yo’l boshlanishiga o’rnatilsa, unga yaqin chorrahagacha amal qilinadi. Belgi o’ng tomonda joylashgan hovli va yo’lga tutash boshqa hududlarga burilishni taqiqlamaydi.',
      ing: '6',
    },
    {
      title: '4.2.1. To’siqni o’ngdan chetlab o’tish',
      description: '',
      ing: '7',
    },
    {
      title: '4.2.2. To’siqni chapdan chetlab o’tish',
      description: '',
      ing: '8',
    },
    {
      title: '4.2.3. To’siqni o’ngdan yoki chapdan chetlab o’tish',
      description: 'To’siqni har tomondan chetlab o’tishga ruxsat etiladi.',
      ing: '9',
    },
    {
      title: '4.3. Aylanma harakatlanish',
      description: 'Yunaltirgichlarda ko’rsatilgan yo’nalishlarda harakatlanishga ruxsat etiladi.',
      ing: '10',
    },
    {
      title: '4.4. Engil avtomobillar harakatlanadi',
      description: 'Engil transport vositalari, avtobus, mototsikllar, to’la vazni 3.5 tonnadan oshmaydigan yuk tashuvchi transport vositalarining harakatlanishiga ruxsat etiladi. Belgining ta’sir oralig’ida yashovchi va ishlovchi fuqarolarga tegishli, ularga va korxonalarga xizmat ko’rsatuvchi transport vositalariga bu belgi tatbiq etilmaydi. Bunday hollarda transport vositalari belgilangan joyga eng chorrahadan kirib yoki chiqib ketishlari kerak.',
      ing: '11',
    },
    {
      title: '4.5. Velosiped yo’lkasi',
      description: 'Faqat velosiped va mopedlarda harakatlanishga ruxsat etiladi. Velosiped yo’lkasidan piyodalar ham yurishlari mumkin.',
      ing: '12',
    },
    {
      title: '4.6. Piyodalar yo’lkasi',
      description: 'Faqat piyodalar yurishiga ruxsat etilgan.',
      ing: '13',
    },
    {
      title: '4.7. Eng kam tezlik',
      description: 'Faqat belgida ko’rsatilgan yoki undan yuqori tezlikda (km/soat) harakatlanishga ruxsat etiladi.',
      ing: '14',
    },
    {
      title: '4.8. Eng kam tezlik belgilangan yo’lning oxiri',
      description: '',
      ing: '15',
    },
    
  ]
 
  return (
    <div>
      <h2>Buyuruvchi belgilar</h2>
      <p className='fs-4'>
        Transport vositalariga ko’rsatilgan yo’nalishda yoki faqat ma’lum
        yo’nalishlarda harakatlanishlari lozimligini buyuradi.
      </p>
      <div>
        {roadsigns.map((sign: any) => (
          <div key={sign.ing}>
            <h3 className="text-center">{sign.title}</h3>
            <p className='fs-4'>{sign.description}</p>
            <img
              src={`${process.env.PUBLIC_URL}/images/belgilar/buyruq/${sign.ing}.png`}
              alt={sign.description}
              className="small-img d-block mx-auto"
            />
            <hr />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Comp4
