import React from 'react'

const Comp25 = () => {
  return (
    <div className='fs-5'>
      <h2>25. Odam tashish</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          25.1
        </span>
        Yuk avtomobilining yukxonasida odam tashish "C" toifasidagi transport
        vositalarini boshqarish huquqiga ega bo’lgan, odamlar soni
        kabinadagilarni ham qo’shib hisoblaganda 8 nafardan ortiq bo’lganda esa
        "C" va "D" toifasidagi transport vositalarini boshqarish huquqiga ega
        bo’lgan va shu toifadagi transport vositalaridan birini 3 yildan ortiq
        boshqargan haydovchilar tomonidan amalga oshirilishi kerak. <br />
        Eslatma: Harbiy haidovchilarning yuk avtomobillarda odam tashishiga yo’l
        qo’ish Mudofaa vazirligi tomonidan belgilangan tartibda amalga
        oshiriladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          25.2
        </span>
        Odam tashish uchun mo’ljallangan yuk avtomobili yukxonasi bortining
        balandligidan 30 santimetr pastda joylashgan o’rindiqlar bilan
        jihozlanishi, ikki yon va orqa bortdagi o’rindiqlar mustahkam
        suyanchiqqa ega bo’lishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          25.3
        </span>
        Tashiladigan odamlar soni jihozlangan o’rindiqlar sonidan oshmasligi
        kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          25.4
        </span>
        Harakatni boshlashdan oldin haydovchi yo’lovchilarga avtomobilga
        chiqish, undan tushish va unga joylashish tartibi hakida tushuntirish
        berishi kerak. <br />
        Haydovchi harakatni boshlashdan oldin yo’lovchilarni havfsiz tashish
        sharoiti ta’minlanganligiga ishonch hosil qilishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          25.5
        </span>
        Odam tashishga moslashtirilmagan yuk avtomobillarining yukxonasida faqat
        yukni olish uchun borayotgan yoki uni kuzatib borayotgan shaxslarning
        bo’lishiga yo’l qo’yiladi, bunda ular bortlardan pastda joylashgan
        o’rindiqlar bilan ta’minlanishlari kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          25.6
        </span>
        Avtobuslarda yoki furgon-yukxonali yuk avtomobillarida bolalarni
        tashishda katta yoshli kuzatib boruvchi shaxs, bortli yuk avtomobilining
        kuzovida tashishda esa kamida ikki nafar katta yoshli kuzatib boruvchi
        shaxs bo’lishi shart. Bunday transport vositalarining oldi va orqa
        tomoniga Bolalar guruhini tashish taniqliq belgisi o’rnatilishi kerak.{' '}
        <br />
        Bolalarni yuk avtomobillarida tashishda esa ushbu talablarga qo’shimcha
        ravishda bort balandligi 1 (bir) metrdan kam bo’lmasligi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          25.7
        </span>
        Haydovchi transport vositasi to’la to’xtagandan keyin odamlarni
        tushirishi va chiqarishi, eshiklar yopilgandan so’nggina harakatlanishni
        boshlashi va to’la to’xtamaguncha ularni ochmasligi shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          25.8
        </span>
        Odamlarni tashish quyidagi hollarda taqiqlanadi: <br />
        avtomobilning kabinasidan tashqarida (bortli yuk yoki furgon-yukxonali
        avtomobillarda odam tashishdan tashqari); <br />
        traktorlar va boshqa o’zi yurar mashinalar, yuk tashiladigan tirkamalar,
        tirkama-uycha, yuk mototsikllari yukxonasida; <br />
        mototsikllarning konstruktsiyasida ko’zda tutilgan joylaridan
        tashqarida; <br />
        12 yoshga to’lmagan bolalarni mototsiklning orqa urindig’ida, shuningdek
        engil avtomobilga bolalarni ushlab turuvchi maxsus qurilma o’rnatilmagan
        bo’lsa, uning oldingi o’rindig’ida; <br />
        12 yoshga to’lmagan bolalarni hisobga olmaganda transport vositasining
        texnik tavsifnomasida ko’zda tutilgan miqdordan ortik. <br />
        Bunda transport vositasining haqiqiy vazni uni ishlab chiqargan korxona
        belgilagan ruxsat etilgan to’la vazndan ortmasligi kerak.
      </p>
    </div>
  )
}

export default Comp25
