import React from "react";
import Comp1 from './Components2/comp1'
import Comp2 from './Components2/comp2'
import Comp3 from './Components2/comp3'
import Comp4 from './Components2/comp4'
import Comp5 from './Components2/comp5'
import Comp6 from './Components2/comp6'
import Comp7 from './Components2/comp7'
import Comp8 from './Components2/comp8'
import Comp9 from './Components2/comp9'
import Comp10 from './Components2/comp10'
import Comp11 from './Components2/comp11'
import Comp12 from './Components2/comp12'
import Comp13 from './Components2/comp13'
import Comp14 from './Components2/comp14'
import Comp15 from './Components2/comp15'
import Comp16 from './Components2/comp16'
import Comp17 from './Components2/comp17'
import Comp18 from './Components2/comp18'
import Comp19 from './Components2/comp19'
import Comp20 from './Components2/comp20'
import Comp21 from './Components2/comp21'
import Comp22 from './Components2/comp22'
import Comp23 from './Components2/comp23'
import Comp24 from './Components2/comp24'
import Comp25 from './Components2/comp25'
import Comp26 from './Components2/comp26'
import Comp27 from './Components2/comp27'
import Comp28 from './Components2/comp28'

const RenderComp = (props:any) =>
{
    const renderComp = () => {
    switch (props.id) {
      case 1:
        return <Comp1 />
      case 2:
        return <Comp2 />
      case 3:
        return <Comp3 />
      case 4:
        return <Comp4 />
      case 5:
        return <Comp5 />
      case 6:
        return <Comp6 />
      case 7:
        return <Comp7 />
      case 8:
        return <Comp8 />
      case 9:
        return <Comp9 />
      case 10:
        return <Comp10 />
      case 11:
        return <Comp11 />
      case 12:
        return <Comp12 />
      case 13:
        return <Comp13 />
      case 14:
        return <Comp14 />
      case 15:
        return <Comp15 />
      case 16:
        return <Comp16 />
      case 17:
        return <Comp17 />
      case 18:
        return <Comp18 />
      case 19:
        return <Comp19 />
      case 20:
        return <Comp20 />
      case 21:
        return <Comp21 />
      case 22:
        return <Comp22 />
      case 23:
        return <Comp23 />
      case 24:
        return <Comp24 />
      case 25:
        return <Comp25 />
      case 26:
        return <Comp26 />
      case 27:
        return <Comp27 />
      case 28:
        return <Comp28 />
    }
  }
    return <div>{ renderComp()}</div>
}

export default RenderComp;