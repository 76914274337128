import React from 'react'

const Comp26 = () => {
  return (
    <div className="fs-5">
      <h2>26. Юк ташиш</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          26.1
        </span>
        Ташилаётган юкнинг вазни ва ўқларга тушадиган оғирлик миқдорининг
        тақсимланиши транспорт воситасини ишлаб чиқарган корхона томонидан
        белгиланган миқдордан ошмаслиги керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          26.2
        </span>
        Ҳайдовчи ҳаракатни бошлашдан олдин юкнинг тўғри жойлашганлиги ва
        маҳкамланганлигига ишонч ҳосил қилиши, ҳаракатланиш вақтида еса юкнинг
        тушиб кетмаслигини ва ҳаракатланишга ҳалақит бермаслигини таъминлаш
        мақсадида унинг ҳолатини кузатиб бориши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          26.3
        </span>
        Қуйидаги шартларга амал қилинганда юк ташишга йўл қўйилади: <br />
        ҳайдовчига теварак-атроф кўринишини чекламаса; <br />
        транспорт воситасини бошқаришни қийинлаштирмаса ва мувозанатини бузмаса;{' '}
        <br />
        ташқи ёритиш асбоблари ва ёруғлик қаитаргичларини, давлат рақами
        белгиларини ва таниқлик белгиларни, шунингдек, ишораларни ва қўл билан
        бериладиган ишораларни кўришни тўсмаса; <br />
        шовқин солмаса, чанг кўтармаса йўлни ва атроф муҳитни ифлос қилмаса.
        <br />
        Aгар юкнинг жойлашуви ва ҳолати қаид етилган талабларга жавоб бермаса,
        ҳайдовчи юқорида санаб ўтилган қоидалар бузилишини бартараф етиш
        чораларини кўриши, унинг иложи бўлмаса бундан кейинги ҳаракатланишни
        тўхтатиши шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          26.4
        </span>
        Транспорт воситасининг габаритларидан олди ёки орқа томонга 1 метрдан,
        габарит чироқларининг четки қисмидан 0,4 метрдан ортиқ чиқиб турган юк
        ушбу Қоидаларнинг 28.3 бандига мувофик Катта ўлчамли юк белгиси билан
        белгиланиши, қоронғи вақтда ва етарлича кўринмайдиган шароитда еса бунга
        қўшимча қилиб олди оқ чироқ ёки ёруғлик қаитаргич, орқаси еса қизил
        чироқ ёки ёруғлик қаитаргич билан белгиланиши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          26.5
        </span>
        Ўта оғир, катта ўлчамли ва хавфли юкларни ташиш, габарит ўлчамлари юкли
        ёки юксиз ҳолда ени 2,5 метрдан ва баландлиги йўл сатҳидан 4 метрдан,
        узунлиги битта тиркама билан 20 метрдан ортиқ бўлган ёхуд юки транспорт
        воситасининг орқа нуқтасидан 2 метрдан ортиқ чиқиб турган транспорт
        воситаларининг, икки ёки ундан ортиқ тиркамали автопоездларнинг
        ҳаракатланиши учун ДAН органларининг рухсатномаси бўлиши керак.
      </p>
    </div>
  )
}

export default Comp26
