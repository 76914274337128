import React from 'react'

const Comp1 = () => {
  const roadsigns2 = [
    {
      title: '1.1. Шлагбаумли темир йўл кесишмаси',
      description: '',
      img: '1',
    },
    {
      title: '1.2. Шлагбаумсиз темир йўл кесишмаси',
      description: '',
      img: '2',
    },
    {
      title: '1.3.1. Бир изли темир йўл',
      description: '',
      img: '3',
    },
    {
      title: '1.3.2. Кўп изли темир йўл',
      description:
        'Шлагбаум билан жиҳозланмаган темир йўл кесишмаси олдида ўрнатилади. 1.3.1 – бир изли йўл, 1.3.2 – икки ва ундан ортиқ изли йўл.',
      img: '4',
    },
    {
      title: '1.4. Темир йўл кесишмасига яқинлашув',
      description:
        'Aҳоли яшамайдиган жойларда темир йўл кесишмасининг яқинлиги ҳақида қошимча огоҳлантириш. 1.4.1 – 1.4.3 белгилари йўлнинг ўнг томонига 1.4.4 – 1.4.6 белгилари йўлнинг чап томонига ўрнатилади.',
      img: '5',
    },
    {
      title: '1.5.Трамвай йўли билан кесишув',
      description: '',
      img: '6',
    },
    {
      title: '1.6. Тенг аҳамиятли йўллар кесишуви',
      description: '',
      img: '7',
    },
    {
      title: '1.7. Aйланма ҳаракатланиш билан кесишув',
      description: '',
      img: '8',
    },
    {
      title: '1.8. Светофор тартибга солади',
      description:
        'Ҳаракат светофор орқали тартибга солинган чорраҳа, пиёдалар ўтиш жойи ёки йўл қисми.',
      img: '9',
    },
    {
      title: '1.9. Кўтарма кўприк',
      description: 'ўтарма кўприк ёки солда кесиб ўтиш.',
      img: '10',
    },
    {
      title: '1.10. Соҳилга чиқиш',
      description: 'арё ойки сув ҳавзаси қирғоғига чиқиш.',
      img: '11',
    },
    {
      title: '1.11. Хавфли бурилиш',
      description:
        'Йўлнинг кичик радиусли ёки кўриниш чекланган бурилиш жойи: 1.11.1 – ўнга, 1.11.2 – чапга.',
      img: '12',
    },
    {
      title: '1.12. Хавфли бурилишлар',
      description:
        'Йўлнинг ҳавфли бурилишлари бўлган қисми. 1.12.1 – биринчи бурилиш ўнга, 1.12.2 – биринчи бурилиш чапга.',
      img: '13',
    },
    {
      title: '1.13. Тик нишаблик',
      description: '',
      img: '14',
    },
    {
      title: '1.14. Тик баландлик',
      description: '',
      img: '15',
    },
    {
      title: '1.15. Сирпанчиқ йўл',
      description: 'ўлнинг ўта сирпанчиқ бўлган қисми.',
      img: '16',
    },
    {
      title: '1.16. Нотекис йўл',
      description:
        'Қатнов қисми нотекис бўлган йўл (ўнқир-чўнқир, ўйдим-чуқур жойлар, кўприкнинг йўлга нотекис туташуви ва шу кабилар).',
      img: '17',
    },
    {
      title: '1.17. Тош отилиш хавфи',
      description:
        'Мукаммал қопламали йўл қисмида транспорт воситаси ғилдираги остидан шағал, тош ва шунга ўхшашларнинг отилиб чиқиш еҳтимоли.',
      img: '18',
    },
    {
      title: '1.18.Йўлнинг торайиши',
      description:
        '1.18.1 икки томонлама торайиш; 1.18.2 – ўнга торайиш ва 1.18.3 – чапга торайиш.',
      img: '19',
    },
    {
      title: '1.19. Икки томонлама ҳаракатланиш',
      description: 'Йўлнинг қарама-қарши ҳаракатланиш қисмининг бошланиши.',
      img: '20',
    },
    {
      title: '1.20. Пиёдалар ўтиш жойи',
      description:
        '5.16.1, 5.16.2 Белгилари ва (ёки) 1.14.1 – 1.14.3 чизиқлари билан белгиланган пиёдалар ўтиш жойи.',
      img: '21',
    },
    {
      title: '1.21. Болалар',
      description:
        'Болалар муассасаси (мактаблар, болаларнинг дам олиш манзиллари ва шунга ўхшашлар) га яқин йўлнинг қатнов қисмидан болаларнинг чиқиб қолиш еҳтимоли.',
      img: '22',
    },
    {
      title: '1.22. Велосипед йўлкаси билан кесишув',
      description: '',
      img: '23',
    },
    {
      title: '1.23. Таъмирлаш ишлари',
      description: '',
      img: '24',
    },
    {
      title: '1.24. Мол ҳайдаб ўтиш',
      description: '',
      img: '25',
    },
    {
      title: '1.25. Ёввойи ҳайвонлар',
      description: '',
      img: '26',
    },
    {
      title: '1.26. Тошлар тушиши',
      description: '',
      img: '27',
    },
    {
      title: '1.27. Ёнлама шамол',
      description: '',
      img: '28',
    },
    {
      title: '1.28. Пастлаб учувчи самолётлар',
      description: '',
      img: '29',
    },
    {
      title: '1.29. Тоннел',
      description:
        'Сунъий равишда ёритилмаган ёки кираверишнинг пештоғининг кўриниши чекланган тоннел.',
      img: '30',
    },
    {
      title: '1.30. Бошқа хавф-хатар',
      description:
        'Огоҳлантурувчи белгиларда кўзда тутилмаган хавф хатарлар бўлган йўл қисми.',
      img: '31',
    },
    {
      title: '1.31.1, 1.31.2 Бурилишнинг йўналиши',
      description:
        'Кичик радиусда қурилган, кўриниши чекланган йўлда ҳаракатланиш йўналиши. Йўлнинг таъмирланаётган қисмини айланиб ўтиш йўналиши.',
      img: '32',
    },
    {
      title: '1.31.3. Бурилишнинг йўналиши',
      description:
        'Т симон чорраҳада ёки йўл айрилишларида ҳаракатланиш йўналиши. Йўлнинг таъмирланаётган қисмини айланиб ўтиш йўналиши.',
      img: '33',
    },
  ]
  const roadsigns = [
    {
      title: '1.1. Shlagbaumli temir yo’l kesishmasi',
      description: '',
      img: '1',
    },
    {
      title: '1.2. Shlagbaumsiz temir yo’l kesishmasi',
      description: '',
      img: '2',
    },
    {
      title: '1.3.1. Bir izli temir yo’l',
      description: '',
      img: '3',
    },
    {
      title: '1.3.2. Ko’p izli temir yo’l',
      description:
        'Shlagbaum bilan jihozlanmagan temir yo’l kesishmasi oldida o’rnatiladi. 1.3.1 – bir izli yo’l, 1.3.2 – ikki va undan ortiq izli yo’l.',
      img: '4',
    },
    {
      title: '1.4. Temir yo’l kesishmasiga yaqinlashuv',
      description:
        'Aholi yashamaydigan joylarda temir yo’l kesishmasining yaqinligi haqida qoshimcha ogohlantirish. 1.4.1 – 1.4.3 belgilari yo’lning o’ng tomoniga 1.4.4 – 1.4.6 belgilari yo’lning chap tomoniga o’rnatiladi.',
      img: '5',
    },
    {
      title: '1.5.Tramvay yo’li bilan kesishuv',
      description: '',
      img: '6',
    },
    {
      title: '1.6. Teng ahamiyatli yo’llar kesishuvi',
      description: '',
      img: '7',
    },
    {
      title: '1.7. Aylanma harakatlanish bilan kesishuv',
      description: '',
      img: '8',
    },
    {
      title: '1.8. Svetofor tartibga soladi',
      description:
        'Harakat svetofor orqali tartibga solingan chorraha, piyodalar o’tish joyi yoki yo’l qismi.',
      img: '9',
    },
    {
      title: '1.9. Ko’tarma ko’prik',
      description: 'Ko’tarma ko’prik yoki solda kesib o’tish.',
      img: '10',
    },
    {
      title: '1.10. Sohilga chiqish',
      description: 'Daryo oyki suv havzasi qirg’og’iga chiqish.',
      img: '11',
    },
    {
      title: '1.11. Xavfli burilish',
      description:
        'Yo’lning kichik radiusli yoki ko’rinish cheklangan burilish joyi: 1.11.1 – o’nga, 1.11.2 – chapga.',
      img: '12',
    },
    {
      title: '1.12. Xavfli burilishlar',
      description:
        'Yo’lning havfli burilishlari bo’lgan qismi. 1.12.1 – birinchi burilish o’nga, 1.12.2 – birinchi burilish chapga.',
      img: '13',
    },
    {
      title: '1.13. Tik nishablik',
      description: '',
      img: '14',
    },
    {
      title: '1.14. Tik balandlik',
      description: '',
      img: '15',
    },
    {
      title: '1.15. Sirpanchiq yo’l',
      description: 'Yo’lning o’ta sirpanchiq bo’lgan qismi.',
      img: '16',
    },
    {
      title: '1.16. Notekis yo’l',
      description:
        'Qatnov qismi notekis bo’lgan yo’l (o’nqir-cho’nqir, o’ydim-chuqur joylar, ko’prikning yo’lga notekis tutashuvi va shu kabilar).',
      img: '17',
    },
    {
      title: '1.17. Tosh otilish xavfi',
      description:
        'Mukammal qoplamali yo’l qismida transport vositasi g’ildiragi ostidan shag’al, tosh va shunga o’xshashlarning otilib chiqish ehtimoli.',
      img: '18',
    },
    {
      title: '1.18.Yo’lning torayishi',
      description:
        '1.18.1 ikki tomonlama torayish; 1.18.2 – o’nga torayish va 1.18.3 – chapga torayish.',
      img: '19',
    },
    {
      title: '1.19. Ikki tomonlama harakatlanish',
      description:
        'Yo’lning qarama-qarshi harakatlanish qismining boshlanishi.',
      img: '20',
    },
    {
      title: '1.20. Piyodalar o’tish joyi',
      description:
        '5.16.1, 5.16.2 Belgilari va (yoki) 1.14.1 – 1.14.3 chiziqlari bilan belgilangan piyodalar o’tish joyi.',
      img: '21',
    },
    {
      title: '1.21. Bolalar',
      description:
        'Bolalar muassasasi (maktablar, bolalarning dam olish manzillari va shunga o’xshashlar) ga yaqin yo’lning qatnov qismidan bolalarning chiqib qolish ehtimoli.',
      img: '22',
    },
    {
      title: '1.22. Velosiped yo’lkasi bilan kesishuv',
      description: '',
      img: '23',
    },
    {
      title: '1.23. Ta’mirlash ishlari',
      description: '',
      img: '24',
    },
    {
      title: '1.24. Mol haydab o’tish',
      description: '',
      img: '25',
    },
    {
      title: '1.25. Yovvoyi hayvonlar',
      description: '',
      img: '26',
    },
    {
      title: '1.26. Toshlar tushishi',
      description: '',
      img: '27',
    },
    {
      title: '1.27. Yonlama shamol',
      description: '',
      img: '28',
    },
    {
      title: '1.28. Pastlab uchuvchi samolyotlar',
      description: '',
      img: '29',
    },
    {
      title: '1.29. Tonnel',
      description:
        'Sun’iy ravishda yoritilmagan yoki kiraverishning peshtog’ining ko’rinishi cheklangan tonnel.',
      img: '30',
    },
    {
      title: '1.30. Boshqa xavf-xatar',
      description:
        'Ogohlanturuvchi belgilarda ko’zda tutilmagan xavf xatarlar bo’lgan yo’l qismi.',
      img: '31',
    },
    {
      title: '1.31.1, 1.31.2 Burilishning yo’nalishi',
      description:
        'Kichik radiusda qurilgan, ko’rinishi cheklangan yo’lda harakatlanish yo’nalishi. Yo’lning ta’mirlanayotgan qismini aylanib o’tish yo’nalishi.',
      img: '32',
    },
    {
      title: '1.31.3. Burilishning yo’nalishi',
      description:
        'T simon chorrahada yoki yo’l ayrilishlarida harakatlanish yo’nalishi. Yo’lning ta’mirlanayotgan qismini aylanib o’tish yo’nalishi.',
      img: '33',
    },
  ]
  const imgSize = (sign: any) => {
    if (
      +sign.img === 5 ||
      +sign.img === 12 ||
      +sign.img === 13 ||
      +sign.img === 19 ||
      +sign.img === 32
    ) {
      return (
        <img
          src={`${process.env.PUBLIC_URL}/images/belgilar/ogoh/${sign.img}.png`}
          className="big-img mx-auto d-block"
          alt={sign.description}
        />
      )
    } else {
      return (
        <img
          src={`${process.env.PUBLIC_URL}/images/belgilar/ogoh/${sign.img}.png`}
          className="small-img d-block mx-auto"
          alt={sign.description}
        />
      )
    }
  }
  return (
    <div>
      <h2>Ogohlanturuvchi belgilar</h2>
      <p className="fs-4">
        Ogohlantiruvchi belgilar yo'l harakati qatnashchilariga yo'lning
        harakatlanishda vaziyatga qarab chora ko'rishni talab qiladigan xavfli
        qismlarining joylashuvi hamda ularning xususiyatlari to'g'risida
        ma'lumot berish uchun qo'llaniladi.
      </p>
      <div>
        {roadsigns.map((sign: any) => (
          <div key={sign.img}>
            <h3 className="text-center">{sign.title}</h3>
            <p className="fs-4">{sign.description}</p>
            {imgSize(sign)}
            <hr />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Comp1
