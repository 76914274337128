import React from 'react'

const Comp8 = () => {
  return (
    <div className='fs-5'>
      <h2>8. Svetofor va tartibga soluvchining ishoralari</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.1
        </span>
        Svetoforlarda yashil, sariq, qizil va oq rangli yorug’lik ishoralari
        qo’llaniladi. <br />
        Svetofor ishoralari vazifasiga bog’lik ravishda doirasimon,
        yo’naltirgich, piyoda tasviri tushirilgan va X-simon ko’rinishda
        bo’lishi mumkin. <br />
        Doira shaklidagi ishorali svetoforlarda yashil doirali ishoralar bilan
        bir sathda joylashgan yashil yo’naltirgich ko’rinishdagi ishorali bitta
        yoki ikkita qo’shimcha tarmoq bo’lishi mumkin.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.2
        </span>
        Svetoforning doirali ishoralari quyidagi ma’noni bildiradi: <br />
        yashil ishora harakatlanishga ruxsat beradi; miltillovchi yashil ishora
        ham harakatlanishga ruxsat beradi va uning ta’sir vaqti tugashi hamda
        ko’p o’tmay taqiqlovchi ishora yonishi haqida axborot beradi; <br />
        yashil ishora o’chishiga soniyalar (sekundlar)da qancha vaqt qolganligi
        haqida axborot berish uchun raqamli tablo qo’llanilishi mumkin; <br />
        sariq ishora harakatni taqiqlaydi (faqat Qoidalarning 8.13 bandida
        ko’zda tutilgandan tashqari) va ishoralar almashuvi haqida
        ogohlantiradi; <br />
        miltillovchi sariq ishora harakatlanishga ruxsat beradi va tartibga
        solinmagan chorraha yoki piyodalar o’tish joyi borligidan xabardor
        qiladi, xavf-xatar haqida ogohlantiradi; <br />
        qizil ishora, shuningdek, miltillovchi qizil ishora harakatlanishni
        taqiqlaydi; <br />
        qizil va sariq ishoralarning bir vaqtda yonishi harakatlanishni
        ta’qiqlaydi va ko’p o’tmay yashil ishora yonishi haqida ahborot beradi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.3
        </span>
        Yunaltirgich ko’rinishidagi qizil, sariq va yashil rangli svetofor
        ishoralari ham doira shaklidagi svetofor ishoralari bilan bir xil
        ma’noga ega bo’ladi. Ular faqat ko’rsatilgan yo’nalishga ta’sir etadi.{' '}
        <br />
        Agar tegishli yo’l belgisi bilan taqiqlanmagan bo’lsa, chapga burilishga
        ruxsat beruvchi yunaltirgich qayrilib olishga ham ruxsat beradi.
        Qo’shimcha tarmoqdagi yashil yo’naltirgich ham huddi shu ma’noni
        bildiradi. <br />
        Qo’shimcha tarmoqdagi ishora o’chirilgan bo’lsa, shu tarmoq tartibga
        solayotgan yo’nalishda harakatlanish taqiqlanganligini bildiradi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.4
        </span>
        Agar svetoforning asosiy yashil ishorasiga qora yo’naltirgich
        (yo’naltirgichlar)ning shakli tushirilgan bo’lsa, u haydovchilarga
        svetoforning qo’shimcha tarmog’i borligi hakida axborot beradi va shu
        asosiy tarmoqdagi yo’naltirgichlar yo’nalishlarida harakatlanishga
        ruxsat berilganligini ko’rsatadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.5
        </span>
        Agar svetofor ishorasi piyoda tasviri ko’rinishida bo’lsa, u faqat
        piyodalarning harakatiga ta’sir ko’rsatadi. Bunda yashil ishora
        piyodalarning harakatlanishiga ruxsat beradi, qizil ishora esa
        taqiqlaydi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.6
        </span>
        Qatnov qismidagi harakat yo’nalishi qarama-qarshi tomonga o’zgarishi
        mumkin bo’lgan bo’laklarida transport vositalarining harakatini tartibga
        solish uchun X-simon kizil ishorali va pastga yo’nalgan yo’naltirgich
        ko’rinishdagi yashil ishorali reversiv svetoforlar qo’llaniladi. Bu
        ishoralar qaysi bo’lak ustiga o’rnatilgan bo’lsa, unda harakatlanishni
        taqiqlaydi yoki ruxsat beradi. Ikki tomoni 1.9 chizig’i bilan
        belgilangan bo’lak ustiga o’rnatilgan ishoralari o’chirilgan reversiv
        svetofor shu bo’lakka kirishni taqiqlaydi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.7
        </span>
        Tramvaylarning, shuningdek, belgilangan yo’nalishli boshqa transport
        vositalarining harakatlanishini, ular uchun ajratilgan maxsus bo’lakda
        tartibga solish uchun T-harfi ko’rinishida joylashgan to’rtta doirasimon
        oq rangli ishorali svetoforlar qo’llanilishi mumkin. <br />
        Yuqoridagi bir yoki bir nechta va pastki ishora bir vaqtning o’zida
        yonganda, harakatlanishga ruxsat etiladi. Ulardan chapdagisi chapga,
        o’ngdagisi o’ng tomonga burilishga, o’rtasidagi to’g’riga yurishga
        ruxsat beradi. Agar faqat yuqoridagi uchta ishora bir vaqtda yonsa,
        harakatlanish taqiqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.8
        </span>
        Tramvaylarning, shuningdek, belgilangan yo’nalishli boshqa transport
        vositalarining harakatlanishini, ular uchun ajratilgan maxsus bo’lakda
        tartibga solish uchun T-harfi ko’rinishida joylashgan to’rtta doirasimon
        oq rangli ishorali svetoforlar qo’llanilishi mumkin. <br />
        Yuqoridagi bir yoki bir nechta va pastki ishora bir vaqtning o’zida
        yonganda, harakatlanishga ruxsat etiladi. Ulardan chapdagisi chapga,
        o’ngdagisi o’ng tomonga burilishga, o’rtasidagi to’g’riga yurishga
        ruxsat beradi. Agar faqat yuqoridagi uchta ishora bir vaqtda yonsa,
        harakatlanish taqiqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.9
        </span>
        Tartibga soluvchining ishoralari quyidagi ma’noni bildiradi: <br />
        QO’LLARI YON TOMONGA UZATILGANDA YOKI TUSHIRILGANDA, chap va o’ng yon
        tomonidan tramvayga to’g’riga, rel’ssiz transport vositalariga to’g’riga
        va o’ngga harakatlanishga, piyodalarga qatnov qismini kesib o’tishga
        ruxsat beriladi. Oldi va orqa tomonidan barcha transport vositalari va
        piyodalarning harakatlanishi taqiqlanadi; <br />
        O’NG QO’LI OLDINGA UZATILGANDA, chap yonidan tramvayga chapga, rel’ssiz
        transport vositalariga barcha yo’nalishlarda, oldi tomonidan barcha
        transport vositalariga faqat o’ngga harakatlanishga ruxsat beriladi.
        Orqa tomonidan va o’ng yonidan barcha transport vositalarining
        harakatlanishi taqiqlanadi. Piyodalarga tartibga soluvchining orqa
        tomonidan qatnov qismini kesib o’tishga ruxsat etiladi; <br />
        QO’LINI YUQORIGA KO’TARGANDA, Qoidalarning 8.13 bandida ko’rsatilganidan
        boshqa hollarda transport vositalari va piyodalarning barcha
        yo’nalishlarda harakatlanishi taqiqlanadi. <br />
        Tartibga soluvchi haydovchi va piyodalarga tushunarli bo’lgan boshqa
        ishoralarni ham berishi mumkin. Ishoralar yaxshi ko’rinishi uchun
        tartibga soluvchi zhezl yoki yorug’lik kaytaradigan qizil rangli
        moslamalarni qo’llashi mumkin.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.10
        </span>
        Harakatni tartibga solayotgan yoki xizmat burchini o’tayotgan militsiya
        xodimi transport vositasi haydovchisiga qarata qo’l ishorasi yoki
        ovozkuchaytirgichli qurilma orqali transport vositasini to’xtatishni
        talab qilishi mumkin. Haydovchi esa ko’rsatilgan joyda to’xtashi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.11
        </span>
        Yo’l harakati qatnashchilarining e’tiborini jalb etish uchun hushtak
        bilan qo’shimcha ishora berilishi mumkin.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.12
        </span>
        Svetofor (reversivdan tashqari)ning yoki tartibga soluvchining
        taqiqlovchi ishorasida haydovchilar transport vositalarini to’xhtash
        chizig’i (5.33 belgisi) oldida, bunday chiziq bo’lmaganda esa: <br />
        chorrahada Qoidalarning 15.7 bandini hisobga olgan holda, piyodalarga
        halaqit bermasdan qatnov qismlari kesishmasi oldida; <br />
        temir yo’l kesishmasi oldida Qoidalarning 17.4 bandiga muvofiq; <br />
        boshqa joylarda svetofor yoki tartibga soluvchining oldida
        harakatlanishi ruxsat berilgan transport vositalari va piyodalarga
        halaqit bermagan holda to’xtashi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.13
        </span>
        Sariq ishora yonganda yoki tartibga soluvchining qo’li yuqori ko’tarilganda, Qoidalarning 8.12 bandida ko’zda tutilgan joylarda keskin tormoz bermasdan to’xtashga ulgira olmaydigan transport vositalarining haydovchilariga harakatni davom ettirishga ruxsat etiladi. <br />
Ushbu ishoralar berilganda, qatnov qismida bo’lgan piyodalar uni bo’shatishlari, agar buning iloji bo’lmasa, qarama-qarshi yo’nalishlardagi transport oqimlarini ajratuvchi chiziqda to’xtashlari kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.14
        </span>
        Haydovchilar va piyodalar tartibga soluvchining ishorasi va ko’rsatmalariga ular svetofor ishoralari, yo’l belgilari va chiziqlariga zid kelgan taqdirda ham amal qilishlari kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          8.15
        </span>
        Temir yo’l kesishmalarida svetoforning miltillovchi qizil ishorasi bilan bir vaqtda piyodalarning kesishma orqali harakatlanishi taqiqlanganligi to’g’risida qo’shimcha axborot sifatida tovushli ishora ham berilishi mumkin.
      </p>
    </div>
  )
}

export default Comp8
