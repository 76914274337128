import React from 'react';
import Home from './home/home2';
import Starttest from './Autotest/Starttest/Starttest';
import Marafon from './Autotest/Marafon/marafon2';
import Shablon from './Autotest/Learntest/shablon';
import Shablonlar from './Autotest/Learntest/shablonlar';
import UzYhq from './YHQ/UzYHQ/uzyhq';
import { BrowserRouter,  Route } from 'react-router-dom';
import Test from './test';

function App ()
{
  return (
      <BrowserRouter>
        <Route path="/" exact component={Home}></Route>
        <Route path="/starttest" exact component={Starttest}></Route>
        <Route path="/marafon" exact component={Marafon}></Route>
        <Route path="/learntests" exact component={Shablonlar}></Route>
        <Route path="/learntests/:id" exact component={Shablon}></Route>
        <Route path="/learnyhq" exact component={UzYhq}></Route>
        <Route path="/test" exact component={Test}></Route>
      </BrowserRouter>
  );
}

export default App;
