import React from 'react'

const Comp3 = () => {
  return (
    <div className="fs-5">
      <h2>3. Ҳайдовчиларнинг умумий мажбуриятлари.</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          3.1
        </span>
        Механик транспорт воситасининг ҳайдовчиси: ҳайдовчилик гувоҳномаси ва
        унинг талонини ёки транспорт воситасини вақтинчалик бошқариш ҳуқуқини
        берувчи талонни, ҳайдовчилик гувоҳномаси белгиланган тартибда олиб
        қўйилган бўлса, унинг талонини, ҳайдовчилик гувоҳномаси ва унинг талони
        олиб қўйилган ҳолларда вақтинчалик рухсатномани; вақтинчалик бошқариш
        ҳуқуқини берувчи талон ёки вақтинчалик руҳсатнома билан транспорт
        воситасини бошқаришда еса ҳайдовчининг шахсини тасдиқловчи ҳужжатни
        (фуқаролик паспорти ва ҳ.к.); <br />
        транспорт воситаси рўйхатдан ўтказилгани ҳақидаги ҳужжатни, транспорт
        воситасининг егаси бўлмаган ҳолларда еса умумий егалик ҳуқуқини берувчи,
        тасарруфга ёки фойдаланишга берилганлигини тасдиқловчи ҳужжатни,
        белгиланга ҳолларда йўл варақаси ва ташилаётган юкнинг ҳужжатларини
        ёнида олиб юриши, милиция ходимлари талаб қилганда текшириш учун бериши
        шартдир.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          3.2
        </span>
        Тегишли тоифадаги ҳайдовчилик гувоҳномасига ега бўлган шахс ўзига
        қарашли бўлмаган транспорт воситасини, унинг егаси, ёхуд тасарруфга ёки
        фойдаланишга берилганлигини тасдиқловчи ҳужжатлари бўлган шахс
        иштироқидагина (уларнинг розилиги билан) бошқариши мумкин.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          3.3
        </span>
        Мотоциклда ҳаракатланаётганда ҳайдовчи махсус бош кийимини кийиб олиши,
        тугмалаши, йўловчилар ҳам махсус бош кийимини кийган ва у тугмаланган
        ҳолда бўлиши шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          3.4
        </span>
        Халқаро йўллардаги ҳаракатланишда иштироқ етаётган механик транспорт
        воситаси ҳайдовчисининг ёнида “Йўл ҳаракати ҳақидаги” Конвенция
        таларларига мос келадиган ҳайдовчилик гувоҳномаси ва транспорт
        воситасининг рўйхатдан ўтганлиги ҳақидаги ҳужжат, транспорт воситасида
        еса рўйхатдан ўтган давлатнинг рақамли ва таниқлик белгиси бўлиши шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          3.5
        </span>
        Транспорт воситасининг ҳайдовчиси қуйидагиларга амал қилишга мажбурдир:{' '}
        <br />
        ҳаракатланишдан олдин транспорт воситасининг техник созлигини,
        тозалигини ва тўлиқ жиҳозланганлигини текшириши; <br />
        ҳаракатланиш вақтида транспорт воситасининг техник ҳолатини соз ҳолда
        бўлишини таъминлаши; <br />
        ҳаракатланишни бошлашдан олдин йўловчиларнинг транспорт воситасига тўлиқ
        чиқиб жойларини егаллаганлигига, транспорт воситасининг ешиклари
        ёпилганлигига, йўловчилар ташиш учун махсус жиҳозланган юк
        автомобилларида йўловчилар ташиётганда ушбу Қоидаларнинг 6.2 банди
        талаблари бажарилганлигига ишонч ҳосил қилиши; <br />
        ҳаракатланиш жараёнида пиёдалар, айниқса болалар, ногиронлар, қарияларга
        ва велосипедчиларга нисбатан еҳтиёткорлик чораларини кўриши; <br />
        милиция ходимларининг талабига кўра мастлик ҳолатини аниқловчи махсус
        текширувдан ўтиши; йўл-транспорт ҳодисаси туфайли шикастланган транспорт
        воситасини шатакка олиш (фақат юк автомобилида), табиий офатлар содир
        бўлган жойларга етиб бориш, шунингдек, кечиктириб бўлмайдиган хизмат
        фазифаларини бажариш зарурати туғилган ҳолларда милиция ходимларига,
        йўлакай йўналишда тиббий ёрдам кўрсатишга кетаётган тиббиёт ходимларига,
        зудлик билан тиббий ёрдамга мухтож бўлган фуқароларни даволаш
        муассасасига олиб бориш учун тиббиёт ва милиция ходимларига, милициянинг
        штатдан ташқари ходимларига ва жамоатчи автоназоратчиларга транспорт
        воситасидан фойдаланишга имкон бериши. <br />
        Еслатма: тиббий ҳизматлар учун транспорт воситасидан фойдаланишга имкон
        беришдан бошқа ҳоллар фуқароларга тегишли транспорт воситаларига
        тааллуқли емас. <br />
        Транспорт воситасидан фойдаланган шахс ҳайдовчи талаб қилганда йўл
        варақасига юрилган вақтни, босиб ўтилган масофани, ўз исми шарифи,
        мансаби, иш жойи номини, йўл варақаси бўлмаган тақдирда тегишли
        намунадаги маълумотнома ёзиб бериши лозим. <br />
        Транспорт воситаларини текшириш ёки ундан фойдаланиш ҳуқуқига ега бўлган
        шахс ҳайдовчининг талабига мувофик ўз хизмат гувоҳномасини кўрсатиши
        шарт.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          3.6
        </span>
        Ҳайдовчиларга қуйидагилар тақиқланади: <br />
        транспорт воситасини ҳар қандай мастлик (алкоголъ, гиёҳванд модда ва
        бошқалар) ҳолатида, сезгирлик ва еътиборни сусайтирадиган дори-дармонлар
        таъсирида, йўл ҳаракати ҳавфсизлигига таҳдид соладиган даражадаги
        чарчоқлик ва бетобликда бошқариш; <br />
        ҳар қандай мастлик ҳолатида сезгирлик ва еътиборни сусайтирадиган
        дори-дармонлар таъсирида, йўл ҳаракати ҳавфсизлигига таҳдид соладиган
        даражада чарчоқлик ва бетобликда бўлган, шунингдек, транспорт воситасини
        бошқариш ҳуқуқини берадиган тегишли тоифадаги гувоҳномаси бўлмаган ёки
        йўл варақасида исми-шарифи кўрсатилмаган шахсларга транспорт воситасини
        бошқариш учун топшириш; <br />
        техник носоз транспорт воситасини, мопед, мотороллер ва мотоциклларни
        товуш пасайтиргичсиз бошқариш; <br />
        транспорт воситасининг тормоз тизими ёки бошқаруви ишдан чиқса, улагич
        мосламаси (автопоезд таркибида) носоз бўлса, коронғи вақтда ёки етарлича
        кўринмаслик шароитида – фаралар ва орқа габарит чироклари ёнмаса, ёмғир,
        қор ёғаётган вақтда еса ҳайдовчи томонидаги ойна тозалагич ишламай
        қолса, бундай транспорт воситаларида ҳаракатланиш; <br />
        Еслатма: ишчи тормоз тизими ёки бошқаруви секин ҳаракатланаётган
        транспорт воситасини тўҳташи ёки манёвр қилишига имкон бермаса, улар
        ишламаётган ҳисобланади. <br />
        транспорт воситаларига тегишли руҳсациз маҳсус техник жиҳозлар (рация,
        сирена ва ҳ.к.)ни ўрнатиш; <br />
        транспорт воситаларининг ташкилий жамланмаси (шунингдек, пиёдалар
        жамланмалари) ҳаракатига халақит бериш ва улар орасига кириш.
      </p>
    </div>
  )
}

export default Comp3
