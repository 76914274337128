import React from 'react'

const Comp14 = () => {
  return (
    <div className='fs-5'>
      <h2>14. To'xtash va to'xtab turish</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.1
        </span>
        Transport vositalariga yo’lning o’ng tomonidagi yoqasida, u bo’lmaganda
        esa qatnov qismining chetida to’xtash va to’xtab turishga ruxsat
        beriladi. <br />
        Aholi yashaydigan joylarda yo’llarning chap tomonida to’xtash va to’xtab
        turishga har yo’nalishda kamida bittadan harakatlanish bo’lagi bo’lgan
        va o’rtada tramvay izi bo’lmagan hamda harakat faqat bir tomonlama
        bo’lgan yo’llarda ruxsat etiladi. <br />
        Ruxsat etilgan to’la vazni 3,5 tonnadan ko’p bo’lgan yuk avtomobillari
        harakat bir tomonlama bo’lgan yo’llarning chap tomonida faqat yuk ortish
        va tushirish uchun to’xtashlari mumkin.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.2
        </span>
        Transport vositalarini qatnov qismining cheti bo’ylab bir qator qilib,
        kajavasi bo’lmagan ikki g’ildirakli transport vositalarini esa ikki
        qator qilib qo’yishga ruxsat etiladi. <br />
        Yo’lning qatnov qismi kengaygan ayrim joylarida boshqa yo’l harakati
        qatnashchilariga halaqit bermaslik sharti bilan transport vositalarini
        boshqacha tartibda ham qo’yishga ruxsat etiladi. <br />
        Piyodalar harakatiga halaqit bermaslik sharti bilan qatnov qismiga
        chegaradosh trotuar chetida faqat engil avtomobillar, mototsikllar,
        mopedlar va velosipedlarga to’xtab turishga ruxsat etiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.3
        </span>
        Aholi yashaydigan joylardan tashqarida tunab qolish, dam olish yoki
        boshqa maqsadlarda to’xtab turish faqat buning uchun ko’zda tutilgan
        maydonchalarda yoki yo’ldan tashkarida ruxsat etiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.4
        </span>
        Quyidagi joylarda to’xtash taqiqlanadi: <br />
        tramvay yo’llarida, shuningdek, bevosita ularga yaqinroqda tramvaylar
        harakatlanishiga halaqit beradigan bo’lsa; <br />
        tonnellarda, temir yo’l kesishmalarida; <br />
        bir yo’nalishda harakatlanish uchun uchtadan kam bo’lagi bo’lgan
        ko’priklar, osma yo’l (estakada)lar, osma ko’priklar va ularning ostida;{' '}
        <br />
        to’xtagan transport vositasi bilan sidirg’a chiziq (qatnov qismining
        chetini belgilagan chiziqdan tashqari) orasidagi masofa 3 metrdan kam
        bo’lgan joylarda; <br />
        piyodalar o’tish joylarida va ularga 5 metrdan kamroq masofa qolganda;{' '}
        <br />
        qatnov qismining havfli burilishlarida; <br />
        loaqal bir yo’nalishdagi ko’rinish 100 metrdan kam bo’lgan yo’l
        do’ngliklari yaqinida; <br />
        qatnov qismlari kesishmalarida va kesilayotgan qatnov qismi chetiga 5
        metrdan kamroq masofa qolganda (qarama-qarshi harakat yo’nalishlari
        sidirg’a chiziq yoki ajratuvchi bo’lak bilan ajratilgan yo’lda, unga
        yondan tutashgan yo’lning qarama-qarshi tomoni bundan mustasno); <br />
        bekat maydonchalariga, ular bo’lmaganda esa harakatga halaqit beradigan
        bo’lsa, belgilangan yo’nalishdagi transport vositalari yoki taksining
        to’xtash joyi ko’rsatkichlariga 15 metr qolganda; <br />
        transport vositasi svetofor ishoralari, yo’l belgilarini boshqa
        haydovchilardan to’sib qo’yadigan yoki boshqa transport vositalarining
        harakatlanishiga (kirish va chiqishga) imkon qoldirmaydigan va
        piyodalarning harakatiga halaqit beradigan joylarda; <br />
        “To’xtash taqiqlangan” yo’l belgisi (3.27) hamda chizig’i (1.4)ning
        ta’sirlari doirasida.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.5
        </span>
        To’xtab turish quyidagi joylarda taqiqlanadi: <br />
        to’xtash taqiqlangan joylarda; <br />
        aholi yashaydigan joylardan tashqarida 2.1 belgisi bilan belgilangan
        yo’llarning qatnov qismida; <br />
        temir yo’l kesishmalariga 50 metr qolganda; <br />
        to’xtash va to’xtab turishni taqiqlovchi yo’l belgilari (3.27–3.30) va
        chiziqlari (1.4, 1.10) ta’sirlari doirasida.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.6
        </span>
        To’xtash taqiqlangan joyda majburiy to’xtagan haydovchi transport
        vositasini bu joydan olib ketishning barcha choralarini ko’rishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.7
        </span>
        Agar boshqa yo’l harakati qatnashchilarning harakatiga halaqit beradigan
        bo’lsa yoki havf tug’dirsa, transport vositasining eshiklarini ochish
        taqiqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          14.8
        </span>
        Haydovchi transport vositasini o’z-o’zidan harakatlanishini yoki o’zi
        yo’qligida undan foydalanishni istisno qiladigan zarur ehtiyot
        choralarini ko’rgandan keyingina o’z joyini tashlab ketishi yoki
        transport vositasini qoldirishi mumkin.
      </p>
    </div>
  )
}

export default Comp14
