import React, { useEffect, useState } from 'react'

const Statistic = (props: any) => {
  const [ts, setTs] = useState(0)
  const [fs, setFs] = useState(0)
  const [hs, setHs] = useState(0)
  const [tb, setTb] = useState(0)
  const [fb, setFb] = useState(0)
  const [hb, setHb] = useState(0)

  useEffect(() => {
    const mar = localStorage.getItem('marafon')
    const shablon = localStorage.getItem('shablon')
    if (mar) {
      const temp = JSON.parse(mar)
      let solved = 0
      let nosolved = 0
      let errorsolved = 0
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].bg === 'bg-success') {
          solved++
        } else if (temp[i].bg === 'bg-primary') {
          nosolved++
        } else if (temp[i].bg === 'bg-danger') {
          errorsolved++
        }
      }
      setTs(solved)
      setFs(errorsolved)
      setHs(nosolved)
    } else {
      setHs(700)
    }
    if (shablon) {
      let dan = 0
      let suc = 0
      const temp = JSON.parse(shablon)
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].value) {
          let success = 0
          for (let j = 0; j < temp[i].arr.length; j++) {
            if (temp[i].arr[j].bg === 'bg-success') {
              success++
            }
          }
          if (success === 10) {
            suc++
          } else {
            dan++
          }
        }
      }
      setHb(70 - suc - dan)
      setTb(suc)
      setFb(dan)
    } else {
      setHb(70)
    }
  }, [])
  return (
    <div>
      <p className="fs-4">{props?.data?.tn}</p>
      <div
        className="m-auto"
        style={{
          position: 'relative',
          width: '100%',
          height: '182px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: '18.5%',
            height: `182px`,
            width: '9%',
            borderRadius: '5px 5px 0px 0px',
            border: '2px solid #00004d',
            bottom: 0,
            zIndex: 100,
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            left: '18.5%',
            height: `${(182 * hs) / 700}px`,
            width: '9%',
            backgroundColor: '#00004d',
            borderRadius: '5px 5px 0px 0px',
            bottom: 0,
          }}
        ></div>
        <div
          className="border border-danger"
          style={{
            position: 'absolute',
            left: '46%',
            height: `182px`,
            width: '9%',
            borderRadius: '5px 5px 0px 0px',
            bottom: 0,
            zIndex: 100,
          }}
        ></div>
        <div
          className="bg-danger"
          style={{
            position: 'absolute',
            left: '46%',
            height: `${(182 * fs) / 700}px`,
            width: '9%',
            borderRadius: '5px 5px 0px 0px',
            bottom: 0,
          }}
        ></div>
        <div
          className="border border-success"
          style={{
            position: 'absolute',
            left: '73.5%',
            height: `182px`,
            width: '9%',
            borderRadius: '5px 5px 0px 0px',
            bottom: 0,
            zIndex: 100,
          }}
        ></div>
        <div
          className="bg-success"
          style={{
            position: 'absolute',
            left: '73.5%',
            height: `${(182 * ts) / 700}px`,
            width: '9%',
            borderRadius: '5px 5px 0px 0px',
            bottom: 0,
          }}
        ></div>
      </div>
      <hr />
      <div className="mt-3">
        <div className="d-flex">
          <span
            className="d-block text-white fs-5 text-center pt-1"
            style={{
              width: '40px',
              height: '40px',
              backgroundColor: '#00004d',
              borderRadius: '50%',
            }}
          >
            {hs}
          </span>
          <p className="fs-5 mx-2 "> - {props?.data?.hyt}</p>
        </div>
        <div className="d-flex">
          <span
            className="d-block bg-danger text-white fs-5 text-center pt-1"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
            }}
          >
            {fs}
          </span>
          <p className="fs-5 mx-2"> - {props?.data?.nyt}</p>
        </div>
        <div className="d-flex">
          <span
            className="d-block bg-success text-white fs-5 text-center pt-1"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
            }}
          >
            {ts}
          </span>
          <p className="fs-5 mx-2"> - {props?.data?.tyt}</p>
        </div>
      </div>
      <hr />
      <p className="fs-4">{props?.data?.bn}</p>
      <div
        className="m-auto"
        style={{
          position: 'relative',
          width: '100%',
          height: '182px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: '18.5%',
            height: `182px`,
            width: '9%',
            borderRadius: '5px 5px 0px 0px',
            border: '2px solid #00004d',
            bottom: 0,
            zIndex: 100,
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            left: '18.5%',
            height: `${(182 * hb) / 70}px`,
            width: '9%',
            backgroundColor: '#00004d',
            borderRadius: '5px 5px 0px 0px',
            bottom: 0,
          }}
        ></div>
        <div
          className="border border-danger"
          style={{
            position: 'absolute',
            left: '46%',
            height: `182px`,
            width: '9%',
            borderRadius: '5px 5px 0px 0px',
            bottom: 0,
            zIndex: 100,
          }}
        ></div>
        <div
          className="bg-danger"
          style={{
            position: 'absolute',
            left: '46%',
            height: `${(182 * fb) / 70}px`,
            width: '9%',
            borderRadius: '5px 5px 0px 0px',
            bottom: 0,
          }}
        ></div>
        <div
          className="border border-success"
          style={{
            position: 'absolute',
            left: '73.5%',
            height: `182px`,
            width: '9%',
            borderRadius: '5px 5px 0px 0px',
            bottom: 0,
            zIndex: 100,
          }}
        ></div>
        <div
          className="bg-success"
          style={{
            position: 'absolute',
            left: '73.5%',
            height: `${(182 * tb) / 70}px`,
            width: '9%',
            borderRadius: '5px 5px 0px 0px',
            bottom: 0,
          }}
        ></div>
      </div>
      <hr />
      <div className="mt-3">
        <div className="d-flex">
          <span
            className="d-block text-white fs-5 text-center pt-1"
            style={{
              width: '40px',
              height: '40px',
              backgroundColor: '#00004d',
              borderRadius: '50%',
            }}
          >
            {hb}
          </span>
          <p className="fs-5 mx-2 "> - {props?.data?.hyb}</p>
        </div>
        <div className="d-flex">
          <span
            className="d-block bg-danger text-white fs-5 text-center pt-1"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
            }}
          >
            {fb}
          </span>
          <p className="fs-5 mx-2"> - {props?.data?.nyb}</p>
        </div>
        <div className="d-flex">
          <span
            className="d-block bg-success text-white fs-5 text-center pt-1"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
            }}
          >
            {tb}
          </span>
          <p className="fs-5 mx-2"> - {props?.data?.tyb}</p>
        </div>
      </div>
    </div>
  )
}

export default Statistic
