import React from 'react'

const Comp27 = () => {
  return (
    <div className="fs-5">
      <h2>
        27. Велосипед ва аравалар ҳаракатланиши, шунингдек, ҳайвонларни ҳайдаб
        ўтишга доир қўшимча талаблар
      </h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.1
        </span>
        Йўлда велосипед, аравани бошқариш, ҳайвонларни ҳайдаб ўтиш ёки уларни
        миниб ўтиш 14 ёшга тўлган, мопедни бошқариш еса 16 ёшга тўлган шахсларга
        рухсат етилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.2
        </span>
        Велосипедлар, мопедлар, араваларни бошқариш, ҳайвонларни миниб бориш ёки
        ҳайдаб кетиш пайтида йўлнинг четки ўнг бўлагида имкони борича ўнг
        томонидан бир қатор бўлиб ҳаракатланишга йўл қўйилади. Aгар пиёдаларга
        ҳалақит бермаса, йўл ёқасида ҳам ҳаракатланишга рухсат етилади. <br />
        Йўлнинг қатнов қисмида ҳаракатланишда велосипедчилар, аравалар
        жамланмаси, миниб олинган ҳайвонлар гуруҳлари ва ҳайдаб кетилаётган
        ҳайвонлар тўдаси, йўл ҳаракати хавфсизлигини таъминлайдиган ҳамда уларни
        қувиб ўтишни осонлаштирадиган гуруҳларга бўлиниши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.3
        </span>
        Велосипед товуш бергич мосламаси билан жиҳозланган ва унинг тормози соз
        бўлиши зарур. Қоронри вақтда ва етарли кўринмаслик шароитида
        велосипеднинг олд томонига оқ рангли, орқа томонига қизил рангли чироқча
        ёки қизил рангли нур қаитаргич, ён томонларига еса зарғалдок ёки қизил
        рангли нур қаитаргич ўрнатилиши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.4
        </span>
        Велосипед ва мопед ҳайдовчиларига қуйидагилар тақиқланади: <br />
        рулни бир қўлда ушлаб ҳаракатланиш (манёврдан олдин ишоралар бериш
        бундан мустасно); <br />
        йўловчи ташиш (ишончли оёқ қўгич билан жиҳозланган қўшимча ўриндиқда 7
        ёшгача бўлган юловчини ташиш бундан мустасно);
        <br />
        габаритдан бўйига ва енига 0,5 метрдан ортиқ чиқиб турган ёки бошкаришга
        ҳалақит берадиган юкларни ташиш; <br />
        велосипед йўлкаси бўлгани ҳолда йўлнинг қатнов қисмида ҳаракатланиш;
        <br />
        трамвайлар ҳаракатланадиган йўлларда ва тегишли йўналишда бўлаги
        биттадан кўп бўлган йўлларда чапга бурилиш ёки қайрилиб олиш;
        <br />
        велосипедларни ва мопедларни шатакка олиш, шунингдек, улардан шатакка
        олишда фойдаланиш, (велосипед ёки мопедларга мўлжалланган тиркамалар
        бундан мустасно).
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.5
        </span>
        Чорраҳадан ташқаридаги ҳаракат тартибга солинмайдиган жойда велосипед
        йўлкасида бораётган велосипед ва мопед ҳайдовчилари бу йўлка билан
        кесишган йўлларда ҳаракатланаётган транспорт воситаларига йўл беришлари
        керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.6
        </span>
        От-араваларда орқага тисарилиб кетишга йўл қўймайдиган тиргаклар ва уни
        тўхтатиб турадиган мослама бўлиши керак. <br />
        Қоронғи вақтда ва етарли кўринмаслик шароитида йўлда ҳаракатланаётган
        от-араванинг олд томонига оқ рангли чироқча ёки иккита нур қайтаргич,
        орқа томонига еса қизил рангли чироқча ёки иккита нур қаитаргич
        ўрнатилиши керак. <br />
        Ёндош ҳудуддан ёки иккинчи даражали йўлдан кўриниш чекланган жойларда
        йўлга чиқаётган арава ҳайдовчиси ҳайвонларнинг жиловини қўйиб
        юбормаслиги керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.7
        </span>
        Ҳайвонлар йўлларда асосан куннинг ёруғ вақтида ҳайдаб борилади.
        Подачилар ҳайвонларни мумкин қадар йўлнинг ўнг четидан олиб юришлари
        керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.8
        </span>
        Темир йўл кесишмалари орқали ўтишда подачилар сонини ҳисобга олган ҳолда
        ҳайвонлар подаси хавф-ҳатарсиз ҳайдаб ўтилиши таъминланадиган тўдаларга
        бўлиниши лозим.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          27.9
        </span>
        Aравани бошқараётган, ҳайвонларни миниб ёки ҳақдаб кетаётган шахсларга
        қуйидагилар тақиқланади: <br />
        йўлларда ҳайвонларни назорациз қолдириш; <br />
        подани темир йўлдан ва йўлда махсус ажратилмаган жойлардан, шунингдек,
        рухсат етилган жойларда еса коронғи вақтда ва етарли кўринмаслик
        шароитида ҳайдаб утиш; <br />
        ҳайвонларни мукаммал қопламали йўллардан ҳайдаб бориш; <br />
        кукаламзорлаштирилган жойларда (йўл, йўл ёқаси, ажратувчи бўлак ва ҳ.к.)
        ҳайвонларни боқиш ва боғлаб қўйиш.
      </p>
    </div>
  )
}

export default Comp27
