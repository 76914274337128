import React from 'react'


const Comp2 = () =>
{
  const roadsigns = [
    {
      description: "2.1 – harakatlanayotgan transport vositalariga xavf tug’diradigan yo’l inshootlarining tik elementlari (ko’priklar va osma yo’llar, ustunlar va h.k.)ni bildiradi.",
      img:"1"
    },
    {
      description: "2.2 – tonnellar, ko’priklar, osma yo’l va ko’priklarning pastki qirrasini bildiradi.",
      img:"2"
    },
    {
      description: "2.3 – ajratish bo’laklaridagi yoki xavfsizlik orolchalari”dagi dumaloq ustunlarni bildiradi.",
      img:"3"
    },
    {
      description: "2.4 – yo’naltiruvchi, beton yoki temir beton ustunlar, to’siq tirgaklar va hokazolarni bildiradi.",
      img:"4"
    },
    {
      description: "2.5 – yo’lning kichik radiusli bo’rilishlari, tik nishabliklari va boshqa xavfli joylarida yo’l chetiga o’rnatilgan to’siqlarning yon yuzalarini bildiradi.",
      img:"5"
    },
    {
      description: "2.6 – boshqa joylardagi to’siqlarning yon yuzalarini bildiradi.",
      img:"6"
    },
    {
      description: "2.7 – yo’lning xavfli joylarida uning chetidagi to’siqni (bordyurni) va yo’l sathidan baland bo’lgan xavfsizlik orolchalari chetini bildiradi.",
      img:"7"
    },
    
  ]
  const imgSize = (sign: any) => {
    if (
      +sign.img === 1 ||+sign.img === 3|| +sign.img === 4
    ) {
      return (
        <img
          src={`${process.env.PUBLIC_URL}/images/chiziqlar/tik/${sign.img}.png`}
          alt={sign.description}
          className="small-img d-block mx-auto"
        />
      )
    } else {
      return (
        <img
          src={`${process.env.PUBLIC_URL}/images/chiziqlar/tik/${sign.img}.png`}
          alt={sign.description}
           className="big-img mx-auto d-block"
        />
      )
    }
  }
  return (
    <div className='fs-5'>
      <h2 className='text-center'>Tik chiziqlar</h2>
      <p>
        Tik chiziqlar – yo’l inshootlari va jihozlari yuzalariga ketma-ket chizilgan yo’l-yo’l oq-qora chiziqlar majmuisidan iborat bo’lib, ularning o’lchamlarini ko’rsatishda, ko’z bilan chamalash vositasi sifatida xizmat qiladi.
      </p>
      <div>
        <img
            src={`${process.env.PUBLIC_URL}/images/chiziqlar/tik/main/1.png`}
            className="w-100"
            alt="tik chiziqlar"
          />
      </div>
  <div className="mt-4">
        {roadsigns.map((sign: any) => (
          <div key={sign.img}>
            <p>{sign.description}</p>
            {
              imgSize(sign)
            }
            
            <hr />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Comp2
