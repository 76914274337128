import { IonIcon, useIonModal } from '@ionic/react'
import { arrowBackOutline, arrowForwardOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react';
import Comp1 from './Components/comp1'
import Comp2 from './Components/comp2'
import Comp3 from './Components/comp3'
import Comp4 from './Components/comp4'
import Comp5 from './Components/comp5'
import Comp6 from './Components/comp6'
import Comp7 from './Components/comp7'
import { InterstitialAd } from '@admob-plus/capacitor'
import "./yolBelgilar.css"

const YolBelgilar = (props:any) => {
  const titles = [
    'Ogohlanturuvchi belgilar',
    'Imtiyoz belgilari',
    'Taqiqlovchi belgilar',
    'Buyuruvchi belgilar',
    'Axborot-ishora belgilari',
    'Servis belgilari',
    "Qo'shimcha axborot belgilari",
  ]
  const titles2=[
 "Огоҳлантурувчи белгиларъ",
 "Имтиёз белгилариъ",
 "Тақиқловчи белгиларъ",
 "Буюрувчи белгиларъ",
 "Aхборот-ишора белгилариъ",
 "Сервис белгилариъ",
 "Қўшимча ахборот белгилари",
 ]
  const [id, setId] = useState(1)
  const [isCard, setCard] = useState(false)
  const handleDismiss = () => {
    dismiss()
  }
  const [present, dismiss] = useIonModal(Body, {
    id,
    onDismiss: handleDismiss,
  })
  useEffect(() => {
    const startAds = async () => {
      const interstitial = new InterstitialAd({
        adUnitId: 'ca-app-pub-2110563711012419/1927421731',
      })
      await interstitial.load()
      await interstitial.show()
    }
    const interval = setInterval(startAds, 420000)
    return () => {
      ;(async () => {
        clearInterval(interval)
      })()
    }
  }, [])
  const renderComp = () => {
    switch (id) {
      case 1:
        return <Comp1 />
      case 2:
        return <Comp2 />
      case 3:
        return <Comp3 />
      case 4:
        return <Comp4 />
      case 5:
        return <Comp5 />
      case 6:
        return <Comp6 />
      case 7:
        return <Comp7 />
    }
    return 10
  }
  return (
    <div>
      {isCard ? (
        <div className='card card-custom2 m-auto'>
          <div className="card-header">
            <div className="d-flex justify-content-between btn-back rounded">
              <button
                onClick={() => {
                  setCard(false)
                }}
                className="btn "
              >
                <IonIcon
                  style={{ fontSize: '30px', color: 'white' }}
                  icon={arrowBackOutline}
                ></IonIcon>
              </button>
              <div>
                <p className="p-2 fs-3 text-white">{id}/7</p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between my-3">
            <button
              className="btn"
              onClick={() => {
                setId((prev) => {
                  if (id === 1) {
                    return 1
                  } else {
                    return prev - 1
                  }
                })
              }}
            >
              <IonIcon
                style={{ fontSize: '30px' }}
                icon={arrowBackOutline}
              ></IonIcon>
            </button>
            <button
              className="btn"
              onClick={() => {
                setId((prev) => {
                  if (id === 7) {
                    return 7
                  } else {
                    return prev + 1
                  }
                })
              }}
            >
              <IonIcon
                style={{ fontSize: '30px' }}
                icon={arrowForwardOutline}
              ></IonIcon>
            </button>
          </div>
          <div
            className="p-2 rounded"
            style={{
              height: '60vh',
              overflow: 'auto',
              width: '96%',
              marginLeft: '2%',
              border: '2px solid #00004d',
            }}
          >
            {renderComp()}
            
          </div>
        </div>
      ) : (
        ''
      )}
      {(props.lang === 'uzlotin'
        ? titles
        : props.lang === 'uzkiril'
        ? titles2
        : props.lang === 'rus'
        ? titles
        : titles
      ).map((title: any, i: any) => (
        <div
          onClick={() => {
            setId(i + 1)
            setCard(true);
            
          }}
          className="rounded bg-light p-1 m-1 text-center text-dark border border-secondary"
          key={title}
        >
          <p className="fs-4">
            {i + 1}. {title}
          </p>
        </div>
      ))}
       <div className="my-5 w-100"></div>
    </div>
  )
}

export default YolBelgilar

const Body: React.FC<{
  id: number
  onDismiss: () => void
}> = ({ id, onDismiss }) => {
  const [index, setIndex] = useState(id)
  const renderComp = () => {
    switch (index) {
      case 1:
        return <Comp1 />
      case 2:
        return <Comp2 />
      case 3:
        return <Comp3 />
      case 4:
        return <Comp4 />
      case 5:
        return <Comp5 />
      case 6:
        return <Comp6 />
      case 7:
        return <Comp7 />
    }
    return 10
  }
  return (
    <div>
      <div className="d-flex justify-content-between mb-2 btn-back">
        <button className="btn " onClick={() => onDismiss()}>
          <IonIcon
            style={{ fontSize: '30px', color: 'white' }}
            icon={arrowBackOutline}
          ></IonIcon>
        </button>
        <div>
          <p className="p-2 fs-3 text-white">{index}/7</p>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-2">
        <button
          className="btn"
          onClick={() => {
            setIndex((prev) => {
              if (index === 1) {
                return 1
              } else {
                return prev - 1
              }
            })
          }}
        >
          <IonIcon
            style={{ fontSize: '30px' }}
            icon={arrowBackOutline}
          ></IonIcon>
        </button>
        <button
          className="btn"
          onClick={() => {
            setIndex((prev) => {
              if (index === 7) {
                return 7
              } else {
                return prev + 1
              }
            })
          }}
        >
          <IonIcon
            style={{ fontSize: '30px' }}
            icon={arrowForwardOutline}
          ></IonIcon>
        </button>
      </div>
      <div
        className="p-2 rounded"
        style={{
          height: '650px',
          overflow: 'scroll',
          width: '96%',
          marginLeft: '2%',
          border: '2px solid #00004d',
        }}
      >
        {renderComp()}
        <div className="d-flex justify-content-between mt-2">
          <button
            className="btn"
            onClick={() => {
              setIndex((prev) => {
                if (index === 1) {
                  return 1
                } else {
                  return prev - 1
                }
              })
            }}
          >
            <IonIcon
              style={{ fontSize: '30px' }}
              icon={arrowBackOutline}
            ></IonIcon>
          </button>
          <button
            className="btn"
            onClick={() => {
              setIndex((prev) => {
                if (index === 7) {
                  return 7
                } else {
                  return prev + 1
                }
              })
            }}
          >
            <IonIcon
              style={{ fontSize: '30px' }}
              icon={arrowForwardOutline}
            ></IonIcon>
          </button>
        </div>
      </div>
    </div>
  )
}
