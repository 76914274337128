import React from 'react'

const Comp3 = () => {
  const roadsigns = [
    {
      title: '3.1. Kirish taqiqlangan',
      description: 'Barcha transport vositalarining kirishi taqiqlangan',
      img: '1',
    },
    {
      title: '3.2. Harakatlanish taqiqlangan',
      description:
        'Barcha transport vositalarining harakatlanishi taqiqlanadi.',
      img: '2',
    },
    {
      title: '3.3. Mexanik transport vositalarining harakatlanishi taqiqlangan',
      description: '',
      img: '3',
    },
    {
      title: '3.4. Yuk avtomobillarining harakatlanishi taqiqlangan',
      description:
        'To’la vazni 3.5 tonnadan (agar vazni belgida ko’rsatilmagan bo’lsa) yoki to’la vazni belgida ko’rsatilganda ortiq bo’lgan yuk avtomobillari va transport vositalari tizimlarining, shuningdek, traktorlar, o’ziyurar moslamalarning harakatlanishi taqiqlanadi. Bu belgi bortlariga qiya oq chiziq tortilgan yoki odamlarni tashiyotgan yuk avtomobillarining harakatlanishini taqiqlamaydi.',
      img: '4',
    },
    {
      title: '3.5. Mototsikllar harakatlanishi taqiqlangan',
      description: '',
      img: '5',
    },
    {
      title: '3.6. Traktorlar harakatlanishi taqiqlangan',
      description:
        'Traktorlar, o’ziyurar moslamalarning harakatlanishi taqiqlanadi.',
      img: '6',
    },
    {
      title: '3.7. Tirkama bilan harakatlanish taqiqlangan',
      description:
        'Yuk tashuvchi transport vositalari, traktorlarning barcha turdagi tirkamalar bilan harakatlanishi, shuningdek, mexanik trasport vositalarini har qanday usulda shatakka olish taqiqlanadi.',
      img: '7',
    },
    {
      title: '3.8. Ot-arava harakatlanishi taqiqlangan',
      description:
        'Ot-arava (chana), otliqlar, yuk ortilgan hayvonlarning harakatlanishi, shuningdek, mol (poda) haydab o’tish taqiqlanadi.',
      img: '8',
    },
    {
      title: '3.9. Velosipedda harakatlanish taqiqlangan',
      description: 'Velosiped va mopedlarning harakatlanishi taqiqlanadi.',
      img: '9',
    },
    {
      title: '3.10. Piyodalarning harakatlanishi taqiqlangan',
      description: '',
      img: '10',
    },
    {
      title: '3.11. Vazn cheklangan',
      description:
        'Haqiqiy umumiy vazni belgida ko’rsatilgandan ortiq bo’lgan transport vositalarining, shuningdek, transport vositalari tizimlarining harakatlanishi taqiqlanadi.',
      img: '11',
    },
    {
      title: '3.12. O‘qqa tushadigan og‘irlik cheklangan',
      description:
        'Birorta o’qiga tushadigan haqiqiy og’irligi belgida ko’rsatilganidan ortiq bo’lgan transport vositalarining harakatlanishi taqiqlanadi.',
      img: '12',
    },
    {
      title: '3.13. Cheklangan balandlik',
      description:
        'Gabarit balandligi (yuk bilan yoki yuksiz) belgida ko’rsatilgandan ortiq bo’lgan transport vositalarining harakatlanishi taqiqlanadi.',
      img: '13',
    },
    {
      title: '3.14. Cheklangan kenglik',
      description:
        'Gabarit kengligi (yuk bilan yoki yuksiz) belgida ko’rsatilgandan ortiq bo’lgan transport vositalarining harakatlanishi taqiqlanadi.',
      img: '14',
    },
    {
      title: '3.15. Cheklangan uzunlik',
      description:
        'Gabarit uzunligi (yuk bilan yoki yuksiz) belgida ko’rsatilgandan ortiq bo’lgan transport vositalari (transport vositalarining tizimlari) ning harakatlanishi taqiqlanadi.',
      img: '15',
    },
    {
      title: '3.16. Eng kam oraliq',
      description:
        'Belgida ko’rsatilganidan kam oraliq masofada harakatlanish taqiqlanadi.',
      img: '16',
    },
    {
      title: '3.17.1. Bojxona',
      description:
        'Bojxona (tekshiruv maskani) oldida to’xtamasdan o’tish taqiqlanadi.',
      img: '17',
    },
    {
      title: '3.17.2. Xavf-xatar',
      description:
        'Yo’l-trasnport hodisasi yoki boshqa xavfli holatlar tufayli barcha transport vositalarining harakatlanishi taqiqlanadi.',
      img: '18',
    },
    {
      title: '3.18.1. O’ngga burilish taqiqlanadi',
      description: '',
      img: '19',
    },
    {
      title: '3.18.2. Chapga burilish taqiqlanadi',
      description: '',
      img: '20',
    },
    {
      title: '3.19. Qayrilish taqiqlanadi',
      description: '',
      img: '21',
    },
    {
      title: '3.20. Quvib o’tish taqiqlanadi',
      description:
        'Soatiga 40 km dan kam tezlikda harakatlanayotgan yakka transport vositasidan boshqa transport vositalarini quvib o’tish taqiqlanadi.',
      img: '22',
    },
    {
      title: '3.21. Quvib o’tish taqiqlangan hududning oxiri',
      description: '',
      img: '23',
    },
    {
      title: '3.22. Yuk avtomobillarida quvib o’tish taqiqlangan',
      description:
        'To’la vazni 3.5 tonnadan ortiq bo’gan yuk avtomobillarida barcha transport vositalarini quvib o’tish taqiqlanadi (soatiga 40 km dan kam tezlikda harakatlanayotgan transport vositasi, traktor, ot-arava, velosiped bundan mustasno).',
      img: '24',
    },
    {
      title:
        '3.23. Yuk avtommobillarida quvib o’tish taqiqlangan hududning oxiri',
      description: '',
      img: '25',
    },
    {
      title: '3.24. Yuqori tezlik cheklangan',
      description:
        'Belgida ko’rsatilgan ortiq tezlikda (km/soat) harakatlanish taqiqlanadi.',
      img: '26',
    },

    {
      title: '3.25. Yuqori tezlik cheklangan hududning oxiri',
      description: '',
      img: '27',
    },
    {
      title: '3.26. Tovuch moslamalaridan foydalanish taqiqlangan',
      description:
        'Yo’l transport hodisasining oldini olish hollaridan boshqa vaziyatlarda tovush moslamasidan foydalanish taqiqlanadi.',
      img: '28',
    },
    {
      title: '3.27. To’xtash taqiqlangan',
      description:
        'Transport vositalarining to’xtashi va to’xtab turishi taqiqlanadi.',
      img: '29',
    },
    {
      title: '3.28. To’xtab turish taqiqlangan',
      description: 'Transport vositalarining to’xtab turishi taqiqlangan.',
      img: '30',
    },
    {
      title: '3.29. Oyning toq kunlarida to’xtab turish taqiqlanadi',
      description: '',
      img: '31',
    },
    {
      title: '3.30. Oyning juft kunlarida to’xtab turish taqiqlanadi',
      description: '',
      img: '32',
    },
    {
      title: '3.31. Barcha cheklovlarning oxiri',
      description:
        '3.16, 3.20, 3.22, 3.24, 3.26-3.30 belgilaridan bir nechtasiga bir vaqtda amal qiladigan oraliqlarning oxirini bildiradi.',
      img: '33',
    },
  ]
  return (
    <div>
      <h2>Taqiqlovchi belgilar</h2>
      <p className='fs-4'>
        Taqiqlovchi belgilar yo’l harakatiga ma’lum cheklanishlar kiritish yoki
        ularni bekor qilish uchun qo’llaniladi.
      </p>
      <div>
        {roadsigns.map((sign: any) => (
          <div key={sign.img}>
            <h3 className="text-center">{sign.title}</h3>
            <p className='fs-4'>{sign.description}</p>
            
            <img
              src={`${process.env.PUBLIC_URL}/images/belgilar/taqiq/${sign.img}.png`}
              alt={sign.description}
              className="small-img d-block mx-auto"
            />
            <hr />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Comp3
