/** @format */

import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router";
import testbg from "./../../images/homebg2.png";
import exit from "./../../images/svg/exit.svg";
import descript from "./../../images/svg/description.svg";
import nextimg from "./../../images/svg/next.svg";
import axios from "axios";
// import { AdMobPlus, InterstitialAd } from '@admob-plus/capacitor'
import "./shablon.css";
import { AdMobPlus, BannerAd, InterstitialAd } from "@admob-plus/capacitor";
import { IonAlert } from "@ionic/react";
const Shablon = () => {
  const history = useHistory();
  const match: any = useRouteMatch();
  const id = match.params.id;
  const [alltests, setAlltests] = useState([]) as any;
  const [tests, setTests] = useState([]) as any;
  const [index, setIndex] = useState(0);
  const [count, setCount] = useState(10);
  const [lost, setLost] = useState(0);
  const [next, setNext] = useState(false);
  const [testresult, setTestresult] = useState([]) as any;
  const [datah, setDataH] = useState({}) as any;
  const [showAlert1, setShowAlert1] = useState(false);

  const lang = localStorage.getItem("lang");
  // const startAds =async() => {
  // const interstitial = new InterstitialAd({
  //   adUnitId: 'ca-app-pub-3940256099942544/1033173712',
  // })
  // await interstitial.load()
  // await interstitial.show()
  // }
  // setTimeout(() =>
  // {
  //   startAds();
  // },5000)
  useEffect(() =>
  {
     let shab: any = localStorage.getItem("shablon");
    if (shab)
    {      
      let tempshab=[...JSON.parse(shab)]
      if (tempshab[id - 1].value)
      {
        setShowAlert1(true)
      }
    }
    
    let banner:any;
    (async () =>
    {
      banner = new BannerAd({
        adUnitId: 'ca-app-pub-2110563711012419/7321534432',
      })
      await banner.show()
      //  ca-app-pub-3940256099942544/6300978111
  
    })();
    return () =>
    {
      (async () =>
    {
        await banner.hide();
      //  ca-app-pub-3940256099942544/6300978111
    })();
    }
  },[])
  useEffect(() => {
    const getTests = async () => {
      let temp = "";
      let temp2 = "";
      if (lang === "uzlotin" || lang === "uzkiril" || lang === "rus") {
        temp = lang;
        if (lang === "uzkiril") {
          temp2 = "kiril";
        }
        if (lang === "uzlotin") {
          temp2 = "lotin";
        }
         if (lang === "rus") {
          temp2 = "ru";
        }
      } else {
        temp = "uzlotin";
        temp2 = "lotin";
        localStorage.setItem("lang", temp);
      }
      const result = await axios.get(
        `${process.env.PUBLIC_URL}/data/${temp}.json`
      );
      const result2 = await axios.get(
        `${process.env.PUBLIC_URL}/data/${temp2}.json`
      );      
      setDataH(result2.data);
      setAlltests(result.data);
      setIndex(0);
      setCount(10);
      setLost(0);
      const temptst = [];
      const num = Math.floor(result.data.length / 10);
      const tempa = [];
      if (id > 0 && id <= num) {
        for (let i = 0; i < 10; i++) {
          temptst[i] = { ind: -1, bg: "bg-primary", help: false };
          tempa[i] = result.data[(+id - 1) * 10 + i];
        }
        setTestresult(temptst);
        setTests(tempa);
      }
    };
    getTests();
  }, [id]);
  const submitChoise = (i: any) => {
    const temp = tests[index].choises[i].answer;
    const newarr = testresult.map((el: any) => el);
    setNext(true);
    if (temp) {
      newarr[index].bg = "bg-success";
      newarr[index].ind = +i;
      setCount(count - 1);
      const audio = new Audio(`${process.env.PUBLIC_URL}/mp3/won.mp3`);
      audio.play();
    } else {
      newarr[index].bg = "bg-danger";
      newarr[index].ind = +i;
      setCount(count - 1);
      setLost(lost + 1);
      const audio = new Audio(`${process.env.PUBLIC_URL}/mp3/lost.mp3`);
      audio.play();
    }
    let tempar: { value: boolean; arr: any }[] = [];
    let shab: any = localStorage.getItem("shablon");
    if (shab)
    {      
      let tempshab=[...JSON.parse(shab)]
      tempshab[id - 1].value = true;
      tempshab[id - 1].arr = newarr;
      localStorage.setItem("shablon",JSON.stringify(tempshab))
    }
    else
      {
        for (let i = 0; i < 70; i++)
          {
            tempar.push({value:false,arr:[]})
          }
          tempar[id - 1].value = true;
          tempar[id - 1].arr = newarr;
      localStorage.setItem("shablon",JSON.stringify(tempar))
      }  
    setTestresult(newarr);
  };
  const nextQuestion = () => {
    if (index < 9) {
      setNext(false);
      setIndex(index + 1);
      const audio = new Audio(`${process.env.PUBLIC_URL}/mp3/start.mp3`);
      audio.play();
    } else {
      setIndex(0);
    }
  };
  const exitGame = () => {
    history.push("/learntests");
  };
  const decideLang = () =>
  {
    if (lang === "uzlotin")
    {
      return "Kuting..."
    }
    if (lang === "uzkiril")
    {
      return "Кутинг...";
    }
    if (lang === "rus")
    {
      return "Подождите ...";
    }
  }
  return (
    <div className='container bg-light'>
            <IonAlert
                isOpen={showAlert1}
                onDidDismiss={() => setShowAlert1(false)}
                cssClass="my-custom-class"
                header={'Davom ettirishni xohlaysizmi?'}
                buttons={[
                  {
                    text: 'Qaytadan boshlash',
                    role: 'cancel',
                    cssClass: 'primary',
                  },
                {
                  text: 'Davom ettirish',
                  handler: () =>
                  {
                   let shab: any = localStorage.getItem("shablon");
                    if (shab)
                    {      
                      let tempshab=[...JSON.parse(shab)]
                      setTestresult(tempshab[id - 1].arr)
                    }
                  },
                },
              ]}
            />
      <div className='d-flex justify-content-between'>
        <div className=' px-3 fs-4 my-3'>
          {lost >= 2 ? (
            <p className='text-danger border border-danger p-1 rounded'>
              {datah?.fail}
            </p>
          ) : count <= 0 && lost <= 1 ? (
            <p className='text-success border border-success p-1 rounded'>
              {datah?.success}
            </p>
          ) : (
            ""
          )}
        </div>
        {count === 0 ? (
          <div className='position-fixed start-0 w-100 h-100  finish-match'>
            <div
              className='d-flex justify-content-center'
              style={{ marginTop: "200px" }}>
              <div className='card' style={{ width: "18rem" }}>
                <div className='card-body'>
                  <h5 className='card-title'>{datah?.results}</h5>
                  <p className='card-text fs-4'>{`${datah?.trueres}: ${
                    10 - lost
                  }/10`}</p>
                  <p className='card-text fs-4'>{`${datah?.falseres}: ${lost}/10`}</p>
                  <div className='d-flex justify-content-between'>
                    <button
                      onClick={() =>
                      {
                            (async () => {
                              await AdMobPlus.start()

                              const interstitial = new InterstitialAd({
                                adUnitId: 'ca-app-pub-2110563711012419/1927421731',
                              })
                              await interstitial.load()
                              await interstitial.show()
                            })()
                        if (+id 
                          < Math.floor(alltests.length / 10)) {
                          history.push(`/learntests/${+id + 1}`);
                          let shab: any = localStorage.getItem("shablon");
                          if (shab)
                          {      
                            let tempshab=[...JSON.parse(shab)]
                            // setTestresult(tempshab[id - 1].arr)
                            
                            if (tempshab[+id].value)
                            {
                              setShowAlert1(true);
                            }
                          }
                        } else {
                          history.push(`/learntests/1`);
                        }
                      }}
                      className='btn btn-primary'>
                      {datah?.cont}
                    </button>
                    <button
                      onClick={() => {
                        exitGame();
                      }}
                      className='btn btn-primary'>
                      {datah?.exit}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className='my-3'>
          <button onClick={exitGame} className='btn btn-primary'>
            <img src={exit} alt='exit game' />
          </button>
        </div>
      </div>

      {tests.length ? (
        <div>
          <div className='d-grid justify-content-center'>
            <div className='d-flex overflow-auto my-2'>
              {tests.map((_: any, i: any) => (
                <p
                  role='button'
                  key={i + "_id"}
                  onClick={() => {
                    setIndex(i);
                    setNext(false);
                    const audio = new Audio(
                      `${process.env.PUBLIC_URL}/mp3/start.mp3`
                    );
                    audio.play();
                  }}
                  style={{ width: "50px", height: "50px" }}
                  className={`d-flex justify-content-center flex-shrink-0 align-items-center mx-1 fs-4 text-white rounded cursor position-relative ${testresult[i].bg}`}>
                  {i + 1}
                  {i === index ? (
                    <span
                      style={{
                        transform: "translateY(6px)",
                        clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
                        width: "30px",
                        height: "18px",
                      }}
                      className='position-absolute bottom-0 bg-light'></span>
                  ) : (
                    ""
                  )}
                </p>
              ))}
            </div>
          </div>

          <div className='row'>
            <div className='col-md-7 order-md-1'>
              <div>
                {tests[index].media?.exist ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/${tests[index]?.media?.name}.png`}
                    className='w-100 mx-auto'
                    alt={`${tests[index]?.question}`}
                  />
                ) : (
                  <img
                    src={testbg}
                    className='w-100 mx-auto'
                    alt={`${tests[index]?.question}`}
                  />
                )}
              </div>
            </div>
            <div className='col-md-5 order-md-0'>
              <div className='p-4'>
                <h2>
                  {index + 1}-{datah?.question}:
                </h2>
                <p>{tests[index]?.question}</p>
                <hr />
                <div className='position-relative'>
                  {testresult[index]?.ind !== -1 ? (
                    <div className='position-absolute w-100 h-100 choise-bg'></div>
                  ) : (
                    ""
                  )}

                  {tests[index]?.choises?.map((el: any, i: any) => (
                    <p
                      role='button'
                      onClick={() => {
                        submitChoise(i);
                      }}
                      key={i + "-id"}
                      className={
                        testresult[index]?.ind === i
                          ? `${testresult[index]?.bg} text-white p-2 rounded`
                          : `${
                              testresult[index]?.bg === "bg-danger"
                                ? `${
                                    tests[index]?.choises[i]?.answer
                                      ? "bg-success"
                                      : "bg-dark"
                                  }`
                                : "bg-dark"
                            } text-white p-2 rounded`
                      }>
                      {el?.text}
                    </p>
                  ))}
                </div>
                <div>
                  {next ? (
                    <button
                      onClick={nextQuestion}
                      className='btn btn-primary px-2 py-1'>
                      <img src={nextimg} alt='keyingi' />
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className='mt-5'>
                  <button
                    onClick={() => {
                      const newarr = testresult.map((el: any) => el);
                      newarr[index].help = !testresult[index]?.help;
                      setTestresult(newarr);
                    }}
                    className='btn btn-primary rounded-circle mx-4'
                    style={{ width: "50px", height: "50px" }}>
                    <img src={descript} alt='descip' />
                  </button>
                  {testresult[index]?.help ? (
                    <div className='p-3 fs-5'>
                      <h4>{datah?.desc}:</h4>
                      <p>{tests[index]?.description}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        `${decideLang()}`
      )}
    </div>
  );
};

export default Shablon;
