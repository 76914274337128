import React from 'react'

const Comp20 = () => {
  return (
    <div className='fs-5'>
      <h2>20. Tik nishablik va balandliklarda harakatlanish</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          20.1
        </span>
        1.13 va 1.14 belgilari bilan belgilangan qiyaliklarda yo’lda
        qarama-qarshi yo’nalishlarda harakatlanishni qiyinlashtiradigan
        biron-bir to’siq bo’lsa, nishablikka harakatlanayotgan transport
        vositasi haydovchisi yo’l berishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          20.2
        </span>
        Tik nishablikda, dovonda va 1.13 belgisi o’rnatilgan yo’lda to’xtatish
        tizimi ishlamay qolgan transport vositalari o’z harakatini to’xtatish
        uchun 5.40 belgisi bilan belgilangan falokatli holatlar uchun yo’lga
        kirishlari shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          20.3
        </span>
        Yo’lning 1.13 “Tik nishablik” yo’l belgisi bilan belgilangan qismida
        uzatma yoki tishlashish mexanizmini ajratilgan holatda harakatlanish
        taqiqlanadi.
      </p>
    </div>
  )
}

export default Comp20
