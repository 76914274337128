import React from 'react'

const Comp5 = () => {
  return (
    <div className='fs-5'>
      <h2>5. Piyodalarning vazifasi</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          5.1
        </span>
        Piyodalar trotuardan yoki piyodalar yo’lkasidan, ular bo’lmaganda esa
        yo’l yoqasidan yurishlari kerak. Qo’pol yuklarni olib ketayotgan,
        nogironlarning yurgizgichsiz aravasida borayotgan shaxslarning trotuar
        yoki yo’l yoqasidan yurishlari boshqa piyodalarning harakatlanishiga
        halaqit berayotgan bo’lsa, ular qatnov qismining chetidan yurishlari
        kerak. <br />
        Trotuarlar, piyodalar yo’lkasi, yo’l yoqasi bo’lmasa yoki ulardan
        yurishning imkoniyati bo’lmagan hollarda piyodalar velosiped yulkasidan
        yoki qatnov qismining chetidan (ajratuvchi bo’lagi bor yo’llarda qatnov
        qismining o’ng chetidan) bir qator bo’lib yurishlari mumkin. <br />
        Aholi yashaydigan joylardan tashqarida yo’lning qatnov qismida
        harakatlanayotgan piyodalar transport vositalarining harakatiga qarshi
        yo’nalishda yurishlari kerak. <br />
        Nogironlarning yurgizgichsiz aravasida ketayotgan, mototsikl, moped,
        velosiped etaklab ketayotgan shaxslar esa bunday hollarda transport
        vositalarining harakat yunalishi buylab yurishlari kerak. <br />
        Eslatma: piyodalar yulkasida, trotuarlarda, yo’lning yoqasida
        piyodalarning harakatlanishiga halaqit beradigan qurilma va jihozlar
        o’rnatish man etiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          5.2
        </span>
        Piyodalarning tashkiliy jamlanmasiga bir qatorda to’rt kishidan ortiq
        bo’lmasdan yo’lning qatnov qismida faqat transport vositalarining
        harakat yunalishi bo’ylab o’ng tomondan yurishga ruxsat etiladi. <br />
        Jamlanmaning oldi va orqasida chap tomondan qizil bayroqcha, qoronri
        vaqtda yoki etarlicha ko’rinmaslik sharoitida esa oldinda oq, orqada
        qizil rangli chiroq ko’targan kuzatuvchilar bo’lishi kerak. <br />
        Bolalar guruhini trotuarlar va piyodalar yo’lkalaridangina, ular
        bo’lmaganda esa yo’l yoqasidan faqat kunduzi va katta yoshdagilar
        kuzatuvida olib yurishga ruhsat etiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          5.3
        </span>
        Piyodalar yo’lning qatnov qismini piyodalar o’tish joylaridan,
        shuningdek, er osti va er usti o’tish joylaridan, ular bo’lmaganda esa
        chorrahalarda trotuar chiziqlari yoki yo’l yoqasi bo’ylab kesib
        o'tishlari kerak. <br />
        Ko’rinadigan oraliqda o’tish joyi yoki chorraha bo’lmasa, ajratuvchi
        bo’laksiz va to’siqsiz yo’llarda piyodalarga yo’lning ikki tomoni yaxshi
        ko’rinadigan joyidan qatnov qismining chetiga nisbatan to’g’ri burchak
        ostida kesib o’tishlariga ruhsat etiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          5.4
        </span>
        Piyodalar yo’l harakati tartibga solingan joylarda tartibga soluvchining
        yoki svetoforlarning, ular bo’lmaganda esa transport svetoforlarining
        ishoralariga amal qilishlari kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          5.5
        </span>
        Piyodalar harakat tartibga solinmaydigan piyodalar o’tish joylarida,
        yaqinlashib kelayotgan transport vositasigacha bo’lgan masofani va uning
        tezligini chamalab ko’rib, o’tish o’zlari uchun havfsiz ekanligiga
        ishonch hosil qilganlaridan so’ng yo’lning qatnov qismiga chiqishlari
        mumkin. <br />
        Shuningdek, ular yo’lning qatnov qismini piyodalar o’tish joylaridan
        tashqarida kesib o’tishda transport vositalarining harakatlanishiga
        halaqit bermasliklari, yaqinlashib kelayotgan transport vositalarining
        yo’qligiga ishonch hosil kilmasdan turib kurinishni cheklovchi to’xtab
        turgan transport vositasi yoki boshqa biror to’sik panasidan
        chiqmasliklari kerak. Piyodalar to’xtab turgan avtobus va trolleybusning
        orqa tomonidan, tramvayning esa oldi tomonidan yo’lni kesib o’tishlari
        shart.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          5.6
        </span>
        Agar yo’l harakati havfsizligini ta’minlash bilan bog’liq bo’lmasa,
        qatnov qismiga chiqqan piyodalar ushlanib qolmasliklari va
        to’xtamasliklari kerak. <br />
        O’tishga ulgurmagan piyodalar qarama-qarshi yo’nalishdagi transport
        oqimlarini ajratuvchi chiziqda to’xtashlari lozim. Keyingi harakatlanish
        havfsiz ekanligiga ishonch hosil qilgandan so’ng va svetofor yoki
        tartibga soluvchining ishoralarini hisobga olgan holda o’tishni davom
        ettirishlari mumkin.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          5.7
        </span>
        Yalt-yalt etuvchi ko’k rangli yoki ko’k va qizil rangli
        chiroq-mayoqchasi va (yoki) maxsus tovush ishorasi yoqilgan transport
        vositalari yaqinlashib kelayotgan bo’lsa, piyodalar qatnov qismidan
        o’tmasliklari, unda harakatlanayotganlar esa bu transport vositalariga
        yo’l berishlari va zudlik bilan qatnov qismini bo’shatishlari kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          5.8
        </span>
        Belgilangan yo’nalishdagi transport vositalari va taksilarni faqat
        bekatlarda, ular bo’lmagan taqdirda esa trotuarlar yoki yo’l yoqasida
        kutish kerak. <br />
        Maxsus jihozlangan bekatlari bo’lmagan to’xtash joylarida transport
        vositasi to’la to’xtagandan so’ng unga chiqish uchun yo’lning qatnov
        qismiga chiqishga ruxsat etiladi. Undan tushgandan keyin ushlanib
        qolmasdan yo’lning qatnov qismini bo’shatishlari shart. <br />
        Bekatlarga borayotgan yoki undan kaytayotgan piyodalar Qoidalarning
        5.4-5.7 bandlari talablariga amal qilishlari kerak.
      </p>
    </div>
  )
}

export default Comp5
