import React from 'react'

const Comp20 = () => {
  return (
    <div className='fs-5'>
 <h2>20. Тик нишаблик ва баландликларда ҳаракатланиш</h2>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 20.1
 </span>
 1.13 ва 1.14 белгилари билан белгиланган қияликларда йўлда
 қарама-қарши йўналишларда ҳаракатланишни қийинлаштирадиган
 бирон-бир тўсиқ бўлса, нишабликка ҳаракатланаётган транспорт
 воситаси ҳайдовчиси йўл бериши керак.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 20.2
 </span>
 Тик нишабликда, довонда ва 1.13 белгиси ўрнатилган йўлда тўхтатиш
 тизими ишламай қолган транспорт воситалари ўз ҳаракатини тўхтатиш
 учун 5.40 белгиси билан белгиланган фалокатли ҳолатлар учун йўлга
 киришлари шарт.
 </p>
 <p>
 <span style={{ fontWeight: 'bold' }} className="mx-2">
 20.3
 </span>
 Йўлнинг 1.13 “Тик нишаблик” йўл белгиси билан белгиланган қисмида
 узатма ёки тишлашиш механизмини ажратилган ҳолатда ҳаракатланиш
 тақиқланади.
 </p>
 </div>
  )
}

export default Comp20
