import React from 'react'

const Comp23 = () => {
  return (
    <div className="fs-5">
      <h2>23. Механик транспорт воситаларини шатакка олиш</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          23.1
        </span>
        Қаттик ёки егилувчан улагич ёрдамида шатакда олиб юриш шатакка олинган
        транспорт воситасининг рул бошқарувида ҳайдовчи бўлгандагина амалга
        оширилиши керак. Қаттик улагичнинг тузилиши (конструкцияси) шатакка
        олинган транспорт воситасини шатакка олган транспорт воситасининг изидан
        боришини таъминлаган ҳоллар бундан мустасно.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          23.2
        </span>
        Қаттик ёки егилувчан улагич ёрдамида шатакка олинган автобусда,
        троллейбусда ва юк автомобили юкҳонасида одам ташиш, қисман ортиш йўли
        билан шатакка олишда еса шатакка олинган транспорт воситасининг
        кабинасида ва юкҳонасида, шунингдек, шатакка олган транспорт
        воситасининг юкҳонасида одамлар бўлиши тақиқланади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          23.3
        </span>
        Егилувчан улагич билан шатакка олишда шатакка олган ва шатакка олинган
        транспорт воситалари ўртасидаги масофа 4-6 метр оралиғида бўлиши, қаттиқ
        улагич ёрдамида еса 4 метрдан кўп бўлмаслиги керак. Егилувчан улагичга
        Қоидаларнинг 28.4 бандига мувофик тахтача ёки байроқча қўйилиши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          23.4
        </span>
        Шатакка олиш қуйидаги ҳолларда тақиқланади: <br />
        рул бошқаруви ишламаётган транспорт воситаларини (қисман ортиш усули
        билан шатакка олиш бундан мустасно); <br />
        икки ва ундан кўпрок транспорт воситаларини; <br />
        шатакка олинган ва шатакка олган транспорт воситаларининг улагич билан
        бирга умумий узунлиги 24 метрдан ортиқ бўлса; <br />
        тўхтатиш тизими ишламаётган транспорт воситасининг ҳақиқий вазни шатакка
        олган транспорт воситасининг ҳақиқий вазнининг ярмидан ортиқ бўлса
        (ҳақиқий вазни кам бўлган бундай транспорт воситасини фақат қаттиқ
        улагич ёрдамида ёки қисман ортиш усули билан шатакка олиш бундан
        мустасно); <br />
        кажавасиз мотоцикллар билан, шунингдек, бундай мотоциклларни; <br />
        йўл яхмалак, сирпанчиқ бўлган ҳолларда егилувчан улагичда.
      </p>
    </div>
  )
}

export default Comp23
