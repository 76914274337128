import React from 'react'

const Comp18 = () => {
  return (
    <div className="fs-5">
      <h2>18. Aвтомагистралларда ҳаракатланиш</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          18.1
        </span>
        Aвтомагистралларда қуйидагилар тақиқланади: <br />
        пиёдалар, уй ҳайвонлари, от-аравалар, велосипедлар, мопедлар, тракторлар
        ва ўзи юрар машиналар, техник тавсифномасига ёки ҳолатига кўра тезлиги
        соатига 40 километрдан кам бўлган транспорт воситаларининг
        ҳаракатланиши; <br />
        рухсат етилган тўла вазни 3,5 тоннадан ортиқ бўлган юк автомобилларига
        биринчи ва иккинчи бўлаклардан бошқа бўлакларда ҳаракатланиши; <br />
        5.15 ёки 6.11 белгилар билан белгиланган махсус тўхтаб туриш
        майдончаларидан бошқа жойларда тўхташ, ажратувчи бўлакнинг технологик
        узилиш жойларига кириш ва қайрилиб олиш; <br />
        орқага ҳаракатланиш; <br />
        транспорт воситасини ўрганиш учун бошқариш.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          18.2
        </span>
        Қатнов қисмида мажбурий тўхтаганда ҳайдовчи транспорт воситасини
        Қоидаларнинг 9-боби талабларига мувофик белгилаши ва бундай ҳолатлар
        учун мўлжалланган бўлакка (қатнов қисмининг четини билдирувчи чизиқдан
        ўнг томонга) олиб чиқишнинг барча чораларини кўриши керак.
      </p>
    </div>
  )
}

export default Comp18
