import React from 'react'

const Comp9 = () => {
  return (
    <div className="fs-5">
      <h2>
        9. Огоҳлантурувчи ва фалокат ишоралари, фалокат сабабли тўхташ
        белгисининг қўлланилиши
      </h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.1
        </span>
        Қуйидагилар огоҳлантирувчи ишоралар ҳисобланади: <br />
        бурилишни кўрсатадиган милтилловчи чироқ ёки қўл билан бериладиган
        ишоралар; <br />
        товушли ишоралар; <br />
        фара чироқларининг ёқиб-ўчирилиши; <br />
        кундуз куни яқинни ёритувчи фара чироғининг ёқилиши.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.2
        </span>
        Ҳаракатланишни бошлашдан, қайта тизилишдан, бурилиш (қайрилиб олиш)дан
        ва тўхташдан олдин ҳайдовчи тегишли йўналишни билдирувчи бурилиш ёруғлик
        кўрсатгичи, улар бўлмаганда ёки бузуқ бўлганда, қўл билан ишоралар
        бериши шарт. <br />
        Чапга бурилиш (қайрилиб олиш)ни билдирувчи ишорага чап қўлни ёки
        тирсакдан тўгри бурчак остида букилган ўнг қўлни ёнга узатиш мос келади.{' '}
        <br />
        Ўнгга бурилишни билдирувчи ишорага ўнг қўлни ёки тирсакдан тўғри бурчак
        остида букилган чап қўлни ёнга узатиш мос келади.
        <br />
        Тўхташ ишораси чап ёки ўнг қўлни юқори кўтариб берилади.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.3
        </span>
        Бурилиш кўрсатгичи ёки қўл билан берилаётган ишора манёврни бажаришдан
        олдинроқ берилиши ва уни тугаллагач дарҳол тўхтатилиши лозим (қўл билан
        берилаётган ишорани манёвр қилишдан бевосита олдин тугаллаш мумкин).{' '}
        <br />
        Ишоралар бериш бошқа йўл ҳаракати қатнашчиларини чалғитмаслиги керак.{' '}
        <br />
        Огоҳлантирувчи ишора бериш ҳайдовчига олдин ўтиш ҳуқуқини бермайди ва
        уни зарурий еҳтиёт чораларини кўриш масъулиятидан озод қилмайди.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.4
        </span>
        Ҳайдовчи ўзидан орқада келаётган, қувиб ўтишни бошлаган ва бошқа
        транспорт воситаларига ҳалақит бермаслигига ишонч ҳосил қилгандан
        кейингина, ҳаракатланиш йўналишини ўзгартириш ҳақида огоҳлантирувчи
        ишора бериши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.5
        </span>
        Товушли ишоралар фақат қуйидаги ҳолларда қўлланилиши мумкин: <br />
        аҳоли яшайдиган жойлардан ташқарида бошқа ҳайдовчиларни қувиб ўтиш
        ҳақида огоҳлантириш учун; <br />
        зарур бўлган ҳолларда йўл-транспорт ҳодисасининг олдини олиш учун.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.6
        </span>
        Қувиб ўтиш ҳақида товушли ишора ўрнига ёки у билан бирга фара
        чироқларини ёқиб-ўчириб огоҳлантириш ишорасини бериш мумкин.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.7
        </span>
        Бурилишни кўрсатувчи барча ёруғлик ишораларининг бир вақтда милтиллаши
        фалокат ишоралари бўлиб ҳисобланади. <br />
        Фалокат ёруғлик ишоралари қуйидаги ҳолларда ёқилиши керак: <br />
        йўл-транспорт ҳодисаси содир бўлганда; <br />
        тўхташ тақиқланган жойларда мажбурий тўхталганда; <br />
        фара чироқлари ҳайдовчининг кўзини қамаштирганда; <br />
        шатакка олишда (шатакка олинган транспорт воситасида). <br />
        Ҳайдовчи йўл ҳаракати қатнашчиларини, ўзи бошқараётган транспорт
        воситаси ҳавф туғдириши мумкин бўлган бошқа ҳолларда ҳам огоҳлантириш
        учун фалокат ёруғлик ишораларини ёқиши керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.8
        </span>
        Қуйидаги ҳолларда фалокат ёруғлик ишоралари ёқилгандан кейин, шунингдек,
        у бўлмаганда ёки бузуқ бўлганда, фалокат сабабли тўхташ белгиси
        (милтилловчи қизил чироқ) дарҳол ўрнатилиши керак: <br />
        йўл-транспорт ҳодисаси содир бўлганда; <br />
        тақиқланган ва кўриниш шароитини ҳисобга олган ҳолда транспорт воситаси
        бошқа ҳайдовчилар томонидан ўз вақтида пайқаб бўлмайдиган жойларда
        мажбурий тўхтаб қолганда. <br />
        Бундай белги (чироқ) муайян вазиятда ҳавф ҳақида ҳайдовчиларни ўз
        вақтида огоҳлантиришни таъминлайдиган масофада ўрнатилиши керак. Лекин
        бу масофа аҳоли яшайдиган жойларда транспорт воситасидан 15 метрдан,
        улардан ташқарида еса 30 метрдан кам бўлмаслиги керак.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.9
        </span>
        Шатакка олинган транспорт воситасида фалокат ёруғлик ишоралари бузуқ
        бўлганда ёки бўлмаган ҳолларда унинг орқа томонига фалокат сабабли
        тўхташ белгиси ўрнатилиши керак.
      </p>
    </div>
  )
}

export default Comp9
