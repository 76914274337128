import React from 'react'

const Comp6 = () => {
  return (
    <div className='fs-5'>
      <h2>6. Yo'lovchilarning vazifasi</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          6.1
        </span>
        Yo’lovchilar transport vositasiga faqat u to’la to’xtagandan so’ng
        trotuar yoki yo’l yoqasi tomonidan chiqishga va tushishga, mototsiklda
        harakatlanganda esa maxsus bosh kiyimini tugmalashga majburdirlar.{' '}
        <br />
        Yo’lovchilarning transport vositalaridan trotuar yoki yo’l yoqasi
        tomonidan tushishi yoki ularga chiqishining iloji bo’lmagan hollarda
        qatnov qismida amalga oshirilishi mumkin, bunda boshqa yo’l harakati
        qatnashchilariga halaqit berilmasligi va havf tug’dirilmasligi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          6.2
        </span>
        Yo’lovchilarga: <br />
        transport vositasi harakatlanayotgan vaqtda haydovchini boshqarishdan
        chalg’itish va unga halaqit berish; bortli yuk avtomobillarida tik
        turish, bortlar sathidagi yoki undan yuqoridagi yuk ustida o'tirish;{' '}
        <br />
        transport vositasi harakatlanayotgan vaqtda uning eshiklarini ochish
        taqiqlanadi
      </p>
    </div>
  )
}

export default Comp6
