/** @format */

import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import exit from "./../../images/svg/exit.svg";
import { AdMobPlus, BannerAd, InterstitialAd } from "@admob-plus/capacitor";

const Shablonlar = () => {
  const history = useHistory();
  const lang = localStorage.getItem("lang");
  const shablon = localStorage.getItem("shablon");
  const [tests, setTests] = useState([]) as any; 
  const [local, setLocal] = useState([]) as any;
  useEffect(() =>
  {
    let banner:any;
    (async () =>
    {
      banner = new BannerAd({
        adUnitId: 'ca-app-pub-2110563711012419/7321534432',
      })
      await banner.show()
      //  ca-app-pub-3940256099942544/6300978111
  
    })();
    (async () => {
  await AdMobPlus.start()

  const interstitial = new InterstitialAd({
    adUnitId: 'ca-app-pub-2110563711012419/1927421731',
  })
  await interstitial.load()
  await interstitial.show()
})()
    return () =>
    {
      (async () =>
    {
        await banner.hide();
      //  ca-app-pub-3940256099942544/6300978111
    })();
    }
  },[])
  useEffect(() =>
  {
    if(shablon)
    {
      setLocal(JSON.parse(shablon))
      
    }
    const getTests = async () => {
      const result = await axios.get(
        `${process.env.PUBLIC_URL}/data/uzlotin.json`
      );
      const num = Math.floor(result.data.length / 10);
      const temparr = [];
      for (let i = 0; i < num; i++) {
        temparr[i] = i;
      }
      setTests(temparr);
    };
    getTests();
  }, []);
  const exitGame = () => {
    history.push("/");
  };
  const decideLang = () =>
  {
    if (lang === "uzlotin")
    {
      return "Kuting..."
    }
    if (lang === "uzkiril")
    {
      return "Кутинг...";
    }
    if (lang === "rus")
    {
      return "Подождите ...";
    }
  }
  const decidebg = (temp:any) =>
  {
    if (temp.value)
    {
      let t = 0;
      for (let i = 0; i < temp.arr.length; i++)
      {
        if (temp.arr[i].bg === "bg-success")
        {
          t++;
        }
      }      
      if (t === 10)
      {
        return true
      }
      return false;
    } else
    {
      return false;
    }
  }
  const errorNum = (temp:any) =>
  {
    let t = 0;
      for (let i = 0; i < temp.arr.length; i++)
      {
        if (temp.arr[i].bg === "bg-success")
        {
          t++;
        }
    }
    return t;
  }
  return (
    <div>
      <div className='container'>
        <div className='my-3'>
          <button onClick={exitGame} className='btn btn-primary'>
            <img src={exit} alt='exit game' />
          </button>
        </div>
        {tests.length ? (
          <div className='row'>
            {tests.map((el: any, i: any) => (
              <Link
                to={`/learntests/${i + 1}`}
                key={i + "-id"}
                className='col-sm-4 p-2 bg-dark'>
                <div className={local.length?decidebg(local[i])?"bg-success":"bg-primary":"bg-primary"}>
                  <div className='card-body text-white'>
                    <h4>
                      {i + 1}-{lang === "uzlotin" ? "Shablon" : "Шаблон"}
                    </h4>
                    <p className='fs-4'>{ local.length?errorNum(local[i]):"0"}/10</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          `${decideLang()}`
        )}
      </div>
    </div>
  );
};

export default Shablonlar;
