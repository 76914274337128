import React from 'react'

const Comp5 = () => {
  const roadsigns = [
    {
      title: '5.1. Avtomagistral',
      description:
        'Yo’l harakati qoidalarining avtomagistrallarda harakatlanish tartibi o’rnatilgan yo’l.',
      img: '1',
    },
    {
      title: '5.2. Avtomagistralning oxiri',
      description: '',
      img: '2',
    },
    {
      title: '5.3. Avtomobillar uchun mo’ljallangan yo’l',
      description:
        'Faqat avtomobillar, avtobus va mototsikllar harakatlanishi uchun mo’ljallangan yo’l.',
      img: '3',
    },
    {
      title: '5.4. Avtomobillar uchun mo’ljallangan yo’lning oxiri',
      description: '',
      img: '4',
    },
    {
      title: '5.5. Bir tomonlama harakatlanish yo’li',
      description:
        'Transport vositalarining butun kenglik bo’yicha bir yo’nalishda harakatlanadigan yo’li yoki yo’l qismi.',
      img: '5',
    },
    {
      title: '5.6. Bir tomonlama harakatlanish yo’lining oxiri',
      description: '',
      img: '6',
    },
    {
      title: '5.7. Bir tomonlama harakatlanish yo’liga chiqish',
      description:
        'Harakatlanish bir tomonlama bo’lgan yo’lga yoki qatnov qismiga chiqish.',
      img: '7',
    },
    {
      title: '5.8.1. Avtomagistral',
      description:
        'Yo’l harakati qoidalarining avtomagistrallarda harakatlanish tartibi o’rnatilgan yo’l.',
      img: '8',
    },
    {
      title: '5.8.2. Bo’lak bo’yicha harakatlanish yo’nalishi',
      description:
        'Bo’lak bo’yicha ruxsat etilgan harakatlanish yo’nalishi. Yo’lning chetki chap bo’lagidan chapga burilishga ruxsat beruvchi 5.8.1 va 5.8.2 belgilari shu bo’lakdan qayrilishga ham ruxsat beradi. Chorrahadan oldin o’rnatilgan 5.8.1 va 5.8.2 belgilarining ta’siri, agar shu turkumdagi boshqa belgilar o’rnatilmagan bo’lsa, butun chorrahaga tadbiq etiladi.',
      img: '9',
    },
    {
      title: '5.8.3. Bo’lakning boshlanishi',
      description:
        'Yo’lning balandlikka ko’tarilish yoki to’xtash qismidagi qo’shimcha bo’lagining boshlanish joyi. Agar qo’shimcha bo’lak oldida 4.7 Eng kam tezlik belgisi o’rnatilgan bo’lsa, ko’rsatilgan yoki undan kattaroq tezlikda harakatlana olmaydigan transport vositasining haydovchisi asosiy bo’lakdan qo’shimcha bo’lakka qayta tizilishi kerak.',
      img: '10',
    },
    {
      title: '5.8.4. Bo’lakning boshlanishi',
      description:
        'Uch bo’lakli yo’llarda harakatlanish uchun o’rta bo’lakning boshlanishi.',
      img: '11',
    },
    {
      title: '5.8.5. Bo’lak oxiri',
      description:
        'Balandlikka ko’tarilish yoki to’xtash qismidagi qo’shimcha bo’lakning oxiri.',
      img: '12',
    },
    {
      title: '5.8.6. Bo’lak oxiri',
      description:
        'Uch bo’lakli yo’lda harakatlanish uchun mo’ljallangan o’rta bo’lakning oxiri.',
      img: '13',
    },
    {
      title: '5.8.7. Bo’laklar bo’yicha harakatlanish yo’nalishi',
      description: '',
      img: '14',
    },
    {
      title: '5.8.8. Bo’laklar bo’yicha harakatlanish yo’nalishi',
      description:
        'Agar 5.8.7 belgisida biror transport vositasining harakatlanishini taqiqlovchi belgi tasvirlangan bo’lsa, unda bu transport vositasining tegishli bo’lakda harakatlanishi taqiqlanadi. 5.8.7 va 5.8.8 belgilari yo’nalishlar soniga ko’ra to’rt va undan ortiq bo’lakli yo’llarga ham tadbiq etilishi mumkin. Tasviri almashilgan 5.8.7, 5.8.8 belgilari yordamida reversiv harakat tashkil etilishi mumkin.',
      img: '15',
    },
    {
      title:
        '5.9. Belgilangan yo’nalishli transport vositalari uchun mo’ljallangan bo’lak',
      description:
        'Transport vositalari yo’nalishida harakatlanadigan belgilangan yo’nalishli transport vositalari uchun mo’ljallangan bo’lak. Belgi qaysi bo’lak ustida joylashgan bo’lsa, shu bo’lakka tatbiq etiladi. Yolning o’ng tomoniga o’rnatilgan belgining ta’siri o’ng bo’lakka tatbiq etiladi.',
      img: '16',
    },
    {
      title:
        '5.10.1. Belgilangan yo’nalishli transport vositalari uchun bo’lagi bor yo’l',
      description:
        'Belgilangan yo’nalishli transport vositalarining umumiy oqimga qarshi harakatlanishi uchun ajratilgan bo’lak.',
      img: '17',
    },
    {
      title:
        '5.10.2, 5.10.3 Belgilangan yo’nalishli transport vositalari uchun bo’lagi bor yo’l',
      description:
        'Belgilangan yo’nalishli transport vositalarining umumiy oqimga qarshi harakatlanishi uchun ajratilgan bo’lak.',
      img: '18',
    },
    {
      title:
        '5.10.4. Belgilangan yo’nalishli transport vositalari uchun bo’lagi bor yo’lning oxiri',
      description: '',
      img: '19',
    },
    {
      title: '5.11.1. Qayirilish joyi',
      description: 'Chapga burilish taqiqlanadi',
      img: '20',
    },
    {
      title: '5.11.2. Qayirilish oralig’i',
      description:
        'Qayirilish oralig’ining uzunligi. Chapga burilish taqiqlanadi.',
      img: '21',
    },
    {
      title: '5.12. Avtobus va trolleybus to’xtash joyi',
      description: '',
      img: '22',
    },
    {
      title: '5.13. Tramvay to’xtash joyi',
      description: '',
      img: '23',
    },
    {
      title: '5.14. Taksi to’xtab turish joyi',
      description: '',
      img: '24',
    },
    {
      title: '5.15. To’xtab turish joyi',
      description: '',
      img: '25',
    },
    {
      title: '5.16. Piyodalar o’tish joyi',
      description:
        'O’tish joyi oldida 1.14.1–1.14.3 yotiq chizig’i bo’lmaganda 5.16.2 belgisi yo’ldan o’ng tomonga o’tish joyining old chegarasiga, 5.16.1 belgisi esa chap tomon o’tish joyining orqa chegarasiga o’rnatiladi.',
      img: '26',
    },
    {
      title: '5.17.1, 5.17.2 Piyodalarning er ostidan o’tish joyi',
      description: '',
      img: '27',
    },
    {
      title: '5.17.3, 5.17.4. Piyodalarning er ustidan o’tish joyi',
      description: '',
      img: '28',
    },
    {
      title: '5.18 Tavsiya etilgan tezlik',
      description:
        'Yo’lning shu qismida tavsiya etilgan harakatlanish tezligi. Belgiga eng yaqin chorrahagacha amal qilinadi. 5.18 belgisi ogohlantiruvchi belgi bilan birga qo’llansa, uning ta’sir oralig’i xavfli qismning uzunligi bilan aniqlanadi.',
      img: '29',
    },
    {
      title: '5.19.1, 5.19.2, 5.19.3. Oxiri berk yo’l, ko’cha',
      description: 'Yo’l yoki ko’chaning ko’rsatilgan yo’nalishda oxiri berk.',
      img: '30',
    },
    {
      title: '5.20. Yo’nalishlarning dastlabki ko’rsatkichi',
      description:
        'Belgida ko’rsatilgan aholi yashaydigan joyga va boshqa manzillarga tomon harakatlanish yo’nalishi. Belgida 5.29.1 belgisining hamda avtomagistral, aeroport va boshqa ramziy belgilar tasviri tushirilgan bo’lishi mumkin. 5.20.1 belgisi yo’lning 3.11-3.15 taqiqlovchi belgilaridan biri o’rnatilgan qismlarini aylanib o’tish kerakligini ko’rsatish uchun ham qo’llaniladi.',
      img: '31',
    },
    {
      title: '5.20.3. Harakatlanish tasviri',
      description:
        'Chorrahada ayrim harakatlar taqiqlangan vaqtda harakatlanish yo’nalishi yoki serqatnov chorrahada ruxsat etilgan harakatlanish yo’nalishi.',
      img: '32',
    },
    {
      title: '5.21.1. Yo’nalish ko’rsatkichi',
      description: '',
      img: '33',
    },
    {
      title: '5.21.2. Yo’nalishlar ko’rsatkichi',
      description:
        'Manzillar tomon harakatlanish yo’nalishlari. Belgilarda manzillargacha bo’lgan masofa (km) ko’rsatilishi, avtomagistrallar, aeroportlar va boshqa ramziy belgilar tasvirlangan bo’lishi mumkin.',
      img: '34',
    },
    {
      title: '5.22. Aholi yashaydigan joyning boshlanishi',
      description:
        'Ushbu Qoidalarning aholi yashaydigan joylarda harakatlanish tartibini belgilovchi talablariga amal qilinadigan joylarning nomi hamda uning boshlanishi.',
      img: '35',
    },
    {
      title: '5.23. Aholi yashaydigan joyning oxiri',
      description:
        'Qoidalarning aholi yashaydigan joylarda harakatlanish tartibi talablari bekor qilingan joy.',
      img: '36',
    },
    {
      title: '5.24. Aholi yashaydigan joyning boshlanishi',
      description:
        'Ushbu Qoidalarning aholi yashaydigan joylarda harakatlanish tartibini belgilovchi talabraliga amal qilinmaydigan joyning nomi hamda uning boshlanishi.',
      img: '37',
    },
    {
      title: '5.25. Aholi yashaydigan joyning oxiri',
      description:
        '5.24 belgisi bilan ko’rsatilgan aholi yashaydigan joyning ohiri.',
      img: '38',
    },
    {
      title: '5.26. Manzil nomi',
      description:
        'Aholi yashamaydigan joylar (daryo, ko’l, dovon, xushmanzara joy va h.k) ning nomi.',
      img: '39',
    },
    {
      title: '5.27. Masofalar ko’rsatkichi',
      description:
        'Yo’nalish boyicha joylashgan aholi yashaydigan joylargacha bo’lgan masofa (km).',
      img: '40',
    },
    {
      title: '5.28. Kilometr belgisi',
      description: 'Yo’lning boshi yoki oxirigacha bo’lgan masofa',
      img: '41',
    },
    {
      title: '5.29.1, 5.29.2. Yo’l raqami',
      description:
        '5.29.1 – yo’lga berilgan raqam, 5.29.2 – yo’lning raqami va yo’nalishi.',
      img: '42',
    },
    {
      title: '5.30. Yuk avtomobillari uchun harakatlanish yo’nalishi',
      description:
        'Chorrahada yuk avtomobillari, traktorlar va o’ziyurar moslamalarga biror yo’nalishda harakatlanish taqiqlangan bo’lsa, bunday transport vositalariga tavsiya etilgan harakatlanish yo’nalishlari.',
      img: '43',
    },
    {
      title: '5.31. Aylanib o’tish tasviri',
      description:
        'Harakatlanish uchun vaqtincha yopib qo’yilgan yo’l qismini chetlab o’tish chizmasi.',
      img: '44',
    },
    {
      title: '5.32. Chetlab o’tish yo’nalishi',
      description:
        'Harakatlanish uchun vaqtincha yopib qo’yilgan yo’l qismini chetlab o’tish yo’nalishi.',
      img: '45',
    },
    {
      title: '5.33. To’xtash',
      description:
        'Svetoforning (tartibga soluvchining) taqiqlovchi ishorasida transport vositalari to’xtaydigan joy.',
      img: '46',
    },
    {
      title:
        '5.34.1, 5.34.1. Boshqa qatnov qismiga qayta tizilishning boshlang’ich ko’rsatkichi',
      description:
        'Ajratuvchi bo’lagi bo’lgan yo’lning harakatlanish uchun yopiq hududini aylanib o’tish yoki ong tomondagi qatnov qismiga qaytish uchun harakatlanish yo’nalishi.',
      img: '47',
    },
    {
      title: '5.35. Reversiv harakatlanish',
      description:
        'Bir yoki bir necha bo’laklarda harakatlanish yo’nalishi qarama-qarshi tomonga o’zgarishi mumkin bo’lgan yo’l qismining boshlanishi.',
      img: '48',
    },
    {
      title: '5.36. Reversiv harakatlanish oxiri',
      description: '',
      img: '49',
    },
    {
      title: '5.37. Reversiv harakatlanish yo’liga chiqish',
      description: '',
      img: '50',
    },
    {
      title: '5.38. Turar-joy dahasi',
      description:
        'Yo’l harakati qoidalarining turar-joy dahalarida harakatlanish tartibi o’rnatilgan yo’l.',
      img: '51',
    },
    {
      title: '5.39. Turar-joy dahasining oxiri',
      description: '',
      img: '52',
    },
    {
      title: '5.40. Falokatli holatlar uchun kirish yo’li',
      description:
        'Yo’l harakati qoidalaridagi 20.2 bandda ko’rsatilgan hollarda foydalanadigan yo’l.',
      img: '53',
    },
  ]
  const imgSize = (sign: any) => {
    if (
      +sign.img === 9 ||
      +sign.img === 10 ||
      +sign.img === 14 ||
      +sign.img === 18 ||
      +sign.img === 26 ||
      +sign.img === 27 ||
      +sign.img === 28 ||
      +sign.img === 30 ||
      +sign.img === 31 ||
      +sign.img === 43 ||
      +sign.img === 45
    ) {
      return (
        <img
          src={`${process.env.PUBLIC_URL}/images/belgilar/axborot/${sign.img}.png`}
          alt={sign.description}
          className="big-img mx-auto d-block"
        />
      )
    } else {
      return (
        <img
          src={`${process.env.PUBLIC_URL}/images/belgilar/axborot/${sign.img}.png`}
          alt={sign.description}
          className="small-img d-block mx-auto"
        />
      )
    }
  }
  return (
    <div>
      <h2>Axborot-ishora belgilari</h2>
      <p className='fs-4'>
        Axborot-ishora belgilari harakat qatnashchilariga yo'ldagi harakat
        tartibi xususiyatlari hamda aholi yashaydigan joylar va manzillarning
        joylashuvi haqida ma'lumot berish uchun qo'llaniladi.
      </p>
      <div>
        {roadsigns.map((sign: any) => (
          <div key={sign.img}>
            <h3 className="text-center">{sign.title}</h3>
            <p className='fs-4'>{sign.description}</p>
            {imgSize(sign)}
            <hr />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Comp5
