import React from 'react'

const Comp22 = () => {
  return (
    <div className='fs-5'>
      <h2>22. Tashqi yoritish asboblaridan foydalanish</h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          22.1
        </span>
        Qorong’i vaqtda va etarlicha ko’rinmaydigan sharoitda, shuningdek,
        tonnellarda harakatlanayotgan barcha transport vositalari: <br />
        yo’lning yoritilmagan qismida uzoqni yoki yaqinni yorituvchi faralarini;{' '}
        <br />
        yo’lning yoritilgan qismida yaqinni yorituvchi faralarini; <br />
        yo’lning yoritilganligidan qat’i nazar, tirkamalarning gabarit
        chiroqlarini yokishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          22.2
        </span>
        Quyidagi hollarda uzoqni yorituvchi fara yaqinni yorituvchi fara bilan
        almashtirilishi kerak: <br />
        aholi yashaydigan joylarda yo’l yoritilgan bo’lsa; <br />
        qarama-qarshi yo’nalishdagi transport vositasiga 150 metrdan kamroq
        masofa qolganda, shuningdek, haydovchi transport vositasining
        chiroqlarini vaqti-vaqti bilan o’chirib bunga zaruriyat borligini
        bildirgan hollarda undan ham ko’prok masofada; <br />
        qarama-qarshi va bir yo’nalishdagi haydovchilarning ko’zini
        qamashtirishi mumkin bo’lgan boshqa barcha hollarda. <br />
        Ko’zi qamashgan haydovchi falokat yorug’lik ishoralarini yoqishi,
        harakatlanish bo’lagini o’zgartirmasdan tezlikni kamaytirishi va
        to’xtashi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          22.3
        </span>
        Qorong’i vaqtda yo’lning yoritilmagan qismida, shuningdek, etarlicha
        ko’rinmaydigan sharoitda to’xtagan yoki to’xtab turgan transport
        vositasida gabarit chiroqlari yoqilgan bo’lishi kerak. <br />
        Etarlicha ko’rinmaydigan sharoitda gabarit chiroqlariga qo’shimcha qilib
        yaqinni yorituvchi faralar, tumanga qarshi faralar va tumanga qarshi
        orqa chiroqlar yoqilishi mumkin.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          22.4
        </span>
        Quyidagi hollarda tumanga qarshi faralarni qo’llash mumkin: <br />
        etarlicha ko’rinmaydigan sharoitda alohida, shuningdek uzoqni yorituvchi
        yoki yaqinni yorituvchi faralar bilan; qoidalarning 22.5 bandida ko’zda
        tutilgan hollarda yaqinni yorituvchi fara o’rnida.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          22.5
        </span>
        Kunning yorug’ vaqtida quyidagi hollarda yaqinni yorituvchi faralar
        yoqilishi kerak: <br />
        transport vositalarining tashkiliy jamlanmasi safida
        harakatlanayotganda; <br />
        umumiy harakat oqimiga qarshi yo’nalishda maxsus ajratilgan bo’lakda
        harakatlanayotgan belgilangan yo’nalishdagi transport vositalarida;{' '}
        <br />
        avtobus va yuk avtomobillarida bolalar guruhini tashkiliy tashishda;{' '}
        <br />
        havfli, katta o’lchamli va og’ir vaznli yuklarni tashishda; <br />
        mexanik transport vositalarini shatakka olishda (shatakka olib
        ketayotgan transport vositasida).
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          22.6
        </span>
        Fara-prozhektor va izlovchi faralardan faqat aholi yashaidigan joylardan
        tashqarida qarama-qarshi yo’nalishdan kelayotgan transport vositalari
        bo’lmaganda foydalanish mumkin. <br />
        Aholi yashaidigan joylarda esa bunday faralardan faqat xizmat
        vazifalarini bajarayotgan tezkor va maxsus xizmat transport
        vositalarining haydovchilariga foydalanishga ruxsat etiladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          22.7
        </span>
        Tumanga qarshi orqa chiroqlarni faqat etarlicha ko’rinmaydigan sharoitda
        qo’llash mumkin. Orqadagi to’xtash chiroqlari (stop-signal)ga tumanga
        qarshi orqa chiroqlarni ulash taqiqlanadi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          22.8
        </span>
        “Avtopoezd” taniqliq belgisi avtopoezd harakatlanayotganda, kunning
        qorong’i vaqtida esa yo’lda to’xtash va to’xtab turish paytida ham yoqib
        qo’yilishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          22.9
        </span>
        Zarg’aldok yoki sariq rangli miltillovchi chiroq-mayoqcha yo’lda
        qurilish, ta’mirlash yoki tozalash ishlari olib borayotgan, shuningdek,
        og’ir vaznli, katta o’lchamli yoki havfli yuklarni tashish qoidalarida
        ko’zda tutilgan hollarda ularni tashiyotgan transport vositalarida
        yokilishi kerak.
      </p>
    </div>
  )
}

export default Comp22
