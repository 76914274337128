import React from 'react'

const Comp9 = () => {
  return (
    <div className='fs-5'>
      <h2>
        9. Ogohlanturuvchi va falokat ishoralari, falokat sababli to'xtash
        belgisining qo'llanilishi
      </h2>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.1
        </span>
        Quyidagilar ogohlantiruvchi ishoralar hisoblanadi: <br />
        burilishni ko’rsatadigan miltillovchi chiroq yoki qo’l bilan beriladigan
        ishoralar; <br />
        tovushli ishoralar; <br />
        fara chiroqlarining yoqib-o’chirilishi; <br />
        kunduz kuni yaqinni yorituvchi fara chirog’ining yoqilishi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.2
        </span>
        Harakatlanishni boshlashdan, qayta tizilishdan, burilish (qayrilib
        olish)dan va to’xtashdan oldin haydovchi tegishli yo’nalishni
        bildiruvchi burilish yorug’lik ko’rsatgichi, ular bo’lmaganda yoki buzuq
        bo’lganda, qo’l bilan ishoralar berishi shart. <br />
        Chapga burilish (qayrilib olish)ni bildiruvchi ishoraga chap qo’lni yoki
        tirsakdan to’gri burchak ostida bukilgan o’ng qo’lni yonga uzatish mos
        keladi. <br />
        O’ngga burilishni bildiruvchi ishoraga o’ng qo’lni yoki tirsakdan
        to’g’ri burchak ostida bukilgan chap qo’lni yonga uzatish mos keladi.{' '}
        <br />
        To’xtash ishorasi chap yoki o’ng qo’lni yuqori ko’tarib beriladi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.3
        </span>
        Burilish ko’rsatgichi yoki qo’l bilan berilayotgan ishora manyovrni
        bajarishdan oldinroq berilishi va uni tugallagach darhol to’xtatilishi
        lozim (qo’l bilan berilayotgan ishorani manyovr qilishdan bevosita oldin
        tugallash mumkin). <br />
        Ishoralar berish boshqa yo’l harakati qatnashchilarini chalg’itmasligi
        kerak. <br />
        Ogohlantiruvchi ishora berish haydovchiga oldin o’tish huquqini bermaydi
        va uni zaruriy ehtiyot choralarini ko’rish mas’uliyatidan ozod qilmaydi.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.4
        </span>
        Haydovchi o’zidan orqada kelayotgan, quvib o’tishni boshlagan va boshqa
        transport vositalariga halaqit bermasligiga ishonch hosil qilgandan
        keyingina, harakatlanish yo’nalishini o’zgartirish haqida
        ogohlantiruvchi ishora berishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.5
        </span>
        Tovushli ishoralar faqat quyidagi hollarda qo’llanilishi mumkin: <br />
        aholi yashaydigan joylardan tashqarida boshqa haydovchilarni quvib
        o’tish haqida ogohlantirish uchun; <br />
        zarur bo’lgan hollarda yo’l-transport hodisasining oldini olish uchun.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.6
        </span>
        Quvib o’tish haqida tovushli ishora o’rniga yoki u bilan birga fara
        chiroqlarini yoqib-o’chirib ogohlantirish ishorasini berish mumkin.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.7
        </span>
        Burilishni ko’rsatuvchi barcha yorug’lik ishoralarining bir vaqtda
        miltillashi falokat ishoralari bo’lib hisoblanadi. <br />
        Falokat yorug’lik ishoralari quyidagi hollarda yoqilishi kerak: <br />
        yo’l-transport hodisasi sodir bo’lganda; <br />
        to’xtash taqiqlangan joylarda majburiy to’xtalganda; <br />
        fara chiroqlari haydovchining ko’zini qamashtirganda; <br />
        shatakka olishda (shatakka olingan transport vositasida). <br />
        Haydovchi yo’l harakati qatnashchilarini, o’zi boshqarayotgan transport
        vositasi havf tug’dirishi mumkin bo’lgan boshqa hollarda ham
        ogohlantirish uchun falokat yorug’lik ishoralarini yoqishi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.8
        </span>
        Quyidagi hollarda falokat yorug’lik ishoralari yoqilgandan keyin,
        shuningdek, u bo’lmaganda yoki buzuq bo’lganda, falokat sababli to’xtash
        belgisi (miltillovchi qizil chiroq) darhol o’rnatilishi kerak: <br />
        yo’l-transport hodisasi sodir bo’lganda; <br />
        taqiqlangan va ko’rinish sharoitini hisobga olgan holda transport
        vositasi boshqa haydovchilar tomonidan o’z vaqtida payqab bo’lmaydigan
        joylarda majburiy to’xtab qolganda. <br />
        Bunday belgi (chiroq) muayyan vaziyatda havf haqida haydovchilarni o’z
        vaqtida ogohlantirishni ta’minlaydigan masofada o’rnatilishi kerak.
        Lekin bu masofa aholi yashaydigan joylarda transport vositasidan 15
        metrdan, ulardan tashqarida esa 30 metrdan kam bo’lmasligi kerak.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }} className="mx-2">
          9.9
        </span>
        Shatakka olingan transport vositasida falokat yorug’lik ishoralari buzuq
        bo’lganda yoki bo’lmagan hollarda uning orqa tomoniga falokat sababli
        to’xtash belgisi o’rnatilishi kerak.
      </p>
    </div>
  )
}

export default Comp9
